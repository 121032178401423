import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, UserSelectField } from 'components'
import {
  Button,
  TextBlock,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { DEPRECATED_useRequest } from 'hooks'
import { useCurrent } from 'contexts/index'
import { putListFolder } from 'requests/lists'
import styles from './FolderShareModal.module.scss'

const FolderShareModal = ({ folder, isOpen, closeModal }) => {
  const { t } = useTranslation()
  const [users, setUsers] = useState(folder.sharees)

  const { currentUser } = useCurrent()

  const { makeRequest, isLoading, hasErrors } = DEPRECATED_useRequest(
    putListFolder,
    { onSuccess: closeModal }
  )

  const shareFolder = () => {
    makeRequest(folder.id, {
      sharee_ids: users.map(s => s.id),
    })
  }

  return (
    <Modal
      id="share-folder"
      title={t(`Share folder`)}
      isOpen={isOpen}
      showLoading={isLoading}
      className={styles.modal}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError
            hide={!hasErrors}
            message={t('There was an error sharing your folder')}
            hideSupportLink
          />
          <TextBlock className={styles.text}>
            {t(
              'Sharing this folder will give other users access to viewing your lists. Other users will not be able to make changes to your list.'
            )}
          </TextBlock>
          <div className={styles.field}>
            <UserSelectField
              label={t('Shared with')}
              isMulti
              onSelect={setUsers}
              users={users}
              filters={[
                {
                  column: 'id',
                  operator: 'is',
                  invert: 'true',
                  param: currentUser.id,
                },
              ]}
              clearable
              usePortal
            />
          </div>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={closeModal}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent onClick={shareFolder}>{t('Share')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default FolderShareModal
