import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchTurfLevels = (params = {}) =>
  fetchJSON(`/api/v1/turf_levels?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const postTurfLevel = turf_level =>
  fetchJSON(`/api/v1/turf_levels`, 'POST', { turf_level }, { useJwt: true })

export const putTurfLevel = (id, turf_level) =>
  fetchJSON(
    `/api/v1/turf_levels/${id}`,
    'PUT',
    { turf_level },
    {
      useJwt: true,
    }
  )

export const destroyTurfLevel = id =>
  fetchJSON(`/api/v1/turf_levels/${id}`, 'DELETE', null, {
    useJwt: true,
  })
