import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { View, ViewContainer, HeaderBlock } from 'components'
import { QueryParamProvider } from 'contexts'
import FilterControls from './FilterControls'
import CanvasserHighlights from './reports/CanvasserHighlights'
import CanvasserReport from './reports/CanvasserReport'
import TurfStats from './reports/TurfStats'
import UploadTiming from './reports/UploadTiming'
import CanvassersNewReview from './reports/CanvassersNewReview'
import { WeeklyReportProvider } from './WeeklyReportContext'

const WeeklyReports = () => (
  <View>
    <HeaderBlock title="Weekly QC Reports">
      <ButtonBlock justify="right">
        <a
          target="_blank"
          href="https://docs.google.com/spreadsheets/d/1XPuCIKdLLNOOEw5KmdFdFvFFIYwJDU4wXv2EEydV40E/edit#gid=0"
          rel="noreferrer"
        >
          <Button.Accent>Data Dictionary</Button.Accent>
        </a>
      </ButtonBlock>
    </HeaderBlock>
    <WeeklyReportProvider>
      <ViewContainer className="weekly-reports">
        <FilterControls />
        <div className="weekly-reports__container">
          <section className="weekly-reports__section--full-width">
            <TurfStats className="weekly-reports__turf-stats" />
          </section>
          <section className="weekly-reports__section--full-width">
            <CanvassersNewReview className="weekly-reports__canvassers-new" />
          </section>
          <section className="weekly-reports__section">
            <CanvasserHighlights className="weekly-reports__canvasser-highlights" />
          </section>
          <section className="weekly-reports__section">
            <UploadTiming className="weekly-reports__upload-timing" />
          </section>
          <section className="weekly-reports__section--full-width">
            <CanvasserReport className="weekly-reports__canvassers-report" />
          </section>
        </div>
      </ViewContainer>
    </WeeklyReportProvider>
  </View>
)

export default () => (
  <QueryParamProvider>
    <WeeklyReports />
  </QueryParamProvider>
)
