import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { CardError } from 'components'
import { Button, ContentBlock } from '@politechdev/blocks-design-system'
import { LegoHead as UserIcon } from 'components/uiKit/icons'
import { DEPRECATED_useRequest } from 'hooks'
import { formatDisplayName } from 'utils/formatting'
import { useCurrent } from 'contexts/index'
import styles from './UserAvatar.module.scss'

const cx = classNames.bind(styles)

const UserAvatar = () => {
  const { t } = useTranslation()
  const uploadRef = useRef()

  const { currentUser: user, updateCurrentUser } = useCurrent()

  const { makeRequest, isLoading, errorMsg } =
    DEPRECATED_useRequest(updateCurrentUser)

  const showUploadPrompt = () => {
    uploadRef.current && uploadRef.current.click()
  }

  const uploadAvatar = event => {
    const avatar = event.target?.files?.[0]
    if (!avatar) return

    makeRequest({ id: user.id, avatar }, true)
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      <ContentBlock className={styles.container}>
        <span className={styles.label}>{t('Profile image')}</span>
        <div className={styles.form}>
          <button
            type="button"
            className={cx('avatar', {
              'avatar--loading': isLoading,
              'avatar--default': !user.avatar,
            })}
            onClick={showUploadPrompt}
          >
            {user.avatar ? (
              <img
                src={user.avatar}
                alt={`${formatDisplayName(user)} avatar`}
              />
            ) : (
              <UserIcon />
            )}
          </button>
          <Button.Secondary onClick={showUploadPrompt}>
            {isLoading ? t('Uploading') : t('Change')}
          </Button.Secondary>
        </div>
      </ContentBlock>
      <input
        id="upload"
        type="file"
        ref={uploadRef}
        value={undefined}
        className={styles.input}
        onChange={uploadAvatar}
      />
    </>
  )
}

export default UserAvatar
