import React from 'react'
import { useSelector } from 'react-redux'
import {
  getMostRecentVisualReview,
  getMostRecentVisualReviewResponses,
  getFullCallHistory,
  getCurrentScan,
} from 'store/qcScans/reducer'
import { Section, DetailItem } from 'components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import CallHistoryList from '../CallHistoryList/CallHistoryList'

const ScanHistoryDetails = () => {
  const { t } = useTranslation()

  const mostRecentVisualReview = useSelector(getMostRecentVisualReview)
  const currentScan = useSelector(getCurrentScan)
  const callAttempts = useSelector(getFullCallHistory)
  const mostVisualReviewResponses = useSelector(
    getMostRecentVisualReviewResponses
  )

  return (
    <>
      {!!callAttempts.length && <CallHistoryList attempts={callAttempts} />}
      <Section label={t('Visual QC')}>
        <DetailItem label={t('Responses')}>
          {mostVisualReviewResponses?.length
            ? mostVisualReviewResponses.map(response => (
                <>
                  <span>{response}</span>
                  <br />
                </>
              ))
            : t('No response found')}
        </DetailItem>
        <DetailItem label={t('Created at')}>
          {(mostRecentVisualReview?.created_at &&
            moment(mostRecentVisualReview.created_at).format(dateFormat)) ||
            'No date found'}
        </DetailItem>
        <DetailItem label={t('Reviewed by')}>
          {mostRecentVisualReview?.user?.full_name ||
            t('No approving reviewer found')}
        </DetailItem>
        <DetailItem label={t('Visual QC Notes')}>
          {currentScan.notes || t('No notes found')}
        </DetailItem>
      </Section>
    </>
  )
}

export default ScanHistoryDetails
