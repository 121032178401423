import { useDispatch, useStore } from 'react-redux'
import useEvent from './useEvent'

export const useReduxAction = action => {
  const dispatch = useDispatch()
  const store = useStore()
  const reduxAction = useEvent((...args) => {
    const result = action(...args)
    return typeof result === 'function'
      ? result(dispatch, store.getState)
      : dispatch(result)
  })
  return reduxAction
}
