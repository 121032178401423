/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { View, ViewContainer, HeaderBlock, TurfSelectField } from 'components'
import {
  Sheet,
  Button,
  ButtonBlock,
  FieldBlock,
  TextBlock,
  Font,
  Icon,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import QuestionList from './QuestionList/QuestionList'
import NewQuestion from './NewQuestion/NewQuestion'
import DateControls from './DateControls/DateControls'
import DeleteCheckinButton from './DeleteCheckinButton/DeleteCheckinButton'
import { ManagementContext } from '../CheckinContext/ManagementContext'

const CheckinManagement = () => {
  const { t } = useTranslation()

  const {
    turf,
    turfOptions,
    setTurf,
    setAdminTurfId,
    currentAdminCheckin,
    newCheckinPreview,
    saveNewCheckIn,
    updateNewCheckin,
    adminIsLoading,
    currentTurfHasCheckin,
    message,
    addingQuestion,
    setAddingQuestion,
    newQuestion,
    setNewQuestion,
    addQuestion,
    currentTurfAncestorCheckin,
  } = useContext(ManagementContext)

  useEffect(() => {
    setTurf(turfOptions[0]?.id)
    setAdminTurfId(turfOptions[0]?.id)
  }, [])

  const [creatingNew, setCreatingNew] = useState(false)

  const checkinHasQuestions = () =>
    newCheckinPreview?.questions.filter(q => !q.archived).length > 0

  return (
    <View>
      <HeaderBlock title={t('Manage Check-ins')}>
        <ButtonBlock justify="right">
          <Link to="/check-ins">
            <Button.Secondary tooltipLabel={t('Remove')}>
              <Icon.Times />
            </Button.Secondary>
          </Link>
        </ButtonBlock>
      </HeaderBlock>
      <FieldBlock>
        <TurfSelectField
          id="turf"
          label={t('Turf')}
          value={turf}
          onSelect={val => {
            setCreatingNew(false)
            setTurf(val)
            setAdminTurfId(val)
          }}
        />
      </FieldBlock>
      <ViewContainer>
        <Sheet>
          {adminIsLoading ? (
            <ProgressBar show />
          ) : (currentAdminCheckin &&
              !currentAdminCheckin.archived &&
              currentTurfHasCheckin) ||
            creatingNew ? (
            <>
              <DateControls />
              <QuestionList />
              <NewQuestion
                addingQuestion={addingQuestion}
                setAddingQuestion={setAddingQuestion}
                newQuestion={newQuestion}
                setNewQuestion={setNewQuestion}
                addQuestion={addQuestion}
              />
              <ButtonBlock>
                <Button.Accent
                  disabled={
                    adminIsLoading || (!checkinHasQuestions() && !newQuestion)
                  }
                  onClick={() => {
                    !currentTurfHasCheckin
                      ? saveNewCheckIn()
                      : updateNewCheckin()
                  }}
                >
                  {t('Save check-in')}
                </Button.Accent>
                <DeleteCheckinButton
                  checkin={currentAdminCheckin}
                  setCreatingNew={setCreatingNew}
                />
                <Link to="/check-ins">
                  <Button.Secondary>{t('Cancel')}</Button.Secondary>
                </Link>
              </ButtonBlock>
              <TextBlock>
                <Font.Copy variant="hint">{t(message)}</Font.Copy>
              </TextBlock>
            </>
          ) : (
            <>
              <TextBlock>
                <Font.Copy Element="p">
                  {currentTurfAncestorCheckin
                    ? `${t(
                        'Using check-ins from'
                      )} ${currentTurfAncestorCheckin}`
                    : t('There are no check-ins on this turf')}
                </Font.Copy>
              </TextBlock>
              <ButtonBlock>
                <Button.Secondary onClick={() => setCreatingNew(true)}>
                  {t('Create Turf Check-in')}
                </Button.Secondary>
              </ButtonBlock>
            </>
          )}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default CheckinManagement
