import PetitionPacketSingle from 'petitions/PetitionPacketSingle/PetitionPacketSingle'
import { QualityControlProvider } from 'qualityControl/QualityControlContext'
import { useRoutePathParams } from 'hooks/router'
import { InboxProvider } from 'qualityControl/inbox/InboxContext'
import { useMemo } from 'react'
import { SHIFT_TYPE } from './constants'
import RegistrationPacketSingle from './RegistrationPacketSingle'

const ShiftPacket = props => {
  const [{ shiftType }] = useRoutePathParams()

  const PacketComponent = useMemo(() => {
    switch (shiftType) {
      case SHIFT_TYPE.PETITION:
        return PetitionPacketSingle
      case SHIFT_TYPE.REGISTRATION:
        return RegistrationPacketSingle
      default:
        return null
    }
  }, [shiftType])

  return <PacketComponent {...props} />
}

export default () => (
  <QualityControlProvider>
    <InboxProvider>
      <ShiftPacket />
    </InboxProvider>
  </QualityControlProvider>
)
