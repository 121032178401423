import React from 'react'
import classNames from 'classnames/bind'
import { Font } from '@politechdev/blocks-design-system'
import styles from './HeaderBlock.module.scss'

const cx = classNames.bind(styles)

const HeaderBlock = ({ title, subtitle, subtitle2, className, children }) => (
  <header className={cx('header', className)}>
    <div className={styles.text}>
      <h1>
        <Font.Display>{title}</Font.Display>
      </h1>
      {subtitle && <Font.Copy variant="secondary">{subtitle}</Font.Copy>}
      {subtitle2 && <Font.Copy variant="secondary">{subtitle2}</Font.Copy>}
    </div>
    {children}
  </header>
)

export default HeaderBlock
