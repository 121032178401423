import { useState, useEffect, useRef } from 'react'
import { Device } from 'twilio-client'
import { noop } from 'lodash/util'
import { fetchJSON } from 'utils/req'

const fetchTwilioToken = () =>
  fetchJSON(`/api/v1/twilio/token`, 'POST', null, {
    useJwt: true,
  })

const defaultErrorCallback = error =>
  // eslint-disable-next-line no-console
  console.error('Twilio call error:', { error })

const useTwilioDevice = ({
  setupOptions,
  onConnect = noop,
  onDisconnect = noop,
  onError = defaultErrorCallback,
}) => {
  const deviceRef = useRef(Device)
  const [customParams, setCustomParams] = useState({})
  const [setupError, setSetupError] = useState(null)

  const fetchToken = async () => {
    const response = await fetchTwilioToken()
    return response.token
  }

  const destroyDialer = () => {
    deviceRef.current && deviceRef.current.destroy()
  }

  useEffect(() => destroyDialer, [])

  const setupDevice = async () => {
    const token = await fetchToken()

    return new Promise(resolve => {
      if (deviceRef.current) destroyDialer()

      const device = deviceRef.current

      try {
        device.setup(token, setupOptions)
        device.on('connect', onConnect)
        device.on('disconnect', (...args) => {
          onDisconnect(...args)
          setCustomParams({})
        })
        device.on('error', onError)
        device.on('incoming', connection => {
          setCustomParams(connection.customParameters)
          connection.accept()
        })
      } catch (err) {
        const error = {
          type: 'SET_UP',
          message: 'The dialer could not complete setup.',
        }
        onError(error)
        setSetupError(error)
        resolve(false)
      }

      device.on('ready', () => {
        setSetupError(null)
        resolve(true)
      })
    })
  }

  const endCall = () => {
    deviceRef.current.disconnectAll()
  }

  return { setupError, setupDevice, endCall, device: deviceRef, customParams }
}

export default useTwilioDevice
