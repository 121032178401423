import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError } from 'components'
import { SelectField } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchTurfLevels } from 'requests/turfLevels'
import { useCurrent } from 'contexts'
import { formatErrorMessage } from 'utils/formatting'
import { buildParams } from './utils'

const TurfLevelSelect = ({ label, filters, onSelect, turfLevel, ...props }) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'turf_level',
    ability: 'view',
  })

  const { makeRequest, isLoading, hasErrors, errors, response } =
    useRequest(fetchTurfLevels)

  const errorMsg = formatErrorMessage(errors)

  const [value, setValue] = useState(turfLevel?.id)
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  useEffect(() => {
    if (value !== turfLevel?.id) {
      const selection = response?.turf_levels.find(({ id }) => id === value)
      onSelect && onSelect(selection)
    }
  }, [value])

  useEffect(() => {
    if (value !== turfLevel?.id) {
      setValue(turfLevel?.id)
    }
  }, [turfLevel])

  const getTurfLevels = () => {
    const params = buildParams({
      query: debounced,
      filters,
    })

    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getTurfLevels()
  }, [debounced])

  const buildTurfLevelOptions = turfLevel => ({
    value: turfLevel.id,
    label: turfLevel.name,
  })

  const options = [
    ...(turfLevel && query === '' ? [turfLevel] : []),
    ...(response?.turf_levels || []),
  ]
    .reduce(
      (options, option) =>
        options.find(({ id }) => id === option.id)
          ? options
          : [...options, option],
      []
    )
    .map(buildTurfLevelOptions)

  return (
    <>
      <CardError hide={!hasErrors} message={errorMsg} />
      <SelectField
        label={label || t('Turf Level')}
        options={options}
        onSelect={setValue}
        onInputChange={setQuery}
        value={value}
        loading={isLoading}
        disabled={!hasPermission}
        hint={
          !hasPermission && t('You do not have permission to edit this field')
        }
        {...props}
      />
    </>
  )
}

TurfLevelSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  turfLevel: PropTypes.object,
  required: PropTypes.bool,
}

TurfLevelSelect.defaultProps = {
  id: 'turfLevel',
  filters: [],
  turfLevel: null,
}

export default TurfLevelSelect
