import { useSelector } from 'react-redux'
import { getCurrentSessionScript } from 'store/qcScans/reducer'
import { Button } from 'react-md'
import { useTranslation } from 'react-i18next'
import PhoneVerificationScriptItem from 'qualityControl/callScripts/PhoneVerificationScriptItem'
import { defaultLanguages } from 'constants/qcCallScriptsConfig/defaultScripts'
import {
  ENGLISH,
  PHONE,
} from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import { setScriptLanguage as setScriptLanguageQcScans } from 'store/qcScans/actions'
import { useContext } from 'react'
import { CallScriptsContext } from 'qualityControl/callScripts/CallScriptsContext'
import { useReduxAction } from 'hooks/useReduxAction'
import styles from './CallScript.module.scss'

const CallScript = ({
  setHasEditedChanges,
  setContactedStatus,
  setVerificationResponses,
  verificationResponses,
  questions,
  phoneNumber,
}) => {
  const { t } = useTranslation()
  const currentScript = useSelector(getCurrentSessionScript)
  const {
    pendingScript: { language: currentLanguage },
    setPendingScript,
  } = useContext(CallScriptsContext)

  const setReduxScriptLanguage = useReduxAction(setScriptLanguageQcScans)

  const setScriptLanguage = lang => {
    setReduxScriptLanguage(lang)
    setPendingScript(oldScript => ({
      ...oldScript,
      language: lang,
    }))
  }

  const isButtonRaised = lang =>
    currentLanguage === lang || (!currentLanguage && lang === ENGLISH)

  return (
    <div className={styles.callScript}>
      <div className={styles.header}>
        <h2>{t('Script')}</h2>
        <div className={styles.languageToggle}>
          {Object.values(defaultLanguages).map(language => (
            <Button
              key={language}
              className={styles.languageToggleButton}
              primary
              raised={isButtonRaised(language)}
              flat={!isButtonRaised(language)}
              onClick={() => setScriptLanguage(language)}
            >
              {t(language)}
            </Button>
          ))}
        </div>
      </div>
      <section className={styles.scriptItemWrapper}>
        {currentScript.map((item, i) =>
          item.questionKey !== PHONE ? (
            <PhoneVerificationScriptItem
              {...item}
              setHasEditedChanges={setHasEditedChanges}
              setContactedStatus={setContactedStatus}
              setVerificationResponses={setVerificationResponses}
              verificationResponses={verificationResponses}
              questions={questions}
              phoneNumber={phoneNumber}
              key={i}
            />
          ) : null
        )}
      </section>
    </div>
  )
}

export default CallScript
