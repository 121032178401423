import { useContext, useEffect } from 'react'
import { HeaderBlock, Sheet, LoadBar } from 'components'
import { ContentBlock } from '@politechdev/blocks-design-system'
import { useReactRouter, useRoutePathParams, useRequest } from 'hooks'
import { fetchTurf } from 'requests/qcCallScripts'
import { defaultLanguages } from 'constants/qcCallScriptsConfig/defaultScripts'
import ScriptEditControls from './edit/ScriptEditControls'
import EditableScript from './edit/EditableScript'
import { CallScriptsContext } from './CallScriptsContext'

const ScriptEdit = () => {
  const [{ scriptId }] = useRoutePathParams()
  const { history } = useReactRouter()
  const { fetchScript, pendingScript, setPendingScriptStructureToDefault } =
    useContext(CallScriptsContext)
  const {
    makeRequest: fetchCurrentScript,
    isLoading,
    hasErrors,
  } = useRequest(fetchScript)

  // eslint-disable-next-line blocks/missing-response-error
  const {
    isRequestComplete: isTurfRequestComplete,
    makeRequest: turfRequest,
    response: turfResponse,
  } = useRequest(fetchTurf)

  useEffect(() => {
    if (pendingScript.turfId) {
      turfRequest(pendingScript.turfId, { fields: ['name'] })
    }
  }, [pendingScript.turfId])

  useEffect(() => {
    if (pendingScript.turfId === '') {
      history.push('/quality_control/call_scripts')
    }
  }, [pendingScript.turfId])

  useEffect(() => {
    if (scriptId !== 'new') {
      fetchCurrentScript(scriptId)
    }
  }, [scriptId])

  useEffect(() => {
    if (scriptId === 'new') {
      setPendingScriptStructureToDefault(pendingScript.language)
    }
  }, [pendingScript.language])

  if (!isTurfRequestComplete) return <LoadBar show />

  const headerLanguage =
    scriptId === 'new'
      ? pendingScript.language
      : defaultLanguages[pendingScript.language]

  return (
    <ContentBlock>
      <Sheet>
        <HeaderBlock
          title={
            turfResponse
              ? `${turfResponse.turf.name} - ${headerLanguage}`
              : 'Loading...'
          }
        />
        <ScriptEditControls
          scriptLoading={isLoading}
          scriptLoadError={hasErrors}
        />
      </Sheet>
      <Sheet>
        <EditableScript />
      </Sheet>
    </ContentBlock>
  )
}

export default ScriptEdit
