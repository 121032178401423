import { Button } from 'react-md'
import { useRoutePathParams, useReactRouter } from 'hooks'

const CloseSidebarButton = () => {
  const {
    history: { push },
  } = useReactRouter()

  const [, replaceParams] = useRoutePathParams()

  const goBackToListView = () => push(replaceParams({ packetId: undefined }))

  return (
    <div className="inbox-viewer__collapse-bar">
      <Button icon primary onClick={goBackToListView}>
        chevron_right
      </Button>
    </div>
  )
}

export default CloseSidebarButton
