import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Modal,
  ContentBlock,
  Icon,
} from '@politechdev/blocks-design-system'
import { DEPRECATED_useRequest, useReactRouter } from 'hooks'
import styles from './DeliveryDeleteModal.module.scss'
import { useDeliveryState } from '../DeliverySingle/DeliveryContext'
import { deleteDeliveryRequest } from '../DeliverySingle/utils'

const DeliveryDeleteModal = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { currentDelivery } = useDeliveryState()

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const { makeRequest, isLoading, errorMsg } = DEPRECATED_useRequest(
    deleteDeliveryRequest,
    () => {
      closeModal()
      history.push('/collect/voter_registration/deliveries')
    }
  )

  return (
    <>
      <Button icon onClick={openModal}>
        <Icon.TrashAlt />
      </Button>
      <Modal
        id="delivery-delete"
        title={t(`Delete delivery`)}
        isOpen={modalOpen}
        showLoading={isLoading}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError hide={!errorMsg} message={errorMsg} hideSupportLink />
            <TextBlock className={styles.text}>
              {t('Are you sure you want to delete')}{' '}
              <strong>
                {t('Delivery')} {currentDelivery.id} {t('for')}{' '}
                {currentDelivery?.turn_in_location?.name}
              </strong>
            </TextBlock>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Danger
              onClick={() => {
                makeRequest(currentDelivery.id)
              }}
            >
              {t('Delete')}
            </Button.Danger>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default DeliveryDeleteModal
