import {
  getIsScanExcludedByPreviousNotContactedResponse,
  getIsScanExcludedByVisualReview,
} from 'store/qcScans/utils'

export const getCallableScansCount = (packet, registeredQuestionId) =>
  packet.scans.filter(
    scan =>
      !getIsScanExcludedByVisualReview(scan) &&
      !getIsScanExcludedByPreviousNotContactedResponse(scan) &&
      !scan.phone_verification_responses.find(
        r => r.phone_verification_question_id === registeredQuestionId
      )
  ).length

const getFirstUncalledScanIndex = (callableScanIds, scanCallMap) =>
  callableScanIds.findIndex(scanId => {
    const hasCallId = !!scanCallMap[scanId]
    return !hasCallId
  })

const getFirstUncalledScanIndexAfterTarget = (
  currentIndex,
  callableScanIds,
  scanCallMap
) =>
  callableScanIds.findIndex((scanId, index) => {
    const hasCallId = !!scanCallMap[scanId]
    return !hasCallId && index > currentIndex
  })

export const getNextCallableScanIndex = (
  currentIndex,
  callableScanIds,
  scanCallMap
) => {
  const firstCallableScanIndex = getFirstUncalledScanIndex(
    callableScanIds,
    scanCallMap
  )

  const firstCallableIndexAfterCurrentScan =
    getFirstUncalledScanIndexAfterTarget(
      currentIndex,
      callableScanIds,
      scanCallMap
    )

  if (firstCallableIndexAfterCurrentScan > -1) {
    return firstCallableIndexAfterCurrentScan
  }

  if (firstCallableScanIndex > -1 && firstCallableScanIndex !== currentIndex) {
    return firstCallableScanIndex
  }

  return null
}
