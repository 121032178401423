const packetFields = [
  'id',
  'shift_id',
  'scans_count',
  'created_at',
  'qc_deadline',
  'file_locator',
  'original_filename',
  'file_url',
  'ocr',
  {
    scans: [
      'id',
      'county',
      'delivery_id',
      'scan_number',
      'file_url',
      'file_locator',
      {
        form: ['display_name', 'pledge_card_url'],
      },
      {
        visual_reviews: [
          {
            response: [
              'response',
              'implies_not_form',
              'implies_skips_phone_verification',
            ],
          },
          { user: ['full_name'] },
        ],
      },
      {
        phone_verification_responses: [
          'response',
          'phone_verification_question_id',
          'round_number',
        ],
      },
      'notes',
      { flags: ['status'] },
      { shift: ['id'] },
    ],
  },
  {
    unresolved_flag_triggers: [
      'id',
      'name',
      'resource_type',
      'default_action_plan',
      'needs_reupload',
      'implies_canvasser_issue',
    ],
  },
  { assignee: ['email'] },
  { location: ['state', 'name', 'timezone'] },
  { creator: 'time_zone' },
  {
    shift: [
      'status',
      'shift_start',
      'notes',
      { canvasser: ['first_name', 'last_name'] },
    ],
  },
  {
    turf: [
      'id',
      'name',
      'language',
      'voter_registration_config',
      { phone_verification_scripts: ['language', 'structure'] },
      {
        visual_review_responses: [
          'id',
          'description',
          'response',
          'active',
          'grouping_metadata',
          'reviewable_type',
        ],
      },
      { phone_verification_questions: ['question', 'id'] },
    ],
  },
]

export default packetFields
