import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getCurrentFlagScans } from 'store/qcFlags/reducer'
import { SectionLabel, TextBlock, Button, Section } from 'components'
import { useTranslation } from 'react-i18next'
import ScanListItem from './ScanListItem/ScanListItem'

const FlagSingleTriggeredScansList = ({ scans, scrollToRef }) => {
  const { t } = useTranslation()
  const [isFull, setFull] = useState(false)

  if (!scans || scans.length === 0) return null
  const scansTrimmed = isFull ? scans : scans.slice(0, 3)

  const scansListLength = scans.length
  const pluralScans = scansListLength > 1
  const headerMessage = `${scansListLength} ${t('scan')}${
    pluralScans ? 's' : ''
  } ${t('triggered this issue')}`

  const handleScrollTo = () => {
    window.scrollTo({
      left: 0,
      top: scrollToRef.current.offsetTop - 60,
      behavior: 'smooth',
    })
  }

  const issueRemoved = scansTrimmed.find(scan => scan.qc_history.length)

  return (
    <Section className="triggered-scans-list">
      <TextBlock>
        <SectionLabel>{headerMessage}</SectionLabel>
      </TextBlock>
      {issueRemoved && (
        <TextBlock warn>
          Notice: One or more issues that triggered this flag have been removed.
        </TextBlock>
      )}
      <div className="triggered-scans-list__list-items">
        {scansTrimmed.map((scan, index) => (
          <ScanListItem
            scan={scan}
            key={index}
            onClick={() => {
              if (scrollToRef) handleScrollTo()
            }}
          />
        ))}
      </div>
      {scansListLength > 3 && (
        <Button
          className="triggered-scans-list__show-more-button"
          flat
          onClick={() => {
            setFull(!isFull)
          }}
        >
          {isFull ? t('Show less') : t('Show more')}
        </Button>
      )}
    </Section>
  )
}

export default connect(state => ({
  scans: getCurrentFlagScans(state),
}))(FlagSingleTriggeredScansList)
