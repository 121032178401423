import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, HeaderBlock } from 'components'
import {
  SelectField,
  DateField,
  FieldBlock,
  Button,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { useCurrent, QueryParamProvider, useQueryParams } from 'contexts'
import GoalList from '../GoalList/GoalList'
import { convertToDateRange } from '../utils'

const GoalSelect = () => {
  const { t } = useTranslation()
  const { queryParams, setQueryParams } = useQueryParams()

  const { goalTagOptions, doesCurrentUserHavePermission } = useCurrent()
  const canCreate = doesCurrentUserHavePermission({
    resource: 'field_management/goal',
    ability: 'modify',
  })

  const tagOptions = ['All', ...goalTagOptions]

  const dateRange = convertToDateRange(
    queryParams.startDate,
    queryParams.endDate
  )

  const changeDateRange = (start, end) => {
    const dateRange = convertToDateRange(start, end)

    setQueryParams({
      ...queryParams,
      startDate: dateRange.start,
      endDate: dateRange.end,
    })
  }

  const parseTag = tag => (tag === 'All' ? null : tag)

  const tag = queryParams.tag ?? 'All'

  const changeTag = tag => {
    setQueryParams({
      ...queryParams,
      tag: parseTag(tag),
    })
  }

  return (
    <View>
      <HeaderBlock title={t('Manage goals')}>
        {canCreate && (
          <ButtonBlock justify="right">
            <Link to="/field-management/goals/new">
              <Button.Accent>{t('New Goal')}</Button.Accent>
            </Link>
          </ButtonBlock>
        )}
      </HeaderBlock>
      <FieldBlock variant="medium">
        <DateField
          label={t('Date range')}
          type="range"
          onChange={changeDateRange}
          startDate={dateRange.start}
          endDate={dateRange.end}
          clearable
        />
        <SelectField
          label={t('Tags')}
          options={tagOptions}
          onSelect={changeTag}
          disabled={!tagOptions.length}
        />
      </FieldBlock>
      <ViewContainer>
        <GoalList
          startDate={dateRange.start}
          endDate={dateRange.end}
          tag={tag}
        />
      </ViewContainer>
    </View>
  )
}

export default () => (
  <QueryParamProvider>
    <GoalSelect />
  </QueryParamProvider>
)
