import { Link, useRouteMatch } from 'react-router-dom'
import {
  CardError,
  HeaderBlock,
  Sheet,
  Section,
  Stepper,
  Step,
  FormattedData,
  Grid,
  View,
} from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  ContentBlock,
  Icon,
  Font,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { DELIVERY_STATUSES } from 'constants/deliveries'
import { useEvent } from 'hooks'
import { useCurrent } from 'contexts'
import SelectionStep from './SelectionStep'
import ReviewStep from './ReviewStep'
import DeliveryStep from './DeliveryStep'
import DeliveredStep from './DeliveredStep'
import DeliveryDeleteModal from '../DeliveryDeleteModal/DeliveryDeleteModal'
import DeliveryWarnVdrModal from '../DeliveryWarnVdrModal/DeliveryWarnVdrModal'
import DeliveryContextProvider, {
  useDeliveryActions,
  useDeliveryState,
} from './DeliveryContext'
import useDeliverySingle from './hooks/useDeliverySingle'
import { getSelectedCounties } from './utils'

const DeliverySingle = () => {
  const { t } = useTranslation()
  const match = useRouteMatch()

  const { doesCurrentUserHavePermission } = useCurrent()

  const canModifyDelivery = doesCurrentUserHavePermission({
    resource: 'delivery',
    ability: 'modify',
  })

  const {
    selectedFormIds,
    currentDelivery,
    isDeliveryLoading,
    deliveryError,
    filteredPackets,
  } = useDeliveryState()

  const { updateDeliveryStatus } = useDeliveryActions()

  const shouldUseVdrs =
    currentDelivery &&
    currentDelivery.office.voter_registration_config.uses_canvasser_vdrs

  const { modalIsOpen, setModalIsOpen, issueCounties, handleAssemblyNext } =
    useDeliverySingle(shouldUseVdrs)

  const checkVdrAndAssemble = useEvent(() =>
    handleAssemblyNext(
      updateDeliveryStatus,
      match,
      selectedFormIds,
      currentDelivery.canvasser,
      getSelectedCounties(filteredPackets, selectedFormIds)
    )
  )

  const deliveryMethods = {
    mail: 'Mail',
    in_person: 'In person',
  }
  const indicators = [
    {
      label: t('Ready for assembly'),
      color: '#F8E71C',
    },
    {
      label: t('Ready for review'),
      color: '#F5A623',
    },
    {
      label: t('Ready for delivery'),
      color: '#4A90E2',
    },
    {
      label: t('Delivered'),
      color: '#0ED5C5',
    },
  ]

  if (isDeliveryLoading || !currentDelivery) {
    return (
      <View loading>
        <ContentBlock>
          <Sheet customHeader>
            <HeaderBlock title={t('Loading')} />
          </Sheet>
        </ContentBlock>
      </View>
    )
  }

  if (deliveryError) {
    return (
      <View>
        <ContentBlock>
          <Sheet>
            <TextBlock>
              <CardError
                message={t(
                  'An internal error occurred while fetching this delivery'
                )}
              />
            </TextBlock>
          </Sheet>
        </ContentBlock>
      </View>
    )
  }

  const statusIndicator =
    indicators[DELIVERY_STATUSES.indexOf(currentDelivery.status)]

  return (
    <View>
      <ContentBlock>
        <Sheet customHeader>
          <HeaderBlock title={`${t('Delivery')} ${currentDelivery.id}`}>
            {statusIndicator && (
              <ContentBlock className="delivery__single__status">
                <i
                  className="delivery__single__status__icon"
                  style={{ backgroundColor: statusIndicator.color }}
                />
                <Font.Copy variant="hint">{statusIndicator.label}</Font.Copy>
              </ContentBlock>
            )}
            <ButtonBlock justify="right">
              <Link
                onClick={e => !canModifyDelivery && e.preventDefault()}
                to={`${match.url}/edit`}
              >
                <Button
                  disabled={!canModifyDelivery}
                  aria-label={t('Edit')}
                  alt={t('Edit')}
                >
                  <Icon.Pencil />
                </Button>
              </Link>
              {currentDelivery.status === 'assembly' && <DeliveryDeleteModal />}
            </ButtonBlock>
          </HeaderBlock>
          <Grid>
            <Section label={t('Due')}>
              <TextBlock>
                <Font.Copy>
                  {currentDelivery.turn_in_date ? (
                    <FormattedData
                      type="date"
                      value={currentDelivery.turn_in_date}
                    />
                  ) : (
                    'Not specified'
                  )}
                </Font.Copy>
              </TextBlock>
            </Section>
            <Section label={t('Total registrations')}>
              <TextBlock>
                <Font.Copy>
                  {currentDelivery.status === 'assembly'
                    ? 'Not yet assembled'
                    : currentDelivery.attached_forms_count}
                </Font.Copy>
              </TextBlock>
            </Section>
            <Section label={t('Delivery location')}>
              <TextBlock>
                <Font.Copy>{currentDelivery.turn_in_location.name}</Font.Copy>
              </TextBlock>
            </Section>
            <Section label={t('Runner')}>
              <TextBlock>
                <Font.Copy>{currentDelivery.canvasser.full_name}</Font.Copy>
              </TextBlock>
            </Section>
            <Section label={t('Status')}>
              <TextBlock>
                <Font.Copy>
                  {statusIndicator && statusIndicator.label}
                </Font.Copy>
              </TextBlock>
            </Section>
            <Section label={t('Office of origin')}>
              <TextBlock>
                <Font.Copy>{currentDelivery.office.name}</Font.Copy>
              </TextBlock>
            </Section>
            <Section label={t('Type')}>
              <TextBlock>
                <Font.Copy>
                  {currentDelivery.delivery_method
                    ? deliveryMethods[currentDelivery.delivery_method]
                    : 'Not specified'}
                </Font.Copy>
              </TextBlock>
            </Section>
          </Grid>
          <Section label={t('Notes')}>
            <TextBlock>
              {currentDelivery.notes ? (
                <Font.Copy variant="reading-block">
                  {currentDelivery.notes}
                </Font.Copy>
              ) : (
                <Link
                  onClick={e => !canModifyDelivery && e.preventDefault()}
                  to={`${match.url}/edit`}
                >
                  <Button
                    primary
                    disabled={!canModifyDelivery}
                    aria-label={t('Edit')}
                    alt={t('Edit')}
                  >
                    Add Notes
                  </Button>
                </Link>
              )}
            </TextBlock>
          </Section>
          <DeliveryWarnVdrModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            deliveryStepRequest={() =>
              updateDeliveryStatus(match.params.id, 'assemble', selectedFormIds)
            }
            issueCounties={issueCounties}
          />
        </Sheet>
        <Sheet>
          <Stepper
            horizontal
            activeStep={DELIVERY_STATUSES.indexOf(currentDelivery.status) + 1}
          >
            <Step
              label={t('Select')}
              hidePreviousButton
              nextButtonLabel={t('Commit forms for review')}
              customNextButtonFunction={checkVdrAndAssemble}
              disableNext={!selectedFormIds || !selectedFormIds.length}
            >
              <SelectionStep />
            </Step>
            <Step
              label={t('Review')}
              nextButtonLabel={t('Complete review')}
              previousButtonLabel={t('Go back to form selection')}
              onNext={() => updateDeliveryStatus(match.params.id, 'review')}
              onPrevious={() =>
                updateDeliveryStatus(match.params.id, 'assemble', [])
              }
            >
              <ReviewStep deliveryId={currentDelivery.id} />
            </Step>
            <Step
              label={t('Deliver')}
              previousButtonLabel={t('Go back to selection review')}
              hideNextButton
              onPrevious={() => updateDeliveryStatus(match.params.id, 'review')}
            >
              <DeliveryStep currentDelivery={currentDelivery} />
            </Step>
            <Step
              summaryStep
              label={t('Delivered')}
              hidePreviousButton
              onPrevious={() =>
                updateDeliveryStatus(match.params.id, 'deliver')
              }
            >
              <DeliveredStep currentDelivery={currentDelivery} />
            </Step>
          </Stepper>
        </Sheet>
      </ContentBlock>
    </View>
  )
}

export default () => (
  <DeliveryContextProvider>
    <DeliverySingle />
  </DeliveryContextProvider>
)
