import { useState } from 'react'
import { DEPRECATED_useRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  ButtonBlock,
  Button,
  TextBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { deleteFlag } from 'store/qcFlags/requests'

const DeleteFlagButton = ({ onDelete, flag }) => {
  const { t } = useTranslation()
  const [isVisible, setVisible] = useState(false)

  const afterDelete = () => {
    onDelete()
    setVisible(false)
  }

  const { makeRequest, isLoading, errorMsg } = DEPRECATED_useRequest(
    deleteFlag,
    afterDelete
  )

  return (
    <>
      <Button.Danger onClick={() => setVisible(true)}>
        {t('Delete')}
      </Button.Danger>
      <Modal
        id="delete-warning"
        isOpen={isVisible}
        onHide={() => setVisible(false)}
        showLoading={isLoading}
        title={t('Delete Flag?')}
      >
        <Modal.Body>
          <TextBlock>
            <p>
              {`${t('You are about to permanently delete flag')} ${flag.id} ${t(
                'for'
              )} ${flag.trigger.name}. ${t(
                'Are you sure you want to delete it?'
              )}`}
            </p>
          </TextBlock>
          <CardError hide={!errorMsg} message={errorMsg} />
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button disabled={isLoading} onClick={() => setVisible(false)}>
              {t('Cancel')}
            </Button>
            <Button
              raised
              disabled={isLoading}
              className="button--warn"
              onClick={() => makeRequest(flag.id)}
            >
              {t('Delete')}
            </Button>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default DeleteFlagButton
