import { useState } from 'react'
import {
  HeaderBlock,
  List,
  ListItem,
  LoadBar,
  Paginator,
  SearchSelect,
  FieldBlock,
  Sheet,
} from 'components'
import { TextBlock } from '@politechdev/blocks-design-system'
import { putScan } from 'requests/shifts'
import { useTranslation } from 'react-i18next'
import { useRoutePathParams } from 'hooks/router'

const ScanActions = ({
  scan,
  scanCounties,
  setScanCounties,
  countyOptions,
}) => {
  const { t } = useTranslation()
  return (
    <FieldBlock>
      <SearchSelect
        label={t('County')}
        key="county-control"
        clearable={false}
        disabled={scan.delivery_id}
        value={scanCounties[scan.id]}
        onChange={val => {
          setScanCounties(oldScanCounties => ({
            ...oldScanCounties,
            [scan.id]: val,
          }))
          putScan(scan.id, {
            county: val,
          })
        }}
        options={countyOptions}
      />
    </FieldBlock>
  )
}

const RegistrationFormTable = ({ scans, countyOptions, loading }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [scanCounties, setScanCounties] = useState({})
  const [{ id: shiftId, shiftType }] = useRoutePathParams()
  const { t } = useTranslation()
  const pageSize = 5
  const paginate = increment => {
    setCurrentPage(oldCurrentPage => oldCurrentPage + increment)
  }

  const scanPage = scans.slice(
    currentPage * pageSize - pageSize,
    pageSize * currentPage
  )

  return (
    <Sheet className="margin--bottom">
      <HeaderBlock title={t('Registration forms')} />
      <TextBlock className="reading-text">
        <p>
          {t(
            'Scans are shown starting from the second page of the PDF attached to this shift.'
          )}
        </p>
      </TextBlock>
      <LoadBar show={loading} />
      <List>
        {scans && scans.length ? (
          scanPage.map(scan => {
            if (scanCounties[scan.id] === undefined) {
              setScanCounties(oldScanCounties => ({
                ...oldScanCounties,
                [scan.id]: scan.county,
              }))
            }

            return (
              <ListItem
                key={scan.scan_number}
                primaryText={
                  <a
                    href={`/collect/${shiftType}/shifts/${shiftId}/packet/scans/${scan.id}?source=shifts&sourceId=${shiftId}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {`${t('Scan')} #${scan.scan_number}`}
                    {scan.form ? ` - ${scan.form.display_name}` : null}
                  </a>
                }
                secondaryText={
                  scan.delivery_id
                    ? `${t('On delivery')} # ${scan.delivery_id}`
                    : t('Not on a delivery')
                }
                actions={
                  <ScanActions
                    scan={scan}
                    scanCounties={scanCounties}
                    setScanCounties={setScanCounties}
                    countyOptions={countyOptions}
                  />
                }
              />
            )
          })
        ) : (
          <TextBlock>
            <p>{t('No registration forms uploaded')}</p>
          </TextBlock>
        )}
      </List>
      {scans && scans.length > 0 && (
        <Paginator
          onNext={() => {
            paginate(+1)
          }}
          onPrevious={() => {
            paginate(-1)
          }}
          currentPage={currentPage}
          totalPages={Math.ceil(scans.length / pageSize)}
        />
      )}
    </Sheet>
  )
}

export default RegistrationFormTable
