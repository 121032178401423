import { useTranslation } from 'react-i18next'
import { CardError, LoadBar } from 'components'
import {
  Button,
  ButtonBlock,
  useToast,
} from '@politechdev/blocks-design-system'
import { useReactRouter, DEPRECATED_useRequest } from 'hooks'
import { postEmailTemplate, putEmailTemplate } from 'requests/email'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { TemplateContext } from '../TemplateContext/TemplateContext'

const TemplateControls = () => {
  const { t } = useTranslation()
  const { setToast } = useToast()
  const { templateName, content, colors, fontFamily, themeName } =
    useContext(TemplateContext)
  const { match, history } = useReactRouter()

  const handleSaveSuccess = () => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      history.push('/email/manage')
    }, 2000)
  }

  const {
    makeRequest: postTemplateReq,
    isLoading: postTemplateLoading,
    errorMsg: postTemplateError,
  } = DEPRECATED_useRequest(postEmailTemplate, () => {
    setToast({
      message: t('Draft created'),
      variant: 'success',
    })
    handleSaveSuccess()
  })
  const {
    makeRequest: putTemplateReq,
    isLoading: putTemplateLoading,
    errorMsg: putTemplateError,
  } = DEPRECATED_useRequest(putEmailTemplate, () => {
    setToast({
      message: t('Draft updated'),
      variant: 'success',
    })
    handleSaveSuccess()
  })

  const template = { content, colors, fontFamily, themeName }
  return (
    <ButtonBlock justify="right">
      <CardError
        hide={!putTemplateError && !postTemplateError}
        message={putTemplateError || postTemplateError}
      />
      <LoadBar show={putTemplateLoading || postTemplateLoading} />
      <Link to="/email/manage">
        <Button.Secondary>{t('Cancel')}</Button.Secondary>
      </Link>
      <Button.Accent
        disabled={!templateName}
        onClick={() => {
          match.params?.id
            ? putTemplateReq(match.params.id, templateName, template)
            : postTemplateReq(templateName, template)
        }}
      >
        {t('Save')}
      </Button.Accent>
    </ButtonBlock>
  )
}

export default TemplateControls
