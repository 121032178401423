import { useTranslation } from 'react-i18next'
import { HeaderBlock, ViewContainer } from 'components'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { Card } from 'react-md'
import React from 'react'
import { Link } from 'react-router-dom'
import ManageTable from '../ManageTable/ManageTable'

const Manage = () => {
  const { t } = useTranslation()
  return (
    <div>
      <HeaderBlock title={t('Email drafts')}>
        <ButtonBlock justify="right">
          <Link to="/email/send">
            <Button.Secondary>{t('Send email')}</Button.Secondary>
          </Link>
          <Link to="/email/template">
            <Button.Accent>{t('Create draft')}</Button.Accent>
          </Link>
        </ButtonBlock>
      </HeaderBlock>
      <ViewContainer>
        <Card>
          <ManageTable />
        </Card>
      </ViewContainer>
    </div>
  )
}
export default Manage
