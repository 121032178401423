import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useRequest } from 'hooks'
import { fetchPacket } from 'store/qcScans/actions'
import { getCurrentPacket } from 'store/qcScans/reducer'
import { LoadBar, CardError } from 'components'
import { VisualApprovePacketViewer } from 'qualityControl/scanReview/PacketViewer'
import PacketAndScanFetcher from 'qualityControl/PacketAndScanFetcher'
import { formatErrorMessage } from 'utils/formatting'
import { QualityControlProvider } from 'qualityControl/QualityControlContext'

const ShiftSinglePacketViewer = ({ fetchPacket, packetId }) => {
  const {
    makeRequest: fetchPacketRequest,
    isLoading: isFetchPacketLoading,
    hasErrors: fetchPacketError,
    errors,
  } = useRequest(fetchPacket)

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    fetchPacketRequest(packetId)
  }, [])

  return (
    <>
      <LoadBar show={isFetchPacketLoading} />
      <PacketAndScanFetcher>
        <QualityControlProvider>
          <VisualApprovePacketViewer
            isOpen
            showDetail={false}
            showCountyLookup={false}
            packetId={packetId}
          />
        </QualityControlProvider>
      </PacketAndScanFetcher>
      <CardError hide={!fetchPacketError} message={errorMsg} hideSupportLink />
    </>
  )
}

export default connect(
  state => ({
    currentPacket: getCurrentPacket(state),
  }),
  { fetchPacket }
)(ShiftSinglePacketViewer)
