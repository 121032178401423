import { useContext, useEffect } from 'react'
import { Card, DataTable, TableBody, TableRow, TableColumn } from 'react-md'
import { CsvDownloadButton, CardError, TxtDownloadButton } from 'components'
import { ButtonBlock, ProgressBar } from '@politechdev/blocks-design-system'
import { useBackgroundRequest } from 'hooks'
import { formatDecimal } from 'utils/reporting'
import { useCurrent } from 'contexts/index'
import { buildReportFilename } from './utils'
import { WeeklyReportContext } from '../WeeklyReportContext'

const CHART_TYPE = 'canvasser_highlights'

const CanvasserHighlights = () => {
  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTurfPerformsExternalQC,
    doesCurrentUserHavePermission,
  } = useCurrent()

  const { chartMap, filters, requestChartId, requestChart } =
    useContext(WeeklyReportContext)

  const { makeRequest, isLoading, errorMsg, isSuccessful } =
    useBackgroundRequest({
      key: CHART_TYPE,
      requestId: type =>
        requestChartId(type, turfId, currentTurfPerformsExternalQC),
      requestData: requestChart,
    })

  useEffect(() => {
    if (filters.turf_id) {
      makeRequest()
    }
  }, [filters])

  const rowNames = {
    best_completion1: 'Best completion rate (ranked)',
    best_completion2: '',
    best_completion3: '',
    best_phone1: 'Best phone collection rate (ranked)',
    best_phone2: '',
    best_phone3: '',
  }

  const chartData = chartMap[CHART_TYPE]?.data

  const rowData = chartData ? chartData.rows : undefined
  const sqlQuery = chartData ? chartData.sql_query : ''

  const indexes = rowData
    ? {
        best_completion1: rowData.findIndex(
          cd => cd.metric === 'best_completion1'
        ),
        best_completion2: rowData.findIndex(
          cd => cd.metric === 'best_completion2'
        ),
        best_completion3: rowData.findIndex(
          cd => cd.metric === 'best_completion3'
        ),
        best_phone1: rowData.findIndex(cd => cd.metric === 'best_phone1'),
        best_phone2: rowData.findIndex(cd => cd.metric === 'best_phone2'),
        best_phone3: rowData.findIndex(cd => cd.metric === 'best_phone3'),
      }
    : {}

  const keys = Object.keys(rowNames)

  const tableColumns = [
    {
      label: '',
      key: 'metric',
    },
    {
      label: '',
      key: 'canvasser',
    },
    {
      label: '',
      key: 'value',
    },
  ]

  const tableRows = rowData
    ? keys.map(key => ({
        metric: rowNames[key],
        canvasser:
          rowData[indexes[key]] && rowData[indexes[key]].canvasser
            ? rowData[indexes[key]].canvasser
            : 'Not set',
        value:
          rowData[indexes[key]] && rowData[indexes[key]].value
            ? formatDecimal(rowData[indexes[key]].value)
            : '',
      }))
    : []

  return (
    <Card>
      <div className="panel__header">Canvasser Highlights</div>
      <ButtonBlock justify="right">
        {doesCurrentUserHavePermission({
          resource: 'weekly',
          ability: 'download',
        }) && (
          <CsvDownloadButton
            columnHeaders={tableColumns}
            data={tableRows}
            filename={buildReportFilename(CHART_TYPE, filters)}
            disabled={isLoading}
          />
        )}
        {doesCurrentUserHavePermission({
          resource: 'weekly',
          ability: 'sql',
        }) && (
          <TxtDownloadButton
            txtString={sqlQuery}
            filename={buildReportFilename(CHART_TYPE, filters, 'txt')}
          />
        )}
      </ButtonBlock>
      <ProgressBar show={isLoading} />
      <CardError hide={!errorMsg} message={errorMsg} hideSupportLink />
      {isSuccessful && (
        <DataTable plain>
          <TableBody>
            {tableRows.map((row, index) => (
              <TableRow key={index}>
                {tableColumns.map((column, index) => (
                  <TableColumn key={column.key}>
                    <span className={index === 0 ? 'bold muted' : ''}>
                      {row[column.key]}
                    </span>
                  </TableColumn>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
      )}
    </Card>
  )
}

export default CanvasserHighlights
