import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  ContentBlock,
  List,
  ListItem,
  ProgressBar,
  Font,
  Icon,
  Button,
  TextBlock,
  ButtonBlock,
  TextField,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import countiesByState from 'utils/counties.json'
import { useTranslation } from 'react-i18next'
import { debounce, startCase, toLower } from 'lodash'
import {
  fetchAddressOptions,
  fetchAddressSuggestions,
} from 'components/AddressValidator/smartyStreetsApiHelpers'
import {
  getCurrentScan,
  getCurrentPacketLocationState,
} from 'store/qcScans/reducer'
import useEvent from 'hooks/useEvent'
import { scanParams, updateScan } from 'store/qcScans/actions'
import { useRequest } from 'hooks/useRequest'
import classNames from 'classnames/bind'
import { CountySelectField } from 'components/index'
import styles from './CountyLookup.module.scss'

const cx = classNames.bind(styles)

const CountyLookup = ({
  currentPacketLocationState,
  countyValue,
  setCountyValue,
  updateScan,
  enableReview,
  currentScan,
  scanId,
}) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [addressSuggestions, setAddressSuggestions] = useState([])

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: getSuggestions, isLoading: areSuggestionsLoading } =
    useRequest(fetchAddressSuggestions, { onSuccess: setAddressSuggestions })

  const updateScanCounty = useEvent(val => {
    if (val === 'cannot_determine') {
      setCountyValue('cannot_determine')
    } else {
      setCountyValue(val)
    }
    enableReview(true)
    if (val === 'cannot_determine') {
      const updatedScan = {
        id: currentScan.id,
        county: null,
      }
      updateScan(updatedScan, scanParams)
      return
    }
    const updatedScan = {
      id: currentScan.id,
      county: val,
    }
    updateScan(updatedScan, scanParams)
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: getCountyAndUpdateScan, isLoading: isLoadingCounty } =
    useRequest(
      ({ street_line, city, state, zipcode }) =>
        fetchAddressOptions({
          fields: {
            street: street_line,
            city,
            state,
            zipcode,
          },
        }),
      {
        onSuccess: response => {
          const match = response?.candidates[0]
          updateScanCounty(match.metadata.county_name)
          setDialogOpen(false)
        },
      }
    )

  useEffect(() => {
    if (
      currentScan.visual_reviews.length > 0 &&
      currentScan.county === null &&
      (currentScan.form?.county === null ||
        currentScan.form?.county === undefined ||
        !countiesByState[currentPacketLocationState].includes(
          startCase(toLower(currentScan.form?.county ?? ''))
        ))
    ) {
      setCountyValue('cannot_determine')
    } else {
      const countyValue = startCase(
        toLower(currentScan.county ?? currentScan.form?.county)
      )
      setCountyValue(countyValue === 'Miami Dade' ? 'Miami-Dade' : countyValue)
    }

    const regFormCountyValue = startCase(toLower(currentScan.form?.county))

    if (
      currentScan.county === null &&
      currentScan.form?.county !== null &&
      currentScan.form?.county !== undefined &&
      (countiesByState[currentPacketLocationState].includes(
        regFormCountyValue
      ) ||
        regFormCountyValue === 'Miami Dade')
    ) {
      updateScan(
        {
          id: currentScan.id,
          county:
            regFormCountyValue === 'Miami Dade'
              ? 'Miami-Dade'
              : regFormCountyValue,
        },
        scanParams
      )
    }
  }, [scanId])

  useEffect(() => {
    setAddressSuggestions(null)
    setSearchTerm('')
  }, [isDialogOpen])

  const doSearch = address => {
    setSearchTerm(address ?? '')
    if (address) {
      getSuggestions({
        fields: { search: address },
      })
    }
  }

  return (
    <>
      <ProgressBar show={isLoadingCounty} />
      <div className={styles.container}>
        <FieldBlock>
          <CountySelectField
            id="county"
            label={t('County')}
            state={currentPacketLocationState}
            extraOptions={[
              {
                label: t('Cannot determine county'),
                value: 'cannot_determine',
              },
            ]}
            county={countyValue}
            onSelect={val => updateScanCounty(val)}
          />
        </FieldBlock>
        <ButtonBlock>
          <Button.Secondary
            onClick={() => {
              setDialogOpen(!isDialogOpen)
            }}
          >
            {isDialogOpen ? <Icon.Times /> : <Icon.InfoCircle />}
            <span>
              {isDialogOpen ? t('Close county lookup') : t('County lookup')}
            </span>
          </Button.Secondary>
        </ButtonBlock>
      </div>
      <div className={cx('panel', { 'panel--open': isDialogOpen })}>
        <TextBlock>
          <Font.Copy variant="highlight" Element="h2">
            {t('County lookup')}
          </Font.Copy>
        </TextBlock>
        <ContentBlock>
          <TextField
            onChange={debounce(doSearch, 700)}
            label={t('Address')}
            type="search"
            placeholder="Ex: 400 Broad St, Seattle, WA 98109"
          />
        </ContentBlock>
        <ProgressBar show={areSuggestionsLoading || isLoadingCounty} />
        {!areSuggestionsLoading &&
        searchTerm.length &&
        addressSuggestions?.length === 0 ? (
          <TextBlock>
            <p>{t('No results found. Try entering a valid full address.')}</p>
          </TextBlock>
        ) : null}
        <List
          itemData={addressSuggestions || []}
          render={suggestion => (
            <ListItem key={buildAddress(suggestion)}>
              <ContentBlock>
                <Font.Copy variant="highlight">
                  {buildAddress(suggestion)}
                </Font.Copy>
              </ContentBlock>
              <ButtonBlock justify="right">
                <Button
                  onClick={() => {
                    getCountyAndUpdateScan(suggestion)
                  }}
                  disabled={isLoadingCounty}
                >
                  {t('Set county')}
                </Button>
              </ButtonBlock>
            </ListItem>
          )}
        />
      </div>
    </>
  )
}

export default connect(
  state => ({
    currentScan: getCurrentScan(state),
    currentPacketLocationState: getCurrentPacketLocationState(state),
  }),
  {
    updateScan,
  }
)(CountyLookup)

function buildAddress(suggestion) {
  let whiteSpace = ''
  let entries = ''

  if (suggestion.secondary) {
    if (suggestion.entries > 1) {
      entries += ` (${suggestion.entries} entries)`
    }
    whiteSpace = ' '
  }
  return `${suggestion.street_line}${whiteSpace}${suggestion.secondary}${entries} ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}
  `
}
