import {
  DataEntryFormStorage as FormStorage,
  DataEntryUploadStorage as UploadStorage,
} from '../storageUtils'

const handleEdit =
  ({ match, history }) =>
  rowData => {
    history.push(`${match.url}/${rowData.id}/edit`)
  }

const handleUpload =
  ({ match, history }) =>
  rowData => {
    history.push(`${match.url}/${rowData.id}/scans`)
  }

const handleStartQC =
  ({ startQC, history, match }) =>
  async rowData => {
    const hasSignatures = !!rowData?.petition_packet?.signatures.length
    if (hasSignatures) {
      await startQC(rowData.id, rowData.petition_packet.id)
      history.push(`${match.url}/${rowData.id}/packet`)
    }
  }
const isStartQcDisabled = (rowData, currentContext) => {
  const { doesCurrentUserHavePermission } = currentContext
  const userHasPermission = doesCurrentUserHavePermission({
    resource: 'petition_signature',
    ability: 'modify',
  })
  return !userHasPermission || !rowData?.petition_packet?.signatures.length
}

const handleUnassign =
  ({ setUnassignModalOpen }) =>
  async rowData => {
    const petitionParams = {
      id: rowData.petition_packet.id,
      assigneeId: rowData.petition_packet.assignee.id,
      name: rowData.petition_packet.assignee.full_name,
    }
    setUnassignModalOpen(petitionParams)
  }

const handleView =
  ({ history, match }) =>
  async rowData => {
    history.push(`${match.url}/${rowData.id}/packet`)
  }

const handleDataEntry =
  ({ match, history }) =>
  rowData => {
    const lastPage = FormStorage.getFirstUnenteredFromStorage(rowData.id)
    history.push(`${match.url}/${rowData.id}/data_entry/${lastPage}`)
  }

const handleCoverSheet =
  ({ match, history }) =>
  rowData => {
    history.push(`${match.url}/${rowData.id}/packet_information`)
  }

const handleRegistrationDelete =
  ({
    setPreventDeleteModalOpen,
    setDeleteShiftParams,
    deleteShiftRequest,
    refreshPage,
  }) =>
  rowData => {
    if (rowData.packet?.unresolved_flag_triggers?.length) {
      setPreventDeleteModalOpen(true)
      return null
    }

    if (rowData.packet) {
      setDeleteShiftParams({
        id: rowData.id,
        packet_id: rowData.packet.id,
      })
      return null
    }

    deleteShiftRequest(rowData.id).then(() => refreshPage())
  }

const handlePetitionDelete =
  ({ setPreventDeleteModalOpen, deleteShiftRequest, refreshPage }) =>
  rowData => {
    rowData.petition_packet?.unresolved_flag_triggers?.length
      ? setPreventDeleteModalOpen(true)
      : deleteShiftRequest(rowData.id).then(() => refreshPage())
  }

const handleViewPacket =
  ({ match, history }) =>
  rowData => {
    history.push(`${match.url}/${rowData.id}/packet`)
  }

const shouldShowEdit = rowData =>
  rowData.status !== 'ready_for_cover_sheet_info'

const isViewDisabled = (_, currentContext) => {
  const { doesCurrentUserHavePermission } = currentContext
  const userHasPermission = doesCurrentUserHavePermission({
    resource: 'petition_signature',
    ability: 'view',
  })
  return !userHasPermission
}

const shouldShowView = (rowData, currentContext) => {
  const { currentUser } = currentContext
  const userIsCurrentAssignee =
    currentUser.id === rowData?.petition_packet?.assignee?.id
  const isCompleted = rowData.status === 'completed'
  return userIsCurrentAssignee || isCompleted
}
const shouldShowCoverSheet = rowData =>
  rowData.status === 'ready_for_cover_sheet_info'

const shouldShowUpload = rowData =>
  rowData.status === 'ready_for_scans' &&
  !UploadStorage.isShiftInStorage(rowData.id)

const shouldShowStartQC = rowData => {
  const isAvailableToQC =
    rowData.status === 'ready_for_qc' || rowData.status === 'in_qc'

  const isUnassigned = !rowData.petition_packet?.assignee
  return isUnassigned && isAvailableToQC
}

const isUnassignDisabled = (_, currentContext) => {
  const { doesCurrentUserHavePermission } = currentContext

  const userHasPermission = doesCurrentUserHavePermission({
    resource: 'petition',
    ability: 'unassign',
  })
  return !userHasPermission
}

const shouldShowUnassign = (rowData, currentContext) => {
  const { currentUser } = currentContext
  const packetHasAssignee = rowData.petition_packet?.assignee
  if (!packetHasAssignee) return false
  const userIsCurrentAssignee =
    currentUser.id === rowData.petition_packet.assignee.id

  return userIsCurrentAssignee
}

const shouldShowDataEntry = rowData =>
  rowData.status === 'ready_for_scans' &&
  UploadStorage.isShiftInStorage(rowData.id)

const shouldShowViewPacket = rowData => rowData.packet?.original_filename

const isDeleteDisabled = (_, currentContext) => {
  const { doesCurrentUserHavePermission } = currentContext
  const userHasPermission = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'remove',
  })
  return !userHasPermission
}

const actionsConfig = {
  voter_registration: [
    {
      primaryText: 'Upload scans',
      onClick: handleUpload,
      show: shouldShowUpload,
    },
    {
      primaryText: 'Continue data entry',
      onClick: handleDataEntry,
      show: shouldShowDataEntry,
    },
    {
      primaryText: 'Enter cover sheet info',
      onClick: handleCoverSheet,
      show: shouldShowCoverSheet,
    },
    {
      primaryText: 'Edit shift',
      onClick: handleEdit,
      show: shouldShowEdit,
    },
    {
      primaryText: 'Delete shift',
      onClick: handleRegistrationDelete,
      isDisabled: isDeleteDisabled,
    },
    {
      primaryText: 'View packet',
      onClick: handleViewPacket,
      show: shouldShowViewPacket,
    },
  ],
  petitions: [
    {
      primaryText: 'Start QC',
      onClick: handleStartQC,
      show: shouldShowStartQC,
      isDisabled: isStartQcDisabled,
    },
    {
      primaryText: 'Unassign QC',
      onClick: handleUnassign,
      show: shouldShowUnassign,
      isDisabled: isUnassignDisabled,
    },
    {
      primaryText: 'View QC',
      onClick: handleView,
      show: shouldShowView,
      isDisabled: isViewDisabled,
    },
    {
      primaryText: 'Upload scans',
      onClick: handleUpload,
      show: shouldShowUpload,
    },
    {
      primaryText: 'Edit shift',
      onClick: handleEdit,
      show: shouldShowEdit,
    },
    {
      primaryText: 'Delete shift',
      onClick: handlePetitionDelete,
      isDisabled: isDeleteDisabled,
    },
    {
      primaryText: 'View packet',
      onClick: handleViewPacket,
      show: shouldShowViewPacket,
    },
  ],
}

const showDefault = () => true
const disableDefault = () => false
export const createActionColumns = ({
  shiftType,
  t,
  grabBag,
  currentContext,
}) =>
  actionsConfig[shiftType].map(action => ({
    primaryText: t(action.primaryText),
    onClick: action.onClick(grabBag),
    show: rowData =>
      action.show ? action.show(rowData, currentContext) : showDefault(),
    isDisabled: rowData =>
      action.isDisabled
        ? action.isDisabled(rowData, currentContext)
        : disableDefault(),
  }))
