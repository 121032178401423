import { useInbox } from 'qualityControl/inbox/InboxContext'
import IssueOption from '../IssueOption/IssueOption'
import styles from './IssueOptionsList.module.scss'

const IssueOptionsList = ({
  issueReasons,
  addIssueReason,
  removeIssueReason,
}) => {
  const { visualReviewIssueOptions } = useInbox()

  const isChecked = id => issueReasons.includes(id)
  const toggleCheckbox = id =>
    isChecked(id) ? removeIssueReason(id) : addIssueReason(id)
  return (
    <div className={styles.list}>
      {visualReviewIssueOptions.map(option => (
        <IssueOption
          key={`issue-option-item-${option.id}`}
          preparedOption={option}
          isChecked={isChecked}
          toggleCheckbox={toggleCheckbox}
        />
      ))}
    </div>
  )
}
export default IssueOptionsList
