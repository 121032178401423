import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { fetchPledgeCardGalleryPacket } from 'requests/qualityControl'
import packetFields from 'store/utils/packetFields'
import { useRequest, useRoutePathParams } from 'hooks'
import { useQueryParams } from 'contexts'
import { sortBy, uniqBy } from 'lodash'

export const PledgeCardGalleryStateContext = createContext()
export const PledgeCardGalleryActionContext = createContext()

export const ViewerType = {
  Select: 'Select',
  Form: 'Form',
  Pledge: 'Pledge',
}

const PledgeCardGalleryContextProvider = ({ children }) => {
  const [packet, setPacket] = useState()
  const [packetScans, setPacketScans] = useState([])
  const [firstScan, setFirstScan] = useState({})
  const [secondScan, setSecondScan] = useState({})
  const {
    queryParams: { type, firstScanId, secondScanId },
    setQueryParams,
  } = useQueryParams()
  const [viewerType, setViewerType] = useState(
    type === ViewerType.Select ||
      type === ViewerType.Form ||
      type === ViewerType.Pledge
      ? type
      : ViewerType.Select
  )
  const [{ packetId }] = useRoutePathParams()

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest } = useRequest(fetchPledgeCardGalleryPacket, {
    onSuccess: ({ voter_registration_scan_batch: incomingPacket }) => {
      incomingPacket.scans.forEach(scan => {
        const formattedScan = {
          id: scan.id,
          fileUrl: scan.file_url,
          fileLocator: scan.file_locator,
          pledgeUrl: scan.form.pledge_card_url,
        }
        setPacketScans(prevScans =>
          sortBy(
            uniqBy(prevScans.concat([formattedScan]), scan => scan.id),
            ['id']
          )
        )
        if (formattedScan.id === +firstScanId) {
          setFirstScan(formattedScan)
        }
        if (formattedScan.id === +secondScanId) {
          setSecondScan(formattedScan)
        }
      })

      setPacket({
        id: incomingPacket.id,
        scansCount: incomingPacket.scans_count,
        originalFilename: incomingPacket.original_filename,
        scanName: incomingPacket.original_filename,
        shift: {
          shift_start: incomingPacket.shift.shift_start,
        },
        canvasser: {
          full_name: `${incomingPacket.shift.canvasser.first_name} ${incomingPacket.shift.canvasser.last_name}`,
        },
        fileUrl: incomingPacket.file_url,
        fileLocator: incomingPacket.file_locator,
      })
    },
  })

  useEffect(() => {
    makeRequest(packetId, { fields: packetFields })
  }, [packetId])

  const pledgeCardGalleryStateValue = useMemo(
    () => ({
      packet,
      packetScans,
      firstScan,
      secondScan,
      viewerType,
    }),
    [packet, packetScans, firstScan, secondScan, viewerType]
  )

  const pledgeCardGalleryActionValue = useMemo(
    () => ({
      setFirstScan,
      setSecondScan,
      setViewerType,
      setQueryParams,
    }),
    []
  )

  return (
    <PledgeCardGalleryStateContext.Provider value={pledgeCardGalleryStateValue}>
      <PledgeCardGalleryActionContext.Provider
        value={pledgeCardGalleryActionValue}
      >
        {children}
      </PledgeCardGalleryActionContext.Provider>
    </PledgeCardGalleryStateContext.Provider>
  )
}

export const usePledgeCardGalleryState = () =>
  useContext(PledgeCardGalleryStateContext)
export const usePledgeCardGalleryActions = () =>
  useContext(PledgeCardGalleryActionContext)

export default PledgeCardGalleryContextProvider
