/* eslint-disable no-nested-ternary */
import {
  ButtonBlock,
  Button,
  ContentBlock,
  Section,
  TextBlock,
  Icon,
  Font,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getPageFromFilename, getTotalPagesFromFilename } from 'shifts/utils'
import { COVER_SHEET_INDEX, DataEntryUploadStorage } from 'shifts/storageUtils'
import { useEvent } from 'hooks'
import DocumentSelector from './components/DocumentSelector'
import { getSelectedScanIds } from './utils'
import styles from './ShiftDataEntry.module.scss'
import { RECORD_TYPES } from './constants'

const DocumentPreselect = ({ sections, fieldSetter }) => {
  const { t } = useTranslation()
  const { shiftId, pageId } = useParams()
  const history = useHistory()
  const selectedScanIds = getSelectedScanIds(shiftId)

  const [sectionScans, setSectionScans] = useState({})
  const updateSectionScans = (id, value) => {
    setSectionScans(current => {
      if (!value) {
        const updated = { ...current }
        delete updated[id]
        return updated
      }

      return { ...current, [id]: value }
    })
  }

  const [currentSection, setCurrentSection] = useState(
    sections.find(({ hasDocument }) => hasDocument)
  )

  const storedRecord = DataEntryUploadStorage.getFromStorage(shiftId, {
    data: [],
  })

  const documents = storedRecord.data
    .slice(1)
    .map(doc => {
      const { url, ...fileData } = doc
      return {
        fileData,
        fileUrl: url,
        scanNumber: getPageFromFilename(doc.metadata.filename),
        totalPages: getTotalPagesFromFilename(doc.metadata.filename),
      }
    })
    .filter(
      ({ scanNumber }) =>
        !selectedScanIds.has(scanNumber) &&
        !Object.values(sectionScans).find(
          selectedScan => selectedScan?.scanNumber === scanNumber
        )
    )

  const sectionsNeedingDocuments = sections.filter(
    section => section.hasDocument
  )

  const displayableSections = sectionsNeedingDocuments.filter(
    section =>
      Object.keys(sectionScans).find(id => id === section.id) ||
      currentSection?.id === section.id
  )

  useEffect(() => {
    setCurrentSection(sections.find(({ hasDocument }) => hasDocument))
  }, [sections])

  const autoSelectFirstDocument = useEvent(() => {
    const storedRecord = DataEntryUploadStorage.getFromStorage(shiftId, {
      data: [],
    })
    const storedScans = storedRecord.data
    const storedScan = storedScans.find(scan => {
      const pageNumber = getPageFromFilename(scan.metadata.filename)
      return (
        pageNumber !== COVER_SHEET_INDEX && !selectedScanIds.has(pageNumber)
      )
    })
    if (!storedScan) return
    const scanNumber = getPageFromFilename(storedScan.metadata.filename)
    const totalPages = getTotalPagesFromFilename(storedScan.metadata.filename)
    const { url: fileUrl, ...fileData } = storedScan
    const scanObj = {}
    scanObj[sectionsNeedingDocuments[0].id] = {
      totalPages,
      scanNumber,
      fileUrl,
      fileData,
    }
    setSectionScans(scanObj)
  })

  useEffect(() => {
    const hasOnlyOneDocumentTypeSelectable =
      sectionsNeedingDocuments.length === 1

    if (hasOnlyOneDocumentTypeSelectable) {
      autoSelectFirstDocument()
    }
  }, [shiftId, pageId, sectionsNeedingDocuments.length])

  const activeSections = [...Object.keys(sectionScans), currentSection?.id]
  const nextSection = sectionsNeedingDocuments.find(
    section => !activeSections.includes(section.id)
  )

  const isVoterRegistration =
    currentSection?.id === RECORD_TYPES.VOTER_REGISTRATION

  const isCurrentSectionSelected = Object.entries(sectionScans).find(
    ([sectionId, document]) => sectionId === currentSection?.id && !!document
  )

  return (
    <Sheet>
      {displayableSections.map(documentSection => (
        <Fragment key={documentSection.id}>
          <TextBlock>
            <Font.Label>{t(documentSection.label)}</Font.Label>
          </TextBlock>
          <Section className={styles['footer-fix']}>
            <ContentBlock secondary className={styles['document-container']}>
              {sectionScans[documentSection.id] ? (
                <ButtonBlock>
                  <DocumentSelector
                    fileUrl={sectionScans[documentSection.id].fileUrl}
                    scanNumber={sectionScans[documentSection.id].scanNumber}
                    isSelected
                  />
                  <Button
                    onClick={() =>
                      updateSectionScans(documentSection.id, undefined)
                    }
                  >
                    <Icon.Times />
                  </Button>
                </ButtonBlock>
              ) : currentSection?.id === documentSection.id ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {documents.length ? (
                    documents.map(document => (
                      <ButtonBlock key={document.fileUrl}>
                        <Button.Secondary
                          onClick={() => {
                            updateSectionScans(documentSection.id, document)
                          }}
                        >
                          <DocumentSelector
                            fileUrl={document.fileUrl}
                            scanNumber={document.scanNumber}
                          />
                        </Button.Secondary>
                      </ButtonBlock>
                    ))
                  ) : (
                    <TextBlock>
                      <Font.Copy>{t(`No scans remaining`)}</Font.Copy>
                    </TextBlock>
                  )}
                </>
              ) : (
                <TextBlock>
                  <Font.Copy>{t(`No selected document`)}</Font.Copy>
                </TextBlock>
              )}
            </ContentBlock>
          </Section>
        </Fragment>
      ))}
      <div className={styles['sticky-footer']}>
        <TextBlock>
          {t(
            'Fetch any necessary physical forms before continuing data entry.'
          )}
        </TextBlock>
        <ButtonBlock>
          <Button.Accent
            disabled={!isCurrentSectionSelected}
            onClick={() => {
              if (nextSection) {
                setCurrentSection(nextSection)
              } else {
                Object.entries(sectionScans).forEach(([id, document]) => {
                  document && fieldSetter(`documents.${id}`)(document)
                })
                fieldSetter(`meta.documentsSelected`)(true)
              }
            }}
          >
            {t('Continue')}
          </Button.Accent>
          {!isVoterRegistration && (
            <Button.Secondary
              disabled={isCurrentSectionSelected}
              onClick={() => {
                if (nextSection) {
                  setCurrentSection(nextSection)
                  currentSection && updateSectionScans(currentSection.id, null)
                } else {
                  Object.entries(sectionScans).forEach(([id, document]) => {
                    document && fieldSetter(`documents.${id}`)(document)
                  })
                  fieldSetter(`meta.documentsSelected`)(true)
                }
              }}
            >
              {t('Continue without {{ formName }}', {
                formName: currentSection?.label,
              })}
            </Button.Secondary>
          )}
          <Button.Secondary
            onClick={() => history.push(`/collect/voter_registration/shifts`)}
          >
            {t('Cancel')}
          </Button.Secondary>
        </ButtonBlock>
      </div>
    </Sheet>
  )
}

export default DocumentPreselect
