import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, Sheet, HeaderBlock } from 'components'
import { useRoutePathParams } from 'hooks/router'
import ShiftForm from '../ShiftForm/ShiftForm'

const ShiftCreate = () => {
  const { t } = useTranslation()

  const [{ shiftType }] = useRoutePathParams()

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <HeaderBlock title={t('New Shift')} />
          <ShiftForm shiftType={shiftType} />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default ShiftCreate
