import { useCurrent } from 'contexts/index'
import { useCallback } from 'react'
import { createContext, useContextSelector } from 'use-context-selector'
import { toArray } from 'utils/array'

const NavigationPermissionContext = createContext()

export const NavigationPermissionProvider = ({ children }) => {
  const { currentUser, tenantBlocks } = useCurrent()

  const checkBlocks = blocks => {
    if (!blocks || !blocks.length) return true

    return blocks.find(block =>
      tenantBlocks.map(tenantBlock => tenantBlock.name).includes(block)
    )
  }

  const checkPermission = useCallback(
    permission => {
      if (currentUser.role.admin) return true
      if (!permission) return true

      const abilities =
        currentUser.role.permissions.find(
          a => a.resource === permission.resource
        )?.abilities || []

      if (!abilities.length) return false
      if (permission.ability === 'any') return true

      return abilities.some(a => toArray(permission.ability).includes(a))
    },
    [currentUser]
  )

  const checkPermissions = useCallback(
    permissions => {
      if (currentUser.role.admin) return true
      if (!permissions || !permissions.length) return true

      return !permissions
        .map(permission => checkPermission(permission))
        .includes(false)
    },
    [currentUser]
  )

  const checkValidChild = child => {
    if (!child) return false

    const hasBlocks = checkBlocks(child.props.blocks)
    const hasPermissions = checkPermissions(child.props.permissions)
    const hasValidChild = toArray(child.props.children).some(checkValidChild)

    return (
      hasBlocks && hasPermissions && (!child.props.children || hasValidChild)
    )
  }

  return (
    <NavigationPermissionContext.Provider
      value={{
        checkBlocks,
        checkPermissions,
        checkPermission,
        checkValidChild,
      }}
    >
      {children}
    </NavigationPermissionContext.Provider>
  )
}

export const useNavigationPermission = ({ blocks, permissions, children }) => {
  const hasBlocks = useContextSelector(
    NavigationPermissionContext,
    ({ checkBlocks }) => checkBlocks(blocks)
  )
  const hasPermissions = useContextSelector(
    NavigationPermissionContext,
    ({ checkPermissions }) => checkPermissions(permissions)
  )
  const hasSomePermissions = useContextSelector(
    NavigationPermissionContext,
    ({ checkPermissions }) =>
      Array.isArray(permissions) &&
      permissions.some(permission => checkPermissions(permission))
  )

  const hasSomePermission = useContextSelector(
    NavigationPermissionContext,
    ({ checkPermission }) =>
      Array.isArray(permissions) &&
      permissions.some(permission => checkPermission(permission))
  )

  const hasValidChildren = useContextSelector(
    NavigationPermissionContext,
    ({ checkValidChild }) => {
      if (!children) return false
      return toArray(children).some(checkValidChild)
    }
  )

  return {
    hasPermission: hasBlocks && hasPermissions,
    hasValidChildren,
    hasSomePermissions,
    hasSomePermission,
  }
}
