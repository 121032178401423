import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { merge } from 'lodash'
import React, { useContext, useState } from 'react'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  Modal,
  TextBlock,
  Checkbox,
  FieldBlock,
  useToast,
} from '@politechdev/blocks-design-system'
import { useReactRouter, DEPRECATED_useRequest } from 'hooks'
import { mergeImportError } from 'requests/duplicatePrevention'
import { RecordContext } from '../RecordContext/RecordContext'

const RecordMergeDialog = ({ isOpen, onClose, personRecord }) => {
  const {
    errorRecord,
    resolvedFields,
    importReq,
    setHideConfirmMerge,
    row,
    setRow,
  } = useContext(RecordContext)
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { setToast } = useToast()
  const [stopAsking, setStopAsking] = useState(false)

  const onSuccess = () => {
    setToast({
      message: t('Success'),
      variant: 'success',
    })
    importReq()
    setHideConfirmMerge(stopAsking)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    if (row === 0) {
      setRow(1)
    }
    onClose()
  }

  const { makeRequest, isLoading, errorMsg } = DEPRECATED_useRequest(
    mergeImportError,
    onSuccess
  )

  return (
    <Modal
      id="merge-error-record-dialog"
      title={t('Confirm merge')}
      showLoading={isLoading}
      isOpen={isOpen}
    >
      <Modal.Body>
        <TextBlock>{`${t('Are you sure you want to merge the data from row')} ${
          errorRecord.id
        } ${t('with this existing record?')}`}</TextBlock>
        <CardError
          hide={!errorMsg}
          message={t('An error occurred while merging this record.')}
        />
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary disabled={isLoading} flat onClick={onClose}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent
            disabled={isLoading}
            onClick={() => {
              makeRequest(match.params.importId, errorRecord.id, {
                target_id: personRecord.id,
                row_data: merge(
                  {
                    residential_address: personRecord.residential_address,
                  },
                  errorRecord,
                  resolvedFields,
                  {
                    birth_date:
                      resolvedFields.birth_date || errorRecord.birth_date
                        ? moment(
                            resolvedFields.birth_date || errorRecord.birth_date
                          ).format('YYYY-MM-DD')
                        : undefined,
                  }
                ),
              })
            }}
            raised
            primary
          >
            {t('Confirm merge')}
          </Button.Accent>
        </ButtonBlock>
        <FieldBlock>
          <Checkbox
            onChange={() => setStopAsking(warning => !warning)}
            checked={stopAsking}
            id="stop-asking-checkbox"
            name="stop-asking-checkbox"
            label={t('Stop asking me to confirm merges')}
            value={stopAsking}
          />
        </FieldBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default RecordMergeDialog
