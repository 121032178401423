import { connect } from 'react-redux'
import { saveActionPlanEdits } from 'store/qcFlags/actions'
import { EditableContent, InputArea } from 'components'
import { useTranslation } from 'react-i18next'
import { getCurrentFlagActionPlan } from 'store/qcFlags/reducer'
import { Button } from 'react-md'
import { useCurrent } from 'contexts/index'
import styles from './FlagSingleActionPlan.module.scss'

const FlagSingleActionPlan = ({ actionPlan, saveActionPlanEdits }) => {
  const { t } = useTranslation()
  const {
    currentUser: {
      role: { admin: isUserAdmin },
    },
    doesCurrentUserHavePermission,
  } = useCurrent()
  const hasEditPermission =
    isUserAdmin ||
    doesCurrentUserHavePermission({
      resource: 'quality_control/flag',
      ability: 'modify',
    })

  return (
    <EditableContent
      label={t('Action plan')}
      onSave={actionPlan => saveActionPlanEdits(actionPlan)}
      content={actionPlan}
      ReadModeDisplay={({ startEditing }) => (
        <InputArea
          PrimaryControl={
            hasEditPermission && (
              <Button icon primary onClick={startEditing}>
                {t('edit')}
              </Button>
            )
          }
        >
          <pre className={styles.actionplan}>{actionPlan}</pre>
        </InputArea>
      )}
    />
  )
}

export default connect(
  state => ({
    actionPlan: getCurrentFlagActionPlan(state),
  }),
  { saveActionPlanEdits }
)(FlagSingleActionPlan)

export const DeliveriesFlagSingleActionPlan = FlagSingleActionPlan
