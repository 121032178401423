import produce from 'immer'
import {
  RECEIVE_PACKET,
  RECEIVE_SCAN,
  CLEAR_CURRENT,
  SET_CURRENT_PACKET_SCANS,
  RECEIVE_PHONE_VER_QUESTIONS,
  SET_SCRIPT_LANGUAGE,
} from 'store/qcScans/actionTypes'
import { createSelector } from 'reselect'
import { sortBy, orderBy, forEach } from 'lodash/collection'
import { defaultScriptStructures } from 'constants/qcCallScriptsConfig/defaultScripts'
import {
  countScanAttempts,
  formatAllAttempts,
  calculatePhoneVerifiedScanCount,
  isPhoneVerificationComplete,
  getFailedCallsForResponse,
  getIsScanExcludedByVisualReview,
  getIsScanExcludedByPreviousNotContactedResponse,
  getCallNotes,
} from './utils'
import {
  NO_PHONE,
  REGISTERED,
  phoneVerificationQuestions,
  requiredQuestionKeys,
  ENGLISH,
} from '../../constants/qcCallScriptsConfig/phoneVerificationQuestions'

const DEFAULT_LANGUAGE = ENGLISH
export const defaultState = {
  currentPacket: {
    filename: '',
    shift_date: '',
    canvasser: { first_name: '', last_name: '' },
    scans: [],
    shift: { id: '' },
    location: {},
    turf: { voter_registration_config: {} },
    creator: { time_zone: 'America/New_York' },
  },
  currentScan: {
    county: null,
    phone_verification_responses: [],
    visual_reviews: [],
  },
  phoneVerificationQuestions: phoneVerificationQuestions[DEFAULT_LANGUAGE],
  currentScriptLanguage: '',
}

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) =>
  produce(state, draft => {
    const { type, payload } = action
    switch (type) {
      case RECEIVE_PACKET: {
        if (action.packet) {
          draft.currentPacket = action.packet
        }
        return draft
      }
      case RECEIVE_SCAN: {
        draft.currentScan = {
          ...payload,
          visual_reviews: payload.visual_reviews.map(review => ({
            ...review,
            response: review.visual_review_response ?? review.response,
          })),
        }
        return draft
      }
      case CLEAR_CURRENT: {
        draft.currentPacket = defaultState.currentPacket
        draft.currentScan = defaultState.currentScan
        return draft
      }
      case SET_CURRENT_PACKET_SCANS: {
        draft.currentPacket.scans = payload

        return draft
      }
      case RECEIVE_PHONE_VER_QUESTIONS: {
        draft.phoneVerificationQuestions = payload

        return draft
      }
      case SET_SCRIPT_LANGUAGE: {
        draft.currentScriptLanguage = payload
        const newQuestions = phoneVerificationQuestions[payload]

        forEach(draft.phoneVerificationQuestions, q => {
          q.content = newQuestions[q.key].content
          q.choices = newQuestions[q.key].choices
        })

        return draft
      }
      default: {
        return state
      }
    }
  })

export const getCurrentPacket = createSelector(
  state => state,
  state => state?.qcScans.currentPacket
)

export const getCurrentPacketTurfId = createSelector(
  getCurrentPacket,
  currentPacket => currentPacket.turf.id
)

export const getCurrentPacketVisualReviewResponses = createSelector(
  getCurrentPacket,
  currentPacket => currentPacket?.turf.visual_review_responses || []
)

export const getCurrentVoterRegistrationScans = createSelector(
  [getCurrentPacket],
  ({ scans }) => (scans.length ? sortBy(scans, 'scan_number') : [])
)

export const getSortedScanIds = createSelector(
  [getCurrentVoterRegistrationScans],
  scans => (scans.length ? scans.map(s => s.id) : [])
)

export const getVisualUnreviewedScans = createSelector(
  [getCurrentVoterRegistrationScans],
  scans => scans.filter(s => !s.visual_reviews.filter(vr => vr.user).length)
)

export const getVisualUnreviewedScanIds = createSelector(
  [getVisualUnreviewedScans],
  scans => scans.map(s => s.id)
)

export const getVisualUnapprovedScans = createSelector(
  [getCurrentVoterRegistrationScans],
  scans =>
    scans?.filter(s =>
      s.visual_reviews.every(r => r.response.response !== 'approved')
    )
)

export const getVisualUnapprovedScanIds = createSelector(
  [getVisualUnapprovedScans],
  scans => scans.map(s => s.id)
)

export const getCurrentPacketVisualQcComplete = createSelector(
  getCurrentVoterRegistrationScans,
  scans =>
    scans.every(
      scan =>
        scan.visual_reviews &&
        scan.visual_reviews.filter(vr => vr.user !== null).length > 0
    )
)

export const getContactedScansCount = createSelector(
  getCurrentVoterRegistrationScans,
  scans =>
    scans.reduce(
      (count, scan) =>
        scan.phone_verification_responses.some(r => r['contacted?'])
          ? count + 1
          : count,
      0
    )
)

export const getCurrentPacketShowPors = createSelector(
  getCurrentPacket,
  packet => packet.turf.voter_registration_config.show_pors
)

export const getCurrentPacketLocationState = createSelector(
  getCurrentPacket,
  packet => packet.location?.state
)

export const getCurrentPacketTurfLanguage = createSelector(
  getCurrentPacket,
  packet => packet.turf.language || ENGLISH
)

export const getCurrentPacketTurfScripts = createSelector(
  getCurrentPacket,
  packet => packet.turf.phone_verification_scripts || []
)

export const getPhoneUnreviewedScans = createSelector(
  getCurrentVoterRegistrationScans,
  scans => scans.filter(s => !s.phone_verification_responses.length)
)

export const getPhoneUnreviewedScanIds = createSelector(
  getPhoneUnreviewedScans,
  scans => scans.map(s => s.id)
)

export const getCurrentScan = createSelector(
  state => state,
  state => state?.qcScans.currentScan
)

export const getCurrentRegistrationForm = createSelector(
  [getCurrentScan],
  currentScan => (currentScan && currentScan.form ? currentScan.form : {})
)

export const getCurrentRegistrantCounty = createSelector(
  [getCurrentRegistrationForm],
  regForm => regForm.county
)

export const getCurrentScanVisualReviews = createSelector(
  getCurrentScan,
  currentScan => {
    const isApproved =
      currentScan.visual_reviews &&
      currentScan.visual_reviews.some(
        visualReview => visualReview.response.response === 'approved'
      )

    if (!currentScan.visual_reviews.length) {
      return {
        status: 'unreviewed',
        responses: [],
      }
    }

    return {
      status: isApproved ? 'approved' : 'issuesRaised',
      responses: currentScan.visual_reviews,
    }
  }
)

export const getApprovedResponseFromCurrentScan = createSelector(
  getCurrentScanVisualReviews,
  ({ responses }) =>
    orderBy(responses, 'created_at', 'desc').find(
      r => r.response.response === 'approved'
    )
)

export const getMostRecentVisualReview = createSelector(
  getCurrentScanVisualReviews,
  ({ responses }) => orderBy(responses, 'created_at', 'desc')[0]
)

export const getMostRecentVisualReviewResponses = createSelector(
  getCurrentScanVisualReviews,
  ({ responses }) => responses.map(response => response.response.description)
)

export const getPhoneVerificationResponses = createSelector(
  getCurrentScan,
  scan => scan.phone_verification_responses
)

export const getPreviousCallAttemptCount = createSelector(
  getPhoneVerificationResponses,
  responses => countScanAttempts(responses)
)

const getPhoneVerificationCalls = createSelector(
  getCurrentScan,
  currentScan => currentScan.phone_verification_calls
)

export const getPhoneVerificationQuestions = createSelector(
  state => state,
  state => state.qcScans.phoneVerificationQuestions
)

export const getVerifyingQuestionId = createSelector(
  getPhoneVerificationQuestions,
  questions => questions[REGISTERED]?.id
)

export const getCurrentLanguage = createSelector(
  state => state,
  state => state.qcScans.currentScriptLanguage
)

export const getCurrentSessionScript = createSelector(
  [
    getCurrentPacketTurfScripts,
    getCurrentPacketTurfLanguage,
    getCurrentLanguage,
  ],
  (customScripts, turfLanguage, userSetLanguage) => {
    const activeLanguage = userSetLanguage || turfLanguage
    const customScript = customScripts.find(
      script => script?.language === activeLanguage
    )
    return customScript?.structure || defaultScriptStructures[activeLanguage]
  }
)

export const getAreAllPhoneVerificationQuestionsAnswered = createSelector(
  getPhoneVerificationQuestions,
  getCurrentSessionScript,
  (phoneVerificationQuestions, currentSessionScript) =>
    requiredQuestionKeys
      .filter(questionKey =>
        currentSessionScript.find(
          scriptItem => scriptItem.questionKey === questionKey
        )
      )
      .every(questionKey => phoneVerificationQuestions[questionKey].response)
)

export const getFullCallHistory = createSelector(
  [
    getPhoneVerificationResponses,
    getVerifyingQuestionId,
    getPhoneVerificationCalls,
  ],
  (responses, verifyingId, phoneVerificationCalls) => {
    if (responses.length === 0) {
      return []
    }
    return formatAllAttempts(responses, verifyingId).map(formattedResponse => ({
      ...formattedResponse,
      failedCalls: getFailedCallsForResponse(
        responses,
        formattedResponse.call,
        phoneVerificationCalls
      ),
      notes: getCallNotes(responses, formattedResponse.call),
    }))
  }
)

export const getVerifiedScansCount = createSelector(
  getCurrentVoterRegistrationScans,
  getVerifyingQuestionId,
  (scans, verifyingId) => calculatePhoneVerifiedScanCount(scans, verifyingId)
)

export const getScansWithPhoneNumbersVerifiedPercent = createSelector(
  getCurrentVoterRegistrationScans,
  getVerifiedScansCount,
  (scans, verifiedCount) => {
    const scansWithPhone = scans.filter(scan => {
      const visualResponses = scan.visual_reviews.some(
        review =>
          review.response.implies_not_form ||
          review.response.implies_skips_phone_verification
      )

      const phoneResponses = scan.phone_verification_responses.some(
        phoneVerificationResponse =>
          phoneVerificationResponse.response === NO_PHONE
      )

      return !visualResponses && !phoneResponses
    })

    if (!scansWithPhone.length) return 100
    return Math.round((verifiedCount / scansWithPhone.length) * 100)
  }
)

export const getCallableScans = createSelector(
  getCurrentVoterRegistrationScans,
  getVerifyingQuestionId,
  (scans, verifyingId) =>
    scans.filter(scan => {
      const contacted = scan.phone_verification_responses.find(
        r => r.phone_verification_question_id === verifyingId
      )

      const isCallable =
        !getIsScanExcludedByVisualReview(scan) &&
        !getIsScanExcludedByPreviousNotContactedResponse(scan)

      return !contacted && isCallable
    })
)

export const getCurrentPacketPhoneVerificationComplete = createSelector(
  getCallableScans,
  getCurrentScan,
  getScansWithPhoneNumbersVerifiedPercent,
  (scans, currentScan, allScansVerifiedPercent) => {
    const {
      min_phone_verification_rounds: turfVerificationRounds,
      min_phone_verification_percent: TurfVerificationPercent,
    } = currentScan?.turf || {}

    return isPhoneVerificationComplete(
      scans,
      allScansVerifiedPercent,
      turfVerificationRounds,
      TurfVerificationPercent
    )
  }
)
