import { useEffect } from 'react'
import { connect } from 'react-redux'
import { getCurrentFlagDuplicates, getCurrentFlag } from 'store/qcFlags/reducer'
import { SectionLabel, CardError, Section, TextBlock } from 'components'
import { useReactRouter, useRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { requestDuplicateFlags } from 'store/qcFlags/actions'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import styles from './FlagSingleDuplicateFlagsList.module.scss'

const FlagSingleDuplicateFlagsList = ({
  duplicateFlags,
  requestDuplicateFlags,
  currentFlag,
}) => {
  const { match, history } = useReactRouter()
  const { t } = useTranslation()

  const { currentTurfPerformsExternalQC } = useCurrent()

  const { isLoading, errors, makeRequest } = useRequest(requestDuplicateFlags)
  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    makeRequest(currentTurfPerformsExternalQC)
  }, [currentFlag])

  if (!duplicateFlags.length || isLoading) return null

  return (
    <Section>
      <div className={styles.header}>
        <TextBlock>
          <SectionLabel>
            {t('Duplicate flags preventing restoration')}
          </SectionLabel>
        </TextBlock>
      </div>
      <CardError hide={!errorMsg} />
      {duplicateFlags.map(duplicateFlag => (
        <div className={styles.wrapper} key={`${duplicateFlag.id}`}>
          <span className={styles.id}>{`Flag ${duplicateFlag.id}`}</span>
          <span
            className={styles.flagName}
            onClick={() =>
              history.push(
                `/quality_control/flags/${match.params.type}/${duplicateFlag.id}/${match.params.originalType}`
              )
            }
          >
            {duplicateFlag.trigger.name}
          </span>
        </div>
      ))}
    </Section>
  )
}

export default connect(
  state => ({
    duplicateFlags: getCurrentFlagDuplicates(state),
    currentFlag: getCurrentFlag(state),
  }),
  { requestDuplicateFlags }
)(FlagSingleDuplicateFlagsList)
