import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useReactRouter } from 'hooks'
import { View, ViewContainer, HeaderBlock, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  FieldBlock,
  Font,
  TextBlock,
  Sheet,
  ProgressBar,
  DateField,
  Grid,
  Section,
  SelectField,
  NumberField,
} from '@politechdev/blocks-design-system'
import { dateFormat } from 'utils/constants'
import { useRequest } from 'hooks/useRequest'
import { fetchGoal, deleteGoal, putGoal } from 'requests/fieldManagement'
import moment from 'moment'
import { formatDateStringAsISOString } from 'utils/formatting'
import { useCurrent, useTurfs } from 'contexts/index'
import GoalSummaryContextProvider, {
  GoalSummaryContext,
} from '../GoalSummaryContext/GoalSummaryContext'
import OtherGoalsList from '../OtherGoalsList/OtherGoalsList'
import GoalSummary from '../GoalSummary/GoalSummary'
import GoalBreakdown from '../GoalBreakdown/GoalBreakdown'
import HandleDeltaModal from '../HandleDeltaModal/HandleDeltaModal'
import GoalDeleteWarningModal from '../GoalDeleteWarningModal/GoalDeleteWarningModal'
import OtherGoalsListContextProvider, {
  OtherGoalsListContext,
} from '../OtherGoalsList/OtherGoalsListContext'
import generateDisabledGoalDates from '../generateDisabledGoalDates'

const formatTargetForBreakdown = (target, index) => ({
  index,
  startDate: target.start_date,
  endDate: target.end_date,
  label: `${moment(target.start_date).format(dateFormat)} - ${moment(
    target.end_date
  ).format(dateFormat)}`,
  registrationsToCollect: target.forms_to_collect,
  registrationsCollected: target.registrations_collected,
})

const GoalSingle = () => {
  const { t } = useTranslation()
  const { goalTagOptions: tagOptions, doesCurrentUserHavePermission } =
    useCurrent()

  const { preparedTurfs: turfs } = useTurfs()

  const canModify = doesCurrentUserHavePermission({
    resource: 'field_management/goal',
    ability: 'modify',
  })
  const canDelete = doesCurrentUserHavePermission({
    resource: 'field_management/goal',
    ability: 'remove',
  })
  const {
    totalStartDate,
    setTotalStartDate,
    totalEndDate,
    setTotalEndDate,
    totalTarget,
    weeklySum,
    setTotalTarget,
    targets,
    setTargets,
    isSplit,
  } = useContext(GoalSummaryContext)
  const { match, history } = useReactRouter()
  const [errorMessage, setErrorMessage] = useState(null)
  const [maxAvailableDate, setMaxAvailableDate] = useState()
  const [disabledDates, setDisabledDates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [goalId, setGoalId] = useState()
  const [selectedTurf, setSelectedTurf] = useState()
  const [selectedTag, setSelectedTag] = useState([])
  const [handleDeltaModalIsVisible, setHandleDeltaModalIsVisible] =
    useState(false)
  const { otherGoals } = useContext(OtherGoalsListContext)

  useEffect(() => {
    const [incomingMaxAvailableDate, incomingDisabledDates] =
      generateDisabledGoalDates({
        otherGoals,
        totalStartDate,
      })
    setMaxAvailableDate(incomingMaxAvailableDate)
    setDisabledDates(incomingDisabledDates)
  }, [otherGoals, totalStartDate])

  const fetchGoalCallbacks = {
    onSuccess: response => {
      const goalData = response['field_management/goal']
      setGoalId(goalData.id)
      setTotalStartDate(goalData.start_date)
      setTotalEndDate(goalData.end_date)
      setSelectedTurf(goalData.turf_id)
      setTargets(
        goalData.targets.map((target, index) =>
          formatTargetForBreakdown(target, index)
        )
      )
      setSelectedTag(goalData.labels[0])
      setTotalTarget(weeklySum)
      setHandleDeltaModalIsVisible(false)
    },
    onError: () => {
      setErrorMessage(
        'An internal error occurred while trying to find this goal'
      )
    },
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: makeFetchGoalReq } = useRequest(
    () =>
      fetchGoal({
        id: match.params.id,
        fields: [
          'id',
          'start_date',
          'end_date',
          'labels',
          'turf_id',
          'targets',
        ],
      }),
    fetchGoalCallbacks
  )

  const updateGoalCallbacks = {
    onSuccess: () => {
      makeFetchGoalReq()
      setIsLoading(false)
      history.push('/field-management/goals')
    },
    onError: () => {
      setErrorMessage('An error occurred while trying to update this goal')
      setIsLoading(false)
    },
  }

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: makePutGoalReq } = useRequest(
    (reqBody, id) => putGoal(reqBody, id),
    updateGoalCallbacks
  )

  const deleteGoalCallbacks = {
    onSuccess: () => {
      history.push('/field-management/goals')
    },
    onError: () => {},
  }

  useEffect(() => {
    makeFetchGoalReq()
    setTotalTarget(weeklySum)
  }, [])

  useEffect(() => {
    if (!totalTarget) {
      setTotalTarget(weeklySum)
    }
  }, [weeklySum])

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: makeDeleteGoalReq } = useRequest(
    () => deleteGoal(match.params.id),
    deleteGoalCallbacks
  )

  const formatTargetsForReq = target => ({
    start_date: target.startDate,
    end_date: target.endDate,
    forms_to_collect: +target.registrationsToCollect,
  })

  const handleUpdateGoal = () => {
    setIsLoading(true)
    setErrorMessage(null)
    const newTargets = isSplit
      ? Object.values(targets).map(formatTargetsForReq)
      : [
          {
            start_date: totalStartDate,
            end_date: totalEndDate,
            forms_to_collect: totalTarget,
          },
        ]

    const goalReqObject = {
      id: match.params.id,
      start_date: totalStartDate,
      end_date: totalEndDate,
      labels: [selectedTag],
      turf_id: selectedTurf,
      targets: newTargets,
    }

    makePutGoalReq(goalReqObject, match.params.id)
  }

  const checkIfCanSave = () => {
    if (+weeklySum === +totalTarget || !isSplit) {
      handleUpdateGoal()
    } else {
      setHandleDeltaModalIsVisible(true)
    }
  }

  const handleDeleteGoal = () => {
    setIsLoading(true)
    setDeleteModalIsOpen(true)
  }

  const selectedTurfName = turfs.find(turf => turf.id === selectedTurf)?.name

  const isValid = totalStartDate && totalEndDate && totalTarget && selectedTag

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <HeaderBlock
            title={`Goal for ${selectedTurfName || '...'}`}
            subtitle={`${moment(totalStartDate).format(dateFormat)} - ${moment(
              totalEndDate
            ).format(dateFormat)}`}
          >
            <ButtonBlock justify="right">
              {canDelete && (
                <Button.Danger
                  aria-label={t('Delete goal')}
                  onClick={handleDeleteGoal}
                >
                  <Icon.TrashAlt />
                </Button.Danger>
              )}
              <Link to="/field-management/goals">
                <Button.Secondary aria-label={t('Exit')}>
                  <Icon.Times />
                </Button.Secondary>
              </Link>
            </ButtonBlock>
          </HeaderBlock>
          <ProgressBar show={isLoading} />
          <CardError hide={!errorMessage} message={errorMessage} />
          <Grid>
            <Section>
              <FieldBlock variant="medium">
                <DateField
                  label={t('Goal date range')}
                  type="range"
                  startDate={formatDateStringAsISOString(totalStartDate)}
                  endDate={formatDateStringAsISOString(totalEndDate)}
                  onChange={(start, end) => {
                    setTotalStartDate(start)
                    setTotalEndDate(end)
                  }}
                  disabled={isSplit || !canModify}
                  max={maxAvailableDate}
                  disabledDates={disabledDates}
                  required
                />
              </FieldBlock>
              <FieldBlock>
                <NumberField
                  label={t('Registrations to collect')}
                  value={totalTarget}
                  onChange={val => {
                    setTotalTarget(val)
                  }}
                  disabled={isSplit || !canModify}
                  required
                />
              </FieldBlock>
              {isSplit && (
                <TextBlock>
                  <Icon.InfoCircle />
                  <Font.Copy variant="hint">
                    Main goal data cannot be edited while the goal is split.
                    Please revert the goal split below if you want to make
                    changes to the main goal.
                  </Font.Copy>
                </TextBlock>
              )}
              <FieldBlock>
                <SelectField
                  label={t('Tag')}
                  value={selectedTag}
                  onChange={val => {
                    setSelectedTag(val)
                  }}
                  options={tagOptions}
                  required
                  disabled={!canModify}
                />
              </FieldBlock>
            </Section>
            <OtherGoalsList turfId={selectedTurf} goalId={goalId} />
          </Grid>
          <GoalSummary />
          <GoalBreakdown readonly={!canModify} />
        </Sheet>
      </ViewContainer>
      <ButtonBlock>
        <Button.Accent
          onClick={checkIfCanSave}
          disabled={!isValid || !canModify}
        >
          Save
        </Button.Accent>
        <Link to="/field-management/goals">
          <Button.Secondary>Cancel</Button.Secondary>
        </Link>
      </ButtonBlock>
      <HandleDeltaModal
        isVisible={handleDeltaModalIsVisible}
        setIsVisible={setHandleDeltaModalIsVisible}
        handleCreateGoal={handleUpdateGoal}
      />
      <GoalDeleteWarningModal
        isOpen={deleteModalIsOpen}
        setIsOpen={setDeleteModalIsOpen}
        setIsLoading={setIsLoading}
        goalToDelete={match.params.id}
        goalDeletionRequest={makeDeleteGoalReq}
      />
    </View>
  )
}

export default () => (
  <GoalSummaryContextProvider>
    <OtherGoalsListContextProvider>
      <GoalSingle />
    </OtherGoalsListContextProvider>
  </GoalSummaryContextProvider>
)
