import { Button, ButtonBlock } from 'components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentPacket } from 'store/qcScans/reducer'
import { ACTIVE, REVIEW } from 'constants/phoneBankSessions'
import { getDaysLeft } from 'qualityControl/utils'
import { useCallSessionContext } from 'phone_banks/components/CallSessionContext/CallSessionContext'
import { Font } from '@politechdev/blocks-design-system'
import styles from './PacketPaginationControls.module.scss'

const PacketPaginationControls = ({
  currentScanIndex,
  scansCount,
  goToNext,
  goToPrevious,
  callQueueAllScansCountText,
}) => {
  const { t } = useTranslation()
  const { callStep } = useCallSessionContext()
  const currentPacket = useSelector(getCurrentPacket)
  const isDisabledByCallStep = callStep === ACTIVE || callStep === REVIEW
  const callableScanCountText = `${t('Scan')} ${
    currentScanIndex + 1
  } of ${scansCount} ${t(' in call queue')}`

  return (
    <div
      className={styles.paginator}
      role="navigation"
      aria-label="scans navigation controls"
    >
      <ButtonBlock>
        <Button
          className={styles.paginator__button}
          floating
          onClick={goToPrevious}
          disabled={
            isDisabledByCallStep || scansCount < 2 || currentScanIndex === 0
          }
        >
          arrow_back
        </Button>
      </ButtonBlock>
      <div className={styles.paginator__detail}>
        <span>
          <Font.Copy>{currentPacket.scan_name}</Font.Copy>
        </span>
        <span>
          <Font.Copy>{callableScanCountText}</Font.Copy>
        </span>
        <div>
          <Font.Copy variant="hint">
            {callQueueAllScansCountText && (
              <span className={styles.aside}>{callQueueAllScansCountText}</span>
            )}
            <span>{` - ${getDaysLeft(currentPacket)}`}</span>
          </Font.Copy>
        </div>
      </div>
      <ButtonBlock>
        <Button
          className={styles.paginator__button}
          floating
          onClick={goToNext}
          disabled={
            isDisabledByCallStep ||
            scansCount < 2 ||
            currentScanIndex + 1 === scansCount
          }
        >
          arrow_forward
        </Button>
      </ButtonBlock>
    </div>
  )
}

export default PacketPaginationControls
