import { useState } from 'react'
import { ButtonBlock, ContentBlock } from 'components'
import { Modal } from '@politechdev/blocks-design-system'
import { Button, FontIcon } from 'react-md'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'
import IssueModal from './IssueModal/IssueModal'

const RedoQcButton = ({ redoResponses, isAutoReviewed, issuesRaised }) => {
  const { t } = useTranslation()
  const [visible, setVisibility] = useState(false)
  const [issueModalVisible, setIssueModalVisibility] = useState(false)
  const { doesCurrentUserHavePermission } = useCurrent()
  const canPerformVisualQC = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'visual_qc',
  })

  return (
    <>
      <Button
        className="button--warn"
        raised
        secondary
        disabled={!canPerformVisualQC}
        onClick={() =>
          isAutoReviewed ? setIssueModalVisibility(true) : setVisibility(true)
        }
        iconBefore={false}
        iconEl={<FontIcon className="material-icons-outlined">redo</FontIcon>}
      >
        {isAutoReviewed ? t('Edit Issues') : t('Redo QC')}
      </Button>
      {isAutoReviewed ? (
        <IssueModal
          isOpen={issueModalVisible}
          closeModal={() => setIssueModalVisibility(false)}
          presetIssueIds={issuesRaised}
        />
      ) : (
        <Modal
          id="redo-qc-modal"
          title={t('Redo scan review?')}
          isOpen={visible}
          onHide={() => setVisibility(false)}
        >
          <Modal.Body>
            <ContentBlock>
              <p>
                {t(
                  'Redoing a scan review will delete the review information for this scan and set it back as unreviewed'
                )}
              </p>
            </ContentBlock>
          </Modal.Body>
          <Modal.Actions>
            <ButtonBlock justify="right">
              <Button flat onClick={() => setVisibility(false)}>
                {t('Cancel')}
              </Button>
              <Button
                secondary
                flat
                className="button--warn"
                onClick={redoResponses}
              >
                {t('Redo QC')}
              </Button>
            </ButtonBlock>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default RedoQcButton
