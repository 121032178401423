import { useTranslation } from 'react-i18next'
import { TabsContainer, Tabs, Tab } from 'react-md'
import { useCurrent } from 'contexts/index'
import OrganizationTeamTable from '../OrganizationTeamTable/OrganizationTeamTable'
import OrganizationMemberTable from '../OrganizationMemberTable/OrganizationMemberTable'
import styles from './OrganizationTabs.module.scss'

const OrganizationTabs = () => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()

  const canViewOrganizationTeams = doesCurrentUserHavePermission({
    resource: 'team',
    ability: 'view',
  })

  const canViewOrganizationMembership = doesCurrentUserHavePermission({
    resource: 'organization_membership',
    ability: 'view',
  })

  if (!canViewOrganizationTeams && !canViewOrganizationMembership) return null

  return (
    <TabsContainer
      slideHeightProp="maxHeight"
      swipeableViewsClassName={styles.container}
    >
      <Tabs tabId="organization-tabs" className={styles.tabs}>
        {canViewOrganizationTeams && (
          <Tab label={t('Teams')} id="teams">
            <OrganizationTeamTable />
          </Tab>
        )}
        {canViewOrganizationMembership && (
          <Tab label={t('Members')} id="members">
            <OrganizationMemberTable />
          </Tab>
        )}
      </Tabs>
    </TabsContainer>
  )
}

export default OrganizationTabs
