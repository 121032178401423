import moment from 'moment-timezone'
import {
  READY_FOR_QC,
  IN_QC,
  READY_FOR_PHONE_VERIFICATION,
  IN_PHONE_VERIFICATION,
  READY_FOR_DATA_ENTRY,
  IN_DATA_ENTRY,
  COMPLETED,
} from '../constants/qualityControl'

export const packetStatus = packet => packet.shift.status

export const isPacketInVizQc = packet =>
  packet.shift.status === READY_FOR_QC || packet.shift.status === IN_QC

export const isPacketInPhoneVerification = packet =>
  packet.shift.status === READY_FOR_PHONE_VERIFICATION ||
  packet.shift.status === IN_PHONE_VERIFICATION

export const isPacketInDataEntry = packet =>
  packet.shift.status === READY_FOR_DATA_ENTRY ||
  packet.shift.status === IN_DATA_ENTRY

export const isPacketCompleted = packet => packet.shift.status === COMPLETED

export const getPacketUSState = packet => {
  const {
    location: { state },
  } = packet
  return state
}

export const getPacketTimeZoneName = packet =>
  packet.location.timezone?.name || packet.creator.time_zone

export const getPacketTimeZoneOffset = packet =>
  packet.location.timezone?.utc_offset

export const getPacketDaysSinceCollection = packet =>
  packet.shift.shift_end ? moment().diff(packet.shift.shift_end, 'days') : null

export const getPacketDaysLeft = packet => {
  const tz = getPacketTimeZoneName(packet)

  return moment
    .utc(packet.qc_deadline)
    .tz(tz)
    .startOf('day')
    .diff(moment.tz(tz).startOf('day'), 'days')
}

export const augmentPacket = packet => {
  const augmentedPacket = { ...packet }
  augmentedPacket.status = packetStatus(packet)
  augmentedPacket.in_viz_qc = isPacketInVizQc(packet)
  augmentedPacket.in_phone_verification = isPacketInPhoneVerification(packet)
  return augmentedPacket
}

export const getStatusForTableReadyPackets = packet => {
  switch (packet.shift.status) {
    case READY_FOR_QC:
      return 'Ready for Visual QC'
    case IN_QC:
      return 'In Visual QC'
    case READY_FOR_PHONE_VERIFICATION:
      return 'Ready for Phone Verification'
    case IN_PHONE_VERIFICATION:
      return 'In Phone Verification'
    case READY_FOR_DATA_ENTRY:
      return 'Ready for Data Entry'
    case IN_DATA_ENTRY:
      return 'In Data Entry'
    case COMPLETED:
      return 'Completed'
    default:
      return ''
  }
}

export const inboxPacketFilters = {
  rules: [
    {
      column: 'shift_status',
      operator: 'in',
      param: [
        'ready_for_qc',
        'in_qc',
        'ready_for_phone_verification',
        'in_phone_verification',
        'completed',
      ],
    },
  ],
}

export const removeEmptyFilters = (filters = []) =>
  filters.filter(filter => filter.param == null || filter.param.length !== 0)

export const getFormattedPacketCreationDate = packet =>
  moment.utc(packet.created_at).tz('America/New_York').format('MM/DD/YYYY')

export const getFormattedPacketShiftStartDate = packet =>
  moment
    .utc(packet.shift.shift_start)
    .tz('America/New_York')
    .format('MM/DD/YYYY')

export const positionSort = (a, b) => a.position - b.position
