import {
  REGISTERED,
  FEEDBACK,
  ADDRESS,
  DOB,
  ENGLISH,
  SPANISH,
} from './phoneVerificationQuestions'

export const defaultLanguages = { en: 'en', es: 'es' }

const defaultScriptIntro = {
  [ENGLISH]: {
    1: 'Hi [registrant name]? Or may I please speak with [registrant name]?',
    2: 'My name is [caller name], and I’m calling to thank you for filling out a voter registration form with [organization name].',
    3: 'We just want to make sure we have your correct information on the application before we turn it into the Board of Elections.',
  },
  [SPANISH]: {
    1: 'Hola, [registrant name]? Con [registrant name] por favor? ',
    2: 'Mi nombre es [caller name] y estoy llamando para darle las gracias por haber llenado una aplicación de registración para votar con [organization name].',
    3: 'Antes de entregarle su tarjeta al condado, queríamos confirmar varias cositas en su aplicación para asegurarnos que todo está correcto, y suele tomar como 30 segundos.',
  },
}

const defaultScriptDialog = {
  [ENGLISH]: {
    1: 'Great! Thank you again for registering to vote.',
    2: '[Organization name] is going to turn in your form to the Board of Elections and you should receive your official voter registration card at the address you registered within 6 weeks.',
    3: 'If you don’t receive your voter registration card in 6 weeks , we recommend you contact the County Board of Elections office directly and ask what else they need to process your application. Would you like that number?',
  },
  [SPANISH]: {
    1: 'Perfecto! Gracias de nuevo por registrarse para votar.',
    2: 'Deberá de recibir su tarjeta de votante dentro de 6 semanas por correo a la dirección en su aplicación.',
    3: 'Si a las 6 semanas, no le ha llegado, nosotros recomendamos que usted contacte al condado directamente para ver que mas necesitan para procesar su aplicación. Necesita ese número?',
  },
}

export const defaultScriptStructures = {
  [ENGLISH]: [
    {
      type: 'dialogue',
      content: defaultScriptIntro[ENGLISH][1],
      position: 1,
    },
    {
      type: 'dialogue',
      content: defaultScriptIntro[ENGLISH][2],
      position: 2,
    },
    {
      type: 'dialogue',
      content: defaultScriptIntro[ENGLISH][3],
      position: 3,
    },
    {
      type: 'single_choice',
      questionKey: REGISTERED,
      position: 4,
    },
    {
      type: 'single_choice',
      questionKey: DOB,
      position: 6,
    },
    {
      type: 'single_choice',
      questionKey: ADDRESS,
      position: 7,
    },
    {
      type: 'single_choice',
      questionKey: FEEDBACK,
      position: 8,
    },
    {
      type: 'dialogue',
      content: defaultScriptDialog[ENGLISH][1],
      position: 11,
    },
    {
      type: 'dialogue',
      content: defaultScriptDialog[ENGLISH][2],
      position: 12,
    },
    {
      type: 'dialogue',
      content: defaultScriptDialog[ENGLISH][3],
      position: 13,
    },
  ],
  [SPANISH]: [
    {
      type: 'dialogue',
      content: defaultScriptIntro[SPANISH][1],
      position: 1,
    },
    {
      type: 'dialogue',
      content: defaultScriptIntro[SPANISH][2],
      position: 2,
    },
    {
      type: 'dialogue',
      content: defaultScriptIntro[SPANISH][3],
      position: 3,
    },
    {
      type: 'single_choice',
      questionKey: REGISTERED,
      position: 4,
    },
    {
      type: 'single_choice',
      questionKey: DOB,
      position: 6,
    },
    {
      type: 'single_choice',
      questionKey: ADDRESS,
      position: 7,
    },
    {
      type: 'single_choice',
      questionKey: FEEDBACK,
      position: 8,
    },
    {
      type: 'dialogue',
      content: defaultScriptDialog[SPANISH][1],
      position: 11,
    },
    {
      type: 'dialogue',
      content: defaultScriptDialog[SPANISH][2],
      position: 12,
    },
    {
      type: 'dialogue',
      content: defaultScriptDialog[SPANISH][3],
      position: 13,
    },
  ],
}
