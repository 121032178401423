import { useRequest } from 'hooks'
import {
  createFlagComment,
  deleteFlagComment,
  fetchFlag,
  updateFlag,
  updateFlagComment,
} from 'store/qcFlags/requests'
import { CardError, LoadBar, Sheet, SectionLabel } from 'components'
import { DeliveriesFlagSingleActionPlan } from 'qualityControl/flags/FlagSingleActionPlan'
import { DeliveriesFlagSingleComments } from 'qualityControl/flags/FlagSingleComments/FlagSingleComments'
import { useState, useEffect } from 'react'
import { formatErrorMessage } from 'utils/formatting'

const FlagViewer = ({ id }) => {
  const [flag, setFlag] = useState({
    comments: [],
    action_plan: '',
    trigger: { name: '' },
  })
  const {
    makeRequest: fetchFlagRequest,
    isLoading,
    hasErrors,
    isRequestComplete,
    errors,
  } = useRequest(fetchFlag, response =>
    setFlag(response['quality_control/flag'])
  )

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    fetchFlagRequest(id, {
      fields: [
        'id',
        'action_plan',
        {
          viewed_by_users: ['email'],
        },
        {
          comments: [
            'id',
            'body',
            'created_at',
            { viewed_by_users: ['email'] },
            { author: ['id', 'name', 'email'] },
          ],
        },
        { trigger: ['id', 'name'] },
      ],
    })
  }, [])

  const updateActionPlan = async actionPlan => {
    await updateFlag(id, { action_plan: actionPlan })
    await fetchFlagRequest(id)
  }

  const updateComment = async (commentId, commentBody) => {
    const updatedComment = await updateFlagComment(id, commentId, commentBody, {
      fields: ['id', 'body', 'created_at', { author: ['id', 'name', 'email'] }],
    })

    setFlag({
      ...flag,
      comments: flag.comments.map(comment =>
        comment.id === updatedComment.id ? updatedComment : comment
      ),
    })
  }

  const createComment = async commentBody => {
    const response = await createFlagComment(id, commentBody, {
      fields: ['id', 'body', 'created_at', { author: ['id', 'name', 'email'] }],
    })

    const createdComment = response['quality_control/comment']

    setFlag({
      ...flag,
      comments: [...flag.comments, createdComment],
    })
  }

  const deleteComment = async commentId => {
    await deleteFlagComment(id, commentId)

    setFlag({
      ...flag,
      comments: flag.comments.filter(comment => comment.id !== commentId),
    })
  }

  const { action_plan, comments, trigger } = flag
  return (
    <Sheet title={trigger.name}>
      <LoadBar show={isLoading} />
      <CardError hide={!hasErrors} message={errorMsg} />
      {isRequestComplete && flag.id !== undefined && (
        <>
          <SectionLabel primary>Action Plan</SectionLabel>
          <DeliveriesFlagSingleActionPlan
            actionPlan={action_plan}
            saveActionPlanEdits={updateActionPlan}
          />
          <SectionLabel accent>Resolution and Response</SectionLabel>
          <DeliveriesFlagSingleComments
            comments={comments}
            createComment={createComment}
            saveCommentEdits={updateComment}
            deleteComment={deleteComment}
          />
        </>
      )}
    </Sheet>
  )
}

export default FlagViewer
