import { fetchJSON, stringifyParams } from 'utils/req'

export const getAssignableScans = params =>
  fetchJSON(
    `/api/v1/scans/assignable_for_qc?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const deletePacket = id =>
  fetchJSON(`/api/v1/packets/${id}`, 'DELETE', null, {
    useJwt: true,
  })
