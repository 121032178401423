import { connect } from 'react-redux'
import {
  ButtonBlock,
  Button,
  Icon,
  ProgressBar,
  useToast,
} from '@politechdev/blocks-design-system'
import { CardError } from 'components'
import { useTranslation } from 'react-i18next'
import { useRouteQueryParams, DEPRECATED_useRequest } from 'hooks'
import { getSortedScanIds } from 'store/qcScans/reducer'
import { approveScan } from 'store/qcScans/actions'
import { useCurrent } from 'contexts/index'
import { useInbox } from 'qualityControl/inbox/InboxContext'

const ReviewSelectionPanel = ({
  toggleIssuePanel,
  approveScan,
  canReview,
  sortedScanIds,
}) => {
  const { t } = useTranslation()
  const [{ scanId: stringScanId }, setQueryParams] = useRouteQueryParams()
  const { doesCurrentUserHavePermission } = useCurrent()
  const { visualReviewApprovedOption } = useInbox()
  const { setToast } = useToast()
  const allowedToReview =
    canReview &&
    doesCurrentUserHavePermission({
      resource: 'shift',
      ability: 'visual_qc',
    })
  const scanId = +stringScanId
  const currentScanIndex = sortedScanIds.indexOf(scanId)

  const goToNextScan = () => {
    setQueryParams({ scanId: sortedScanIds[currentScanIndex + 1] })
  }

  const {
    isLoading: approveReqLoading,
    errorMsg: approveReqErrorMsg,
    makeRequest: approveReq,
  } = DEPRECATED_useRequest(approveScan, () => {
    setToast({ message: t('Scan approved'), variant: 'success' })
    goToNextScan()
  })

  return (
    <>
      <ButtonBlock justify="center">
        <Button.Danger disabled={!allowedToReview} onClick={toggleIssuePanel}>
          <span>{t('Needs attention')}</span>
          <Icon.CircleExclamation />
        </Button.Danger>
        <Button.Accent
          disabled={!allowedToReview}
          onClick={() => approveReq({ visualReviewApprovedOption })}
        >
          <span>{t('Approve form')}</span>
          <Icon.Check />
        </Button.Accent>
      </ButtonBlock>
      <ProgressBar show={approveReqLoading} />
      <CardError hide={!approveReqErrorMsg} />
    </>
  )
}

export default connect(
  state => ({
    sortedScanIds: getSortedScanIds(state),
  }),
  { approveScan }
)(ReviewSelectionPanel)
