import { useTranslation } from 'react-i18next'
import { View, ViewContainer, Sheet, HeaderBlock } from 'components'
import TeamForm from '../TeamForm/TeamForm'

const TeamNew = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <HeaderBlock title={t('New Team')} />
          <TeamForm />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default TeamNew
