import { View, Sheet, ContentBlock, HeaderBlock } from 'components'
import { useTranslation } from 'react-i18next'
import FormsTable from './FormsTable'

const FormsSelect = () => {
  const { t } = useTranslation()

  return (
    <View>
      <HeaderBlock title={t('Registration Forms')} />
      <ContentBlock>
        <Sheet>
          <FormsTable />
        </Sheet>
      </ContentBlock>
    </View>
  )
}

export default FormsSelect
