import { CardError, List, ListItem, LoadBar, Checkbox } from 'components'
import { ButtonBlock } from '@politechdev/blocks-design-system'
import { connect } from 'react-redux'
import { useEffect, useContext } from 'react'
import {
  getFilters,
  getFlagDataToReview,
  getToReviewListPage,
} from 'store/qcFlags/reducer'
import { requestPendingFlags } from 'store/qcFlags/actions'
import { useReactRouter, useRequest } from 'hooks'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import {
  doesFlagHaveUnreadComments,
  getFlagItemPrimaryText,
  getFlagItemSecondaryText,
  isFlagTechnical,
} from './utils'
import { ToReviewListPager } from './ListPager'
import ReviewListIconSet from './ReviewListIconSet/ReviewListIconSet'
import ViewFlagButton from './buttons/ViewFlagButton'
import DeleteFlagButton from './buttons/DeleteFlagButton'
import { usePacketAssignment } from '../packetAssignment/PacketAssignmentContext'

const FlagListItemAction = ({ flag, typeOverride, reloadFlags }) => {
  const { unassignPacket } = usePacketAssignment()
  return (
    <ButtonBlock>
      <ViewFlagButton flagId={flag.id} typeOverride={typeOverride} />
      <DeleteFlagButton
        onDelete={async () => {
          if (isFlagTechnical(flag)) {
            await unassignPacket(flag.packet)
          }
          reloadFlags()
        }}
        flag={flag}
      />
    </ButtonBlock>
  )
}

const TableProgramReviewList = ({
  requestFlags,
  flags,
  currentPage,
  filters,
  checkboxContext,
  hideCheckbox,
  BlankSlate,
  typeOverride,
}) => {
  const { isChecked, toggleCheckbox, clearCheckboxes } =
    useContext(checkboxContext)
  const { currentUser, currentTurfPerformsExternalQC } = useCurrent()
  const {
    makeRequest: makeRequestFlags,
    isLoading: isFlagsLoading,
    hasErrors: flagsHaveErrors,
    errors: flagsErrors,
    isRequestComplete: isFlagsRequestComplete,
  } = useRequest(requestFlags)

  const flagsErrorMsg = formatErrorMessage(flagsErrors)

  const { match } = useReactRouter()

  const reloadFlags = () =>
    makeRequestFlags(
      typeOverride !== undefined ? typeOverride : match.params.type,
      currentTurfPerformsExternalQC,
      currentUser.id
    )

  useEffect(() => {
    clearCheckboxes()
    reloadFlags()
  }, [currentPage, filters, currentUser.id])

  return (
    <>
      <LoadBar show={isFlagsLoading} />
      <CardError hide={!flagsHaveErrors} message={flagsErrorMsg} />
      {isFlagsRequestComplete && !!flags.length && (
        <>
          <List>
            {flags.map(flag => (
              <ListItem
                key={`flag-group-list-item-${flag.id}`}
                primaryText={getFlagItemPrimaryText(flag)}
                secondaryText={getFlagItemSecondaryText(flag, currentUser)}
                showAlertIcon={doesFlagHaveUnreadComments(flag, currentUser)}
                actions={
                  <FlagListItemAction
                    flag={flag}
                    typeOverride={typeOverride}
                    reloadFlags={reloadFlags}
                  />
                }
                Checkbox={
                  hideCheckbox ? undefined : (
                    <Checkbox
                      checked={isChecked(flag.id)}
                      onChange={() => toggleCheckbox(flag.id)}
                      id={`flag-checkbox-${flag.id}`}
                      name={`flag-checkbox-${flag.id}`}
                      aria-label="select column"
                      aria-labelledby={`flag-checkbox-${flag.id}`}
                    />
                  )
                }
                AdditionalContent={<ReviewListIconSet flag={flag} />}
              />
            ))}
          </List>
          <ToReviewListPager />
        </>
      )}
      {isFlagsRequestComplete && !flags.length && <BlankSlate />}
    </>
  )
}

export const PendingTableProgramReviewList = connect(
  state => ({
    flags: getFlagDataToReview(state),
    currentPage: getToReviewListPage(state),
    filters: getFilters(state),
  }),
  { requestFlags: requestPendingFlags }
)(TableProgramReviewList)
