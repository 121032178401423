import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, Sheet, HeaderBlock } from 'components'
import OrganizationForm from '../OrganizationForm/OrganizationForm'

const OrganizationNew = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <HeaderBlock title={t('New organization')} />
          <OrganizationForm />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default OrganizationNew
