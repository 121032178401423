import { combineReducers } from 'redux'
import qcScans from 'store/qcScans/reducer'
import { qcFlags } from './qcFlags/reducer'

const rootReducer = combineReducers({
  qcScans,
  qcFlags,
})

export default rootReducer
