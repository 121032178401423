import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
  TextField,
} from '@politechdev/blocks-design-system'
import { useEvent, useRequest } from 'hooks'
import { useCurrent } from 'contexts/index'
import { postTurfLevel } from 'requests/turfLevels'

const TurfLevelCreateModal = ({ onClose }) => {
  const { t } = useTranslation()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyTurfLevels = doesCurrentUserHavePermission({
    resource: 'turf_level',
    ability: 'modify',
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [name, setName] = useState('')

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    onClose && onClose()
    setModalOpen(false)
  }

  const { makeRequest, isLoading, hasErrors } = useRequest(postTurfLevel, {
    onSuccess: closeModal,
  })

  const save = useEvent(() => {
    makeRequest({ name })
  })

  return (
    <>
      <Button.Accent disabled={!canModifyTurfLevels} onClick={openModal}>
        {t('Add turf level')}
      </Button.Accent>
      <Modal
        id="turf-level-create"
        title={t(`Create turf level`)}
        isOpen={modalOpen}
        showLoading={isLoading}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t('There was an error creating your turf level')}
              hideSupportLink
            />
            <TextField
              id="turf-level-name"
              label={t('Turf level name')}
              value={name}
              onChange={setName}
              required
            />
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent onClick={save} disabled={name === '' || isLoading}>
              {t('Save')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default TurfLevelCreateModal
