import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import {
  CardError,
  HeaderBlock,
  LoadBar,
  UserSelectField,
  PersonSelectField,
  LocationSelectField,
  TimePicker,
  Sheet,
  View,
  CampaignSelectField,
} from 'components'
import {
  Button,
  TextBlock,
  ButtonBlock,
  FieldBlock,
  SelectField,
  DateField,
  TextField,
} from '@politechdev/blocks-design-system'
import { postMeeting } from 'requests/events'
import { LOCATION_TYPES } from 'constants/locations'
import { useRequest } from 'hooks/useRequest'
import { useForm, FormProvider, useCurrent } from 'contexts'
import { useHistory } from 'react-router'
import CreatePartialPersonModal from 'components/CreatePartialPerson/CreatePartialPersonModal'
import styles from './MeetingNew.module.scss'

const MeetingNew = () => {
  const { formData, setField } = useForm()
  const {
    currentUser: { time_zone: timezone },
    meetingTypeOptions,
    currentTurfActivismLanguages: languages,
  } = useCurrent()
  const [isCreateGuestOpen, setCreateGuestOpen] = useState(false)
  const [isCreateAdditionalGuestOpen, setCreateAdditionalGuestOpen] =
    useState(false)
  const [timeError, setTimeError] = useState(false)
  const [eventDay, setEventDay] = useState(moment().format('YYYY-MM-DD'))

  useEffect(() => {
    setTimeError(false)
    const { start_time, end_time } = formData
    if (moment(start_time).isAfter(moment(end_time))) {
      setTimeError(true)
    }
  }, [formData])

  const { t } = useTranslation()

  const history = useHistory()

  const {
    makeRequest: createMeetingReq,
    isLoading: isMeetingLoading,
    hasErrors: meetingError,
  } = useRequest(postMeeting, {
    onSuccess: ({ meeting }) => history.push(`/build/meetings/${meeting.id}`),
  })

  const error = meetingError
  const loading = isMeetingLoading

  const handleSave = () => {
    const {
      user,
      location,
      campaign,
      person,
      additional_person,
      start_time,
      end_time,
      ...submittableData
    } = formData

    const formattedStartTime = moment
      .tz(`${eventDay} ${start_time}`, timezone)
      .format()
    const formattedEndTime = moment
      .tz(`${eventDay} ${end_time}`, timezone)
      .format()

    const baseRequestData = {
      ...submittableData,
      start_time: formattedStartTime,
      end_time: formattedEndTime,
      user_id: user.id,
      person_id: person.id,
      additional_person_id: additional_person?.id || null,
      location_id: location?.id || null,
      campaign_id: campaign?.id || null,
    }

    return createMeetingReq(baseRequestData, { fields: ['id'] })
  }

  const isFormValid =
    eventDay &&
    formData.start_time &&
    formData.end_time &&
    formData.person &&
    formData.user

  return (
    <View>
      <HeaderBlock title={t('Create meeting')} />
      <Sheet className={styles['meeting-form']}>
        {meetingTypeOptions.length > 0 && (
          <FieldBlock>
            <SelectField
              id="meeting-type"
              label={t('Type')}
              options={meetingTypeOptions}
              value={formData.type}
              onSelect={val => setField(val, 'type')}
            />
          </FieldBlock>
        )}
        <FieldBlock>
          <DateField
            label={t('Meeting day')}
            value={eventDay && moment(eventDay).toISOString()}
            clearable
            onClear={() => setEventDay(null)}
            onChange={start => {
              setEventDay(moment(start).format('YYYY-MM-DD'))
            }}
            required
          />
        </FieldBlock>
        <FieldBlock>
          <TimePicker
            label="Start Time"
            id="event-start-time"
            onChange={val =>
              setField(moment(val).format('HH:mm:ss'), 'start_time')
            }
            initialTime={moment({ hour: 9 })}
            errors={timeError && 'Start time must be before end time.'}
          />
          <TimePicker
            label="End Time"
            id="event-end-time"
            onChange={val =>
              setField(moment(val).format('HH:mm:ss'), 'end_time')
            }
            initialTime={moment({ hour: 17 })}
          />
        </FieldBlock>
        <FieldBlock>
          <UserSelectField
            label={t('Host (type to search)')}
            onSelect={user => setField(user, 'user')}
            user={formData.user}
            required
          />
        </FieldBlock>
        <FieldBlock className={styles.guest}>
          <div className={styles.select}>
            <PersonSelectField
              id="guestSearch"
              label={t('Guest')}
              onSelect={person => setField(person, 'person')}
              person={formData.person}
              excludedPersonIds={
                formData.additional_person
                  ? [formData.additional_person.id]
                  : []
              }
              required
              canCreatePerson
              onSelectCreate={() => setCreateGuestOpen(true)}
            />
          </div>
          <CreatePartialPersonModal
            isOpen={isCreateGuestOpen}
            setIsOpen={setCreateGuestOpen}
            onCreated={person => setField(person, 'person')}
            languages={languages}
          />
        </FieldBlock>
        <FieldBlock className={styles.guest}>
          <div className={styles.select}>
            <PersonSelectField
              id="additionalGuestSearch"
              label={t('Additional guest')}
              clearable
              onClear={() => setField(null, 'additional_person')}
              onSelect={person => setField(person, 'additional_person')}
              person={formData.additional_person}
              excludedPersonIds={formData.person ? [formData.person.id] : []}
              canCreatePerson
              onSelectCreate={() => setCreateAdditionalGuestOpen(true)}
            />
          </div>
          <CreatePartialPersonModal
            isOpen={isCreateAdditionalGuestOpen}
            setIsOpen={setCreateAdditionalGuestOpen}
            onCreated={person => setField(person, 'additional_person')}
            languages={languages}
          />
        </FieldBlock>
        <FieldBlock>
          <LocationSelectField
            id="venue"
            label={t('Location (optional)')}
            onSelect={location => setField(location, 'location')}
            locationTypes={[LOCATION_TYPES.venue]}
            location={formData.location}
            clearable
          />
        </FieldBlock>
        <FieldBlock>
          <CampaignSelectField
            id="campaign"
            label={t('Campaign (optional)')}
            onSelect={campaign => setField(campaign, 'campaign')}
            campaign={formData.campaign || {}}
            clearable
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            label={t('Notes (optional)')}
            id="meetingNotes"
            value={formData.notes}
            onChange={notes => setField(notes, 'notes')}
          />
        </FieldBlock>
        <TextBlock>
          <CardError
            message={t(
              'An internal error occurred while trying to create this meeting. Please check your form entry and try again'
            )}
            hideSupportLink
            hide={!error}
          />
        </TextBlock>
        <ButtonBlock>
          <Button onClick={handleSave} disabled={!isFormValid}>
            {t('Create meeting')}
          </Button>
        </ButtonBlock>
        <LoadBar show={loading} />
      </Sheet>
    </View>
  )
}

export default () => (
  <FormProvider>
    <MeetingNew />
  </FormProvider>
)
