import { useState, useContext } from 'react'
import { useReactRouter } from 'hooks/router'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, HeaderBlock, TurfSelectField } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  FieldBlock,
  Sheet,
  Grid,
  Section,
  TextField,
  DateField,
} from '@politechdev/blocks-design-system'
import { createProjectionWeeks } from 'utils/fieldManagement'
import { formatDateStringAsISOString } from 'utils/formatting'
import { useTurfs } from 'contexts/index'
import useCreateProjection from '../Hooks/useCreateProjection'
import ProjectionSummary from '../ProjectionSummary/ProjectionSummary'
import ProjectionBreakdown from '../ProjectionBreakdown/ProjectionBreakdown'
import ProjectionContextProvider, {
  ProjectionContext,
} from '../ProjectionContext/ProjectionContext'
import ProjectionAssociatedGoal from '../ProjectionAssociatedGoal/ProjectionAssociatedGoal'
import ProjectionExitWarningModal from '../ProjectionExitWarningModal/ProjectionExitWarningModal'

const ProjectionNew = () => {
  const { t } = useTranslation()
  const { preparedTurfs: turfs } = useTurfs()
  const [name, setName] = useState('')
  const [exitModalIsOpen, setExitModalIsOpen] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [totalStartDate, setTotalStartDate] = useState()
  const [totalEndDate, setTotalEndDate] = useState()
  const [selectedTurfId, setSelectedTurfId] = useState()
  const { history } = useReactRouter()
  const { setProjection } = useCreateProjection(() =>
    history.push('/field-management/projections')
  )
  const { weeklyProjectedData, setWeeklyProjectedData } =
    useContext(ProjectionContext)

  const handleExit = () => {
    if (dirty) {
      setExitModalIsOpen(true)
    } else {
      history.push('/field-management/projections')
    }
  }

  const handleSave = () => {
    setProjection({
      startDate: totalStartDate,
      endDate: totalEndDate,
      name,
      turfId: selectedTurfId,
      weeklyProjectedData,
    })
  }

  const selectedTurf = turfs.find(turf => turf.value === selectedTurfId)

  const isValid = name && totalStartDate && totalEndDate && selectedTurfId

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <HeaderBlock title={t('New projection')}>
            <ButtonBlock justify="right">
              <Button.Secondary aria-label={t('Exit')} onClick={handleExit}>
                <Icon.Times />
              </Button.Secondary>
            </ButtonBlock>
          </HeaderBlock>
          <Grid>
            <Section>
              <FieldBlock>
                <TextField
                  label={t('Projection name')}
                  value={name}
                  onChange={name => {
                    setName(name)
                    setDirty(true)
                  }}
                  required
                />
              </FieldBlock>
              <FieldBlock>
                <TurfSelectField
                  label={t('Turf')}
                  value={selectedTurfId}
                  onSelect={turfId => {
                    setSelectedTurfId(turfId)
                    setDirty(true)
                  }}
                  turfs={turfs.filter(
                    turf => turf.voter_registration_enabled && !turf.archived
                  )}
                  required
                />
              </FieldBlock>
              <FieldBlock variant="medium">
                <DateField
                  label={t('Start date')}
                  type="range"
                  startDate={formatDateStringAsISOString(totalStartDate)}
                  endDate={formatDateStringAsISOString(totalEndDate)}
                  onChange={(start, end) => {
                    setTotalStartDate(new Date(start))
                    setTotalEndDate(new Date(end))
                    setWeeklyProjectedData(
                      createProjectionWeeks({
                        startDate: new Date(start),
                        endDate: new Date(end),
                      })
                    )
                    setDirty(true)
                  }}
                  required
                />
              </FieldBlock>
            </Section>
            {selectedTurf && totalStartDate && totalEndDate && (
              <ProjectionAssociatedGoal
                selectedTurf={selectedTurf}
                startDate={totalStartDate}
                endDate={totalEndDate}
              />
            )}
          </Grid>
          <ProjectionSummary />
          <ProjectionBreakdown setDirty={setDirty} />
          <ButtonBlock>
            <Button.Accent onClick={handleSave} disabled={!isValid}>
              Save
            </Button.Accent>
            <Link to="/field-management/projections">
              <Button.Secondary>Cancel</Button.Secondary>
            </Link>
          </ButtonBlock>
        </Sheet>
      </ViewContainer>
      <ProjectionExitWarningModal
        isOpen={exitModalIsOpen}
        setIsOpen={setExitModalIsOpen}
      />
    </View>
  )
}

export default () => (
  <ProjectionContextProvider>
    <ProjectionNew />
  </ProjectionContextProvider>
)
