import { useTranslation } from 'react-i18next'
import { View, ViewContainer, Sheet, HeaderBlock } from 'components'
import EventLocationForm from '../EventLocationForm/EventLocationForm'

const EventLocationCreate = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <HeaderBlock title={t('New Location')} />
          <EventLocationForm />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default EventLocationCreate
