import { CardError, List, ListItem } from 'components'
import {
  Section,
  ProgressBar,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { requestGroupFlags } from 'store/qcFlags/actions'
import { useEffect } from 'react'
import { useRequest } from 'hooks/useRequest'
import {
  getToReviewListPage,
  getFlagDataToReview,
  getFilters,
} from 'store/qcFlags/reducer'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import {
  getFlagItemReadStatus,
  getFlagItemPrimaryText,
  getFlagItemPrimaryIcon,
  getFlagItemSecondaryText,
  doesFlagHaveUnreadComments,
} from './utils'
import { ToReviewListPager } from './ListPager'
import ViewFlagButton from './buttons/ViewFlagButton'

const FlagsReviewList = ({
  requestGroupFlags,
  flags,
  currentPage,
  filters,
}) => {
  const { t } = useTranslation()
  const { currentUser, currentTurfPerformsExternalQC } = useCurrent()
  const {
    makeRequest: requestFlags,
    isLoading: isFlagsLoading,
    hasErrors: flagsHaveErrors,
    errors: flagsErrors,
    isRequestComplete: isFlagsRequestComplete,
  } = useRequest(requestGroupFlags)

  const flagsErrorMsg = formatErrorMessage(flagsErrors)

  useEffect(() => {
    requestFlags(currentTurfPerformsExternalQC)
  }, [currentPage, filters])

  return (
    <>
      <ProgressBar show={isFlagsLoading} />
      <CardError hide={!flagsHaveErrors} message={flagsErrorMsg} />
      {isFlagsRequestComplete && !!flags.length && (
        <>
          <List>
            {flags.map(flag => (
              <ListItem
                key={`flag-group-list-item-${flag.id}`}
                primaryText={getFlagItemPrimaryText(flag)}
                primaryIcon={getFlagItemPrimaryIcon(flag)}
                secondaryText={getFlagItemSecondaryText(flag, currentUser)}
                read={getFlagItemReadStatus(flag, currentUser.email)}
                showAlertIcon={doesFlagHaveUnreadComments(flag, currentUser)}
                actions={() => <ViewFlagButton flagId={flag.id} />}
              />
            ))}
          </List>
          <ToReviewListPager />
        </>
      )}
      {isFlagsRequestComplete && !flags.length && (
        <Section secondary collapsed>
          <TextBlock>{t('You have no flags to review')}</TextBlock>
        </Section>
      )}
    </>
  )
}

export default connect(
  state => ({
    flags: getFlagDataToReview(state),
    currentPage: getToReviewListPage(state),
    filters: getFilters(state),
  }),
  { requestGroupFlags }
)(FlagsReviewList)
