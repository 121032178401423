/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ContentEditor, Button, ButtonBlock } from 'components'

const EditableContent = ({
  label,
  onSave,
  content,
  ReadModeDisplay,
  isNew,
  newButtonText,
}) => {
  const [isEditing, setEditing] = useState(false)
  return (
    <div>
      {isEditing ? (
        <ContentEditor
          label={label}
          onSave={onSave}
          content={content}
          endEditing={() => setEditing(false)}
        />
      ) : isNew ? (
        <ButtonBlock>
          <Button primary raised onClick={() => setEditing(true)}>
            {newButtonText}
          </Button>
        </ButtonBlock>
      ) : (
        <ReadModeDisplay startEditing={() => setEditing(true)} />
      )}
    </div>
  )
}

EditableContent.propTypes = {
  isNew: PropTypes.bool,
  newButtonText: PropTypes.string,
  label: PropTypes.string,
  content: PropTypes.string,
  onSave: PropTypes.func.isRequired,
}

EditableContent.defaultProps = {
  isNew: false,
  newButtonText: '',
  label: '',
  content: '',
}

export default EditableContent
