import React from 'react'
import { Switch, Route } from 'react-router-dom'
import TurfLevelSelect from '../TurfLevelSelect/TurfLevelSelect'

const TurfLevelRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={TurfLevelSelect} />
  </Switch>
)

export default TurfLevelRoutes
