import { useState, useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import {
  View,
  ViewContainer,
  ReportDescription,
  TurfSelectField,
  HeaderBlock,
} from 'components'
import {
  DateField,
  Sheet,
  FieldBlock,
  ButtonBlock,
  Button,
} from '@politechdev/blocks-design-system'
import {
  AutoTableProvider,
  useAutoTable,
  useQueryParams,
  useFilters,
  ReportModalProvider,
  useSort,
  useCurrent,
  useTurfs,
} from 'contexts'
import { useRouteQueryParams } from 'hooks'
import config from './reportsConfig'
import ReportPicker from './ReportPicker'
import StandardReportTable from './StandardReportTable'

const defaultStartDate = moment().subtract(7, 'days').toISOString()
const defaultEndDate = moment().toISOString()

const RegistrationReports = () => {
  const { t } = useTranslation()
  const { setTableId } = useAutoTable()

  const {
    defaultFilters,
    clearFiltersOnTableIdChange,
    activeFilterId,
    setActiveFilter,
  } = useFilters()

  const { setSort } = useSort()
  const { queryParams, setQueryParams } = useQueryParams()

  const {
    currentUser: {
      turf: { id: topLevelTurfId },
      role: currentUserRole,
    },
    doesCurrentUserHavePermission,
  } = useCurrent()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  const { reportTypes, reportCategories } = useMemo(() => {
    const reportTypes = config.reportTypes.filter(reportType => {
      if (typeof reportType.isViewable === 'boolean') {
        return reportType.isViewable
      }
      if (typeof reportType.isViewable === 'function') {
        return reportType.isViewable(currentUserRole)
      }
      return true
    })

    // eslint-disable-next-line import/no-named-as-default-member
    const reportCategories = config.reportCategories
      .map(reportCategory => {
        const types = reportCategory.reportTypes.filter(reportType =>
          reportTypes.find(
            availableReportType => availableReportType.id === reportType
          )
        )

        if (!reportTypes.length) return null
        return {
          ...reportCategory,
          reportTypes: types,
          defaultType: types[0],
        }
      })
      .filter(Boolean)

    return { reportTypes, reportCategories }
  }, [currentUserRole.id])

  const getDefaultType = () => {
    const validTypes = reportCategories
      .filter(reportCategory =>
        doesCurrentUserHavePermission({
          resource: reportCategory.permissionId,
          ability: 'view',
        })
      )
      .flatMap(reportCategory => reportCategory.reportTypes)

    if (
      reportTypes.find(({ id }) => id === queryParams.reportType) &&
      validTypes.includes(queryParams.reportType)
    ) {
      return queryParams.reportType
    }

    if (validTypes.includes(config.defaultReportType)) {
      return config.defaultReportType
    }

    return validTypes[0]
  }

  const [reportType, setReportType] = useState(getDefaultType())
  const [turfId, setTurfId] = useState(+queryParams.turfId || topLevelTurfId)
  const [dateRange, setDateRange] = useState({
    start: moment(queryParams.startDate, 'YYYYMMDD', true).isValid()
      ? moment(queryParams.startDate, 'YYYYMMDD').toISOString()
      : defaultStartDate,
    end: moment(queryParams.endDate, 'YYYYMMDD', true).isValid()
      ? moment(queryParams.endDate, 'YYYYMMDD').toISOString()
      : defaultEndDate,
  })
  const [categoryId, setCategoryId] = useState(
    reportCategories.find(category => category.reportTypes.includes(reportType))
      ?.id
  )

  const selectedReport = reportTypes.find(({ id }) => id === reportType)

  const selectedCategory = reportCategories.find(
    category => category.id === categoryId
  )

  useEffect(() => {
    const defaultParams = {}
    if (
      !queryParams.startDate ||
      !moment(queryParams.startDate, 'YYYYMMDD', true).isValid() ||
      !queryParams.endDate ||
      !moment(queryParams.endDate, 'YYYYMMDD', true).isValid()
    ) {
      defaultParams.startDate = moment(defaultStartDate).format('YYYYMMDD')
      defaultParams.endDate = moment(defaultEndDate).format('YYYYMMDD')
    }
    if (!queryParams.turfId) {
      defaultParams.turfId = topLevelTurfId
    }
    if (
      !queryParams.reportType ||
      !reportTypes.find(({ id }) => id === queryParams.reportType)
    ) {
      defaultParams.reportType = config.defaultReportType
    }
    if (!queryParams.sortColumn) {
      defaultParams.sortColumn = ''
    }
    if (!queryParams.sortOrder) {
      defaultParams.sortOrder = 'asc'
    }
    setQueryParams(defaultParams, 'replace')
  }, [])

  useEffect(() => {
    refreshCurrentTurfs({
      associations: [''],
      fields: ['id', 'name', 'parent_id', 'archived'],
      indexed: true,
    })

    if (!queryParams.reportType) {
      setTableId(getDefaultType())
    } else {
      setTableId(queryParams.reportType)
    }
  }, [])

  useEffect(() => {
    setSort({
      sortColumn: queryParams.sortColumn,
      sortOrder: queryParams.sortOrder,
    })
  }, [queryParams.sortColumn, queryParams.sortOrder])

  useEffect(() => {
    setQueryParams({ activeFilterId })
  }, [activeFilterId])

  useEffect(() => {
    if (!queryParams.activeFilterId && !queryParams.filterRules) {
      setActiveFilter('default-0')
    }
  }, [defaultFilters])

  const changeReportType = reportType => {
    setReportType(reportType)
    setTableId(reportType)
    clearFiltersOnTableIdChange()
    setQueryParams({ reportType, sortColumn: '', sortOrder: 'asc' })
  }

  const changeTurf = turfId => {
    setTurfId(turfId)
    setQueryParams({ turfId })
  }

  const changeDateRange = (start, end) => {
    if (!start || !end) return
    setDateRange({
      start,
      end,
    })
    setQueryParams({
      startDate: moment.tz(start, 'UTC').format('YYYYMMDD'),
      endDate: moment.tz(end, 'UTC').format('YYYYMMDD'),
    })
  }

  const changeReportCategory = categoryId => {
    setCategoryId(categoryId)
    changeReportType(selectedCategory.defaultType)
  }

  if (!selectedReport) return null

  return (
    <View>
      <HeaderBlock title={t('Registration reports')}>
        <ButtonBlock justify="right">
          <a
            href={
              categoryId === 'coverSheetParty' &&
              selectedReport.partisanDataDictionaryUrl
                ? selectedReport.partisanDataDictionaryUrl
                : selectedReport.dataDictionaryUrl
            }
            target="_blank"
            rel="noreferrer"
          >
            <Button.Accent>{t('Data dictionary')}</Button.Accent>
          </a>
        </ButtonBlock>
      </HeaderBlock>
      <ViewContainer loading={turfRefreshIsLoading}>
        <Sheet>
          <FieldBlock variant="medium">
            <DateField
              type="range"
              label={t('Date range')}
              onChange={changeDateRange}
              startDate={dateRange.start}
              endDate={dateRange.end}
            />
            <TurfSelectField
              label={t('Turf')}
              value={turfId}
              onSelect={changeTurf}
              isExpanded
              showArchived
            />
          </FieldBlock>
        </Sheet>
        <ReportPicker
          categoryId={categoryId}
          reportType={reportType}
          reportTypes={reportTypes}
          reportCategories={reportCategories}
          changeReportType={changeReportType}
          changeReportCategory={changeReportCategory}
        />
        <ReportDescription
          currentReport={selectedReport}
          categoryName={selectedCategory?.name}
        />
        <ReportModalProvider>
          <StandardReportTable
            reportType={selectedReport}
            dateRange={dateRange}
            turfId={turfId}
          />
        </ReportModalProvider>
      </ViewContainer>
    </View>
  )
}

export default () => {
  const [{ reportType }] = useRouteQueryParams()
  const selectedReport = config.reportTypes.find(({ id }) => id === reportType)

  return (
    <AutoTableProvider
      persistSelectedFilter
      isFrontend
      enableQueryParams
      enableFilters
      enableSort
      showDownloadButton
      showFilterEditor
      showQuickSearch
      showTotals
      freezeLeftColumns={1}
      defaultFilters={selectedReport?.defaultFilters || []}
    >
      <RegistrationReports />
    </AutoTableProvider>
  )
}
