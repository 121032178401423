import { useEffect, useMemo, useState } from 'react'
import { DEPRECATED_useRequest, useInterval } from 'hooks'

const useBackgroundRequest = ({
  key,
  requestId,
  requestData,
  attemptLimit = 10,
}) => {
  const [attemptCount, setAttemptCount] = useState(0)
  const incrementAtemptCount = () => setAttemptCount(count => count + 1)
  const attemptLimitReached = attemptCount >= attemptLimit

  const {
    response: id,
    makeRequest: makeIdRequest,
    isLoading: idLoading,
    errorMsg: idErrorMsg,
  } = DEPRECATED_useRequest(requestId)

  const {
    response: dataResponse,
    makeRequest: makeDataRequest,
    isLoading: dataLoading,
    errorMsg: dataErrorMsg,
  } = DEPRECATED_useRequest(requestData)

  const loadedId = dataResponse?.id
  const isDataLoaded = useMemo(() => id && loadedId === id, [loadedId, id])

  const { startInterval, resetInterval } = useInterval(() => {
    makeDataRequest(id, key)
    incrementAtemptCount()
  }, 5000)

  useEffect(() => {
    if ((!dataLoading && loadedId && loadedId === id) || attemptLimitReached) {
      resetInterval()
    }
  }, [id, loadedId, dataLoading, attemptLimitReached])

  useEffect(() => {
    setAttemptCount(0)
    resetInterval()
    id && startInterval()
  }, [id])

  const makeRequest = () => makeIdRequest(key)

  const isLoading =
    idLoading || dataLoading || (!isDataLoaded && !attemptLimitReached)
  const errorMsg =
    idErrorMsg || dataErrorMsg || (attemptLimitReached && 'Request timed out')
  const isSuccessful = !isLoading && !errorMsg

  return {
    makeRequest,
    isLoading,
    errorMsg,
    isSuccessful,
    response: dataResponse?.response,
  }
}

export default useBackgroundRequest
