/* eslint-disable react/jsx-props-no-spreading */
import { Switch, Route, Redirect } from 'react-router-dom'
import { CurrentProvider, LocaleProvider, TurfsProvider } from 'contexts'
import Intercom from './Intercom'
import PersonRoutes from './people/PersonRoutes/PersonRoutes'
import TeamRoutes from './teams/TeamRoutes/TeamRoutes'
import CanvasserRoutes from './canvassers/CanvasserRoutes/CanvasserRoutes'
import DeliveryRoutes from './deliveries/DeliveryRoutes/DeliveryRoutes'
import CampaignRoutes from './campaigns/CampaignRoutes/CampaignRoutes'
import TurfRoutes from './turfs/TurfRoutes/TurfRoutes'
import Roles from './roles/Roles/Roles'
import Dashboard from './dashboard/Dashboard/Dashboard'
import PhoneBankRoutes from './phone_banks/PhoneBankRoutes/PhoneBankRoutes'
import UserRoutes from './users/UserRoutes/UserRoutes'
import EventRoutes from './events/EventRoutes/EventRoutes'
import MeetingRoutes from './events/MeetingRoutes/MeetingRoutes'
import ShiftPacketInformation from './shifts/ShiftPacketInformation'
import ShiftSelect from './shifts/ShiftSelect/ShiftSelect'
import ShiftCalendar from './shifts/ShiftCalendar/ShiftCalendar'
import ShiftCreate from './shifts/ShiftCreate/ShiftCreate'
import ShiftEdit from './shifts/ShiftEdit/ShiftEdit'
import FormsSelect from './registration_forms/FormsSelect'
import OrganizationRoutes from './organizations/OrganizationRoutes/OrganizationRoutes'
import ScanUpload from './shifts/ScanUpload'
import ScanSingle from './shifts/ScanSingle'
import RegistrationReports from './registrationReports/RegistrationReports'
import QualityControlReports from './qualityControlReports/QualityControlReports'
import WeeklyReports from './weeklyReports'
import QualityControl from './qualityControl'
import LocationRoutes from './locations/LocationRoutes/LocationRoutes'
import ListRoutes from './lists/ListRoutes/ListRoutes'
import DocumentRoutes from './documents/DocumentRoutes/DocumentRoutes'
import UserSettingRoutes from './userSettings/UserSettingRoutes/UserSettingRoutes'
import TurfLevelRoutes from './turfLevels/TurfLevelRoutes/TurfLevelRoutes'
import OrganizingReports from './organizingReports/OrganizingReports/OrganizingReports'
import PhoneBankReports from './phoneBankReports/PhoneBankReports/PhoneBankReports'
import EmailRoutes from './email/EmailRoutes/EmailRoutes'
import DuplicatePreventionRoutes from './duplicatePrevention/DuplicatePreventionRoutes/DuplicatePreventionRoutes'
import FieldManagementRoutes from './fieldManagement/FieldManagementRoutes/FieldManagementRoutes'
import CheckinRoutes from './checkins/CheckinRoutes/CheckinRoutes'
import ApplicationFrame from './layout/ApplicationFrame/ApplicationFrame'
import ShiftPacket from './shifts/ShiftPacket'
import PetitionReports from './petitionReports/PetitionReports/PetitionReports'
import ScheduledExports from './dataSources/scheduledExports'
import ImportsUpload from './Imports/ImportsUpload'
import Imports from './Imports/Imports'
import ShiftDataEntry from './shifts/ShiftDataEntry/ShiftDataEntry'
import RoleContextProvider from './roles/RoleContext/RoleContext'
import TurfContextProvider from './turfs/TurfContext/TurfContext'
import DataEntryEdit from './shifts/ShiftDataEntry/DataEdit'

const AuthenticatedApp = props => (
  <CurrentProvider>
    <TurfsProvider>
      <LocaleProvider>
        <Intercom />
        <ApplicationFrame>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route path="/reporting/weekly_reports" component={WeeklyReports} />
            <Route path="/admin/users" component={UserRoutes} />
            <Route path="/admin/user_settings" component={UserSettingRoutes} />
            <Route path="/organize/phone_banks" component={PhoneBankRoutes} />
            <Route path="/email" component={EmailRoutes} />
            <Route path="/organize/people/" component={PersonRoutes} />
            <Route path="/organize/lists" component={ListRoutes} />
            <Route
              path="/organize/organizations"
              component={OrganizationRoutes}
            />
            <Route path="/organize/teams" component={TeamRoutes} />
            <Route
              path="/collect/voter_registration/registration_forms"
              render={routeProps => <FormsSelect {...routeProps} />}
            />
            <Route
              path="/collect/voter_registration/deliveries"
              component={DeliveryRoutes}
            />
            <Route
              path="/collect/:shiftType/canvassers"
              component={CanvasserRoutes}
            />
            <Route
              path="/collect/:shiftType/locations"
              component={LocationRoutes}
            />
            <Route
              exact
              path="/collect/:shiftType/shifts"
              component={ShiftSelect}
            />
            <Route
              exact
              path="/collect/:shiftType/shifts/:id/packet_information"
              component={ShiftPacketInformation}
            />
            <Route
              exact
              path="/collect/:shiftType/shifts/calendar"
              component={ShiftCalendar}
            />
            <Route
              exact
              path="/collect/:shiftType/shifts/new"
              component={ShiftCreate}
            />
            <Route
              path="/collect/:shiftType/shifts/:id/edit"
              component={ShiftEdit}
            />
            <Route
              exact
              path="/collect/:shiftType/shifts/:id/scans"
              render={routeProps => (
                <ScanUpload {...routeProps} createScan={props.createScan} />
              )}
            />
            <Route
              exact
              path="/collect/:shiftType/shifts/:id/packet/new"
              render={routeProps => (
                <ScanUpload {...routeProps} createScan={props.createScan} />
              )}
            />
            <Route
              exact
              path="/collect/:shiftType/shifts/:shiftId/packet/scans/:id"
              render={routeProps => <ScanSingle {...routeProps} />}
            />
            <Route
              path="/collect/:shiftType/shifts/:id/packet"
              component={ShiftPacket}
            />
            <Route
              path="/collect/voter_registration/shifts/:shiftId/data_entry/:pageId"
              component={ShiftDataEntry}
            />
            <Route
              path="/collect/voter_registration/shifts/:shiftId/edit_form_data/:formId"
              component={DataEntryEdit}
            />
            <Route path="/build/events/venues" component={LocationRoutes} />
            <Route path="/build/events" component={EventRoutes} />
            <Route path="/build/meetings" component={MeetingRoutes} />
            <Route
              path="/reporting/reports/registration_reports"
              component={RegistrationReports}
            />
            <Route
              path="/reporting/reports/quality_control"
              component={QualityControlReports}
            />
            <Route
              path="/reporting/reports/organizing_reports"
              component={OrganizingReports}
            />
            <Route
              path="/reporting/reports/phone_bank_reports"
              component={PhoneBankReports}
            />
            <Route
              path="/reporting/reports/petition_reports"
              component={PetitionReports}
            />
            <Route path="/share/documents" component={DocumentRoutes} />
            <Route
              path="/admin/turfs"
              render={routeProps => (
                <TurfContextProvider>
                  <TurfRoutes match={routeProps.match} />
                </TurfContextProvider>
              )}
            />
            <Route path="/admin/turf_levels" component={TurfLevelRoutes} />
            <Route path="/admin/campaigns" component={CampaignRoutes} />
            <Route
              path="/admin/roles"
              render={routeProps => (
                <RoleContextProvider>
                  <Roles match={routeProps.match} />
                </RoleContextProvider>
              )}
            />
            <Route
              path="/data_sources/imports/:importId/duplicate_prevention"
              component={DuplicatePreventionRoutes}
            />
            <Route
              path="/data_sources/scheduled_exports"
              component={ScheduledExports}
            />
            <Route exact path="/data_sources/imports" component={Imports} />
            <Route
              exact
              path="/data_sources/imports/new"
              component={ImportsUpload}
            />
            <Route
              path="/quality_control"
              render={routeProps => <QualityControl {...routeProps} />}
            />
            <Route path="/field-management" component={FieldManagementRoutes} />
            <Route path="/check-ins" component={CheckinRoutes} />
            <Route path="/auth">
              <Redirect to="/" />
            </Route>
          </Switch>
        </ApplicationFrame>
      </LocaleProvider>
    </TurfsProvider>
  </CurrentProvider>
)

export default AuthenticatedApp
