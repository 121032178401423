import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  ViewContainer,
  HeaderBlock,
  CardError,
  Calendar,
} from 'components'
import { DEPRECATED_useRequest } from 'hooks'
import { fetchEvents, fetchMeetings } from 'requests/events'
import { useCurrent } from 'contexts/index'
import EventCalendarItem from '../EventCalendarItem/EventCalendarItem'
import EventCalendarLegend from '../EventCalendarLegend/EventCalendarLegend'
import {
  buildEventParams,
  buildMeetingParams,
  buildEvents,
  buildMeetings,
} from './utils'

const EventCalendar = () => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewEvents = doesCurrentUserHavePermission({
    resource: 'event',
    ability: 'view',
  })
  const canViewMeetings = doesCurrentUserHavePermission({
    resource: 'meeting',
    ability: 'view',
  })

  if (!canViewEvents && !canViewMeetings) {
    return (
      <CardError
        hide={canViewEvents}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  const [items, setItems] = useState([])
  const [dateRange, setDateRange] = useState(null)

  const {
    makeRequest: eventRequest,
    isLoading: eventLoading,
    errorMsg: eventError,
    response: eventResponse,
  } = DEPRECATED_useRequest(fetchEvents)
  const {
    makeRequest: meetingRequest,
    isLoading: meetingLoading,
    errorMsg: meetingError,
    response: meetingResponse,
  } = DEPRECATED_useRequest(fetchMeetings)

  useEffect(() => {
    if (eventLoading || meetingLoading) return
    if (eventError || meetingError) return

    setItems([
      ...(eventResponse?.events || []).map(buildEvents),
      ...(meetingResponse?.meetings || []).map(buildMeetings),
    ])
  }, [
    eventLoading,
    eventError,
    eventResponse,
    meetingLoading,
    meetingError,
    meetingResponse,
  ])

  useEffect(() => {
    if (dateRange) {
      eventRequest(
        buildEventParams({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        })
      )
      meetingRequest(
        buildMeetingParams({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        })
      )
    }
  }, [dateRange])

  const isLoading = eventLoading || meetingLoading
  const errorMsg = eventError || meetingError

  return (
    <View>
      <HeaderBlock title={t('Event Calendar')} />
      <ViewContainer>
        <Calendar
          items={items}
          isLoading={isLoading}
          errorMsg={errorMsg}
          onRangeChange={setDateRange}
          renderItem={itemProps => <EventCalendarItem {...itemProps} />}
        />
        <EventCalendarLegend />
      </ViewContainer>
    </View>
  )
}

export default EventCalendar
