import { useTranslation } from 'react-i18next'
import { Button, ButtonBlock, Icon } from '@politechdev/blocks-design-system'
import { HeaderBlock } from 'components'
import { QueryParamProvider } from 'contexts/'
import GallerySubheader from './GallerySubheader/GallerySubheader'
import PledgeCardSelect from './PledgeCardSelect/PledgeCardSelect'
import PledgeCardCompareForm from './PledgeCardCompare/PledgeCardCompareForm/PledgeCardCompareForm'
import PledgeCardComparePledge from './PledgeCardCompare/PledgeCardComparePledge/PledgeCardComparePledge'
import PledgeCardGalleryContextProvider, {
  usePledgeCardGalleryActions,
  usePledgeCardGalleryState,
  ViewerType,
} from './PledgeCardGalleryContext/PledgeCardGalleryContext'
import styles from './PledgeCardGallery.module.scss'

const PledgeCardGallery = () => {
  const { t } = useTranslation()
  const { packet, viewerType, firstScan } = usePledgeCardGalleryState()
  const { setViewerType, setQueryParams } = usePledgeCardGalleryActions()

  if (!packet) return null

  const packetGalleryName = t('Packet Gallery - {{ scanName }}', {
    scanName: packet.scanName,
  })
  const registrationNum = t('Registration #{{ id }}', { id: firstScan.id })
  const viewTitle =
    viewerType === ViewerType.Select ? packetGalleryName : registrationNum
  const viewSubtitle = viewerType !== ViewerType.Select && packetGalleryName

  return (
    <>
      <HeaderBlock
        title={viewTitle}
        subtitle={viewSubtitle}
        className={styles['header--no-bottom-margin']}
      >
        {viewerType !== ViewerType.Select && (
          <ButtonBlock justify="right">
            <Button.Secondary
              onClick={() => {
                setViewerType(ViewerType.Select)
                setQueryParams({
                  type: ViewerType.Select,
                })
              }}
            >
              <Icon.Times />
            </Button.Secondary>
          </ButtonBlock>
        )}
      </HeaderBlock>
      <GallerySubheader expanded={viewerType === ViewerType.Select} />
      {viewerType === ViewerType.Select && <PledgeCardSelect />}
      {viewerType === ViewerType.Form && <PledgeCardCompareForm />}
      {viewerType === ViewerType.Pledge && <PledgeCardComparePledge />}
    </>
  )
}

export default () => (
  <QueryParamProvider>
    <PledgeCardGalleryContextProvider>
      <PledgeCardGallery />
    </PledgeCardGalleryContextProvider>
  </QueryParamProvider>
)
