import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, Sheet, CardError, HeaderBlock } from 'components'
import { useReactRouter, useRequest } from 'hooks'
import { fetchCampaign } from 'requests/campaigns'
import CampaignForm from '../CampaignForm/CampaignForm'

const CampaignEdit = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const [currentCampaign, setCurrentCampaign] = useState({})

  const { makeRequest, isLoading, hasErrors, isRequestComplete } = useRequest(
    campaignId =>
      fetchCampaign(campaignId, {
        fields: ['id', 'name', 'start_date', 'end_date'],
      }),
    {
      onSuccess: ({ campaign }) => {
        setCurrentCampaign(campaign)
      },
    }
  )

  useEffect(() => {
    makeRequest(match.params.id)
  }, [match.params.id])

  const campaignLoaded =
    isRequestComplete && !hasErrors && currentCampaign.id === +match.params.id

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <Sheet>
          <HeaderBlock title={t('Edit Campaign')} />
          <CardError
            hide={!hasErrors}
            message={t("We're unable to retrieve this campaign")}
          />
          {campaignLoaded && <CampaignForm campaign={currentCampaign} />}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default CampaignEdit
