import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ButtonBlock, Modal, Button } from '@politechdev/blocks-design-system'
import { CardError, SectionLabel, TextBlock } from 'components'
import { useRequest } from 'hooks'
import { defaultFlagParams } from 'store/qcFlags/actions'
import { fetchFlagComments, fetchFlag } from 'store/qcFlags/requests'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import { formatLegacyErrorMsg } from 'utils/formatting'
import { useTranslation } from 'react-i18next'
import styles from './FlagCommentsModal.module.scss'

const FlagCommentsModal = ({ isOpen, setIsOpen, flagId }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [errorMsg, setErrorMsg] = useState('')
  const [comments, setComments] = useState([])
  const [flag, setFlag] = useState(null)

  const {
    makeRequest: commentsReq,
    isLoading: isCommentsLoading,
    hasErrors: commentsHaveErrors,
  } = useRequest(fetchFlagComments, {
    onError: async error => {
      const message = await formatLegacyErrorMsg(error)
      setErrorMsg(message)
    },
    onSuccess: response => {
      setComments(response['quality_control/comments'])
    },
  })

  const {
    makeRequest: flagReq,
    isLoading: isFlagLoading,
    hasErrors: flagHasErrors,
  } = useRequest(fetchFlag, {
    onSuccess: res => {
      setFlag(res['quality_control/flag'])
    },
    onError: async error => {
      const message = await formatLegacyErrorMsg(error)
      setErrorMsg(message)
    },
  })

  useEffect(() => {
    flagId !== undefined && flagReq(flagId, defaultFlagParams)
  }, [flagId])

  useEffect(() => {
    flag &&
      commentsReq(flagId, {
        fields: ['created_at', 'body', { author: ['name'] }],
      })
  }, [flag])

  const reportOriginParams = location.search.slice(1)

  return (
    <Modal
      id="reports-table-cell-modal"
      title={`${t('Comments for Flag')} ${flagId}`}
      onHide={() => setIsOpen(false)}
      isOpen={isOpen}
      showLoading={isCommentsLoading || isFlagLoading}
    >
      <Modal.Body className={styles.content}>
        <div>
          <CardError
            hide={!commentsHaveErrors && !flagHasErrors}
            message={errorMsg}
          />
          {comments?.map((comment, i) => (
            <div key={`comment-${i}`}>
              <SectionLabel className={styles.author}>
                {`${comment.author.name} | ${moment(comment.created_at).format(
                  dateFormat
                )}`}
              </SectionLabel>
              <TextBlock>{comment.body}</TextBlock>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Actions className={styles.actions}>
        <ButtonBlock justify="right">
          <Button.Accent
            onClick={() => {
              const routeParams = {
                pending: 'start',
                ready: 'review',
                reviewed: 'complete',
                completed: 'reopen',
              }
              history.push(
                `/quality_control/flags/${routeParams[flag.status]}/${flagId}/${
                  routeParams[flag.status]
                }/1/${reportOriginParams}`
              )
            }}
            disabled={!(flag && flag.status)}
          >
            {t('Open Flag')}
          </Button.Accent>
          <Button
            onClick={() => {
              setIsOpen(false)
            }}
            flat
          >
            {t('Close')}
          </Button>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default FlagCommentsModal
