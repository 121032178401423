import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { View, ViewContainer, CardError, HeaderBlock } from 'components'
import {
  ButtonBlock,
  Button,
  Icon,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { fetchCanvasser } from 'requests/canvassers'
import { formatLegacyErrorMsg } from 'utils/formatting'
import CanvasserForm from '../CanvasserForm/CanvasserForm'

const CanvasserEdit = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const [errorMsg, setErrorMsg] = useState('')
  const [currentCanvasser, setCurrentCanvasser] = useState({})
  const { makeRequest, isLoading, isRequestComplete } = useRequest(
    () =>
      fetchCanvasser(match.params.id, {
        fields: [
          'first_name',
          'last_name',
          'phone_number',
          { turf: ['id'] },
          'notes',
          'vdrs',
          'archived',
        ],
        associations: ['turf'],
      }),
    {
      onSuccess: ({ canvasser }) => {
        setCurrentCanvasser({
          first_name: canvasser.first_name,
          last_name: canvasser.last_name,
          phone_number: canvasser.phone_number,
          turf_id: canvasser.turf.id,
          notes: canvasser.notes,
          vdrs: canvasser.vdrs,
          archived: canvasser.archived,
        })
      },
      onError: async error => {
        const message = await formatLegacyErrorMsg(error)
        setErrorMsg(message)
      },
    }
  )

  useEffect(() => {
    makeRequest()
  }, [match.params.id])

  const canvasserLoaded =
    isRequestComplete && !errorMsg && !isLoading && !!currentCanvasser

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <Sheet>
          <HeaderBlock title={t('Edit Canvasser')}>
            {canvasserLoaded && (
              <ButtonBlock justify="right">
                <Link
                  to={`/collect/voter_registration/canvassers/${match.params.id}`}
                >
                  <Button.Secondary
                    tooltipLabel={t('Close')}
                    tooltipPosition="bottom"
                  >
                    <Icon.Times />
                  </Button.Secondary>
                </Link>
              </ButtonBlock>
            )}
          </HeaderBlock>
          <CardError
            hide={!errorMsg}
            message={t("We're unable to retrieve this canvasser")}
          />
          {canvasserLoaded && <CanvasserForm canvasser={currentCanvasser} />}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default CanvasserEdit
