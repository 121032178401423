import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { isFinite } from 'lodash'
import {
  CardError,
  HeaderBlock,
  LocationSelectField,
  CampaignSelectField,
  OrganizationSelectField,
  TeamSelectField,
  View,
  ViewContainer,
  EventTypeSelectField,
} from 'components'
import {
  DateField,
  Sheet,
  TextField,
  Section,
  Button,
  ButtonBlock,
  TextBlock,
  FieldBlock,
  Checkbox,
  Font,
  Switch,
  Icon,
  ProgressBar,
  TimePicker,
  TextArea,
} from '@politechdev/blocks-design-system'
import { LOCATION_TYPES } from 'constants/locations'
import { fetchEvents, postEvent } from 'requests/events'
import { useRequest } from 'hooks/useRequest'
import { useForm, FormProvider, useCurrent } from 'contexts'
import { useHistory } from 'react-router'
import RecurringEvents from './RecurringEvents/RecurringEvents'
import styles from './EventNew.module.scss'

const EventNew = () => {
  const { formData, setField } = useForm()
  const {
    currentUser: {
      time_zone: timezone,
      turf: { id: turfId },
    },
  } = useCurrent()
  const [timeError, setTimeError] = useState(false)
  const [eventDate, setEventDate] = useState()
  const [eventIsRecurring, setEventIsRecurring] = useState(false)
  const [recurringInterval, setRecurringInterval] = useState('week')
  const [recurringUntil, setRecurringUntil] = useState()
  const [recurringShifts, setRecurringShifts] = useState([])
  const [eventNameTaken, setEventNameTaken] = useState(false)

  useEffect(() => {
    setTimeError(false)
    const { start_time, end_time } = formData
    if (moment(start_time).isAfter(moment(end_time))) {
      setTimeError(true)
    }
  }, [formData])

  const { t } = useTranslation()

  const history = useHistory()

  const {
    makeRequest: createEventReq,
    isLoading: isEventLoading,
    hasErrors: eventError,
  } = useRequest(postEvent, {
    onSuccess: ({ event }) => {
      history.push(`/build/events/${event.id}`)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: fetchEventsReq, isLoading: isFetchEventsLoading } =
    useRequest(fetchEvents, {
      onSuccess: res => {
        setEventNameTaken(res && res.meta.total_count > 0)
      },
    })

  const [debouncedfetchEventsReq] = useDebouncedCallback(fetchEventsReq, 500)

  useEffect(() => {
    if (!formData.name) return

    debouncedfetchEventsReq({
      fields: ['name'],
      indexed: true,
      filters: {
        rules: [
          {
            column: 'name',
            operator: 'is',
            param: formData.name,
          },
          {
            column: 'turf',
            operator: 'is',
            param: turfId,
          },
        ],
      },
    })
  }, [formData.name])

  useEffect(() => {
    setField(
      ['email', 'street', 'city', 'state', 'invited_by', 'organizations'],
      'public_settings'
    )
    setField(moment().hour(13).minute(0).second(0).toISOString(), 'start_time')
    setField(moment().hour(14).minute(0).second(0).toISOString(), 'end_time')
  }, [])

  const updateSettings = (checked, field) => {
    if (checked) {
      setField([...formData.public_settings, field], 'public_settings')
    } else {
      setField(
        formData.public_settings.filter(setting => setting !== field),
        'public_settings'
      )
    }
  }

  const error = eventError
  const loading = isEventLoading

  const handleSave = () => {
    const {
      user,
      location,
      campaign,
      organization,
      person,
      coorganizations,
      teams,
      start_time,
      end_time,
      public: isPublic,
      public_settings,
      publicEventTitle,
      publicEventSettingsHasOpenField,
      publicEventSettingsOpenFieldLabel,
      postRegistrationMessage,
      ...submittableData
    } = formData
    const formattedStartTime = moment(
      `${moment(eventDate).format('YYYY-MM-DD')}T${moment(start_time).format(
        'HH:mm:ss'
      )}`
    ).toISOString()
    const formattedEndTime = moment(
      `${moment(eventDate).format('YYYY-MM-DD')}T${moment(end_time).format(
        'HH:mm:ss'
      )}`
    ).toISOString()

    const baseRequestData = {
      ...submittableData,
      start_time: formattedStartTime,
      end_time: formattedEndTime,
      user_id: user?.id,
      person_id: person?.id,
      location_id: location?.id,
      campaign_id: campaign?.id,
      organization_id: organization?.id,
      coorganization_ids: coorganizations?.map(o => o.id),
      team_ids: teams?.map(t => t.id),
      public: isPublic,
      public_settings: isPublic
        ? {
            extra_fields: publicEventSettingsHasOpenField
              ? [...public_settings, 'other']
              : public_settings,
            other_field_label: publicEventSettingsHasOpenField
              ? publicEventSettingsOpenFieldLabel
              : undefined,
            post_registration_message: postRegistrationMessage,
            title: publicEventTitle,
          }
        : undefined,
    }

    if (eventIsRecurring) {
      return createEventReq(
        {
          ...baseRequestData,
          recurring: {
            interval: recurringInterval,
            end_date: moment(recurringUntil).format('YYYY-MM-DD'),
          },
          shifts: recurringShifts.map(s => {
            const shiftStart = moment(eventDate)
              .set('hour', s.start.hour)
              .set('minute', s.start.minute || 0)
            const shiftEnd = moment(eventDate)
              .set('hour', s.end.hour)
              .set('minute', s.end.minute || 0)
            return {
              start_time: moment.tz(shiftStart, timezone).format(),
              end_time: moment.tz(shiftEnd, timezone).format(),
            }
          }),
        },
        { fields: ['id'] }
      )
    }
    return createEventReq(baseRequestData, { fields: ['id'] })
  }

  const isFormValid =
    formData.name &&
    formData.type &&
    formData.organization &&
    formData.start_time &&
    formData.end_time &&
    !timeError &&
    eventDate &&
    (!eventIsRecurring || recurringUntil) &&
    !eventNameTaken

  return (
    <View>
      <ViewContainer>
        <Sheet className={styles['event-form']}>
          <HeaderBlock title={t('Create event')}>
            <ButtonBlock justify="right">
              <Button.Secondary>
                <Icon.Times />
              </Button.Secondary>
            </ButtonBlock>
          </HeaderBlock>
          <div>
            <FieldBlock>
              <TextField
                label={t('Event name')}
                id="eventName"
                value={formData.name}
                onChange={name => setField(name, 'name')}
                required
                error={eventNameTaken}
                errorMessage={
                  eventNameTaken ? 'Name has already been taken' : 'Required'
                }
                loading={isFetchEventsLoading}
              />
              <EventTypeSelectField
                id="eventType"
                name="eventType"
                label={t('Event type')}
                eventType={formData.type}
                required
                errorText={t('Required')}
                onSelect={eventType => setField(eventType, 'type')}
              />
            </FieldBlock>
            <FieldBlock>
              <DateField
                label={t('Event day')}
                value={eventDate}
                onChange={val => {
                  if (val === '') {
                    setEventDate(null)
                    return
                  }
                  if (moment(val).isAfter(moment(recurringUntil))) {
                    setRecurringUntil(val)
                  }
                  setEventDate(val)
                }}
                required
              />
            </FieldBlock>
            <FieldBlock>
              <TimePicker
                label="Start Time"
                id="event-start-time"
                onSelect={val => {
                  setField(
                    moment(formData.start_time)
                      .hour(val.hour)
                      .minute(val.minute)
                      .second(0)
                      .toISOString(),
                    'start_time'
                  )
                }}
                hour={
                  formData.start_time &&
                  isFinite(moment(formData.start_time).hour())
                    ? moment(formData.start_time).hour()
                    : undefined
                }
                minute={
                  formData.start_time &&
                  isFinite(moment(formData.start_time).minute())
                    ? moment(formData.start_time).minute()
                    : undefined
                }
                error={timeError}
                errorMessage={t('Start time must be before end time')}
                required
              />
              <TimePicker
                label={t('End time')}
                id="event-end-time"
                onSelect={val => {
                  setField(
                    moment(formData.end_time)
                      .hour(val.hour)
                      .minute(val.minute)
                      .second(0)
                      .toISOString(),
                    'end_time'
                  )
                }}
                hour={
                  formData.end_time &&
                  isFinite(moment(formData.end_time).hour()) &&
                  moment(formData.end_time).hour()
                }
                minute={
                  formData.end_time &&
                  isFinite(moment(formData.end_time).minute()) &&
                  moment(formData.end_time).minute()
                }
                required
              />
            </FieldBlock>
            <FieldBlock>
              <Checkbox
                id="event-recurring-checkbox"
                name="event-recurring-checkbox"
                label={t('Event is recurring')}
                checked={eventIsRecurring}
                onChange={checked => {
                  setEventIsRecurring(checked)
                }}
              />
            </FieldBlock>
            {eventIsRecurring && (
              <RecurringEvents
                eventDate={eventDate}
                recurringInterval={recurringInterval}
                setRecurringInterval={setRecurringInterval}
                recurringUntil={recurringUntil}
                setRecurringUntil={setRecurringUntil}
                recurringShifts={recurringShifts}
                setRecurringShifts={setRecurringShifts}
              />
            )}
            <FieldBlock>
              <OrganizationSelectField
                label={t('Organization')}
                onSelect={organization => {
                  setField(organization, 'organization')
                  setField(
                    formData.coorganizations?.filter(
                      co => co.id !== organization?.id
                    ),
                    'coorganizations'
                  )
                }}
                organization={formData.organization}
                required
              />
              <OrganizationSelectField
                label={t('Co-organizations')}
                organizations={formData.coorganizations}
                isMulti
                filters={[
                  formData.organization?.id && {
                    column: 'id',
                    operator: 'is',
                    invert: 'true',
                    param: formData.organization.id,
                  },
                ]}
                onSelect={coorganizations =>
                  setField(coorganizations, 'coorganizations')
                }
              />
            </FieldBlock>
            <FieldBlock>
              <TeamSelectField
                label={t('Teams')}
                teams={formData.teams}
                isMulti
                filters={[
                  formData.team?.id && {
                    column: 'id',
                    operator: 'is',
                    invert: 'true',
                    param: formData.team.id,
                  },
                ]}
                onSelect={teams => {
                  setField(teams, 'teams')
                }}
                clearable
              />
              <Checkbox
                label={t('Add all members as guests')}
                disabled={!formData.teams?.length}
                checked={!!formData.add_all_team_members}
                onChange={addAllTeamMembers =>
                  setField(addAllTeamMembers, 'add_all_team_members')
                }
              />
            </FieldBlock>
            <FieldBlock variant="large">
              <LocationSelectField
                id="venue"
                label={t('Location')}
                onSelect={location => setField(location, 'location')}
                locationTypes={[LOCATION_TYPES.venue]}
                location={formData.location}
                clearable
              />
            </FieldBlock>
            <FieldBlock variant="large">
              <TextField
                label={t('URL')}
                id="eventUrl"
                value={formData.url}
                onChange={url => setField(url, 'url')}
                placeholder="https://example.com"
              />
            </FieldBlock>
            <FieldBlock variant="large">
              <TextField
                label={t('URL label')}
                id="eventUrl"
                value={formData.url_label}
                onChange={url => setField(url, 'url_label')}
              />
            </FieldBlock>
            <FieldBlock variant="large">
              <TextField
                label={t('Description')}
                id="description"
                value={formData.description}
                onChange={description => setField(description, 'description')}
              />
            </FieldBlock>
            <FieldBlock variant="large">
              <TextField
                label={t('Notes')}
                id="eventNotes"
                value={formData.notes}
                onChange={notes => setField(notes, 'notes')}
              />
            </FieldBlock>
            <FieldBlock>
              <CampaignSelectField
                label={t('Campaign')}
                onSelect={campaign => setField(campaign, 'campaign')}
                filters={[
                  {
                    column: 'start_date',
                    operator: 'before',
                    param: moment().add(1, 'days').format('YYYY-MM-DD'),
                  },
                  {
                    column: 'end_date',
                    operator: 'after',
                    param: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                  },
                ]}
                campaign={formData.campaign || {}}
                clearable
              />
            </FieldBlock>
            <FieldBlock>
              <Checkbox
                id="event-public"
                name="Public"
                label={t('Make public')}
                checked={formData.public}
                onChange={checked => setField(checked, 'public')}
              />
            </FieldBlock>
            {formData.public && (
              <Section label={t('Public events settings')}>
                <Sheet>
                  <FieldBlock>
                    <TextField
                      label={t('Public event title')}
                      id="publicTitle"
                      value={formData.publicEventTitle}
                      onChange={publicEventTitle =>
                        setField(publicEventTitle, 'publicEventTitle')
                      }
                    />
                  </FieldBlock>
                  <TextBlock>
                    <Font.Copy variant="hint">
                      {t(
                        'Include the following fields in public event sign-up forms'
                      )}
                    </Font.Copy>
                  </TextBlock>
                  <FieldBlock>
                    <div>
                      <Checkbox
                        id="first-name"
                        name="First name"
                        label={t('First name')}
                        disabled
                        checked
                      />
                      <Checkbox
                        id="last-name"
                        name="Last name"
                        label={t('Last name')}
                        disabled
                        checked
                      />
                      <Checkbox
                        id="phone-number"
                        name="Phone number"
                        label={t('Phone number')}
                        disabled
                        checked
                      />
                      <Checkbox
                        id="zip-code"
                        name="Zip code"
                        label={t('Zip code')}
                        disabled
                        checked
                      />
                      <Checkbox
                        id="email"
                        name="Email"
                        label={t('Email')}
                        checked={formData.public_settings.includes('email')}
                        onChange={checked => {
                          updateSettings(checked, 'email')
                        }}
                      />
                      <Checkbox
                        id="street-address"
                        name="Street address"
                        label={t('Street address')}
                        checked={formData.public_settings.includes('street')}
                        onChange={checked => {
                          updateSettings(checked, 'street')
                        }}
                      />
                      <Checkbox
                        id="city"
                        name="City"
                        label={t('City')}
                        checked={formData.public_settings.includes('city')}
                        onChange={checked => {
                          updateSettings(checked, 'city')
                        }}
                      />
                      <Checkbox
                        id="state"
                        name="State"
                        label={t('State')}
                        checked={formData.public_settings.includes('state')}
                        onChange={checked => {
                          updateSettings(checked, 'state')
                        }}
                      />
                      <Checkbox
                        id="invited-by"
                        name="Invited by"
                        label={t('Invited by')}
                        checked={formData.public_settings.includes(
                          'invited_by'
                        )}
                        onChange={checked => {
                          updateSettings(checked, 'invited_by')
                        }}
                      />
                      <Checkbox
                        id="organizations"
                        name="Organizations"
                        label={t('Organizations')}
                        checked={formData.public_settings.includes(
                          'organizations'
                        )}
                        onChange={checked => {
                          updateSettings(checked, 'organizations')
                        }}
                      />
                      <Checkbox
                        id="child-care"
                        name="Child care"
                        label={t('Child care needs (if any)')}
                        checked={formData.public_settings.includes(
                          'child_care_needs'
                        )}
                        onChange={checked => {
                          updateSettings(checked, 'child_care_needs')
                        }}
                      />
                      <Checkbox
                        id="transportation"
                        name="Transportation"
                        label={t('Transportation needs (if any)')}
                        checked={formData.public_settings.includes(
                          'transportation_needs'
                        )}
                        onChange={checked => {
                          updateSettings(checked, 'transportation_needs')
                        }}
                      />
                      <Checkbox
                        id="dietary-restrictions"
                        name="Dietary restrictions"
                        label={t('Dietary restrictions (if any)')}
                        checked={formData.public_settings.includes(
                          'dietary_restrictions'
                        )}
                        onChange={checked => {
                          updateSettings(checked, 'dietary_restrictions')
                        }}
                      />
                      <Checkbox
                        id="preferred-language"
                        name="Preferred language"
                        label={t('Preferred language')}
                        checked={formData.public_settings.includes(
                          'preferred_language'
                        )}
                        onChange={checked => {
                          updateSettings(checked, 'preferred_language')
                        }}
                      />
                      <Checkbox
                        id="receives_sms"
                        name="Allow sms"
                        label={t('SMS Opt-in')}
                        checked={formData.public_settings.includes(
                          'receives_sms'
                        )}
                        onChange={checked => {
                          updateSettings(checked, 'receives_sms')
                        }}
                      />
                    </div>
                  </FieldBlock>
                  <TextBlock>
                    <Font.Copy variant="hint">Custom open field</Font.Copy>
                  </TextBlock>
                  <FieldBlock
                    className={styles['public-event-checkbox-container']}
                  >
                    <Switch
                      label={t('Include open field')}
                      value={formData.publicEventSettingsHasOpenField}
                      onChange={val => {
                        setField(val, 'publicEventSettingsHasOpenField')
                      }}
                    />
                    <TextField
                      label={t('Open field label')}
                      disabled={!formData.publicEventSettingsHasOpenField}
                      value={formData.publicEventSettingsOpenFieldLabel || ''}
                      onChange={val => {
                        setField(val, 'publicEventSettingsOpenFieldLabel')
                      }}
                    />
                  </FieldBlock>
                  <FieldBlock
                    className={styles['public-event-checkbox-container']}
                    variant="large"
                  >
                    <TextArea
                      label={t('Post registration message')}
                      id="post-reg"
                      name="post-reg"
                      onChange={val => {
                        setField(val, 'postRegistrationMessage')
                      }}
                      value={formData.postRegistrationMessage}
                      rows={5}
                    />
                  </FieldBlock>
                </Sheet>
              </Section>
            )}
            <TextBlock>
              <CardError
                message={t(
                  'An internal error occurred while trying to create this event. Please check your form entry and try again'
                )}
                hideSupportLink
                hide={!error}
              />
            </TextBlock>
            <ButtonBlock>
              <Button.Accent onClick={handleSave} disabled={!isFormValid}>
                {t('Create event')}
              </Button.Accent>
            </ButtonBlock>
            <ProgressBar show={loading} />
          </div>
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default () => (
  <FormProvider>
    <EventNew />
  </FormProvider>
)
