import { useTranslation } from 'react-i18next'
import { HeaderBlock, Button, ButtonBlock, ViewContainer } from 'components'
import { Card } from 'react-md'
import React, { useContext, useState } from 'react'
import RecordsTable from '../RecordsTable/RecordsTable'
import RecordsSaveAllModal from '../RecordsSaveAllModal/RecordsSaveAllModal'
import RecordsDiscardAllModal from '../RecordsDiscardAllModal/RecordsDiscardAllModal'
import RecordsContextProvider, {
  RecordsContext,
} from '../RecordsContext/RecordsContext'
import styles from './Records.module.scss'

const Records = () => {
  const { t } = useTranslation()
  const [isDiscardAllOpen, setDiscardAllOpen] = useState(false)
  const [isSaveAllOpen, setSaveAllOpen] = useState(false)
  const { count } = useContext(RecordsContext)
  return (
    <div>
      <RecordsSaveAllModal
        onClose={() => setSaveAllOpen(false)}
        isOpen={isSaveAllOpen}
      />{' '}
      <RecordsDiscardAllModal
        onClose={() => setDiscardAllOpen(false)}
        isOpen={isDiscardAllOpen}
      />
      <HeaderBlock
        title={`${count} ${t('import validation errors to resolve')}`}
      />
      <ViewContainer>
        <Card>
          <div className={styles.actions}>
            <h2>{t('Bulk actions')}</h2>
            <ButtonBlock justify="right">
              <Button warn raised onClick={() => setDiscardAllOpen(true)}>
                {t(' Discard all')}
              </Button>
              <Button accent raised onClick={() => setSaveAllOpen(true)}>
                {t('Save all as new')}
              </Button>
            </ButtonBlock>
          </div>
          <RecordsTable />
        </Card>
      </ViewContainer>
    </div>
  )
}
export default () => (
  <RecordsContextProvider>
    <Records />
  </RecordsContextProvider>
)
