import { FontIcon, IconSeparator } from 'react-md'
import {
  didPacketFlagCompletePhoneVerification,
  didPacketFlagCompleteVisualReview,
  isPacketFlag,
} from '../utils'
import styles from './ReviewListIconSet.module.scss'

const Result = ({ didPass }) =>
  didPass ? (
    <FontIcon iconClassName="material-icons-outlined">done</FontIcon>
  ) : (
    <FontIcon iconClassName="material-icons-outlined">clear</FontIcon>
  )

const VisualReview = ({ flag }) => (
  <span className={styles.indicator}>
    <FontIcon iconClassName="material-icons-outlined">remove_red_eye</FontIcon>
    <Result didPass={didPacketFlagCompleteVisualReview(flag)} />
  </span>
)

const PhoneVerification = ({ flag }) => (
  <span className={styles.indicator}>
    <FontIcon iconClassName="material-icons-outlined">phone</FontIcon>
    <Result didPass={didPacketFlagCompletePhoneVerification(flag)} />
  </span>
)

const Comments = ({ flag }) => (
  <IconSeparator
    label={flag.comments.length}
    iconBefore
    className={styles.indicator}
  >
    <FontIcon iconClassName="material-icons-outlined">comment</FontIcon>
  </IconSeparator>
)

const ReviewListIconSet = ({ flag }) =>
  isPacketFlag(flag) ? (
    <div className={styles.container}>
      <VisualReview flag={flag} />
      <PhoneVerification flag={flag} />
      <Comments flag={flag} />
    </div>
  ) : (
    <div>
      <Comments flag={flag} />
    </div>
  )

export default ReviewListIconSet
