import { useReactRouter, useReduxAction } from 'hooks'
import { ListItem } from 'components'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { setScriptLanguage as setScriptLanguageQcScans } from 'store/qcScans/actions'
import { CallScriptsContext } from './CallScriptsContext'

const TurfScriptListItem = ({ language, scriptId, turfId, turfName }) => {
  const { t } = useTranslation()
  const { history, location } = useReactRouter()

  const { setPendingScript } = useContext(CallScriptsContext)
  const setReduxScriptLanguage = useReduxAction(setScriptLanguageQcScans)

  const goToScript = () => {
    setReduxScriptLanguage(language)
    setPendingScript(oldPendingScript => ({
      ...oldPendingScript,
      language,
      turfId,
    }))
    history.push({
      pathname: `${location.pathname}/${scriptId}`,
    })
  }

  return (
    <ListItem
      primaryText={`${scriptId !== 'new' ? t('Custom') : t('Default')} ${t(
        'Script'
      )}`}
      secondaryText={language}
      actions={() => (
        <div key={turfName + language} className="button__group">
          <Button onClick={goToScript} aria-label={t('Edit')}>
            <Icon.PencilAlt />
          </Button>
        </div>
      )}
    />
  )
}

export default TurfScriptListItem
