import React from 'react'
import { Switch, Route } from 'react-router'
import { useTranslation } from 'react-i18next'
import { HeaderBlock } from 'components/'
import { adminSettingsPaths } from 'layout/routesConfig'

import styles from './SectionHeader.module.scss'

const SectionHeader = () => {
  const { t } = useTranslation()
  return (
    <Switch>
      <Route path={adminSettingsPaths}>
        <HeaderBlock className={styles.header} title={t('Admin settings')} />
      </Route>
    </Switch>
  )
}

export default SectionHeader
