import { Section, TextBlock } from 'components'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getCurrentFlagCanvasser,
  getCurrentFlagTurf,
} from 'store/qcFlags/reducer'

const FlagSingleCanvasserDetail = ({ canvasser, turf }) => {
  const { t } = useTranslation()

  return (
    <Section className="flag-single__canvasser-detail" label={t('Canvasser')}>
      <TextBlock>
        <h2>{canvasser?.full_name}</h2>
      </TextBlock>
      <div className="flag-single__canvasser-detail__item-container">
        <div className="flag-single__canvasser-detail__item-container__sub-item">
          <h5>{t('TURF')}</h5>
        </div>
        <div className="flag-single__canvasser-detail__item-container__sub-item">
          {turf?.name}
        </div>
      </div>
    </Section>
  )
}

export default connect(state => ({
  canvasser: getCurrentFlagCanvasser(state),
  turf: getCurrentFlagTurf(state),
}))(FlagSingleCanvasserDetail)
