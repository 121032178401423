import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, Sheet, HeaderBlock, CardError } from 'components'
import { useReactRouter, useRequest } from 'hooks'
import { fetchLocation } from 'requests/locations'
import EventLocationForm from '../EventLocationForm/EventLocationForm'

const EventLocationEdit = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()

  const [currentLocation, setCurrentLocation] = useState({})

  const { makeRequest, isLoading, hasErrors, isRequestComplete } = useRequest(
    fetchLocation,
    {
      onSuccess: ({ location }) => {
        setCurrentLocation(location)
      },
    }
  )

  useEffect(() => {
    makeRequest(match.params.id, {
      fields: [
        'id',
        'name',
        'street_address',
        'city',
        'state',
        'zipcode',
        'county',
        'has_hosted_event',
        'has_it_support',
        'has_wifi',
        'has_av',
        'has_projector',
        'has_public_transportation',
        'contact_name',
        'contact_phone',
        'contact_email',
        'largest_turnout',
        'maximum_occupancy',
        'parking_spots_available',
        'rooms_available',
        'notes',
        { turf: ['id', 'name'] },
      ],
      associations: ['turf'],
    })
  }, [match.params.id])

  const locationLoaded =
    isRequestComplete && currentLocation.id === +match.params.id && !hasErrors

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <Sheet>
          <HeaderBlock title={t('Edit Location')} />
          <CardError
            hide={!hasErrors}
            message={t("We're unable to retrieve this location")}
          />
          {locationLoaded && <EventLocationForm location={currentLocation} />}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default EventLocationEdit
