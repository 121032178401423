import React, { useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getCurrentFlagPacketName,
  getCurrentFlagCanvasser,
  getCurrentFlag,
  doesCurrentFlagNeedReupload,
  isCurrentFlagComplete,
  isCurrentFlagTechnical,
} from 'store/qcFlags/reducer'
import { receiveCurrentFlag, defaultFlagParams } from 'store/qcFlags/actions'
import { fetchFlag } from 'store/qcFlags/requests'
import { useReactRouter, useRequest } from 'hooks'
import { Link, useParams } from 'react-router-dom'
import {
  Sheet,
  ViewContainer,
  Section,
  HeaderBlock,
  CardError,
  LoadBar,
  View,
} from 'components'
import { Button, ButtonBlock, Icon } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import FlagSingleCanvasserDetail from 'qualityControl/flags/FlagSingleCanvasserDetail'
import { QueryParamProvider } from 'contexts/index'
import FlagSingleReadyButton from './FlagSingleReadyButton'
import FlagSingleActionPlan from './FlagSingleActionPlan'
import FlagSingleComments from './FlagSingleComments/FlagSingleComments'
import FlagSingleTriggeredScansList from './FlagSingleTriggeredScansList'
import FlagSinglePacketViewer from './FlagSinglePacketViewer'
import { getFlagItemPrimaryText } from './utils'
import FlagSingleReuploadButton from './FlagSingleReuploadButton'
import FlagSingleAssociatedFlagsList from './FlagSingleAssociatedFlagsList/FlagSingleAssociatedFlagsList'
import FlagSingleDuplicateFlagsList from './FlagSingleDuplicateFlagsList/FlagSingleDuplicateFlagsList'

const FlagSingleMain = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { originalType, reportOriginParams } = useParams()
  const dispatch = useDispatch()

  const previousLink = reportOriginParams
    ? `/reporting/reports/quality_control?${reportOriginParams}`
    : `/quality_control/flags/${originalType}`

  const packetViewerRef = useRef(null)

  const { isLoading, hasErrors, errors, makeRequest } = useRequest(fetchFlag, {
    onSuccess: response => {
      dispatch(receiveCurrentFlag(response['quality_control/flag']))
    },
  })

  useEffect(() => {
    makeRequest(match.params.flagId, defaultFlagParams)
  }, [match.params.flagId])

  const flag = useSelector(getCurrentFlag)
  const packetName = useSelector(getCurrentFlagPacketName)
  const flagCanvasser = useSelector(getCurrentFlagCanvasser)
  const needsReupload = useSelector(doesCurrentFlagNeedReupload)
  const isComplete = useSelector(isCurrentFlagComplete)
  const isTechnical = useSelector(isCurrentFlagTechnical)

  const flagLoaded = useMemo(
    () => +match.params.flagId === flag.id,
    [match.params.flagId, flag.id]
  )

  return (
    <View>
      <HeaderBlock
        title={getFlagItemPrimaryText(flag)}
        subtitle={isTechnical && packetName}
      >
        <ButtonBlock justify="right">
          <Link to={previousLink}>
            <Button.Secondary>
              <Icon.Times />
            </Button.Secondary>
          </Link>
        </ButtonBlock>
      </HeaderBlock>
      <ViewContainer>
        <Sheet>
          <CardError
            hide={!hasErrors}
            message={Object.values(errors).join(', ')}
          />
          <LoadBar show={isLoading} />
          {!isLoading && !hasErrors && flagLoaded && (
            <>
              {needsReupload && isComplete ? (
                <div />
              ) : (
                <FlagSingleReuploadButton />
              )}
              <FlagSingleReadyButton />
              <FlagSingleDuplicateFlagsList />
              <Section label={t('Action Plan')}>
                <FlagSingleActionPlan />
              </Section>
              <Section label={t('Resolution and Responses')}>
                <FlagSingleComments />
              </Section>
              {flagCanvasser && <FlagSingleCanvasserDetail />}
              <FlagSingleAssociatedFlagsList />
              <FlagSingleTriggeredScansList scrollToRef={packetViewerRef} />
              <FlagSinglePacketViewer refProp={packetViewerRef} />
            </>
          )}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default () => (
  <QueryParamProvider>
    <FlagSingleMain />
  </QueryParamProvider>
)
