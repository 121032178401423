import { Section } from 'components'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import RecordDuplicate from '../RecordDuplicate/RecordDuplicate'
import { RecordContext } from '../RecordContext/RecordContext'
import styles from './RecordDuplicates.module.scss'

const RecordDuplicates = () => {
  const { t } = useTranslation()
  const { duplicateRes } = useContext(RecordContext)

  return (
    <Section
      label={`${duplicateRes.length} ${
        duplicateRes.length > 1
          ? t('potential duplicates')
          : t('potential duplicate')
      }`}
    >
      <div className={styles.duplicates}>
        {duplicateRes.map(person => (
          <div className={styles.duplicate} key={`duplicate-${person.id}`}>
            <RecordDuplicate key={person.id} personRecord={person} />
          </div>
        ))}
      </div>
    </Section>
  )
}

export default RecordDuplicates
