import React from 'react'
import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { AutoTableProvider, useAutoTable } from 'contexts'
import { useReactRouter, DEPRECATED_useRequest } from 'hooks'
import { destroyEmailTemplate, fetchEmailTemplates } from 'requests/email'
import tableConfig from './tableConfig'

const ManageTable = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { renderTable, refreshPage } = useAutoTable()
  const {
    makeRequest: templatesReq,
    isLoading: templatesLoading,
    response: templatesRes,
    errorMsg: templatesReqError,
  } = DEPRECATED_useRequest(params =>
    fetchEmailTemplates({
      fields: ['id', 'name', 'created_at', { user: 'name' }],
      current_page: params?.page,
      per: params?.pageSize,
      sort_attr: params?.sortColumn,
      sort_dir: params?.sortOrder,
      filters: {
        rules: params?.filterRules,
        conjunction: params?.filterConjunction,
      },
      indexed: true,
    })
  )
  const {
    makeRequest: deleteTemplateReq,
    isLoading: deleteTemplateLoading,
    errorMsg: deleteReqError,
  } = DEPRECATED_useRequest(destroyEmailTemplate, refreshPage)

  const handleEdit = rowData => {
    history.push(`/email/template/${rowData.id}`)
  }

  const handleDelete = rowData => deleteTemplateReq(rowData.id)

  const handleSend = rowData => {
    history.push(`/email/send/${rowData.id}`)
  }

  const columns = [
    {
      actions: [
        createRowAction(t('Send'), handleSend),
        createRowAction(t('Edit'), handleEdit),
        createRowAction(t('Delete'), handleDelete),
      ],
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  return renderTable({
    data: templatesRes?.email_templates || [],
    loading: templatesLoading || deleteTemplateLoading,
    error: templatesReqError || deleteReqError,
    totalRecords: templatesRes?.meta?.total_count || 0,
    columns,
    fetchAction: templatesReq,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
  >
    <ManageTable />
  </AutoTableProvider>
)
