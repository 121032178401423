import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  Icon,
  ContentBlock,
  Font,
  TextBlock,
  Grid,
  FieldBlock,
  DateField,
  TextField,
  Sheet,
  Section,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import {
  View,
  ViewContainer,
  HeaderBlock,
  CardError,
  FilledLineChart,
} from 'components'
import { formatNumberAsString } from 'utils/formatting'
import { QueryParamProvider, useTurfs } from 'contexts/'
import { useReactRouter } from 'hooks'
import { buildProjectionsChart, tickFormatter } from 'utils/fieldManagement'
import useProjection from '../Hooks/useProjection'
import useUpdateProjection from '../Hooks/useUpdateProjection'
import styles from '../FieldManagement.module.scss'
import ProjectionSummary from '../ProjectionSummary/ProjectionSummary'
import ProjectionBreakdown from '../ProjectionBreakdown/ProjectionBreakdown'
import ProjectionContextProvider, {
  ProjectionContext,
} from '../ProjectionContext/ProjectionContext'
import ProjectionAssociatedGoal from '../ProjectionAssociatedGoal/ProjectionAssociatedGoal'
import ProjectionDateWarningModal from '../ProjectionDateWarningModal/ProjectionDateWarningModal'
import ProjectionDeleteWarningModal from '../ProjectionDeleteWarningModal/ProjectionDeleteWarningModal'
import ProjectionExitWarningModal from '../ProjectionExitWarningModal/ProjectionExitWarningModal'

const ProjectionSingle = () => {
  const { t } = useTranslation()
  const { history, match } = useReactRouter()
  const [dateModalIsOpen, setDateModalIsOpen] = useState(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [exitModalIsOpen, setExitModalIsOpen] = useState(false)
  const [originalStartDate, setOriginalStartDate] = useState()
  const [newStartDate, setNewStartDate] = useState()
  const [originalEndDate, setOriginalEndDate] = useState()
  const [newEndDate, setNewEndDate] = useState()
  const [dirty, setDirty] = useState(false)
  const { preparedTurfs: turfs } = useTurfs()
  const {
    weeklyProjectedData,
    setWeeklyProjectedData,
    weeklyActualData,
    setWeeklyActualData,
    goal,
    totalStartDate,
    setTotalStartDate,
    totalEndDate,
    setTotalEndDate,
    formattedChartData,
    setFormattedChartData,
  } = useContext(ProjectionContext)
  const {
    isLoading,
    errorMessage,
    isBlank,
    projectionName,
    setProjectionName,
    selectedTurfId,
  } = useProjection(match.params.id, {
    setWeeklyProjectedData,
    setWeeklyActualData,
    totalStartDate,
    setTotalStartDate,
    totalEndDate,
    setTotalEndDate,
  })

  useEffect(() => {
    buildProjectionsChart({
      weeklyProjectedData,
      weeklyActualData,
      setFormattedChartData,
      totalStartDate,
    })
  }, [weeklyProjectedData, weeklyActualData, totalStartDate])

  const series = [
    {
      dataKey: 'collected',
      color: '#59b0ee',
    },
    {
      dataKey: 'projected',
      color: '#5c7192',
    },
  ]

  const chartInfo = {
    projectionData: formattedChartData
      .slice()
      .filter(
        datapoint =>
          datapoint.date >= moment(totalStartDate).format('X') &&
          datapoint.date <= moment(totalEndDate).format('X')
      ),
    goalLine: goal,
    series,
  }

  const { setProjection } = useUpdateProjection(match.params.id, () =>
    history.push('/field-management/projections')
  )

  const handleUpdate = () => {
    setProjection({
      start_date: totalStartDate,
      end_date: totalEndDate,
      name: projectionName,
      turfId: selectedTurfId,
      weeklyProjectedData,
    })
  }

  const handleDelete = () => {
    setDeleteModalIsOpen(true)
  }

  const handleExit = () => {
    if (dirty) {
      setExitModalIsOpen(true)
    } else {
      history.push('/field-management/projections')
    }
  }

  const selectedTurf = turfs.find(turf => turf.value === selectedTurfId)

  const isValid =
    projectionName && totalStartDate && totalEndDate && selectedTurfId

  useEffect(() => {
    if (totalStartDate && !originalStartDate) {
      setOriginalStartDate(totalStartDate)
    }
  }, [totalStartDate])

  useEffect(() => {
    if (totalEndDate && !originalEndDate) {
      setOriginalEndDate(totalEndDate)
    }
  }, [totalEndDate])

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <HeaderBlock
            title={projectionName || '...'}
            subtitle={selectedTurf?.name}
          >
            <ButtonBlock justify="right">
              <Button.Danger aria-label={t('Delete')} onClick={handleDelete}>
                <Icon.TrashAlt />
              </Button.Danger>
              <Button.Secondary aria-label={t('Exit')} onClick={handleExit}>
                <Icon.Times />
              </Button.Secondary>
            </ButtonBlock>
          </HeaderBlock>
          <Grid>
            <Section>
              <FieldBlock>
                <TextField
                  label={t('Projection name')}
                  value={projectionName}
                  onChange={val => {
                    setProjectionName(val)
                    setDirty(true)
                  }}
                />
              </FieldBlock>
              <FieldBlock variant="medium">
                <DateField
                  type="range"
                  label={t('Date range')}
                  startDate={totalStartDate}
                  endDate={totalEndDate}
                  onChange={(start, end) => {
                    if (!originalStartDate || !originalEndDate) return
                    if (
                      new Date(start).getTime() !==
                        new Date(originalStartDate).getTime() ||
                      new Date(end).getTime() !==
                        new Date(originalEndDate).getTime()
                    ) {
                      setTotalStartDate(null)
                      setTotalEndDate(null)
                      setNewStartDate(start)
                      setNewEndDate(end)
                      setDateModalIsOpen(true)
                    }
                  }}
                />
              </FieldBlock>
            </Section>
            {selectedTurf && totalStartDate && totalEndDate && (
              <ProjectionAssociatedGoal
                selectedTurf={selectedTurf}
                startDate={totalStartDate}
                endDate={totalEndDate}
              />
            )}
          </Grid>
          <Section>
            <ProgressBar show={isLoading} />
            <CardError hide={!errorMessage} message={errorMessage} />
            {isBlank && (
              <TextBlock>
                <Font.Copy variant="secondary">
                  No projections found with the selected filters
                </Font.Copy>
              </TextBlock>
            )}
            {!isBlank && (
              <ContentBlock className={styles.chart__container}>
                <div className={styles.chart__line}>
                  <TextBlock>
                    <Font.Copy variant="highlight">
                      Voter registrations collected
                    </Font.Copy>
                  </TextBlock>
                  <FilledLineChart
                    data={chartInfo.projectionData}
                    reference={chartInfo.goalLine}
                    referenceLabel={`Goal - ${formatNumberAsString(
                      chartInfo.goalLine
                    )} registrations`}
                    series={chartInfo.series}
                    xAxisKey="date"
                    xTickFormatter={tickFormatter}
                  />
                </div>
              </ContentBlock>
            )}
          </Section>
          <ProjectionSummary />
          <ProjectionBreakdown setDirty={setDirty} />
          <ButtonBlock>
            <Button.Accent onClick={handleUpdate} disabled={!isValid}>
              Save
            </Button.Accent>
            <Link to="/field-management/projections">
              <Button.Secondary>Cancel</Button.Secondary>
            </Link>
          </ButtonBlock>
        </Sheet>
      </ViewContainer>
      <ProjectionDateWarningModal
        isOpen={dateModalIsOpen}
        setIsOpen={setDateModalIsOpen}
        newStartDate={newStartDate}
        originalStartDate={originalStartDate}
        newEndDate={newEndDate}
        originalEndDate={originalEndDate}
        setDirty={setDirty}
      />
      <ProjectionDeleteWarningModal
        isOpen={deleteModalIsOpen}
        setIsOpen={setDeleteModalIsOpen}
        projectionToDelete={match.params.id}
      />
      <ProjectionExitWarningModal
        isOpen={exitModalIsOpen}
        setIsOpen={setExitModalIsOpen}
      />
    </View>
  )
}

export default () => (
  <QueryParamProvider>
    <ProjectionContextProvider>
      <ProjectionSingle />
    </ProjectionContextProvider>
  </QueryParamProvider>
)
