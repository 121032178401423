import { useRef, useState } from 'react'
import { formatLegacyErrorMsg, formatApiError } from 'utils/formatting'
import useEvent from './useEvent'

export type Request<T> = (...args: any[]) => Promise<T>

export type RequestErrors = Record<string, string[]>

export type UseRequestHandlers<T> = {
  onSuccess?:
    | ((response: Awaited<T>) => void)
    | ((response: Awaited<T>) => Promise<unknown>)
  onError?:
    | ((error: unknown, args: unknown[]) => void)
    | ((error: unknown, args: unknown[]) => Promise<unknown>)
}

export type UseRequestData<T> = {
  errors: RequestErrors
  hasErrors: boolean
  isRequestComplete: boolean
  isLoading: boolean
  response?: Awaited<T>
}

export type UseRequestActions<T> = {
  clearRequest: () => void
  makeRequest: (...args: any[]) => Promise<RequestErrors | T | undefined>
}

export type UseRequestState<T> = UseRequestData<T> & UseRequestActions<T>

const defaultRequestData = {
  errors: {},
  hasErrors: false,
  isRequestComplete: false,
  isLoading: false,
}

export const useRequest = <T>(
  request: Request<T>,
  handlers?: UseRequestHandlers<T>
): UseRequestState<T> => {
  const { onSuccess, onError } = handlers || {}

  const latestInit = useRef<number>()
  const [requestData, setRequestData] =
    useState<UseRequestData<T>>(defaultRequestData)

  const clearRequest = () => setRequestData(defaultRequestData)

  const makeRequest = async (...args: Parameters<typeof request>) => {
    const initTime = Date.now()
    latestInit.current = initTime
    setRequestData({
      ...defaultRequestData,
      isLoading: true,
    })

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const response = await request(...args)

      if (latestInit.current === initTime) {
        if (typeof onSuccess === 'function') {
          await onSuccess(response)
        }

        setRequestData({
          ...defaultRequestData,
          response,
          isRequestComplete: true,
        })

        return response
      }
    } catch (error) {
      if (latestInit.current === initTime) {
        if (error instanceof Error) {
          if (error.name === 'AbortError') {
            setRequestData(defaultRequestData)
            return
          }
          if (error.name !== 'RequestException') {
            throw error
          }
        }

        const formattedErrors = await formatApiError(error)
        const errors = formattedErrors as RequestErrors

        if (typeof onError === 'function') {
          await onError(error, args)
        }

        setRequestData({
          errors,
          isLoading: false,
          hasErrors: true,
          isRequestComplete: true,
        })

        return errors
      }
    }
  }

  return {
    clearRequest,
    makeRequest,
    ...requestData,
  }
}

useRequest.MAX_CACHE_LENGTH = 10

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DEPRECATED_useRequest = <T>(
  request: Request<T>,
  onSuccess: (response: unknown) => void,
  formatError = formatLegacyErrorMsg
): UseRequestActions<T> & UseRequestData<T> & { errorMsg: string } => {
  const [errorMsg, setErrorMsg] = useState('')
  const handleOnErrors = async (error: any) => {
    const message = await formatError(error)
    setErrorMsg(message)
  }
  const requestUtils = useRequest(request, {
    onSuccess,
    onError: handleOnErrors,
  })

  return {
    errorMsg,
    ...requestUtils,
  }
}

const cache = {}

export const useCachedRequest = <T>(
  request: Request<T>,
  handlers?: UseRequestHandlers<T>,
  opts?: { timeToStale: number }
) => {
  const { onSuccess, onError } = handlers || {}
  const { timeToStale } = opts || { timeToStale: 2000 }

  const _cache = cache as Record<string, Awaited<T> & { isStale?: boolean }>

  const cachedRequest = useEvent(async (key: string, ...args: any[]) => {
    if (_cache[key] && !_cache[key].isStale) {
      return _cache[key]
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const result = await request(...args)

    _cache[key] = {
      ...result,
      isStale: false,
    }

    setTimeout(() => {
      _cache[key].isStale = true
    }, timeToStale)

    return _cache[key]
  })

  return useRequest(cachedRequest, { onSuccess, onError })
}
