import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  View,
  HeaderBlock,
  ViewContainer,
  CardError,
  Sheet,
  DetailItem,
  Section,
} from 'components'
import { Button, ButtonBlock, Icon } from '@politechdev/blocks-design-system'
import { useRoutePathParams, useReactRouter, useRequest } from 'hooks'
import { formatDisplayName } from 'utils/formatting'
import { fetchMeeting } from 'requests/events'
import { useCurrent } from 'contexts/index'
import EventDeleteModal from '../EventDeleteModal/EventDeleteModal'
import MeetingDetails from '../MeetingDetails/MeetingDetails'

const MeetingSingle = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const [{ id: meetingId }] = useRoutePathParams()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyMeeting = doesCurrentUserHavePermission({
    resource: 'meeting',
    ability: 'modify',
  })

  const [currentMeeting, setCurrentMeeting] = useState()

  const { makeRequest, isLoading, hasErrors } = useRequest(fetchMeeting, {
    onSuccess: ({ meeting }) => {
      setCurrentMeeting(meeting)
    },
  })

  useEffect(() => {
    makeRequest(meetingId)
  }, [meetingId])

  const eventLoaded =
    !isLoading && !hasErrors && currentMeeting?.id === +meetingId

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <CardError
          hide={!hasErrors}
          message={t("We're unable to retrieve this meeting")}
        />
        {eventLoaded && (
          <Sheet>
            <HeaderBlock title={t('Meeting')}>
              <ButtonBlock justify="right">
                <Link
                  onClick={e => !canModifyMeeting && e.preventDefault()}
                  to={`${match.url}/edit`}
                >
                  <Button disabled={!canModifyMeeting}>
                    <Icon.Pencil />
                  </Button>
                </Link>
                <EventDeleteModal meeting={currentMeeting} />
              </ButtonBlock>
            </HeaderBlock>
            <Section label={t('Attendance')}>
              <DetailItem label={t('Host')}>
                {formatDisplayName(currentMeeting.user)}
              </DetailItem>
              <DetailItem label={t('Guest')}>
                {currentMeeting.person ? (
                  <Link to={`/organize/people/${currentMeeting.person.id}`}>
                    {currentMeeting.person.name}
                  </Link>
                ) : (
                  <p>[Person removed]</p>
                )}
              </DetailItem>
              <DetailItem label={t('Additional guest')}>
                {currentMeeting.additional_person ? (
                  <Link
                    to={`/organize/people/${currentMeeting.additional_person.id}`}
                  >
                    {currentMeeting.additional_person.name}
                  </Link>
                ) : (
                  <p>No additional guest</p>
                )}
              </DetailItem>
            </Section>
            <MeetingDetails
              currentMeeting={currentMeeting}
              setCurrentMeeting={setCurrentMeeting}
            />
          </Sheet>
        )}
      </ViewContainer>
    </View>
  )
}

export default MeetingSingle
