export const SET_ACTIVE_TAB = 'qcFlags/SET_ACTIVE_TAB'
export const RECEIVE_FLAGS_TO_REVIEW = 'qcFlags/RECEIVE_FLAGS_TO_REVIEW'
export const SET_TO_REVIEW_PAGE = 'qcFlags/SET_TO_REVIEW_PAGE'
export const RECEIVE_FLAGS_AWAITING_GROUP_QC_ACTION =
  'qcFlags/RECEIVE_FLAGS_AWAITING_GROUP_QC_ACTION'
export const SET_AWAITING_GROUP_QC_ACTION_LIST_PAGE =
  'qcFlags/SET_AWAITING_GROUP_QC_ACTION_LIST_PAGE'
export const RECEIVE_SELECTED_FLAG = 'qcFlags/RECEIVE_SELECTED_FLAG'
export const RECEIVE_FLAG_TRIGGERS = 'qcFlags/RECEIVE_FLAG_TRIGGERS'
export const SET_SORT_BY = 'qcFlags/SET_SORT_BY'
export const SET_TECHNICAL_FLAG_FILTER = 'qcFlags/SET_TECHNICAL_FLAG_FILTER'
export const SET_CANVASSER_FLAG_FILTER = 'qcFlags/SET_CANVASSER_FLAG_FILTER'
export const SET_START_DATE_FILTER = 'qcFlags/SET_START_DATE_FILTER'
export const SET_END_DATE_FILTER = 'qcFlags/SET_END_DATE_FILTER'
export const SET_FLAG_ID_FILTER = 'qcFlags/SET_FLAG_ID_FILTER'
export const SET_TURF_FILTER = 'qcFlags/SET_TURF_FILTER'
export const SET_FLAG_NAME_FILTER = 'qcFlags/SET_FLAG_NAME_FILTER'
export const SET_CANVASSER_FILTER = 'qcFlags/SET_CANVASSER_FILTER'
export const SET_NEW_COMMENTS_FILTER = 'qcFlags/SET_NEW_COMMENTS_FILTER'
export const CLEAR_ALL_FILTERS = 'qcFlags/CLEAR_ALL_FILTERS'
export const SET_UNREAD_STATUS_FOR_FLAG_GROUPS =
  'qcFlags/SET_UNREAD_STATUS_FOR_FLAG_GROUPS'
export const RECEIVE_RESOLVED_FLAGS = 'qcFlags/RECEIVE_RESOLVED_FLAGS'
export const SET_RESOLVED_FLAGS_LIST_PAGE =
  'qcFlags/SET_RESOLVED_FLAGS_LIST_PAGE'
export const RECEIVE_FLAGS_ASSOCIATED_WITH_CURRENT_FLAG =
  'qcFlags/RECEIVE_FLAGS_ASSOCIATED_WITH_CURRENT_FLAG'
export const RECEIVE_CURRENT_FLAG_DUPLICATES =
  'qcFlags/RECEIVE_CURRENT_FLAG_DUPLICATES'
