import { connect } from 'react-redux'
import {
  getTechnicalFlagsAssociatedWithCurrentFlag,
  getCurrentFlag,
} from 'store/qcFlags/reducer'
import {
  SectionLabel,
  CardError,
  LoadBar,
  Section,
  TextBlock,
} from 'components'
import { useReactRouter, DEPRECATED_useRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { requestFlagsAssociatedWithCurrentFlag } from 'store/qcFlags/actions'
import { useEffect } from 'react'
import styles from './FlagSingleAssociatedFlagsList.module.scss'

const FlagSingleAssociatedFlagsList = ({
  technicalFlagsAssociatedWithCurrentFlag,
  requestFlagsAssociatedWithCurrentFlag,
  currentFlag,
}) => {
  const { match, history } = useReactRouter()
  const { t } = useTranslation()

  const { isLoading, errorMsg, makeRequest } = DEPRECATED_useRequest(
    requestFlagsAssociatedWithCurrentFlag
  )

  useEffect(() => {
    makeRequest()
  }, [currentFlag])

  return (
    <Section>
      <div className={styles.header}>
        <TextBlock>
          <SectionLabel>
            {isLoading || technicalFlagsAssociatedWithCurrentFlag.length !== 0
              ? t('Other technical flags on this packet')
              : t('No other technical flags found on this packet')}
          </SectionLabel>
        </TextBlock>
      </div>
      <CardError hide={!errorMsg} />
      <LoadBar show={isLoading} />
      {technicalFlagsAssociatedWithCurrentFlag.map(
        flagAssociatedWithCurrentFlag => (
          <div
            className={styles.wrapper}
            key={`associated-flag-${flagAssociatedWithCurrentFlag.id}`}
          >
            <span
              className={styles.id}
            >{`Flag ${flagAssociatedWithCurrentFlag.id}`}</span>
            <span
              className={styles.flagName}
              onClick={() =>
                history.push(
                  `/quality_control/flags/${match.params.type}/${flagAssociatedWithCurrentFlag.id}/${match.params.originalType}`
                )
              }
            >
              {flagAssociatedWithCurrentFlag.trigger.name}
            </span>
            <span className={styles.actionPlan}>
              {flagAssociatedWithCurrentFlag.action_plan}
            </span>
          </div>
        )
      )}
    </Section>
  )
}

export default connect(
  state => ({
    technicalFlagsAssociatedWithCurrentFlag:
      getTechnicalFlagsAssociatedWithCurrentFlag(state),
    currentFlag: getCurrentFlag(state),
  }),
  { requestFlagsAssociatedWithCurrentFlag }
)(FlagSingleAssociatedFlagsList)
