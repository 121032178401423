import { useEffect } from 'react'
import { HeaderBlock, LoadBar } from 'components'
import { ContentBlock } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useTurfs } from 'contexts/index'
import TurfScriptList from './TurfScriptList'

const ScriptSelect = () => {
  const { t } = useTranslation()

  const {
    currentTurfs: userTurfs,
    refreshCurrentTurfs,
    turfRefreshIsLoading,
  } = useTurfs()

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  return (
    <>
      <HeaderBlock title={t('Manage phone verification scripts')} />
      <LoadBar show={turfRefreshIsLoading} />
      <ContentBlock>
        {(userTurfs || []).map(({ id, name }) => (
          <TurfScriptList key={id} turfId={id} turfName={name} />
        ))}
      </ContentBlock>
    </>
  )
}

export default ScriptSelect
