import { HeaderBlock, TextBlock } from 'components'
import { forwardRef, useMemo } from 'react'
import moment from 'moment'
import { dateFormat, dateTimeFormat } from 'utils/constants'
import styles from './ShiftCalendarWeekDownload.module.scss'

const ShiftCalendarWeekDownload = forwardRef(({ items: shifts }, ref) => {
  const groupedSortedShifts = useMemo(() => {
    const groupedShifts = shifts.reduce(
      (res, shift) => {
        const day = moment(shift.shift_start).day()
        res[day].push(shift)
        return res
      },
      [[], [], [], [], [], [], []]
    )

    groupedShifts.forEach(shifts =>
      shifts.sort((shiftA, shiftB) =>
        moment(shiftA.shift_start).diff(shiftB.shift_start)
      )
    )

    return groupedShifts
  }, [shifts])

  return (
    <div ref={ref}>
      {groupedSortedShifts.map((shifts, i) => {
        if (!shifts.length) {
          return null
        }
        return (
          <div key={i}>
            <div className={styles.header}>
              <HeaderBlock
                title={moment(shifts[0].shift_start).format(
                  `dddd ${dateFormat}`
                )}
              />
            </div>
            {shifts.map((shift, j) => (
              <div key={j} className={styles.item}>
                <TextBlock>
                  {`${moment(shift.shift_start).format(`dddd ${dateTimeFormat}`)}
                  -
                    ${moment(shift.end_time).format('h:mm A')} ${
                      shift.canvasser?.full_name
                    } : ${shift.location?.name}`}
                </TextBlock>
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
})

export default ShiftCalendarWeekDownload
