import { useState } from 'react'
import {
  View,
  ViewContainer,
  Sheet,
  HeaderBlock,
  Stepper,
  Step,
  CardError,
  LoadBar,
} from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Font,
  ContentBlock,
  List,
  ListItem,
  Modal,
} from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isValidPhone, isValidEmail } from 'utils/inputValidations'
import { maskPhone } from 'utils/inputMasks'
import { useReactRouter, useRequest } from 'hooks'
import { postPerson, fetchPeopleDuplicates } from 'requests/people'
import { formatLegacyErrorMsg } from 'utils/formatting'
import { cleanAddress } from 'utils/inputProcessing'
import { useCurrent } from 'contexts/index'
import NewPersonFormBasic from '../NewPersonFormBasic/NewPersonFormBasic'
import NewPersonFormContact from '../NewPersonFormContact/NewPersonFormContact'
import NewPersonFormAdditional from '../NewPersonFormAdditional/NewPersonFormAdditional'

const NewPersonForm = ({ addNewContactMethodFields }) => {
  const {
    currentUser: { id: currentUserId },
  } = useCurrent()
  const [state, setState] = useState({
    newPerson: {
      prefix: '',
      suffix_name: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      birth_date: '',
      gender: '',
      ethnicities: [],
      pronouns: '',
      residential_address: {
        line_one: '',
        line_two: '',
        city: '',
        county: '',
        state: '',
        zipcode: '',
      },
      best_contact_method: '',
      primary_phone_number: '',
      primary_email_address: '',
      receives_sms: false,
      organization_memberships: [],
      team_memberships: [{}],
      responsibility: '',
      primary_language: '',
      languages: [],
      issues: [],
      skills: [],
      denominations: [],
      social_links: [],
      notes: [{ content: '', created_by_user_id: currentUserId }],
      external_id: '',
      custom_field_data: {},
    },
    newPhones: [{ contact_type: '', content: '' }],
    newEmails: [{ contact_type: '', content: '' }],
    error: false,
    loading: false,
  })
  const [postPersonReqErrorMsg, setPostPersonReqErrorMsg] = useState('')
  const [duplicateFound, setDuplicateFound] = useState(false)
  const [potentialDuplicates, setPotentialDuplicates] = useState([])

  const { t } = useTranslation()
  const { history } = useReactRouter()
  const {
    makeRequest: postPersonReq,
    isLoading: isPostPersonReqLoading,
    errors: postPersonErrors,
  } = useRequest(postPerson, {
    onSuccess: response => {
      history.push(`/organize/people/${response.person.id}`)
    },
    onError: error => {
      setPostPersonReqErrorMsg(formatLegacyErrorMsg(error))
    },
  })

  const buildPersonFromState = () => ({
    ...state.newPerson,
    notes: state.newPerson.notes.filter(note => note.content),
    primary_email_address: state.newPerson.primary_email_address || null,
    contact_methods: state.newPhones
      .concat(state.newEmails)
      .filter(({ content }) => !!content),
    residential_address: cleanAddress(state.newPerson.residential_address),
    organization_memberships: state.newPerson.organization_memberships,
    team_memberships: state.newPerson.team_memberships
      .filter(({ team }) => team?.id)
      .map(({ team, responsibility }) => ({
        team_id: team.id,
        responsibility,
      })),
  })

  const {
    makeRequest: dupeCheckReq,
    isLoading: dupeCheckIsLoading,
    errors: dupeCheckErrors,
  } = useRequest(fetchPeopleDuplicates, {
    onSuccess: ({ people }) => {
      const noDupe = people.length === 0
      if (noDupe) {
        return postPersonReq(buildPersonFromState(), { fields: ['id'] })
      }

      setPotentialDuplicates(people)
      return setDuplicateFound(true)
    },
  })

  const isContactInfoValid = () => {
    const primaryPhoneNumber = state.newPerson.primary_phone_number

    const isPrimaryPhoneValid =
      isValidPhone(primaryPhoneNumber) || primaryPhoneNumber?.length === 0

    const arePhonesValid = state.newPhones.every(
      phone => isValidPhone(phone.content) || phone.content.length === 0
    )

    const primaryEmail = state.newPerson.primary_email_address

    const isPrimaryEmailValid = !primaryEmail || isValidEmail(primaryEmail)

    const areEmailsValid = state.newEmails.every(
      email => !email.content || isValidEmail(email.content)
    )

    return (
      isPrimaryPhoneValid &&
      isPrimaryEmailValid &&
      arePhonesValid &&
      areEmailsValid
    )
  }

  const { newPerson, newPhones, newEmails } = state

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <HeaderBlock title={t('Add a person')} />
          <Stepper>
            <Step
              label={t('Basic information')}
              disableNext={
                !newPerson.first_name.trim() || !newPerson.last_name.trim()
              }
              onPrevious={() => history.push('/organize/people')}
            >
              <NewPersonFormBasic
                newPerson={newPerson}
                updatePersonState={newState => {
                  setState(prevState => ({ ...prevState, ...newState }))
                }}
              />
            </Step>
            <Step
              label={t('Contact Information')}
              disableNext={!isContactInfoValid()}
            >
              <NewPersonFormContact
                newPerson={newPerson}
                newPhones={newPhones}
                newEmails={newEmails}
                updatePersonState={newState =>
                  setState(prevState => ({ ...prevState, ...newState }))
                }
                addNewContactMethodFields={addNewContactMethodFields}
              />
            </Step>
            <Step
              label={t('Additional information')}
              nextButtonLabel={t('Save')}
              customNextButtonFunction={() =>
                dupeCheckReq({
                  fields: [
                    'id',
                    'name',
                    'primary_phone_number',
                    'primary_email_address',
                  ],
                  attrs: {
                    first_name: state.newPerson.first_name,
                    last_name: state.newPerson.last_name,
                    primary_phone_number: state.newPerson.primary_phone_number,
                    primary_email_address:
                      state.newPerson.primary_email_address,
                  },
                })
              }
              disableNext={isPostPersonReqLoading || dupeCheckIsLoading}
              disablePrevious={isPostPersonReqLoading || dupeCheckIsLoading}
            >
              <NewPersonFormAdditional
                newPerson={newPerson}
                currentUserId={currentUserId}
                newPhones={newPhones}
                newEmails={newEmails}
                updatePersonState={newState =>
                  setState(prevState => ({ ...prevState, ...newState }))
                }
                errors={postPersonErrors || dupeCheckErrors}
              />
              <CardError
                message={t(
                  'An error occurred while trying to create this record. Please check your form entry and try again'
                )}
                hideSupportLink
                hide={!postPersonReqErrorMsg}
              />
            </Step>
          </Stepper>
          <LoadBar show={isPostPersonReqLoading || dupeCheckIsLoading} />
        </Sheet>
        <Modal
          id="warn-dupe-person"
          title={t('Potential duplicate person found')}
          isOpen={duplicateFound}
        >
          <Modal.Body>
            <TextBlock>
              <Font.Copy Element="p">
                {t(
                  'Potential duplicate records were found. Are you sure this person is not already stored in Blocks?'
                )}
              </Font.Copy>
            </TextBlock>
            <List
              itemData={potentialDuplicates}
              render={person => (
                <ListItem>
                  <ContentBlock>
                    <Font.Copy>
                      <Link to={`/organize/people/${person.id}`}>
                        {person.name}
                      </Link>
                    </Font.Copy>
                    <Font.Copy variant="hint">
                      {t('Email')}: {person.primary_email_address || t('None')},{' '}
                      {t('Phone')}:{' '}
                      {person.primary_phone_number
                        ? maskPhone(person.primary_phone_number)
                        : t('None')}
                    </Font.Copy>
                  </ContentBlock>
                </ListItem>
              )}
            />
          </Modal.Body>
          <Modal.Actions>
            <ButtonBlock>
              <Button.Accent
                onClick={() => {
                  setDuplicateFound(false)
                }}
              >
                {t('Make changes before saving')}
              </Button.Accent>
              <Button.Secondary
                onClick={() => {
                  setDuplicateFound(false)
                  postPersonReq(buildPersonFromState(), { fields: ['id'] })
                }}
              >
                {t('Ignore warning and create')}
              </Button.Secondary>
            </ButtonBlock>
          </Modal.Actions>
        </Modal>
      </ViewContainer>
    </View>
  )
}

export default NewPersonForm
