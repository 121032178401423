import { Paginator } from 'components'
import {
  getTotalToReviewListPages,
  getToReviewListPage,
  getAwaitingGroupQcActionListPage,
  getTotalAwaitingGroupQcActionListPages,
  getResolvedFlagListPage,
  getTotalResolvedFlagListPages,
} from 'store/qcFlags/reducer'
import {
  setToReviewPage,
  setAwaitingGroupQcActionListPage,
  setResolvedFlagsListPage,
} from 'store/qcFlags/actions'
import { connect } from 'react-redux'

const ListPager = ({ setPage, currentPage, totalPages }) => (
  <Paginator
    onNext={() => {
      setPage(currentPage + 1)
    }}
    currentPage={currentPage}
    totalPages={totalPages}
    onPrevious={() => setPage(currentPage - 1)}
  />
)

export const ToReviewListPager = connect(
  state => ({
    currentPage: getToReviewListPage(state),
    totalPages: getTotalToReviewListPages(state),
  }),
  { setPage: setToReviewPage }
)(ListPager)

export const AwaitingGroupQcActionListPager = connect(
  state => ({
    currentPage: getAwaitingGroupQcActionListPage(state),
    totalPages: getTotalAwaitingGroupQcActionListPages(state),
  }),
  { setPage: setAwaitingGroupQcActionListPage }
)(ListPager)

export const ResolvedListPager = connect(
  state => ({
    currentPage: getResolvedFlagListPage(state),
    totalPages: getTotalResolvedFlagListPages(state),
  }),
  { setPage: setResolvedFlagsListPage }
)(ListPager)
