import { connect } from 'react-redux'
import { useState } from 'react'
import { TextBlock, FormattedData, PlaceholderText } from 'components'
import { getCurrentRegistrationForm } from 'store/qcScans/reducer'
import { updateRegistrantInformation } from 'store/qcScans/actions'
import { useTranslation } from 'react-i18next'
import EditRegistrantProfileModal from '../RegistrantProfileComponents/EditRegistrantProfileModal/EditRegistrantProfileModal'
import RegistrantProfileEditButton from '../RegistrantProfileComponents/RegistrantProfileEditButton/RegistrantProfileEditButton'
import styles from './RegistrantProfile.module.scss'

const RegistrantProfile = ({ currentRegistrationForm }) => {
  const { t } = useTranslation()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const {
    first_name,
    last_name,
    voting_street_address_one,
    voting_street_address_two,
    voting_city,
    voting_state,
    voting_zipcode,
    phone_number,
    date_of_birth,
  } = currentRegistrationForm
  return (
    <div className={styles.profile}>
      <TextBlock>
        <p className={styles.label}> {t('Registrant info')}</p>
        <p className={styles.name}>
          {first_name && last_name ? (
            `${first_name} ${last_name}`
          ) : (
            <PlaceholderText>{t('No name found')}</PlaceholderText>
          )}
        </p>
      </TextBlock>
      <TextBlock>
        <p className={styles.label}> {t('Phone number')}</p>
        {phone_number ? (
          <FormattedData type="phone" value={phone_number} />
        ) : (
          <PlaceholderText>{t('No phone found')}</PlaceholderText>
        )}
      </TextBlock>
      <TextBlock>
        <p className={styles.label}> {t('Address')}</p>
        {voting_street_address_one ? (
          <p>
            <div className={styles.address}>{voting_street_address_one}</div>
            <div className={styles.address}>{voting_street_address_two}</div>
            <div className={styles.address}>
              {`${voting_city}, `}
              {`${voting_state} `}
              {voting_zipcode}
            </div>
          </p>
        ) : (
          <PlaceholderText>{t('No address found')}</PlaceholderText>
        )}
      </TextBlock>
      <TextBlock>
        <p className={styles.label}> {t('Date of birth')}</p>
        {date_of_birth ? (
          <FormattedData type="date" value={date_of_birth} />
        ) : (
          <PlaceholderText>{t('No date of birth found')}</PlaceholderText>
        )}
      </TextBlock>
      <RegistrantProfileEditButton setIsEditModalOpen={setIsEditModalOpen} />
      <EditRegistrantProfileModal
        setIsOpen={setIsEditModalOpen}
        isOpen={isEditModalOpen}
      />
    </div>
  )
}

export default connect(
  state => ({
    currentRegistrationForm: getCurrentRegistrationForm(state),
  }),
  { updateRegistrantInformation }
)(RegistrantProfile)
