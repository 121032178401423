import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { View, HeaderBlock, FormattedData } from 'components'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'
import QuestionContainer from './QuestionContainer/QuestionContainer'

const CheckinView = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { doesCurrentUserHavePermission } = useCurrent()
  const hasManagementPermission = doesCurrentUserHavePermission({
    resource: 'check_in',
    ability: 'manage',
  })

  return (
    <View>
      <HeaderBlock
        title={t('Check-in')}
        subtitle={<FormattedData value={moment()} type="date" />}
      >
        <ButtonBlock justify="right">
          <Button.Secondary
            onClick={() => history.push(`${location.pathname}/manage`)}
            disabled={!hasManagementPermission}
          >
            {t('Manage check-ins')}
          </Button.Secondary>
          <Button.Secondary
            onClick={() => history.push(`${location.pathname}/review`)}
            disabled={!hasManagementPermission}
          >
            {t('Review responses')}
          </Button.Secondary>
        </ButtonBlock>
      </HeaderBlock>
      <QuestionContainer />
    </View>
  )
}

export default CheckinView
