import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  Icon,
  Modal,
  TextField,
} from '@politechdev/blocks-design-system'
import { useRequest, useEvent } from 'hooks'
import { useCurrent } from 'contexts/index'
import { putTurfLevel } from 'requests/turfLevels'

const TurfLevelEditModal = ({ turfLevel, onClose }) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyTurfLevels = doesCurrentUserHavePermission({
    resource: 'turf_level',
    ability: 'modify',
  })

  const [modalOpen, setModalOpen] = useState(false)
  const [name, setName] = useState(turfLevel.name)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    onClose && onClose()
    setModalOpen(false)
  }

  const { makeRequest, isLoading, hasErrors } = useRequest(putTurfLevel, {
    onSuccess: closeModal,
  })

  const save = useEvent(() => {
    makeRequest(turfLevel.id, { name })
  })

  return (
    <>
      <Button
        aria-label={t('Edit')}
        onClick={openModal}
        disabled={!canModifyTurfLevels}
      >
        <Icon.Pencil />
      </Button>
      <Modal
        id="turf-level-edit"
        title={t(`Edit turf level`)}
        isOpen={modalOpen}
        showLoading={isLoading}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t('There was an error updating your turf level')}
              hideSupportLink
            />
            <TextField
              id="turf-level-name"
              label={t('Turf level name')}
              value={name}
              onChange={setName}
              required
            />
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Accent onClick={save} disabled={name === '' || isLoading}>
              {t('Save')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default TurfLevelEditModal
