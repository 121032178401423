import { useCallback, useLayoutEffect, useRef } from 'react'

// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
const useEvent = callback => {
  const ref = useRef(null)

  useLayoutEffect(() => {
    ref.current = callback
  })

  return useCallback((...args) => {
    const callback = ref.current
    return callback(...args)
  }, [])
}

export default useEvent
