import { useEffect, useState } from 'react'
import moment from 'moment'
import {
  HeaderBlock,
  Stepper,
  Step,
  ToggleBoxGroup,
  CardError,
  TurfSelectField,
} from 'components'
import {
  Section,
  SectionLabel,
  DetailItem,
  ContentBlock,
  FieldBlock,
  TextBlock,
  TextField,
  SelectField,
  Checkbox,
  Switch,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import {
  postScheduledExport,
  fetchSingleExport,
  putScheduledExport,
} from 'requests/exports'
import { useReactRouter, useRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useCurrent, useTurfs } from 'contexts/index'
import TABLE_DISPLAY_NAMES from '../tableNamesConfig'
import { EXPORTABLE_TABLES, TABLE_OPTIONS } from '../constants'
import styles from './ScheduledExportsForm.module.scss'

const ScheduledExportsForm = ({ isEdit }) => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()

  const {
    currentUser: { id: userId, email: userEmail },
  } = useCurrent()

  const { currentTurfs } = useTurfs()

  const [currentExport, setCurrentExport] = useState({ recipients: [] })

  const turfOptions = [{ name: 'All Turfs', id: 0 }, ...currentTurfs]

  const [exportData, setExportData] = useState({
    selectedTable: null,
    selectedColumns: [],
    exportName: 'Example',
    allColumns: true,
    frequency: 'Daily',
    hour: 12,
    paused: false,
    meridiem: 'PM',
    recipients: userEmail ? [userEmail] : [],
    turf: 0,
  })

  const {
    makeRequest: exportReq,
    isLoading: isExportReqLoading,
    hasErrors: exportReqError,
    isRequestComplete: exportReqComplete,
  } = useRequest(fetchSingleExport, {
    onSuccess: ({ scheduled_export }) => {
      setCurrentExport(scheduled_export)
    },
  })

  const {
    makeRequest: updateExportReq,
    isLoading: isUpdateExportReqLoading,
    hasErrors: updateExportReqError,
  } = useRequest(putScheduledExport, {
    onSuccess: () => {
      history.push(`/data_sources/scheduled_exports/${currentExport.id}`)
    },
  })

  const {
    makeRequest: createExportReq,
    isLoading: isCreateExportReqLoading,
    hasErrors: createExportReqError,
  } = useRequest(postScheduledExport, {
    onSuccess: ({ scheduled_export }) => {
      history.push(`/data_sources/scheduled_exports/${scheduled_export.id}`)
    },
  })

  useEffect(() => {
    isEdit &&
      exportReq(match.params.id, {
        indexed: true,
        fields: [
          'id',
          'frequency',
          'table_name',
          'paused',
          'columns',
          'recipients',
          'attachment_name',
          'hour_of_the_day',
          'day_of_the_week',
          { turfs: ['id'] },
        ],
        associations: ['turfs'],
      })
  }, [])

  useEffect(() => {
    if (isEdit && exportReqComplete) {
      const {
        table_name,
        columns,
        attachment_name,
        frequency,
        hour_of_the_day,
        day_of_the_week,
        recipients,
        turfs,
        paused,
      } = currentExport

      setExportData(prevExportData => ({
        ...prevExportData,
        selectedTable: {
          tableName: table_name,
          label: TABLE_DISPLAY_NAMES[table_name],
        },
        selectedColumns: columns,
        exportName: attachment_name,
        allColumns: columns.length === EXPORTABLE_TABLES[table_name].length,
        frequency: frequency.charAt(0).toUpperCase() + frequency.slice(1),
        hour: hour_of_the_day > 12 ? hour_of_the_day - 12 : hour_of_the_day,
        dayOfTheWeek:
          day_of_the_week &&
          day_of_the_week.charAt(0).toUpperCase() + day_of_the_week.slice(1),
        meridiem: hour_of_the_day >= 12 ? 'PM' : 'AM',
        recipients,
        turf: turfs.length > 1 ? 0 : turfs[0].id,
        paused,
      }))
    }
  }, [exportReqComplete])

  const routeToSelect = () => {
    history.push('/data_sources/scheduled_exports')
  }

  const selectTable = table => {
    setExportData(prevExportData => ({
      ...prevExportData,
      selectedTable: table,
      selectedColumns: table ? EXPORTABLE_TABLES[table.tableName] : [],
      allColumns: true,
    }))
  }

  const selectColumn = (column, value) => {
    const { selectedColumns } = exportData
    const newSelectedColumns = value
      ? [column, ...selectedColumns]
      : selectedColumns.filter(c => c !== column)

    setExportData(prevExportData => ({
      ...prevExportData,
      selectedColumns: newSelectedColumns,
      allColumns: value ? prevExportData.allColumns : false,
    }))
  }

  const setExportName = exportName => {
    setExportData(prevExportData => ({ ...prevExportData, exportName }))
  }

  const setEmails = emailString => {
    const recipients = emailString.replace(/\s/g, '').split(',')
    setExportData(prevExportData => ({ ...prevExportData, recipients }))
  }

  const toggleCheckAll = () => {
    setExportData(prevExportData => ({
      ...prevExportData,
      allColumns: !prevExportData.allColumns,
      selectedColumns: prevExportData.allColumns
        ? []
        : EXPORTABLE_TABLES[prevExportData.selectedTable.tableName],
    }))
  }

  const setField = (value, field) => {
    setExportData(prevExportData => ({
      ...prevExportData,
      [field]: value,
    }))
  }
  const {
    selectedTable,
    turf,
    selectedColumns,
    exportName,
    recipients,
    frequency,
    dayOfTheWeek,
    allColumns,
    hour,
    meridiem,
    paused,
  } = exportData

  const submitExport = () => {
    const allTurfs = turfOptions.filter(t => t.id !== 0).map(t => t.id)

    const hourOfTheDay = meridiem === 'AM' || hour === 12 ? hour : hour + 12

    const params = {
      table_name: selectedTable.tableName,
      attachment_name: exportName,
      user_id: userId,
      turf_ids: turf ? [turf] : allTurfs,
      format: 'csv',
      columns: selectedColumns,
      frequency: frequency.toLowerCase(),
      day_of_the_week: dayOfTheWeek && dayOfTheWeek.toLowerCase(),
      hour_of_the_day: hourOfTheDay,
      recipients,
      paused,
    }

    isEdit ? updateExportReq(currentExport.id, params) : createExportReq(params)
  }

  const selectedTurf = turfOptions.find(t => t.id === turf)

  const summaryString = `Sent ${frequency.toLowerCase()} ${
    frequency === 'Weekly' ? `on ${dayOfTheWeek}s` : ''
  } at ${hour}:00 ${meridiem}`

  return (
    <div>
      <HeaderBlock
        title={isEdit && currentExport ? exportName : t('New export')}
      />
      <ContentBlock>
        <Stepper>
          <Step
            label={t('Select data')}
            disableNext={
              !selectedTable || (!allColumns && !selectedColumns.length)
            }
            onPrevious={routeToSelect}
          >
            <TextBlock>
              <p>{t('Select a database table')}</p>
            </TextBlock>
            <section>
              {!isEdit || (isEdit && currentExport) ? (
                <ContentBlock>
                  <ToggleBoxGroup
                    options={TABLE_OPTIONS}
                    dataLabel="label"
                    dataDetail="tableName"
                    onChange={selectTable}
                    startingIndex={
                      isEdit &&
                      TABLE_OPTIONS.findIndex(
                        t => t.tableName === currentExport.table_name
                      )
                    }
                  />
                </ContentBlock>
              ) : null}
            </section>
            {selectedTable ? (
              <section>
                <FieldBlock>
                  <TurfSelectField
                    id="select-turf"
                    label={t('Turf')}
                    name="turf"
                    onSelect={val => {
                      setField(val, 'turf')
                    }}
                    value={turf}
                    showArchived
                  />
                </FieldBlock>
                <ContentBlock>
                  <div className={styles['checkbox-group']}>
                    <Checkbox
                      id="table-column-all"
                      label={t('All columns')}
                      name="allColumns"
                      type="checkbox"
                      checked={allColumns}
                      defaultValue
                      onChange={toggleCheckAll}
                    />
                    <div className={styles['checkbox-group--stacked']}>
                      {EXPORTABLE_TABLES[selectedTable.tableName].map(
                        column => (
                          <Checkbox
                            id={`table-column-${column}`}
                            key={column}
                            label={column}
                            name={column}
                            type="checkbox"
                            checked={
                              allColumns || selectedColumns.includes(column)
                            }
                            onChange={value => selectColumn(column, value)}
                          />
                        )
                      )}
                    </div>
                  </div>
                </ContentBlock>
              </section>
            ) : null}
          </Step>
          <Step label="Prepare exports">
            <Section label={t('File')}>
              <FieldBlock>
                <TextField
                  id="export-name"
                  label={t('Export name')}
                  placeholder={t('Example')}
                  value={exportName}
                  onChange={setExportName}
                  hint={t(
                    'Export date and format are added to the file name automatically'
                  )}
                />
                <p className={styles.field__preview}>
                  <i>
                    {`${exportName
                      .replace(/\s+/g, '-')
                      .toLowerCase()}--${moment().format('MM-DD-YYYY')}.csv`}
                  </i>
                </p>
              </FieldBlock>
            </Section>
            <Section label={t('Recipients')}>
              <FieldBlock>
                <TextField
                  id="set-emails"
                  label={t('Emails')}
                  value={
                    isEdit && currentExport
                      ? currentExport.recipients.join(', ')
                      : exportData.recipients.join(', ')
                  }
                  onChange={setEmails}
                  hint={t('Separate emails by a comma')}
                />
              </FieldBlock>
            </Section>
            <Section label={t('Schedule')}>
              <ContentBlock>
                <Switch
                  id="paused"
                  label={t('Paused')}
                  value={paused}
                  defaultChecked
                  onChange={isPaused => setField(isPaused, 'paused')}
                />
              </ContentBlock>
              {!paused && (
                <FieldBlock>
                  <SelectField
                    id="select-frequency"
                    label={t('Frequency')}
                    name="frequency"
                    onSelect={val => {
                      setField(val, 'frequency')(val, 'hour')
                    }}
                    options={[
                      { label: 'Daily', value: 'Daily' },
                      { label: 'Weekly', value: 'Weekly' },
                    ]}
                    value={frequency}
                  />
                  <SelectField
                    id="set-hour"
                    label={t('Time')}
                    name="hour"
                    onSelect={val => {
                      setField(val, 'hour')
                    }}
                    options={[
                      { label: '1', value: 1 },
                      { label: '2', value: 2 },
                      { label: '3', value: 3 },
                      { label: '4', value: 4 },
                      { label: '5', value: 5 },
                      { label: '6', value: 6 },
                      { label: '7', value: 7 },
                      { label: '8', value: 8 },
                      { label: '10', value: 10 },
                      { label: '11', value: 11 },
                      { label: '12', value: 12 },
                    ]}
                    value={hour}
                    defaultValue={12}
                  />
                  <SelectField
                    id="am-pm"
                    label="AM/PM"
                    name="meridiem"
                    onSelect={val => {
                      setField(val, 'meridiem')
                    }}
                    options={[
                      { label: 'AM', value: 'AM' },
                      { label: 'PM', value: 'PM' },
                    ]}
                    value={meridiem}
                    defaultValue="PM"
                  />
                  {frequency === 'Weekly' ? (
                    <SelectField
                      id="select-day"
                      label={t('Day of the week')}
                      name="dayOfTheWeek"
                      onSelect={val => {
                        setField(val, 'dayOfTheWeek')
                      }}
                      value={dayOfTheWeek}
                      options={[
                        {
                          label: 'Sunday',
                          value: 'Sunday',
                        },
                        {
                          label: 'Monday',
                          value: 'Monday',
                        },
                        {
                          label: 'Tuesday',
                          value: 'Tuesday',
                        },
                        {
                          label: 'Wednesday',
                          value: 'Wednesday',
                        },
                        {
                          label: 'Thursday',
                          value: 'Thursday',
                        },
                        {
                          label: 'Friday',
                          value: 'Friday',
                        },
                        {
                          label: 'Saturday',
                          value: 'Saturday',
                        },
                      ]}
                    />
                  ) : null}
                </FieldBlock>
              )}
            </Section>
          </Step>
          <Step
            label={t('Review')}
            nextButtonLabel={isEdit ? t('Update') : t('Create')}
            onNext={submitExport}
            disableNext={!recipients.length}
          >
            <ProgressBar
              show={
                isExportReqLoading ||
                isUpdateExportReqLoading ||
                isCreateExportReqLoading
              }
            />
            <SectionLabel accent>{exportName}</SectionLabel>
            <DetailItem single label={t('Table')}>
              {selectedTable ? selectedTable.label : t('No table selected')}
            </DetailItem>
            <DetailItem single label={t('Turf')}>
              {selectedTurf ? selectedTurf.name : t('All Turfs')}
            </DetailItem>
            <DetailItem single label={t('Columns')}>
              {allColumns ? t('All columns') : selectedColumns.join(', ')}
            </DetailItem>
            <DetailItem single label={t('Recipients')}>
              {recipients.length ? (
                recipients.join(', ')
              ) : (
                <p className="text--warn">{t('No recipients added')}</p>
              )}
            </DetailItem>
            {!paused && (
              <DetailItem single label={t('Schedule')}>
                {summaryString}
              </DetailItem>
            )}
            <CardError
              hide={
                !exportReqError &&
                !createExportReqError &&
                !updateExportReqError
              }
              message={t(
                'An internal error occurred while attempting to create this export schedule'
              )}
            />
          </Step>
        </Stepper>
      </ContentBlock>
    </div>
  )
}

export default ScheduledExportsForm
