export const PHONE = 'Phone number correct'
export const NOT_CONTACTED_REASON = 'Call Result if Not Contacted'
export const REGISTERED = 'Person registered'
export const FEEDBACK = 'Feedback on canvasser'
export const ADDRESS = 'Mailing address correct'
export const DUPLICATE = 'Form may be a duplicate'
export const DOB = 'Birth date correct'
export const NEEDS_ATTENTION_OTHER = 'Needs attention other'
export const ENGLISH = 'en'
export const SPANISH = 'es'

export const questionKeys = [
  REGISTERED,
  PHONE,
  DOB,
  ADDRESS,
  FEEDBACK,
  NOT_CONTACTED_REASON,
  DUPLICATE,
  NEEDS_ATTENTION_OTHER,
]

export const requiredQuestionKeys = [REGISTERED, DOB, ADDRESS, FEEDBACK]

export const RESPONSES_REQUIRING_NOTES = [
  [FEEDBACK, ['positive_feedback', 'negative_feedback']],
  [NEEDS_ATTENTION_OTHER, ['t']],
  [REGISTERED, ['f']],
]

const booleanChoices = {
  [ENGLISH]: [
    { value: 't', label: 'verified' },
    { value: 'f', label: 'needs attention' },
    { value: 'r', label: 'refused to answer' },
  ],
  [SPANISH]: [
    { value: 't', label: 'verificado' },
    { value: 'f', label: 'necesita atención' },
    { value: 'r', label: 'se negó a responder' },
  ],
}

const feedbackChoices = {
  [ENGLISH]: [
    { value: 'positive_feedback', label: 'positive feedback' },
    { value: 'negative_feedback', label: 'negative feedback' },
    { value: 'no_feedback', label: 'no feedback' },
  ],
  [SPANISH]: [
    { value: 'positive_feedback', label: 'comentario positivo' },
    { value: 'negative_feedback', label: 'comentario negativo' },
    { value: 'no_feedback', label: 'sin comentario' },
  ],
}

export const NOT_HOME = 'nh'
export const LANGUAGE_BARRIER = 'lb'
export const REFUSED = 'rf'
export const CALL_BACK = 'cb'
export const BUSY = 'bs'
export const DISCONNECTED = 'dc'
export const DO_NOT_CALL = 'dn'
export const WRONG_PHONE = 'wn'
export const VOICEMAIL_NOT_APPLICANT = 'na'
export const VOICEMAIL_MATCHES_APPLICANT = 'vm'
export const NO_PHONE = 'np'

export const notContactedChoices = {
  [ENGLISH]: [
    { value: NOT_HOME, label: 'Not Home' },
    { value: LANGUAGE_BARRIER, label: 'Language barrier' },
    { value: REFUSED, label: 'Refused' },
    { value: CALL_BACK, label: 'Call back' },
    { value: BUSY, label: 'Busy' },
    { value: DISCONNECTED, label: 'Disconnected' },
    { value: DO_NOT_CALL, label: 'Do Not Call' },
    { value: WRONG_PHONE, label: 'Wrong Number' },
    { value: VOICEMAIL_NOT_APPLICANT, label: 'Voicemail Not Applicant' },
    {
      value: VOICEMAIL_MATCHES_APPLICANT,
      label: 'Voicemail Matches Applicant',
    },
    { value: NO_PHONE, label: 'No phone number' },
  ],
  [SPANISH]: [
    { value: NOT_HOME, label: 'No hogar' },
    { value: LANGUAGE_BARRIER, label: 'Barrera del idioma' },
    { value: REFUSED, label: 'Rechazado' },
    { value: CALL_BACK, label: 'Llamar de vuelta' },
    { value: BUSY, label: 'Ocupado' },
    { value: DISCONNECTED, label: 'Desconectado' },
    { value: DO_NOT_CALL, label: 'No llames' },
    { value: WRONG_PHONE, label: 'Número equivocado' },
    { value: VOICEMAIL_NOT_APPLICANT, label: 'Correo de voz no solicitante' },
    {
      value: VOICEMAIL_MATCHES_APPLICANT,
      label: 'Correo de voz si es del solicitante',
    },
    { value: NO_PHONE, label: 'Sin número de teléfono' },
  ],
}

export const excludeFromQueue = [
  NO_PHONE,
  WRONG_PHONE,
  DO_NOT_CALL,
  REFUSED,
  DISCONNECTED,
  LANGUAGE_BARRIER,
]

const questionText = {
  [ENGLISH]: {
    [DOB]: ({ dob }) =>
      `We have your DOB as ${dob || '[dob]'}. Is that correct?`,

    [REGISTERED]: ({ registrationDate }) =>
      `Did you register to vote on ${registrationDate || '[date]'}?`,

    [ADDRESS]: ({ address }) =>
      `The address I have in your application is ${
        address || '[address]'
      }. Is that correct?`,

    [FEEDBACK]: () =>
      `And finally, was the person who helped you register friendly and courteous?`,

    [NOT_CONTACTED_REASON]: () => `Call result if not contacted`,

    [DUPLICATE]: () => `Form may be a duplicate`,
    [NEEDS_ATTENTION_OTHER]: () => `Needs attention for some other reason`,
  },
  [SPANISH]: {
    [DOB]: ({ dob }) =>
      `La fecha de nacimiento que tenemos es ${dob || '[dob]'}. Esta correcta?`,

    [REGISTERED]: ({ registrationDate }) =>
      `Te registraste para votar el ${registrationDate || '[date]'}?`,

    [ADDRESS]: ({ address }) =>
      `La direccion que tengo en la aplicacion es ${
        address || '[address]'
      }. Esta correcta?`,

    [FEEDBACK]: () => `Y cómo le trató la persona que le ayudó a registrarse?`,

    [NOT_CONTACTED_REASON]: () => `Resultado de la llamada si no se contacta`,

    [DUPLICATE]: () => `El formulario puede ser un duplicado`,
    [NEEDS_ATTENTION_OTHER]: () => `Necesita atención por otro motivo`,
  },
}

const choices = (key, language) => {
  switch (key) {
    case NOT_CONTACTED_REASON:
      return notContactedChoices[language]
    case FEEDBACK:
      return feedbackChoices[language]
    default:
      return booleanChoices[language]
  }
}

const makeScriptQuestions = language =>
  questionKeys.reduce(
    (questions, key) => ({
      ...questions,
      [key]: {
        key,
        content: questionText[language][key],
        choices: choices(key, language),
        response: '',
      },
    }),
    {}
  )

export const phoneVerificationQuestions = {
  [ENGLISH]: makeScriptQuestions(ENGLISH),
  [SPANISH]: makeScriptQuestions(SPANISH),
}

export const NOT_CONTACTED_RESPONSES = {
  nh: 'Not Home',
  lb: 'Language barrier',
  rf: 'Refused',
  cb: 'Call back',
  bs: 'Busy',
  dc: 'Disconnected',
  dn: 'Do Not Call',
  wn: 'Wrong Number',
  na: 'Voicemail Not Applicant',
  np: 'No Phone Number',
  vm: 'Voicemail Matches Applicant',
}
