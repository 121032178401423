import {
  CardError,
  HeaderBlock,
  Sheet,
  View,
  ViewContainer,
} from 'components/index'
import { useReactRouter } from 'hooks/router'
import { useTranslation } from 'react-i18next'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import { useCurrent } from 'contexts/index'
import DeliveryTable from '../DeliveryTable/DeliveryTable'

const DeliverySelect = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewDeliveries = doesCurrentUserHavePermission({
    resource: 'delivery',
    ability: 'view',
  })

  const canModifyDeliveries = doesCurrentUserHavePermission({
    resource: 'delivery',
    ability: 'modify',
  })

  if (!canViewDeliveries) {
    return (
      <CardError
        hide={canViewDeliveries}
        message={t('You do not have permission to view this page.')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <HeaderBlock title={t('Deliveries')}>
        <ButtonBlock justify="right">
          <Link
            onClick={e => !canModifyDeliveries && e.preventDefault()}
            to={`${match.url}/new`}
          >
            <Button.Accent disabled={!canModifyDeliveries}>
              {t('Add delivery')}
            </Button.Accent>
          </Link>
        </ButtonBlock>
      </HeaderBlock>
      <ViewContainer>
        <Sheet>
          <DeliveryTable />
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default DeliverySelect
