import { createContext, useContext, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useRouteQueryParams } from 'hooks'

const QueryParamContext = createContext()

export const QueryParamProvider = ({ debounceInterval = 50, children }) => {
  const inboundParams = useRef({})
  const [queryParams, updateQueryParams] = useRouteQueryParams()
  const replaceQueryParams = method => {
    if (Object.keys(inboundParams.current).length) {
      updateQueryParams({ ...queryParams, ...inboundParams.current }, method)
      inboundParams.current = {}
    }
  }

  const [debounceReplaceQueryParams] = useDebouncedCallback(
    replaceQueryParams,
    debounceInterval
  )

  const setQueryParams = (params, method) => {
    inboundParams.current = {
      ...inboundParams.current,
      ...params,
    }
    debounceReplaceQueryParams(method || 'push')
  }

  return (
    <QueryParamContext.Provider value={{ queryParams, setQueryParams }}>
      {children}
    </QueryParamContext.Provider>
  )
}

export const useQueryParams = (enabled = true) => {
  if (!enabled) {
    return {
      queryParams: {},
      setQueryParams: () => {},
    }
  }

  const context = useContext(QueryParamContext)
  if (context === undefined) {
    throw new Error('useQueryParams must be used within a QueryParamProvider')
  }

  return context
}
