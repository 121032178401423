import { DetailItem, FormattedData } from 'components'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getCurrentPacket,
  getScansWithPhoneNumbersVerifiedPercent,
  getContactedScansCount,
} from 'store/qcScans/reducer'
import styles from './PacketDetails.module.scss'

const PacketDetails = ({
  packetName,
  canvasserName,
  shiftDate,
  office,
  percentContacted,
  contactedInPacket,
  turfName,
}) => {
  const { t } = useTranslation()
  return (
    <div className={styles.details}>
      <DetailItem label={t('packet name')}>{packetName}</DetailItem>
      <DetailItem label={t('canvasser')}>{canvasserName}</DetailItem>
      <DetailItem label={t('shift date')}>
        <FormattedData type="date" value={shiftDate} />
      </DetailItem>
      <DetailItem label={t('collection location')}>{office}</DetailItem>
      <DetailItem label={t('turf')}>{turfName}</DetailItem>
      <DetailItem label={t('percent contacted')}>{percentContacted}</DetailItem>
      <DetailItem label={t('contacted in packet')}>
        {contactedInPacket}
      </DetailItem>
    </div>
  )
}

const mapStateToProps = state => {
  const { original_filename, shift, location, turf } = getCurrentPacket(state)

  return {
    packetName: original_filename,
    canvasserName: `${shift.canvasser?.first_name} ${shift.canvasser?.last_name}`,
    shiftDate: shift.shift_start,
    office: location.name,
    turfName: turf.name,
    percentContacted: getScansWithPhoneNumbersVerifiedPercent(state),
    contactedInPacket: getContactedScansCount(state),
  }
}

export default connect(state => mapStateToProps(state))(PacketDetails)
