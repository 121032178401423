import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError, HeaderBlock } from 'components'
import { useReactRouter, useRequest } from 'hooks'
import {
  Sheet,
  Button,
  ButtonBlock,
  Icon,
} from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import { fetchOrganization, organizationFields } from 'requests/organizations'
import OrganizationForm from '../OrganizationForm/OrganizationForm'

const OrganizationEdit = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const [currentOrganization, setCurrentOrganization] = useState()

  const { makeRequest, isLoading, hasErrors, isRequestComplete } = useRequest(
    fetchOrganization,
    {
      onSuccess: ({ 'people/organization': organization }) => {
        setCurrentOrganization(organization)
      },
    }
  )

  useEffect(() => {
    makeRequest(match.params.id, {
      fields: organizationFields,
    })
  }, [match.params.id])

  const organizationLoaded =
    isRequestComplete &&
    !hasErrors &&
    (currentOrganization?.id === +match.params.id ||
      currentOrganization?.slug === match.params.id)

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <Sheet>
          <HeaderBlock title={t('Edit Organization')}>
            <ButtonBlock justify="right">
              <Link to="/organize/organizations">
                <Button.Secondary>
                  <Icon.Times />
                </Button.Secondary>
              </Link>
            </ButtonBlock>
          </HeaderBlock>
          <CardError
            hide={!hasErrors}
            message={t("We're unable to retrieve this organization")}
          />
          {organizationLoaded && (
            <OrganizationForm organization={currentOrganization} />
          )}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default OrganizationEdit
