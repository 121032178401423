import { Button } from 'react-md'
import { useRequest } from 'hooks'
import { CardError, InputArea, LoadBar } from 'components'
import { dateTimeFormat } from 'utils/constants'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useCurrent } from 'contexts/index'

const ExistingCommentReadMode = ({ comment, startEditing, deleteComment }) => {
  const { t } = useTranslation()
  const { makeRequest, isLoading, hasErrors } = useRequest(deleteComment)
  const {
    currentUser: { email: userEmail },
  } = useCurrent()
  const userIsAuthor = comment.author.email === userEmail
  const authoredDate = comment.created_at
  const commentBody = comment.body

  return (
    <div>
      <LoadBar show={isLoading} />
      <CardError hide={!hasErrors} />
      <InputArea
        PrimaryControl={
          userIsAuthor && (
            <Button icon primary onClick={startEditing}>
              {t('edit')}
            </Button>
          )
        }
        SecondaryControl={
          userIsAuthor && (
            <Button icon primary onClick={() => makeRequest(comment.id)}>
              {t('delete')}
            </Button>
          )
        }
      >
        <div>
          <div>{commentBody}</div>
          <br />
          <div>
            {comment.author.name} |{' '}
            {moment(authoredDate).format(dateTimeFormat)}
          </div>
        </div>
      </InputArea>
    </div>
  )
}

export default ExistingCommentReadMode
