import { createContext, useEffect } from 'react'
import { useCheckboxGroup } from 'hooks/useCheckboxGroup'
import { useStore } from 'react-redux'

const CheckboxGroupProvider =
  (Context, useCheckboxData) =>
  ({ children }) => {
    const checkboxManagement = useCheckboxGroup()
    const { getState } = useStore()
    const checkboxData = useCheckboxData(getState())
    useEffect(() => {
      const { setCheckboxData } = checkboxManagement
      setCheckboxData(checkboxData)
    }, [checkboxData])
    return (
      <Context.Provider value={checkboxManagement}>{children}</Context.Provider>
    )
  }

export const generateCheckboxGroupProvider = useCheckboxData => {
  const CheckboxGroupContext = createContext()
  const GeneratedCheckboxGroupProvider = CheckboxGroupProvider(
    CheckboxGroupContext,
    useCheckboxData
  )
  return [CheckboxGroupContext, GeneratedCheckboxGroupProvider]
}
