import { connect } from 'react-redux'
import { CardError, List, ListItem } from 'components'
import {
  Section,
  ProgressBar,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { requestResolvedFlags } from 'store/qcFlags/actions'
import { useEffect } from 'react'
import { useRequest } from 'hooks/useRequest'
import {
  getFilters,
  getResolvedFlagListPage,
  getResolvedFlagData,
} from 'store/qcFlags/reducer'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import {
  getFlagItemPrimaryText,
  getFlagItemPrimaryIcon,
  getFlagItemSecondaryText,
  doesFlagHaveUnreadComments,
} from './utils'
import { ResolvedListPager } from './ListPager'
import ViewFlagButton from './buttons/ViewFlagButton'

const ResolvedList = ({
  requestResolvedFlags,
  flags,
  currentPage,
  filters,
}) => {
  const { t } = useTranslation()
  const { currentUser, currentTurfPerformsExternalQC } = useCurrent()
  const {
    makeRequest: requestFlags,
    isLoading: isFlagsLoading,
    hasErrors: flagsHaveErrors,
    errors: flagsErrors,
    isRequestComplete: isFlagsRequestComplete,
  } = useRequest(requestResolvedFlags)

  const flagsErrorMsg = formatErrorMessage(flagsErrors)

  useEffect(() => {
    requestFlags(currentTurfPerformsExternalQC, currentUser.id)
  }, [currentPage, filters, currentUser.id])

  return (
    <>
      <ProgressBar show={isFlagsLoading} />
      <CardError hide={!flagsHaveErrors} message={flagsErrorMsg} />
      {isFlagsRequestComplete && !!flags.length && (
        <>
          <List>
            {flags.map(flag => (
              <ListItem
                key={`resolved-flag-list-item-${flag.id}`}
                primaryText={getFlagItemPrimaryText(flag)}
                primaryIcon={getFlagItemPrimaryIcon(flag)}
                secondaryText={getFlagItemSecondaryText(flag, currentUser)}
                showAlertIcon={doesFlagHaveUnreadComments(flag, currentUser)}
                actions={() => (
                  <ViewFlagButton typeOverride="reopen" flagId={flag.id} />
                )}
              />
            ))}
          </List>
          <ResolvedListPager />
        </>
      )}
      {isFlagsRequestComplete && !flags.length && (
        <Section secondary collapsed>
          <TextBlock>{t('There are no resolved flags')}</TextBlock>
        </Section>
      )}
    </>
  )
}

export default connect(
  state => ({
    flags: getResolvedFlagData(state),
    currentPage: getResolvedFlagListPage(state),
    filters: getFilters(state),
  }),
  { requestResolvedFlags }
)(ResolvedList)
