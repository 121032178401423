import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useRequest } from 'hooks/useRequest'
import { ViewContainer, HeaderBlock } from 'components'
import {
  List,
  ListItem,
  ProgressBar,
  SectionLabel,
  Button,
  ButtonBlock,
  Sheet,
  TextBlock,
  ContentBlock,
  Font,
  Icon,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { currentDateEasternYMDDash } from 'utils/dateTime'
import { clearFilters, requestPendingFlags } from 'store/qcFlags/actions'
import { getTotalToReviewFlagCount } from 'store/qcFlags/reducer'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { fetchSchedulesOnDate } from 'requests/qualityControl'
import { fetchTwilioCalls } from 'requests/callRecordings'
import { getAssignableScans } from 'requests/scans'
import { USER_TYPES } from 'constants/qualityControl'
import styles from './Manage.module.scss'

const Manage = ({ requestPendingFlags, totalFlags, clearFilters }) => {
  const history = useHistory()

  const { currentTurfPerformsExternalQC, doesCurrentUserHavePermission } =
    useCurrent()

  const canViewTwilioCalls = doesCurrentUserHavePermission({
    resource: 'twilio_call',
    ability: 'view',
  })

  const [totalAssignableScansCount, setTotalAssignableScansCount] = useState(0)
  const [assignedScansCount, setAssignedScansCount] = useState(0)

  const fetchRecordedCalls = useRequest(fetchTwilioCalls)

  const {
    isLoading: totalAssignableScansLoading,
    errors: totalAssignableScansErrors,
    makeRequest: totalAssignableScansRequest,
  } = useRequest(getAssignableScans, {
    onSuccess: ({ meta: { total_count } }) => {
      setTotalAssignableScansCount(total_count)
    },
  })

  const totalAssignableScansErrorMsg = formatErrorMessage(
    totalAssignableScansErrors
  )

  const {
    isLoading: assignedScansLoading,
    errors: assignedScansErrors,
    makeRequest: assignedScansRequest,
  } = useRequest(getAssignableScans, {
    onSuccess: ({ meta: { total_count } }) => {
      setAssignedScansCount(total_count)
    },
  })

  const assignedScansErrorMsg = formatErrorMessage(assignedScansErrors)

  const {
    isLoading: scheduledUsersResponseLoading,
    hasErrors: scheduledUsersResponseHasErrors,
    makeRequest: fetchScheduledUsers,
    response: scheduledUsersResponse,
  } = useRequest(fetchSchedulesOnDate)

  useEffect(() => {
    clearFilters()

    fetchScheduledUsers(currentDateEasternYMDDash, {
      fields: [
        'id',
        'user_type',
        'minutes',
        'date',
        { user: ['id'] },
        { turf: ['qc_config'] },
      ],
    })

    requestPendingFlags('start', currentTurfPerformsExternalQC, undefined)
    totalAssignableScansRequest({
      filters: {
        rules: [
          {
            column: 'shift_qc_external',
            operator: 'is',
            param: currentTurfPerformsExternalQC,
          },
        ],
      },
    })

    assignedScansRequest({
      filters: {
        rules: [
          {
            column: 'packet_assignee_id',
            operator: 'is_nil',
            invert: true,
          },
          {
            column: 'shift_qc_external',
            operator: 'is',
            param: currentTurfPerformsExternalQC,
          },
        ],
      },
    })

    if (canViewTwilioCalls) {
      fetchRecordedCalls.makeRequest({
        filters: {
          rules: [
            {
              column: 'twilio_recording_sid',
              operator: 'is_nil',
              invert: true,
            },
          ],
        },
      })
    }
  }, [])

  const goToSchedule = () => {
    history.push('/quality_control/manage/schedule')
  }

  const goToAssignment = () => {
    history.push('/quality_control/manage/assign')
  }

  const goToFlags = () => {
    history.push('/quality_control/flags/start')
  }

  const goToRecordings = () => {
    history.push('/quality_control/manage/call_recordings')
  }

  const getAssignmentLabel = () => {
    if (totalAssignableScansLoading || assignedScansLoading) {
      return 'Loading'
    }
    if (totalAssignableScansErrorMsg || assignedScansErrorMsg) {
      return 'Failed to get scan count'
    }

    return `${assignedScansCount || 0}/${totalAssignableScansCount || 0}`
  }

  const { t } = useTranslation()

  const scheduledStaffCount =
    scheduledUsersResponse?.schedules.filter(({ user_type, turf }) => {
      const userIsStaff = user_type === USER_TYPES.STAFF
      const externalQCSettingSameAsUser =
        turf.qc_config.performs_external_qc === currentTurfPerformsExternalQC

      return userIsStaff && externalQCSettingSameAsUser
    }).length || 0

  const getStaffCountLabel = () => {
    if (scheduledUsersResponseLoading) {
      return 'Loading'
    }
    if (scheduledUsersResponseHasErrors) {
      return 'Failed to get scheduled users count'
    }

    return scheduledStaffCount
  }

  const getRecordedCallsLabel = () => {
    if (fetchRecordedCalls.isLoading) {
      return 'Loading'
    }
    if (fetchRecordedCalls.hasErrors) {
      return 'Failed to get scheduled users count'
    }

    return fetchRecordedCalls.response?.meta.total_count
  }

  const items = [
    {
      primary: getStaffCountLabel(),
      secondary: t('QC staff scheduled today'),
      icon: <Icon.CalendarDay />,
      actionLabel: t('Schedule'),
      actionHandler: goToSchedule,
    },
    {
      primary: getAssignmentLabel(),
      secondary: t('Scans assigned'),
      icon: <Icon.Clipboard />,
      actionLabel: t('Assign'),
      actionHandler: goToAssignment,
    },
    {
      primary: totalFlags,
      secondary: t('Flags need attention'),
      icon: <Icon.Flag />,
      actionLabel: t('View flags'),
      actionHandler: goToFlags,
    },
  ]

  if (canViewTwilioCalls) {
    items.push({
      primary: getRecordedCallsLabel(),
      secondary: t('Phone verification calls recorded'),
      icon: <Icon.UserHeadset />,
      actionLabel: t('Review'),
      actionHandler: goToRecordings,
    })
  }

  return (
    <>
      <HeaderBlock title={t('Table QC Manager')} />
      <ViewContainer>
        <Sheet>
          <ProgressBar
            show={
              scheduledUsersResponseLoading ||
              totalAssignableScansLoading ||
              assignedScansLoading
            }
            data-testid="loadbar"
          />
          <TextBlock>
            <SectionLabel primary>{t('Notifications')}</SectionLabel>
          </TextBlock>
          <List
            itemData={items}
            render={item => (
              <ListItem key={item.secondary}>
                <ContentBlock className={styles.list__primary}>
                  <Font.Display variant="small" className={styles.list__icon}>
                    {item.icon}
                  </Font.Display>
                  <div>
                    <Font.Copy variant="highlight">{item.primary}</Font.Copy>
                    <Font.Copy variant="hint">{item.secondary}</Font.Copy>
                  </div>
                </ContentBlock>
                <ButtonBlock justify="right">
                  <Button.Secondary onClick={item.actionHandler}>
                    <span>{item.actionLabel}</span>
                    <Icon.ChevronRight />
                  </Button.Secondary>
                </ButtonBlock>
              </ListItem>
            )}
          />
        </Sheet>
      </ViewContainer>
    </>
  )
}

export default connect(
  state => ({
    totalFlags: getTotalToReviewFlagCount(state),
  }),
  {
    requestPendingFlags,
    clearFilters,
  }
)(Manage)
