import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNumber } from 'lodash'
import {
  CardError,
  TurfLevelSelect,
  TurfSelectField,
  ExpandPanel,
} from 'components'
import {
  ProgressBar,
  Button,
  ButtonBlock,
  FieldBlock,
  Switch,
  Font,
  Section,
  TextBlock,
  Checkbox,
  Modal,
  TextField,
  SelectField,
  NumberField,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useLocalForm, useRequest } from 'hooks'
import {
  archiveTurf as archiveTurfRequest,
  fetchTurf,
  postTurf,
  putTurf,
  unarchiveTurf as unarchiveTurfRequest,
} from 'requests/turfs'
import { useCurrent, useTurfs } from 'contexts/index'
import {
  COPYABLE_FIELDS,
  PHONE_VERIFICATION_LANGUAGE_OPTIONS,
  QC_EXTERNAL_OPTIONS,
  TURF_TYPE_OPTIONS,
} from './constants'
import { buildRequest, getDescendantCount } from './utils'
import styles from './TurfForm.module.scss'
import TurfSpecificSettings from './TurfSpecificSettings'

const TurfForm = ({ turf }) => {
  const { formData, setField, setFormData } = useLocalForm()
  const isEditForm = !!turf
  const formAction = isEditForm ? data => putTurf(turf.id, data) : postTurf
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { qcEnabled, vrEnabled, petitionsEnabled, currentUser } = useCurrent()

  const {
    refreshCurrentTurfs,
    turfRefreshIsLoading,
    currentTurfs: currentUserTurfTree,
  } = useTurfs()

  const {
    makeRequest,
    isLoading,
    hasErrors,
    isRequestComplete,
    response,
    errors,
  } = useRequest(formAction)

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: archiveTurf } = useRequest(archiveTurfRequest, {
    onSuccess: () => {
      history.push('/admin/turfs')
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: unarchiveTurf } = useRequest(unarchiveTurfRequest, {
    onSuccess: () => {
      history.push('/admin/turfs')
    },
  })

  const [parentTurf, setParentTurf] = useState({})
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
  const countDescendants = useMemo(
    () => getDescendantCount(turf, currentUserTurfTree),
    [turf, currentUserTurfTree]
  )

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: getParentTurfDetails } = useRequest(
    id =>
      fetchTurf(id, {
        fields: [
          'voter_registration_config',
          { visual_review_responses: ['id'] },
          { phone_verification_questions: ['id'] },
          'options',
          'reporting_config',
          'archived',
        ],
      }),
    {
      onSuccess: ({ turf: parentTurf }) => {
        setParentTurf(parentTurf)

        setField(
          parentTurf.voter_registration_config,
          'voter_registration_config'
        )
        setField(parentTurf.visual_review_responses, 'visual_review_responses')
        setField(
          parentTurf.phone_verification_questions,
          'phone_verification_questions'
        )
        setField(parentTurf.options, 'options')
        setField(parentTurf.reporting_config, 'reporting_config')
      },
    }
  )

  useEffect(() => {
    refreshCurrentTurfs({
      fields: ['id', 'name', 'parent_id', 'lft', 'rgt', 'archived'],
    })
  }, [])

  useEffect(() => {
    if (formData.parent_id) {
      getParentTurfDetails(formData.parent_id)
    }
  }, [formData.parent_id])

  const setQcField = (field, val) => {
    setField(
      {
        ...formData.qc_config,
        [field]: val,
      },
      'qc_config'
    )
  }

  useEffect(() => {
    if (qcEnabled) {
      turf
        ? setFormData(
            turf.qc_config
              ? turf
              : {
                  ...turf,
                  qc_config: {
                    visual_review_enabled: true,
                    phone_verification_enabled: true,
                    required_for_delivery: true,
                    external: false,
                  },
                }
          )
        : setFormData({
            voter_registration_enabled: false,
            qc_turnaround_days: 5,
            qc_config: {
              visual_review_enabled: true,
              phone_verification_enabled: true,
              required_for_delivery: true,
              external: false,
            },
          })
    } else {
      turf && setFormData(turf)
    }
  }, [])

  useEffect(() => {
    if (hasErrors || !isRequestComplete) return

    history.push('/admin/turfs')
  }, [isRequestComplete, hasErrors, response])

  const errorMsg = Object.entries(errors)
    .map(([field, errors]) => `${field}: ${errors}`)
    .join(', ')

  const isFormValid = () =>
    !isEmpty(formData.name) &&
    !isEmpty(formData.turf_level) &&
    (isEditForm || isNumber(formData.parent_id)) &&
    (!isEmpty(formData.phone_verification_language) ||
      !formData.voter_registration_enabled)

  const submitForm = () => {
    makeRequest(buildRequest(formData, isEditForm))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (formData.copy_to_descendants?.length) {
      setIsConfirmationModalOpen(true)
    } else {
      submitForm()
    }
  }

  const handleCheckboxToggle = (fields, value) => {
    const activeValues = formData.copy_to_descendants || []
    const updatedFields = value
      ? [...activeValues, ...fields]
      : activeValues.filter(value => !fields.includes(value))
    setField(updatedFields, 'copy_to_descendants')
  }

  return (
    <>
      <ProgressBar show={isLoading} />
      <CardError hide={!hasErrors} message={errorMsg} />
      <form>
        <TextBlock>
          <Font.Label variant="section">{t('Main settings')}</Font.Label>
        </TextBlock>
        <Section>
          <FieldBlock>
            <TextField
              id="name"
              label={t('Turf name')}
              value={formData.name}
              onChange={val => setField(val, 'name')}
              required
            />
          </FieldBlock>
          <FieldBlock>
            <TurfLevelSelect
              turfLevel={formData.turf_level}
              onSelect={val => setField(val, 'turf_level')}
              required
            />
            <Link to="/admin/turf_levels">
              <Button.Secondary className={styles['manage-button']}>
                {t('Manage Turf Levels')}
              </Button.Secondary>
            </Link>
          </FieldBlock>
          <FieldBlock>
            <TurfSelectField
              label={t('Parent Turf')}
              turfs={currentUserTurfTree
                .filter(turf => isEditForm || !turf.archived)
                .map(turf => ({
                  ...turf,
                  value: turf.id,
                  label: turf.name,
                }))}
              value={formData.parent_id}
              disabled={
                (isEditForm && turf.parent_id === null) || turfRefreshIsLoading
              }
              onSelect={val => {
                setField(val, 'parent_id')
              }}
              isExpanded
              showArchived={isEditForm}
              disableArchived
              required
            />
          </FieldBlock>
        </Section>
        {vrEnabled && (
          <>
            <TextBlock>
              <Font.Label variant="section">
                {t('voter registration')}
              </Font.Label>
            </TextBlock>
            <Section secondary>
              <FieldBlock>
                <SelectField
                  id="turf-type"
                  label={t('Turf Type')}
                  options={TURF_TYPE_OPTIONS}
                  value={formData.voter_registration_enabled}
                  onSelect={val => setField(val, 'voter_registration_enabled')}
                />
              </FieldBlock>
              <ExpandPanel label="About Turf Types">
                <TextBlock>
                  <Font.Copy variant="hint">
                    {t(`Canvassing turfs reflect actual, physical offices, and is the lowest
                          level of turf. Registration forms, canvassers, and goals should only be
                          assigned to canvassing turfs.`)}
                  </Font.Copy>
                  <Font.Copy variant="hint">
                    {t(`Managing turfs exist above canvassing turfs in the hierarchy.
                          They allow us to group canvassing turfs by organization, collective,
                          and other grouping.
                          Registration forms, canvassers, and goals should not be directly assigned
                          to managing turfs.`)}
                  </Font.Copy>
                </TextBlock>
              </ExpandPanel>
              <br />
              <br />
              {qcEnabled && (
                <>
                  <FieldBlock>
                    <SelectField
                      id="qc-organization"
                      label={t('QC organization')}
                      options={QC_EXTERNAL_OPTIONS}
                      value={formData.qc_config?.external}
                      onSelect={value => setQcField('external', value)}
                    />
                    <NumberField
                      id="qc-turnaround-days"
                      label={t('Days of turnaround to complete QC')}
                      min={0}
                      onChange={days =>
                        setField(Number(days), 'qc_turnaround_days')
                      }
                      value={String(formData.qc_turnaround_days)}
                    />
                  </FieldBlock>
                  <FieldBlock className={styles.switch}>
                    <Switch
                      id="visual-review-switch"
                      name="visual-review-switch"
                      label="Visual qc"
                      value={formData.qc_config?.visual_review_enabled}
                      onChange={value =>
                        setQcField('visual_review_enabled', value)
                      }
                    />
                    <Switch
                      id="phone-verification-switch"
                      name="phone-verification-switch"
                      label="Phone verification"
                      value={formData.qc_config?.phone_verification_enabled}
                      onChange={value =>
                        setQcField('phone_verification_enabled', value)
                      }
                    />
                  </FieldBlock>
                  {formData.qc_config?.phone_verification_enabled ? (
                    <>
                      <TextBlock>
                        <Font.Label>Phone verification</Font.Label>
                      </TextBlock>
                      <FieldBlock>
                        <SelectField
                          id="phone-type"
                          label={t('Phone Verification Language')}
                          options={PHONE_VERIFICATION_LANGUAGE_OPTIONS}
                          value={formData.phone_verification_language}
                          onSelect={val =>
                            setField(val, 'phone_verification_language')
                          }
                          required={formData.voter_registration_enabled}
                        />
                        <NumberField
                          id="phone-verification-percent"
                          label={t('Phone verification percent')}
                          min={1}
                          onChange={days =>
                            setField(
                              Number(days),
                              'min_phone_verification_percent'
                            )
                          }
                          value={formData.min_phone_verification_percent}
                        />
                        <NumberField
                          id="phone-verification-rounds"
                          label={t('Phone verification rounds')}
                          min={1}
                          onChange={days =>
                            setField(
                              Number(days),
                              'min_phone_verification_rounds'
                            )
                          }
                          value={formData.min_phone_verification_rounds}
                        />
                      </FieldBlock>
                    </>
                  ) : null}
                  <TextBlock>
                    <Font.Label>{t('Delivery requirements')}</Font.Label>
                  </TextBlock>
                  <ContentBlock>
                    <Switch
                      id="required-for-delivery-switch"
                      name="required-for-delivery-switch"
                      label={t('Allow deliveries before completing QC')}
                      value={!formData.qc_config?.required_for_delivery}
                      onChange={value =>
                        setQcField('required_for_delivery', !value)
                      }
                    />
                  </ContentBlock>
                  {isEditForm ? (
                    <>
                      <TextBlock>
                        <Font.Label>
                          {t('Apply the following changes to all child turfs')}
                        </Font.Label>
                      </TextBlock>
                      <FieldBlock>
                        <div>
                          {COPYABLE_FIELDS.map(field => {
                            let shouldShow = true
                            if (field.shouldShow) {
                              shouldShow = field.shouldShow(formData)
                            }
                            const isChecked = field.values.some(value =>
                              formData.copy_to_descendants?.includes(value)
                            )
                            return shouldShow ? (
                              <Checkbox
                                label={t(field.label)}
                                onChange={isChecked =>
                                  handleCheckboxToggle(field.values, isChecked)
                                }
                                checked={isChecked}
                              />
                            ) : null
                          })}
                        </div>
                      </FieldBlock>
                    </>
                  ) : null}
                </>
              )}
            </Section>
          </>
        )}
        {petitionsEnabled && (
          <>
            <TextBlock>
              <Font.Label variant="section">
                {t('petition requirements')}
              </Font.Label>
            </TextBlock>
            <Section secondary>
              <TurfSpecificSettings
                settings={formData.petition_requirements}
                onChange={reqs => setField(reqs, 'petition_requirements')}
                label={t('Requirement')}
                keyPrefix="petition-requirement"
              />
            </Section>
          </>
        )}
        <Section>
          <ButtonBlock justify="left">
            <Button.Accent
              onClick={handleSubmit}
              disabled={!isFormValid() || isLoading}
            >
              {t(isEditForm ? 'Save Turf' : 'Create Turf')}
            </Button.Accent>
            {isEditForm && !turf.archived && (
              <Button.Danger
                onClick={() => setIsArchiveModalOpen(true)}
                disabled={turf.id === currentUser.turf.id}
              >
                {t('Archive turf')}
              </Button.Danger>
            )}
            {isEditForm && turf.archived && !parentTurf?.archived && (
              <Button.Primary onClick={() => setIsArchiveModalOpen(true)}>
                {t('Unarchive turf')}
              </Button.Primary>
            )}
            <Button.Secondary onClick={() => history.push('/admin/turfs')}>
              {t('Cancel')}
            </Button.Secondary>
          </ButtonBlock>
          <Modal
            id="confirm-archive-modal"
            title={t(
              isEditForm && turf.archived ? 'Unarchive turf?' : 'Archive turf?'
            )}
            isOpen={isArchiveModalOpen}
          >
            <Modal.Body>
              <TextBlock>
                <Font.Copy>
                  {isEditForm && turf.archived
                    ? `${t(
                        'This action will only unarchive the following turf:'
                      )} ${turf.name}`
                    : t(
                        'This action will archive the selected turf and all of its child turfs. Additionally, all canvassers in these turfs will be archived and all users in these turfs will be disabled. These turfs will no longer be selectable for new shifts or canvasser records.'
                      )}
                </Font.Copy>
              </TextBlock>
            </Modal.Body>
            <Modal.Actions>
              <ButtonBlock justify="right">
                <Button.Secondary onClick={() => setIsArchiveModalOpen(false)}>
                  {t('Cancel')}
                </Button.Secondary>
                <Button.Accent
                  onClick={() =>
                    turf.archived
                      ? unarchiveTurf(turf.id)
                      : archiveTurf(turf.id)
                  }
                >
                  {t('Done')}
                </Button.Accent>
              </ButtonBlock>
            </Modal.Actions>
          </Modal>
          <Modal
            id="confirm-copy-modal"
            title={t('This action will modify child turfs')}
            isOpen={isConfirmationModalOpen}
          >
            <Modal.Body>
              <TextBlock>
                <Font.Copy>
                  {t(
                    `This update will also make changes to {{ countDescendants }} child ${
                      countDescendants > 1 ? 'turfs' : 'turf'
                    }. Are you sure you want to apply these changes?`,
                    {
                      countDescendants,
                    }
                  )}
                </Font.Copy>
              </TextBlock>
            </Modal.Body>
            <Modal.Actions>
              <ButtonBlock justify="right">
                <Button.Secondary
                  onClick={() => setIsConfirmationModalOpen(false)}
                >
                  {t('Cancel')}
                </Button.Secondary>
                <Button.Accent onClick={submitForm}>
                  {t('Confirm')}
                </Button.Accent>
              </ButtonBlock>
            </Modal.Actions>
          </Modal>
        </Section>
      </form>
    </>
  )
}

export default TurfForm
