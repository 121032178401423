import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { IconSeparator } from 'react-md'
import { DEPRECATED_useRequest } from 'hooks'
import { postPublicLink } from 'requests/events'
import {
  Section,
  Button,
  ButtonBlock,
  Icon,
} from '@politechdev/blocks-design-system'
import styles from './EventInviteLink.module.scss'
import { EventContext } from '../EventContext/EventContext'

const EventInviteLink = () => {
  const { t } = useTranslation()

  const [copied, setCopied] = useState(false)
  const [link, setLink] = useState('')
  const [url, setUrl] = useState('')
  const { currentEvent } = useContext(EventContext)

  const hasProtocolPattern = /^((http|https):\/\/)/

  const { makeRequest } = DEPRECATED_useRequest(postPublicLink, response => {
    const publicEventLinkUrl = response?.public_event_link?.url
    setLink(publicEventLinkUrl)
    setUrl(
      hasProtocolPattern.test(publicEventLinkUrl)
        ? publicEventLinkUrl
        : `http://${publicEventLinkUrl}`
    )
  })

  useEffect(() => {
    if (currentEvent.public) {
      makeRequest(currentEvent.id, { fields: ['url'] })
    }
  }, [])

  if (!currentEvent.public) return null

  return (
    <Section label={t('Invite Link')}>
      <div className={styles.link}>
        <a href={url}>{link}</a>
        <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
          <div className={styles.container}>
            <ButtonBlock>
              <Button aria-label="Copy link">
                <Icon.Clipboard />
              </Button>
            </ButtonBlock>
            {copied && (
              <span className={styles.message}>
                <IconSeparator label={t('Copied')} iconBefore>
                  <Icon.Check />
                </IconSeparator>
              </span>
            )}
          </div>
        </CopyToClipboard>
      </div>
    </Section>
  )
}

export default EventInviteLink
