import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, HeaderBlock, CardError } from 'components'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { useReactRouter } from 'hooks'
import { useCurrent } from 'contexts/index'
import ListFolders from '../ListFolders/ListFolders'

const ListSelect = () => {
  const { t } = useTranslation()
  const { location } = useReactRouter()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canViewLists = doesCurrentUserHavePermission({
    resource: 'list',
    ability: 'view',
  })
  const canModifyLists = doesCurrentUserHavePermission({
    resource: 'list',
    ability: 'modify',
  })
  const canViewListFolders = doesCurrentUserHavePermission({
    resource: 'list_folder',
    ability: 'view',
  })

  if (!canViewLists) {
    return (
      <CardError
        hide={canViewLists}
        message={t('You do not have permission to view lists')}
        hideSupportLink
      />
    )
  }

  if (!canViewListFolders) {
    return (
      <CardError
        hide={false}
        message={t('You do not have permission to view list folders')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <HeaderBlock title={t('Lists')}>
        <ButtonBlock justify="right">
          {canViewListFolders && (
            <Link to={`${location.pathname}/folders`}>
              <Button.Secondary flat>{t('Manage folders')}</Button.Secondary>
            </Link>
          )}
          {canModifyLists && (
            <Link to={`${location.pathname}/new`}>
              <Button.Accent>{t('Add list')}</Button.Accent>
            </Link>
          )}
        </ButtonBlock>
      </HeaderBlock>
      <ListFolders />
    </View>
  )
}

export default ListSelect
