import { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { HeaderBlock, View, ViewContainer, TurfSelectField } from 'components'
import { DateField, Sheet, FieldBlock } from '@politechdev/blocks-design-system'
import {
  AutoTableProvider,
  useAutoTable,
  useQueryParams,
  useFilters,
  ReportModalProvider,
  useCurrent,
  useTurfs,
} from 'contexts'
import { useRouteQueryParams } from 'hooks'
import { reportTypes, tableConfig, defaultReportType } from '../configs'
import PhoneBankReportsPicker from '../PhoneBankReportsPicker/PhoneBankReportsPicker'
import PhoneBankReportsTable from '../PhoneBankReportsTable/PhoneBankReportsTable'

const defaultStartDate = moment().subtract(7, 'days').toISOString()
const defaultEndDate = moment().toISOString()

const PhoneBankReports = () => {
  const { t } = useTranslation()
  const { setTableId } = useAutoTable()
  const { clearFiltersOnTableIdChange } = useFilters()
  const { queryParams, setQueryParams } = useQueryParams()
  const { reportType } = queryParams

  const {
    currentUser: {
      turf: { id: topLevelTurfId },
    },
  } = useCurrent()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  const [turfId, setTurfId] = useState(+queryParams.turfId || topLevelTurfId)
  const [dateRange, setDateRange] = useState({
    start: moment(queryParams.startDate, 'YYYYMMDD', true).isValid()
      ? moment(queryParams.startDate).toISOString()
      : defaultStartDate,
    end: moment(queryParams.endDate, 'YYYYMMDD', true).isValid()
      ? moment(queryParams.endDate).toISOString()
      : defaultEndDate,
  })

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  useEffect(() => {
    const defaultParams = {}
    if (
      !queryParams.startDate ||
      !moment(queryParams.startDate, 'YYYYMMDD', true).isValid() ||
      !queryParams.endDate ||
      !moment(queryParams.endDate, 'YYYYMMDD', true).isValid()
    ) {
      defaultParams.startDate = moment(defaultStartDate).format('YYYYMMDD')
      defaultParams.endDate = moment(defaultEndDate).format('YYYYMMDD')
    }
    if (!queryParams.turfId) {
      defaultParams.turfId = topLevelTurfId
    }
    if (!queryParams.reportType) {
      defaultParams.reportType = defaultReportType
      setTableId(defaultReportType)
    } else {
      setTableId(queryParams.reportType)
    }
    setQueryParams(defaultParams, 'replace')
  }, [])

  useEffect(() => {
    if (reportType) {
      setTableId(reportType)
      clearFiltersOnTableIdChange()
      setQueryParams({ reportType })
    }
  }, [reportType])

  const changeReportType = reportType => {
    setTableId(reportType)
    clearFiltersOnTableIdChange()
    setQueryParams({ reportType })
  }

  const changeTurf = turfId => {
    setTurfId(turfId)
    setQueryParams({ turfId })
  }

  const changeDateRange = (start, end) => {
    if (!start || !end) return
    setDateRange({
      start,
      end,
    })
    setQueryParams({
      startDate: moment(start).format('YYYYMMDD'),
      endDate: moment(end).format('YYYYMMDD'),
    })
  }

  return (
    <View>
      <HeaderBlock title={t('phone banking reports')} />
      <ViewContainer loading={turfRefreshIsLoading}>
        <Sheet>
          <FieldBlock variant="medium">
            <TurfSelectField
              id="turf"
              label={t('Turf')}
              value={turfId}
              onSelect={changeTurf}
            />
            <DateField
              type="range"
              label={t('Date range')}
              onChange={changeDateRange}
              startDate={dateRange.start}
              endDate={dateRange.end}
            />
          </FieldBlock>
        </Sheet>
        <PhoneBankReportsPicker
          reportType={reportType}
          reportTypes={reportTypes}
          changeReportType={changeReportType}
        />
        <ReportModalProvider>
          {!!reportType && (
            <PhoneBankReportsTable
              reportType={reportType}
              dateRange={dateRange}
              turfId={turfId}
            />
          )}
        </ReportModalProvider>
      </ViewContainer>
    </View>
  )
}

export default () => {
  const [{ reportType }] = useRouteQueryParams()

  return (
    <AutoTableProvider
      persistSelectedFilter
      isFrontend
      enableQueryParams
      enableFilters
      enableSort
      showDownloadButton
      showFilterEditor
      showQuickSearch
      showTotals
      freezeLeftColumns={1}
      defaultFilter={tableConfig[reportType]?.defaultFilters || []}
    >
      <PhoneBankReports />
    </AutoTableProvider>
  )
}
