import moment from 'moment'
import {
  SET_ACTIVE_TAB,
  RECEIVE_FLAGS_TO_REVIEW,
  SET_TO_REVIEW_PAGE,
  RECEIVE_SELECTED_FLAG,
  SET_SORT_BY,
  SET_TECHNICAL_FLAG_FILTER,
  SET_START_DATE_FILTER,
  SET_CANVASSER_FILTER,
  SET_CANVASSER_FLAG_FILTER,
  SET_END_DATE_FILTER,
  SET_FLAG_NAME_FILTER,
  SET_FLAG_ID_FILTER,
  SET_TURF_FILTER,
  CLEAR_ALL_FILTERS,
  RECEIVE_FLAGS_AWAITING_GROUP_QC_ACTION,
  RECEIVE_FLAG_TRIGGERS,
  SET_AWAITING_GROUP_QC_ACTION_LIST_PAGE,
  SET_UNREAD_STATUS_FOR_FLAG_GROUPS,
  SET_NEW_COMMENTS_FILTER,
  RECEIVE_RESOLVED_FLAGS,
  SET_RESOLVED_FLAGS_LIST_PAGE,
  RECEIVE_FLAGS_ASSOCIATED_WITH_CURRENT_FLAG,
  RECEIVE_CURRENT_FLAG_DUPLICATES,
} from './actionTypes'
import {
  fetchFlagTriggers,
  requestFlagsWithBaseFilters,
  completeFlag,
  reopenFlag,
  fetchFlag,
  reviewFlag,
  startFlag,
  updateFlagComment,
  updateFlag,
  deleteFlagComment,
  createFlagComment,
  requestFlagsWithUnreadCommentsByStatus,
  fetchFlags,
} from './requests'
import {
  getCurrentFlag,
  getToReviewListPage,
  getAwaitingGroupQcActionListPage,
  getResolvedFlagListPage,
  createRequestFilters,
  isCurrentFlagComplete,
  getFilters,
} from './reducer'
import { getQcFlagFilter } from './utils'

export const defaultFlagParams = {
  fields: [
    'id',
    'status',
    'action_plan',
    'created_at',
    {
      viewed_by_users: ['email'],
    },
    {
      comments: [
        'id',
        'body',
        'created_at',
        { viewed_by_users: ['email'] },
        { author: ['id', 'name', 'email'] },
      ],
    },
    {
      packet: ['id', 'shift_id', 'original_filename', 'scans_count'],
    },
    {
      trigger: [
        'id',
        'name',
        'implies_canvasser_issue',
        'needs_reupload',
        'resource_type',
      ],
    },
    { canvasser: ['id', 'full_name'] },
    { turf: ['id', 'name'] },
    {
      triggered_by_scans: [
        'id',
        'scan_number',
        'qc_history',
        { packet: ['id', 'original_filename', 'scans_count'] },
      ],
    },
    {
      triggered_by_shift: ['status'],
    },
  ],
}

export const receiveCurrentFlag = flag => ({
  type: RECEIVE_SELECTED_FLAG,
  payload: flag,
})

export const receiveFlagTriggers = triggers => ({
  type: RECEIVE_FLAG_TRIGGERS,
  payload: triggers,
})

export const receiveFlagsToReview = flags => ({
  type: RECEIVE_FLAGS_TO_REVIEW,
  payload: flags,
})

export const receiveFlagsAwaitingGroupQcAction = flags => ({
  type: RECEIVE_FLAGS_AWAITING_GROUP_QC_ACTION,
  payload: flags,
})

export const receiveResolvedFlags = flags => ({
  type: RECEIVE_RESOLVED_FLAGS,
  payload: flags,
})

export const setToReviewPage = page => ({
  type: SET_TO_REVIEW_PAGE,
  payload: page,
})

export const setAwaitingGroupQcActionListPage = page => ({
  type: SET_AWAITING_GROUP_QC_ACTION_LIST_PAGE,
  payload: page,
})

export const setResolvedFlagsListPage = page => ({
  type: SET_RESOLVED_FLAGS_LIST_PAGE,
  payload: page,
})

export const setTechnicalFlagFilter = filter => ({
  type: SET_TECHNICAL_FLAG_FILTER,
  payload: filter,
})

export const setCanvasserFlagFilter = filter => ({
  type: SET_CANVASSER_FLAG_FILTER,
  payload: filter,
})

export const setStartDateFilter = filter => ({
  type: SET_START_DATE_FILTER,
  payload: filter,
})

export const setEndDateFilter = filter => ({
  type: SET_END_DATE_FILTER,
  payload: filter,
})

export const setActiveTab = tabIndex => ({
  type: SET_ACTIVE_TAB,
  payload: tabIndex,
})

export const setFlagIdFilter = filter => ({
  type: SET_FLAG_ID_FILTER,
  payload: filter,
})

export const setTurfFilter = filter => ({
  type: SET_TURF_FILTER,
  payload: filter,
})

export const setFlagNameFilter = filter => ({
  type: SET_FLAG_NAME_FILTER,
  payload: filter,
})
export const setCanvasserFilter = filter => ({
  type: SET_CANVASSER_FILTER,
  payload: filter,
})

export const setNewCommentsFilter = filter => ({
  type: SET_NEW_COMMENTS_FILTER,
  payload: filter,
})

export const setSortBy = sortBy => ({
  type: SET_SORT_BY,
  payload: sortBy,
})

export const clearFilters = () => ({
  type: CLEAR_ALL_FILTERS,
})

export const setUnreadStatusForFlagGroups = statuses => ({
  type: SET_UNREAD_STATUS_FOR_FLAG_GROUPS,
  payload: statuses,
})

export const receiveFlagsAssociatedWithCurrentFlag = flags => ({
  type: RECEIVE_FLAGS_ASSOCIATED_WITH_CURRENT_FLAG,
  payload: flags,
})

export const recieveCurrentFlagDuplicates = flags => ({
  type: RECEIVE_CURRENT_FLAG_DUPLICATES,
  payload: flags,
})

const setFlagTriggersWithCountsByRequest = flagsRequest => async dispatch => {
  const triggerFields = [
    'id',
    'name',
    'resource_type',
    'default_action_plan',
    'needs_reupload',
    'implies_canvasser_issue',
  ]

  const [flagTriggers, flagsResponse] = await Promise.all([
    fetchFlagTriggers({ fields: triggerFields, per: Number.MAX_SAFE_INTEGER }),
    flagsRequest([
      {
        column: 'trigger_name',
      },
    ]),
  ])

  const triggersWithCounts = flagTriggers['quality_control/flag_triggers'].map(
    trigger => ({
      ...trigger,
      count:
        flagsResponse.meta.counts_by_trigger.find(
          triggerCountObj => triggerCountObj.trigger_id === trigger.id
        )?.count || 0,
    })
  )

  dispatch(receiveFlagTriggers(triggersWithCounts))
}

export const requestGroupFlags =
  (currentTurfPerformsExternalQC, userId) => async (dispatch, getState) => {
    const state = getState()
    const filters = [
      getQcFlagFilter({
        only360: currentTurfPerformsExternalQC,
      }),
    ]
    const request = filtersToDisable =>
      requestFlagsWithBaseFilters(
        state,
        getToReviewListPage(state),
        [
          {
            column: 'status',
            operator: 'is',
            param: 'ready',
          },
          ...filters,
        ],
        filtersToDisable,
        userId
      )
    const flags = await request()
    dispatch(receiveFlagsToReview(flags))
    await dispatch(setFlagTriggersWithCountsByRequest(request))
  }

export const requestPendingFlags =
  (flagAbility, currentTurfPerformsExternalQC, userId) =>
  async (dispatch, getState) => {
    const state = getState()

    const filters = []
    if (flagAbility === 'start') {
      filters.push(
        getQcFlagFilter({
          only360: currentTurfPerformsExternalQC,
          exclude360: !currentTurfPerformsExternalQC,
        })
      )
      const request = filtersToDisable =>
        requestFlagsWithBaseFilters(
          state,
          getToReviewListPage(state),
          [
            {
              column: 'status',
              operator: 'is',
              param: 'pending',
            },
            ...filters,
          ],
          filtersToDisable,
          userId
        )
      const flags = await request()
      dispatch(receiveFlagsToReview(flags))
      await dispatch(setFlagTriggersWithCountsByRequest(request))
    }

    if (flagAbility === 'complete') {
      filters.push(
        getQcFlagFilter({
          only360: currentTurfPerformsExternalQC,
        })
      )
      const request = filtersToDisable =>
        requestFlagsWithBaseFilters(
          state,
          getToReviewListPage(state),
          [
            {
              column: 'status',
              operator: 'is',
              param: 'reviewed',
            },
            ...filters,
          ],
          filtersToDisable,
          userId
        )
      const flags = await request()
      dispatch(receiveFlagsToReview(flags))
      await dispatch(setFlagTriggersWithCountsByRequest(request))
    }
  }

export const requestFlagsAwaitingGroupQcAction =
  (currentTurfPerformsExternalQC, userId) => async (dispatch, getState) => {
    const state = getState()
    const filters = [
      getQcFlagFilter({ only360: currentTurfPerformsExternalQC }),
    ]
    const request = filtersToDisable =>
      requestFlagsWithBaseFilters(
        state,
        getAwaitingGroupQcActionListPage(state),
        [
          {
            column: 'status',
            operator: 'is',
            param: 'ready',
          },
          ...filters,
        ],
        filtersToDisable,
        userId
      )
    const flags = await request()
    dispatch(receiveFlagsAwaitingGroupQcAction(flags))
    await dispatch(setFlagTriggersWithCountsByRequest(request))
  }

export const requestResolvedFlags =
  (currentTurfPerformsExternalQC, userId) => async (dispatch, getState) => {
    const state = getState()
    const filters = getFilters(state)
    const request = filtersToDisable =>
      requestFlagsWithBaseFilters(
        state,
        getResolvedFlagListPage(state),
        [
          filters.startDate
            ? undefined
            : {
                column: 'created_at',
                operator: 'after',
                param: moment().startOf('week').toDate(),
              },
          filters.endDate
            ? undefined
            : {
                column: 'created_at',
                operator: 'before',
                param: moment().endOf('week').toDate(),
              },
          {
            column: 'status',
            operator: 'is',
            param: 'completed',
          },
          getQcFlagFilter({
            only360: currentTurfPerformsExternalQC,
            exclude360: !currentTurfPerformsExternalQC,
          }),
        ],
        filtersToDisable,
        userId
      )
    const flags = await request()
    dispatch(receiveResolvedFlags(flags))
    await dispatch(setFlagTriggersWithCountsByRequest(request))
  }

export const resetFlagsRequest = () => dispatch => {
  dispatch(setToReviewPage(1))
  dispatch(setAwaitingGroupQcActionListPage(1))
  dispatch(setResolvedFlagsListPage(1))
}

export const advanceFlagsStatuses =
  (flags, flagAbility, currentTurfPerformsExternalQC) => async dispatch => {
    const getRequest = flagAbility => {
      switch (flagAbility) {
        case 'start': {
          return startFlag
        }
        case 'complete': {
          return completeFlag
        }
        default: {
          throw new Error('Invalid permissions for bulk flag advancement')
        }
      }
    }

    const advanceStatus = getRequest(flagAbility)
    const requests = flags.map(flag => advanceStatus(flag.id))
    await Promise.all(requests)
    await dispatch(
      requestPendingFlags(undefined, currentTurfPerformsExternalQC)
    )
  }

export const requestFlagsAssociatedWithCurrentFlag =
  () => async (dispatch, getState) => {
    const currentFlag = getCurrentFlag(getState())
    if (currentFlag.packet?.id) {
      const flagsResponse = await fetchFlags({
        ...defaultFlagParams,
        filters: {
          rules: [
            {
              column: 'packet',
              operator: 'is',
              param: currentFlag.packet.id,
            },
          ],
        },
      })
      dispatch(
        receiveFlagsAssociatedWithCurrentFlag(
          flagsResponse['quality_control/flags']
        )
      )
    }
  }
export const requestFlag = id => async dispatch => {
  const response = await fetchFlag(id, defaultFlagParams)
  dispatch(receiveCurrentFlag(response['quality_control/flag']))
}

export const advanceFlagStatus = flagAbility => async (dispatch, getState) => {
  const state = getState()
  const { id } = getCurrentFlag(state)

  switch (flagAbility) {
    case 'start': {
      await startFlag(id)
      break
    }
    case 'review': {
      await reviewFlag(id)
      break
    }
    case 'complete': {
      await completeFlag(id)
      break
    }
    case 'reopen': {
      await reopenFlag(id)
      break
    }
    default: {
      throw new Error('Invalid permissions for flag advancement')
    }
  }
  await dispatch(requestFlag(id))
}

export const saveActionPlanEdits = actionPlan => async (dispatch, getState) => {
  const state = getState()
  const { id } = getCurrentFlag(state)

  await updateFlag(id, { flag: { action_plan: actionPlan } })
  await dispatch(requestFlag(id))
}

export const createComment = commentBody => async (dispatch, getState) => {
  const state = getState()
  const currentFlag = getCurrentFlag(state)

  const response = await createFlagComment(currentFlag.id, commentBody, {
    fields: ['id', 'body', 'created_at', { author: ['id', 'name', 'email'] }],
  })
  const createdComment = response['quality_control/comment']

  dispatch(
    receiveCurrentFlag({
      ...currentFlag,
      comments: [...currentFlag.comments, createdComment],
    })
  )
}

export const saveCommentEdits =
  (commentId, commentBody) => async (dispatch, getState) => {
    const state = getState()
    const currentFlag = getCurrentFlag(state)

    const response = await updateFlagComment(
      currentFlag.id,
      commentId,
      commentBody,
      {
        fields: [
          'id',
          'body',
          'created_at',
          { author: ['id', 'name', 'email'] },
        ],
      }
    )

    const updatedComment = response['quality_control/comment']

    dispatch(
      receiveCurrentFlag({
        ...currentFlag,
        comments: currentFlag.comments.map(comment =>
          comment.id === updatedComment.id ? updatedComment : comment
        ),
      })
    )
  }

export const deleteComment = commentId => async (dispatch, getState) => {
  const state = getState()
  const currentFlag = getCurrentFlag(state)

  await deleteFlagComment(currentFlag.id, commentId)

  dispatch(
    receiveCurrentFlag({
      ...currentFlag,
      comments: currentFlag.comments.filter(
        comment => comment.id !== commentId
      ),
    })
  )
}

export const checkUnreadStatusForFlagGroups =
  (userEmail, currentTurfPerformsExternalQC) => async dispatch => {
    const filters = []
    filters.push(
      getQcFlagFilter({
        only360: currentTurfPerformsExternalQC,
        exclude360: !currentTurfPerformsExternalQC,
      })
    )

    const [tableReview, awaitingGroupQc, awaitingResolution, resolved] =
      await Promise.all([
        requestFlagsWithUnreadCommentsByStatus('pending', 1, 1, filters),
        requestFlagsWithUnreadCommentsByStatus('ready', 1, 1, filters),
        requestFlagsWithUnreadCommentsByStatus('reviewed', 1, 1, filters),
        requestFlagsWithUnreadCommentsByStatus('completed', 1, 1, filters),
      ])

    const filterFlagsWithUnreadComments = flags =>
      flags.filter(
        flag =>
          flag.comments.filter(comment =>
            comment.viewed_by_users.every(user => user.email !== userEmail)
          ).length > 0
      )

    const unreadStatuses = {
      tableReview:
        filterFlagsWithUnreadComments(tableReview['quality_control/flags'])
          .length > 0,
      awaitingGroupQc:
        filterFlagsWithUnreadComments(awaitingGroupQc['quality_control/flags'])
          .length > 0,
      awaitingResolution:
        filterFlagsWithUnreadComments(
          awaitingResolution['quality_control/flags']
        ).length > 0,
      resolved:
        filterFlagsWithUnreadComments(resolved['quality_control/flags'])
          .length > 0,
    }
    dispatch(setUnreadStatusForFlagGroups(unreadStatuses))
  }

export const requestReviewableCurrentFlag =
  (type, currentTurfPerformsExternalQC) => async (dispatch, getState) => {
    const state = getState()
    const currentFlag = getCurrentFlag(state)

    const getNextId = flags => {
      if (flags.length) {
        return flags.find(flag => flag.id !== currentFlag?.id)?.id
      }
    }

    const setNextFlag = async flags => {
      if (flags.length) {
        const nextId = getNextId(flags)
        nextId && (await dispatch(requestFlag(nextId)))
      }
    }

    const filters = createRequestFilters(state)()
    if (['review', 'complete'].includes(type)) {
      filters.push(
        getQcFlagFilter({
          only360: currentTurfPerformsExternalQC,
        })
      )
    } else {
      filters.push(
        getQcFlagFilter({
          only360: currentTurfPerformsExternalQC,
          exclude360: !currentTurfPerformsExternalQC,
        })
      )
    }

    switch (type) {
      case 'start': {
        const response = await fetchFlags({
          ...defaultFlagParams,
          filters: {
            rules: [
              {
                column: 'status',
                operator: 'is',
                param: 'pending',
              },
              ...filters,
            ],
          },
          per: 2,
        })
        await setNextFlag(response['quality_control/flags'])
        return getNextId(response['quality_control/flags'])
      }
      case 'review': {
        const response = await fetchFlags({
          ...defaultFlagParams,
          filters: {
            rules: [
              {
                column: 'status',
                operator: 'is',
                param: 'ready',
              },
              ...filters,
            ],
          },
          per: 2,
        })
        await setNextFlag(response['quality_control/flags'])
        return getNextId(response['quality_control/flags'])
      }
      case 'complete': {
        const response = await fetchFlags({
          ...defaultFlagParams,
          filters: {
            rules: [
              {
                column: 'status',
                operator: 'is',
                param: 'reviewed',
              },
              ...filters,
            ],
          },
          per: 2,
        })
        await setNextFlag(response['quality_control/flags'])
        return getNextId(response['quality_control/flags'])
      }
      case 'reopen': {
        const response = await fetchFlags({
          ...defaultFlagParams,
          filters: {
            rules: [
              {
                column: 'status',
                operator: 'is',
                param: 'completed',
              },
              ...filters,
            ],
          },
          per: 2,
        })
        await setNextFlag(response['quality_control/flags'])
        return getNextId(response['quality_control/flags'])
      }
      default: {
        throw new Error('Invalid type for fetching flags')
      }
    }
  }

export const requestDuplicateFlags =
  currentTurfPerformsExternalQC => async (dispatch, getState) => {
    const state = getState()
    const currentFlag = getCurrentFlag(state)
    const isComplete = isCurrentFlagComplete(state)

    if (!isComplete) {
      dispatch(recieveCurrentFlagDuplicates([]))
    }

    const filterRules = [
      getQcFlagFilter({
        only360: currentTurfPerformsExternalQC,
      }),
      {
        column: 'id',
        operator: 'is',
        param: currentFlag.id,
        invert: 'true',
      },
      {
        column: 'status',
        operator: 'is',
        param: 'completed',
        invert: 'true',
      },
      {
        column: 'trigger_name',
        operator: 'is',
        param: currentFlag.trigger.name,
      },
    ]

    if (currentFlag.trigger.resource_type === 'packet') {
      filterRules.push({
        column: 'packet',
        operator: 'is',
        param: currentFlag.packet?.id,
      })
    } else {
      filterRules.push({
        column: 'canvasser',
        operator: 'is',
        param: currentFlag.canvasser?.id,
      })
    }

    const response = await fetchFlags({
      ...defaultFlagParams,
      filters: { rules: filterRules },
      per: 2,
    })

    dispatch(recieveCurrentFlagDuplicates(response['quality_control/flags']))
  }
