import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  ContentBlock,
  TextField,
  Section,
  SectionLabel,
  ButtonBlock,
  FieldBlock,
  Modal,
  useToast,
} from '@politechdev/blocks-design-system'
import {
  useReactRouter,
  useRouteQueryParams,
  useRequest,
  useEvent,
} from 'hooks'
import {
  getCurrentScan,
  getCurrentPacket,
  getSortedScanIds,
} from 'store/qcScans/reducer'
import { disapproveScan } from 'store/qcScans/actions'
import { redirectToInbox } from 'qualityControl/inbox/utils'
import { ScanPdfViewer } from 'qualityControl/scanReview/PdfViewer'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import { useInbox } from 'qualityControl/inbox/InboxContext'
import { qcPacketFields } from 'constants/qualityControl'
import IssueOptionsList from '../IssueOptionsList/IssueOptionsList'
import styles from './IssueModal.module.scss'

const IssueModal = ({
  isOpen,
  closeModal,
  currentScan,
  currentPacket,
  sortedScanIds,
  disapproveScan,
  presetIssueIds = [],
}) => {
  const { t } = useTranslation()
  const [{ scanId: stringScanId }, setQueryParams] = useRouteQueryParams()
  const { setToast } = useToast()
  const scanId = +stringScanId
  const currentScanIndex = sortedScanIds.indexOf(scanId)

  const { inboxUser: user } = useInbox()
  const { getPackets, inspectedUser, getInspectedUser } = useQualityControl()
  const staffId = user.id

  const goToNextScan = () => {
    setQueryParams({ scanId: sortedScanIds[currentScanIndex + 1] })
  }

  const [notes, setNotes] = useState(() => currentScan.notes)
  const [issueReasons, setIssueReasons] = useState([])

  const { history } = useReactRouter()
  const onDisapproveScanSuccess = updatedScan => {
    closeModal()
    const doesStopQc = updatedScan.unresolved_flag_triggers.some(
      trigger => trigger.needs_reupload || trigger.stops_qc
    )

    inspectedUser.id
      ? getInspectedUser(inspectedUser.id)
      : getPackets({
          fields: qcPacketFields,
        })

    if (doesStopQc) {
      redirectToInbox(staffId, history, inspectedUser.id)
      setToast({ message: t('Issues submitted'), variant: 'success' })
    } else {
      goToNextScan()
    }
  }

  const { isLoading, hasErrors, makeRequest } = useRequest(disapproveScan, {
    onSuccess: onDisapproveScanSuccess,
  })

  const handleDisapproveScan = useEvent(async () => {
    await makeRequest(scanId, notes, issueReasons)
  })

  useEffect(() => {
    setNotes('')
    setIssueReasons(presetIssueIds)
  }, [isOpen])

  const addIssueReason = reason => {
    setIssueReasons(oldReasons => oldReasons.concat([reason]))
  }

  const removeIssueReason = reason => {
    setIssueReasons(oldReasons =>
      oldReasons.filter(oldReason => oldReason !== reason)
    )
  }

  return (
    <Modal
      id="visual-qc-issue"
      title={t(`Attention needed`)}
      isOpen={isOpen}
      showLoading={isLoading}
      className={styles.modal}
    >
      <Modal.Body>
        <CardError hide={!hasErrors} />
        <div className={styles.grid}>
          <div>
            <ContentBlock>
              <SectionLabel primary>{`${t('Scan')} ${
                Math.max(currentScanIndex, 0) + 1
              } ${t('of')} ${currentPacket.scans_count}`}</SectionLabel>
            </ContentBlock>
            <ContentBlock>
              <ScanPdfViewer
                scanUrl={currentScan.file_url}
                removedAt={currentScan.file_locator?.metadata?.redacted_at}
                isInteractive
              />
            </ContentBlock>
          </div>
          <div>
            <ContentBlock>
              <SectionLabel>{t('Issues Found')}</SectionLabel>
            </ContentBlock>
            <ContentBlock>
              <IssueOptionsList
                issueReasons={issueReasons}
                addIssueReason={addIssueReason}
                removeIssueReason={removeIssueReason}
              />
            </ContentBlock>
            <Section label={t('Notes')}>
              <FieldBlock variant="large">
                <TextField
                  aria-label={t('Notes')}
                  id="notes"
                  value={notes || ''}
                  onChange={setNotes}
                />
              </FieldBlock>
            </Section>
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary disabled={isLoading} onClick={closeModal}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent
            disabled={issueReasons.length === 0 || isLoading}
            onClick={handleDisapproveScan}
          >
            {t(isLoading ? 'Submitting' : 'Submit')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default connect(
  state => ({
    currentScan: getCurrentScan(state),
    currentPacket: getCurrentPacket(state),
    sortedScanIds: getSortedScanIds(state),
  }),
  {
    disapproveScan,
  }
)(IssueModal)
