import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  View,
  ViewContainer,
  CardError,
  Sheet,
  LoadBar,
  SubheaderBlock,
} from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'

import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import { fetchTurfLevels } from 'requests/turfLevels'
import { defaultTo } from 'lodash'
import TurfLevelCreateModal from '../TurfLevelCreateModal/TurfLevelCreateModal'
import TurfLevelEditModal from '../TurfLevelEditModal/TurfLevelEditModal'
import TurfLevelDeleteModal from '../TurfLevelDeleteModal/TurfLevelDeleteModal'
import styles from './TurfLevelSelect.module.scss'

const TurfLevelSelect = () => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()

  const canViewTurfLevels = doesCurrentUserHavePermission({
    resource: 'turf_level',
    ability: 'view',
  })
  const canViewTurfs = doesCurrentUserHavePermission({
    resource: 'turf',
    ability: 'view',
  })

  const { makeRequest, isLoading, hasErrors, errors, response } = useRequest(
    () =>
      fetchTurfLevels({
        per: Number.MAX_SAFE_INTEGER,
        fields: ['id', 'name', { turfs: ['id'] }],
      })
  )

  const turfLevels = defaultTo(response?.turf_levels, [])

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    makeRequest()
  }, [])

  if (!canViewTurfLevels) {
    return (
      <CardError
        hide={canViewTurfLevels}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  return (
    <View>
      <SubheaderBlock title={t('Turf levels')}>
        <ButtonBlock justify="right">
          <Link
            onClick={e => !canViewTurfs && e.preventDefault()}
            to="/admin/turfs"
          >
            <Button disabled={!canViewTurfs}>{t('View Turfs')}</Button>
          </Link>
          <TurfLevelCreateModal onClose={makeRequest} />
        </ButtonBlock>
      </SubheaderBlock>
      <ViewContainer loading={isLoading}>
        <CardError hide={!hasErrors} message={errorMsg} />
        <Sheet>
          <div className={styles.list}>
            <div className={styles.loadbar}>
              <LoadBar show={isLoading} />
            </div>
            {turfLevels.map(turfLevel => (
              <div key={turfLevel.id} className={styles.item}>
                <ContentBlock>
                  <span className={styles.title}>{turfLevel.name}</span>
                </ContentBlock>
                <ButtonBlock justify="right">
                  <TurfLevelEditModal
                    turfLevel={turfLevel}
                    onClose={makeRequest}
                  />
                  <TurfLevelDeleteModal
                    turfLevel={turfLevel}
                    onClose={makeRequest}
                  />
                </ButtonBlock>
              </div>
            ))}
          </div>
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default TurfLevelSelect
