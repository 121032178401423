import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderBlock, Section } from '../../components'

const DuplicatePreventionDone = () => {
  const { t } = useTranslation()
  return (
    <Section>
      <HeaderBlock title={t('All errors in the import have been resolved.')} />
    </Section>
  )
}

export default DuplicatePreventionDone
