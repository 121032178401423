import { connect } from 'react-redux'
import { getCurrentFlagComments } from 'store/qcFlags/reducer'
import {
  createComment,
  deleteComment,
  saveCommentEdits,
} from 'store/qcFlags/actions'
import { orderBy } from 'lodash'
import moment from 'moment'
import ExistingComment from './ExistingComment'
import NewComment from './NewComment'

const FlagSingleComments = ({
  comments,
  saveCommentEdits,
  createComment,
  deleteComment,
}) => (
  <div>
    {orderBy(comments, comment => moment(comment.created_at), ['asc']).map(
      comment => (
        <ExistingComment
          key={`flag-single-comment-${comment.id}`}
          comment={comment}
          saveCommentEdits={saveCommentEdits}
          deleteComment={deleteComment}
        />
      )
    )}
    <NewComment createComment={createComment} />
  </div>
)

export default connect(
  state => ({
    comments: getCurrentFlagComments(state),
  }),
  { saveCommentEdits, createComment, deleteComment }
)(FlagSingleComments)

export const DeliveriesFlagSingleComments = FlagSingleComments
