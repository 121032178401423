import { useState } from 'react'
import PropTypes from 'prop-types'
import { IconSeparator, FontIcon, Button } from 'react-md'
import { useTranslation } from 'react-i18next'
import styles from './ScanReviewSection.module.scss'

const ScanReviewSection = ({ children, approved, initExpanded }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(initExpanded || false)
  const reviewStatusMap = {
    approved: {
      label: t('Approved'),
      icon: 'check',
    },
    disapproved: {
      label: t('Issues found'),
      icon: 'error_outline',
    },
  }
  const status = approved ? 'approved' : 'disapproved'

  return (
    <div className={styles.container} data-testid="scan-review-item">
      <div className={styles.section}>
        <IconSeparator
          data-testid="status"
          label={reviewStatusMap[status].label}
          iconBefore
        >
          <FontIcon className="material-icons-outlined">
            {reviewStatusMap[status].icon}
          </FontIcon>
        </IconSeparator>
        <Button
          flat
          primary
          onClick={() => {
            setExpanded(!expanded)
          }}
        >
          {expanded ? t('Collapse') : t('Inspect')}
        </Button>
      </div>
      {expanded && <div className={styles.content}>{children}</div>}
    </div>
  )
}

ScanReviewSection.propTypes = {
  initExpanded: PropTypes.bool,
}

ScanReviewSection.defaultProps = {
  initExpanded: false,
}

export default ScanReviewSection
