import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  View,
  ViewContainer,
  Sheet,
  CardError,
  LoadBar,
  Grid,
  Section,
  HeaderBlock,
} from 'components'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { useRequest, useRoutePathParams } from 'hooks'
import { fetchShift } from 'requests/shifts'
import ShiftForm from '../ShiftForm/ShiftForm'
import ShiftPdfViewer from '../ShiftPdfViewer/ShiftPdfViewer'

import styles from './ShiftEdit.module.scss'

const ShiftEdit = () => {
  const { t } = useTranslation()
  const [{ shiftType, id: shiftId }] = useRoutePathParams()
  const history = useHistory()

  const [currentShift, setCurrentShift] = useState(null)

  const { makeRequest, isLoading, hasErrors } = useRequest(fetchShift, {
    onSuccess: ({ shift }) => setCurrentShift(shift),
  })

  useEffect(() => {
    makeRequest(shiftId, {
      fields: [
        'id',
        'shift_start',
        'shift_end',
        'field_start',
        'field_end',
        'notes',
        'soft_count_cards_total_collected',
        'soft_count_cards_complete_collected',
        'soft_count_cards_incomplete_collected',
        'soft_count_cards_with_phone_collected',
        'soft_count_pre_registration_cards_collected',
        'custom_field_data',
        'status',
        { forms: ['id'] },
        { packet: ['id', 'file_url', 'file_locator'] },
        { petition_packet: ['id', 'file_url', 'file_locator'] },
        { location: ['id', 'name', 'street_address', { turf: 'name' }] },
        { canvasser: ['id', 'full_name'] },
        { turf: ['options', 'voter_registration_config'] },
      ],
    })
  }, [shiftId])

  const goToPacket = () => {
    history.push(`/collect/${shiftType}/shifts/${shiftId}/packet`)
  }

  const goToEdit = () => {
    const formId = currentShift.forms
      .map(({ id }) => id)
      .sort()
      .at(0)

    history.push(
      `/collect/${shiftType}/shifts/${shiftId}/edit_form_data/${formId}`
    )
  }

  const shiftLoaded = currentShift && currentShift.id === +shiftId && !hasErrors
  const packet = currentShift?.packet || currentShift?.petition_packet
  const shouldShowEditForms =
    !!currentShift &&
    !currentShift.turf.voter_registration_config.uses_legacy_entry

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <HeaderBlock title={t('Edit Shift')} />
          <LoadBar show={isLoading} />
          <CardError
            hide={!hasErrors}
            message={t("We're unable to retrieve this shift")}
          />
          {shiftLoaded && (
            <Grid>
              <Section>
                <ShiftForm shift={currentShift} shiftType={shiftType} />
              </Section>
              {packet && (
                <Section>
                  <ShiftPdfViewer
                    url={packet.file_url}
                    filename={packet.file_locator?.metadata?.filename}
                    removedAt={packet.file_locator?.metadata?.redacted_at}
                  />
                  <ButtonBlock className={styles.container}>
                    <Button onClick={goToPacket} className={styles.button}>
                      {t('View shift packet')}
                    </Button>
                    {shouldShowEditForms && (
                      <Button onClick={goToEdit} className={styles.button}>
                        {t('Edit data entry')}
                      </Button>
                    )}
                  </ButtonBlock>
                </Section>
              )}
            </Grid>
          )}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default ShiftEdit
