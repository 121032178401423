import { useState, useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import {
  View,
  ViewContainer,
  ReportDescription,
  TurfSelectField,
  HeaderBlock,
} from 'components'
import {
  Sheet,
  FieldBlock,
  ButtonBlock,
  Button,
  DateField,
} from '@politechdev/blocks-design-system'
import {
  AutoTableProvider,
  useAutoTable,
  useQueryParams,
  useFilters,
  ReportModalProvider,
  useCurrent,
  useTurfs,
} from 'contexts'
import { useRouteQueryParams } from 'hooks'
import { formatDateStringToISO } from 'utils/formatting'
import { REPORT_PARAMS_DATE_FORMAT } from 'utils/constants'
import config from './reportsConfig'
import ReportPicker from './ReportPicker'
import TimelineReportTable from './TimelineReportTable'
import StandardReportTable from './StandardReportTable'
import QualityControlReportsProvider from './QualityControlReportsContext'

const defaultStartDate = moment().subtract(7, 'days').toISOString()
const defaultEndDate = moment().toISOString()

const QualityControlReports = () => {
  const { t } = useTranslation()
  const { setTableId } = useAutoTable()
  const {
    defaultFilters,
    clearFiltersOnTableIdChange,
    activeFilterId,
    setActiveFilter,
  } = useFilters()

  const { queryParams, setQueryParams } = useQueryParams()

  const {
    currentUser: {
      turf: { id: topLevelTurfId },
      role: currentUserRole,
    },
    doesCurrentUserHavePermission,
  } = useCurrent()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  const { reportTypes, reportCategories } = useMemo(() => {
    const reportTypes = config.reportTypes.filter(reportType => {
      if (typeof reportType.isViewable === 'boolean') {
        return reportType.isViewable
      }
      if (typeof reportType.isViewable === 'function') {
        return reportType.isViewable(currentUserRole)
      }
      return true
    })

    // eslint-disable-next-line import/no-named-as-default-member
    const reportCategories = config.reportCategories
      .map(reportCategory => {
        const types = reportCategory.reportTypes.filter(reportType =>
          reportTypes.find(
            availableReportType => availableReportType.id === reportType
          )
        )

        if (!reportTypes.length) return null
        return {
          ...reportCategory,
          reportTypes: types,
          defaultType: types[0],
        }
      })
      .filter(Boolean)

    return { reportTypes, reportCategories }
  }, [currentUserRole.id])

  const getDefaultType = () => {
    const validTypes = reportCategories
      .filter(reportCategory =>
        doesCurrentUserHavePermission({
          resource: reportCategory.permissionId,
          ability: 'view',
        })
      )
      .flatMap(reportCategory => reportCategory.reportTypes)

    if (
      reportTypes.find(({ id }) => id === queryParams.reportType) &&
      validTypes.includes(queryParams.reportType)
    ) {
      return queryParams.reportType
    }

    if (validTypes.includes(config.defaultReportType)) {
      return config.defaultReportType
    }

    return validTypes[0]
  }

  const [reportType, setReportType] = useState(getDefaultType())
  const [turfId, setTurfId] = useState(+queryParams.turfId || topLevelTurfId)
  const [dateRange, setDateRange] = useState({
    start: moment(
      queryParams.startDate,
      REPORT_PARAMS_DATE_FORMAT,
      true
    ).isValid()
      ? formatDateStringToISO(queryParams.startDate, REPORT_PARAMS_DATE_FORMAT)
      : defaultStartDate,
    end: moment(queryParams.endDate, REPORT_PARAMS_DATE_FORMAT, true).isValid()
      ? formatDateStringToISO(queryParams.endDate, REPORT_PARAMS_DATE_FORMAT)
      : defaultEndDate,
  })

  const [categoryId, setCategoryId] = useState(
    reportCategories.find(category => category.reportTypes.includes(reportType))
      ?.id
  )

  const selectedCategory = reportCategories.find(
    category => category.id === categoryId
  )

  const selectedReport = reportTypes.find(({ id }) => id === reportType)

  useEffect(() => {
    refreshCurrentTurfs()
    if (!queryParams.reportType) {
      setTableId(getDefaultType())
    } else {
      setTableId(queryParams.reportType)
    }
  }, [])

  useEffect(() => {
    const defaultParams = {}
    if (
      !queryParams.startDate ||
      !moment(
        queryParams.startDate,
        REPORT_PARAMS_DATE_FORMAT,
        true
      ).isValid() ||
      !queryParams.endDate ||
      !moment(queryParams.endDate, REPORT_PARAMS_DATE_FORMAT, true).isValid()
    ) {
      defaultParams.startDate = moment(defaultStartDate).format(
        REPORT_PARAMS_DATE_FORMAT
      )
      defaultParams.endDate = moment(defaultEndDate).format(
        REPORT_PARAMS_DATE_FORMAT
      )
    }
    if (!queryParams.turfId) {
      defaultParams.turfId = topLevelTurfId
    }
    if (
      !queryParams.reportType ||
      !reportTypes.find(({ id }) => id === queryParams.reportType)
    ) {
      defaultParams.reportType = config.defaultReportType
    }
    setQueryParams(defaultParams, 'replace')
  }, [])

  useEffect(() => {
    setQueryParams({ activeFilterId })
  }, [activeFilterId])

  useEffect(() => {
    if (!queryParams.activeFilterId && !queryParams.filterRules) {
      setActiveFilter('default-0')
    }
  }, [defaultFilters])
  const changeReportType = reportType => {
    setReportType(reportType)
    setTableId(reportType)
    clearFiltersOnTableIdChange()
    setQueryParams({ reportType })
  }

  const changeTurf = turfId => {
    setTurfId(turfId)
    setQueryParams({ turfId })
  }

  const changeDateRange = (start, end) => {
    if (!start || !end) return
    setDateRange({
      start,
      end,
    })
    setQueryParams({
      startDate: moment.tz(start, 'UTC').format(REPORT_PARAMS_DATE_FORMAT),
      endDate: moment.tz(end, 'UTC').format(REPORT_PARAMS_DATE_FORMAT),
    })
  }

  const changeTimelineDate = end => {
    setDateRange({
      start: end,
      end,
    })
    setQueryParams({
      startDate: moment.tz(end, 'UTC').format(REPORT_PARAMS_DATE_FORMAT),
      endDate: moment.tz(end, 'UTC').format(REPORT_PARAMS_DATE_FORMAT),
    })
  }

  const changeReportCategory = categoryId => {
    setCategoryId(categoryId)
    changeReportType(selectedCategory.defaultType)
  }

  return (
    <View>
      <HeaderBlock title={t('Quality control reports')}>
        <ButtonBlock justify="right">
          <a
            href={selectedReport.dataDictionaryUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Button.Accent>{t('Data dictionary')}</Button.Accent>
          </a>
        </ButtonBlock>
      </HeaderBlock>
      <ViewContainer loading={turfRefreshIsLoading}>
        <Sheet>
          <FieldBlock variant="medium">
            {selectedReport.isTimeline ? (
              <DateField
                label={t('Timeline end date')}
                value={dateRange.end}
                onChange={date => {
                  changeTimelineDate(date)
                }}
              />
            ) : (
              reportType !== 'qc_packets_by_day' && (
                <DateField
                  label={t('Date range')}
                  type="range"
                  onChange={changeDateRange}
                  customPresets={config.customDatePresets}
                  startDate={dateRange.start}
                  endDate={dateRange.end}
                />
              )
            )}
            <TurfSelectField
              label={t('Turf')}
              value={turfId}
              onSelect={changeTurf}
              isExpanded
              showArchived
            />
          </FieldBlock>
        </Sheet>
        <ReportPicker
          categoryId={categoryId}
          reportType={reportType}
          reportTypes={reportTypes}
          reportCategories={reportCategories}
          changeReportType={changeReportType}
          changeReportCategory={changeReportCategory}
        />
        <ReportDescription
          currentReport={selectedReport}
          categoryName={selectedCategory?.name}
        />
        <ReportModalProvider>
          <QualityControlReportsProvider>
            {selectedReport.isTimeline ? (
              <TimelineReportTable
                reportType={selectedReport}
                dateRange={dateRange}
                turfId={turfId}
              />
            ) : (
              <StandardReportTable
                reportType={selectedReport}
                dateRange={dateRange}
                turfId={turfId}
              />
            )}
          </QualityControlReportsProvider>
        </ReportModalProvider>
      </ViewContainer>
    </View>
  )
}

export default () => {
  const [{ reportType }] = useRouteQueryParams()
  const selectedReport = config.reportTypes.find(({ id }) => id === reportType)

  return (
    <AutoTableProvider
      persistSelectedFilter
      isFrontend
      enableQueryParams
      enableFilters
      enableSort
      showDownloadButton
      showFilterEditor
      showQuickSearch
      showTotals
      freezeLeftColumns={1}
      defaultFilters={selectedReport?.defaultFilters || []}
    >
      <QualityControlReports />
    </AutoTableProvider>
  )
}
