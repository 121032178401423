import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ViewContainer,
  HeaderBlock,
  Sheet,
  LoadBar,
  Section,
  DetailItem,
  FormattedData,
  CardError,
  PdfViewer,
  CountySelectField,
} from 'components'
import {
  TextBlock,
  FieldBlock,
  ContentBlock,
  Grid,
  Font,
} from '@politechdev/blocks-design-system'
import { fetchScan, putScan } from 'requests/shifts'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { fetchPhoneVerificationQuestions } from 'store/qcScans/actions'
import GalleryButtonBlock from 'qualityControl/scanReview/ScanViewHeader/GalleryButtonBlock'
import { useReactRouter } from 'hooks/router'
import { useRequest } from 'hooks/useRequest'
import { useCurrent } from 'contexts/index'
import { defaultTo, isEmpty } from 'lodash'
import { getVerifyingQuestionId } from 'store/qcScans/reducer'
import {
  formatAllAttempts,
  getCallNotes,
  getFailedCallsForResponse,
} from 'store/qcScans/utils'
import FlagViewer from './FlagViewer'
import CallHistoryList from '../qualityControl/scanReview/phoneVerification/callSession/CallHistoryList/CallHistoryList'

const ScanSingle = () => {
  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: requestScan, response: scanResponse } = useRequest(id =>
    fetchScan(id, [
      'id',
      'delivery_id',
      'scan_number',
      'county',
      'file_url',
      'file_locator',
      { flags: ['id'] },
      { form: ['first_name', 'last_name'] },
      { phone_verification_responses: ['notes'] },
      {
        packet: ['id', 'original_filename'],
      },
    ])
  )

  const currentScan = defaultTo(scanResponse?.voter_registration_scan, {})

  const verifyingId = useSelector(getVerifyingQuestionId)

  const responses = defaultTo(currentScan.phone_verification_responses, [])
  const calls = defaultTo(currentScan.phone_verification_calls, [])

  const callHistory =
    !calls.length || !responses.length
      ? []
      : formatAllAttempts(responses, verifyingId).map(formattedResponse => ({
          ...formattedResponse,
          failedCalls: getFailedCallsForResponse(
            responses,
            formattedResponse.call,
            calls
          ),
          notes: getCallNotes(responses, formattedResponse.call),
        }))

  const dispatch = useDispatch()
  const { getState } = useStore()

  const [county, setCounty] = useState(currentScan.county)

  const { match } = useReactRouter()

  const {
    currentTenant: { states },
  } = useCurrent()

  const { makeRequest, isLoading, hasErrors } = useRequest(putScan)

  useEffect(() => {
    if (match.params.id) {
      requestScan(match.params.id)
    }
  }, [match.params.id])

  useEffect(() => {
    if (currentScan.packet?.id) {
      fetchPhoneVerificationQuestions(currentScan.packet.id)(dispatch, getState)
    }
  }, [currentScan.packet?.id])

  useEffect(() => {
    if (currentScan.county) {
      setCounty(currentScan.county)
    }
  }, [currentScan])

  const updateCounty = selectedCounty => {
    makeRequest(currentScan.id, {
      county: selectedCounty,
    }).then(() => setCounty(selectedCounty))
  }

  const { t } = useTranslation()

  if (isEmpty(currentScan)) {
    return (
      <ContentBlock loading>
        <Sheet customHeader>
          <HeaderBlock title={t('Loading form')} />
          <LoadBar show />
        </Sheet>
      </ContentBlock>
    )
  }

  const { notes, phone_verification_responses } = currentScan
  const allNotes = notes ? [{ source: t('Visual QC'), note: notes }] : []

  if (phone_verification_responses.length) {
    phone_verification_responses.forEach(
      res =>
        res.notes &&
        allNotes.push({ source: t('Phone Verification'), note: res.notes })
    )
  }

  return (
    <>
      <HeaderBlock
        title={`${t('Scan')} #${currentScan.scan_number} ${
          currentScan.form
            ? `- ${currentScan.form.first_name} ${currentScan.form.last_name}`
            : ''
        }`}
        subtitle={
          currentScan.delivery_id
            ? `On Delivery ${currentScan.delivery_id}`
            : t('Available for delivery')
        }
      />
      <ViewContainer>
        <Sheet>
          <Grid>
            <DetailItem label={t('Packet Name')}>
              {currentScan.packet.original_filename}
            </DetailItem>
            <DetailItem label={t('Collected')}>
              <FormattedData
                type="date"
                value={currentScan.form?.registration_date}
              />
            </DetailItem>
            <FieldBlock>
              <CountySelectField
                label={t('County')}
                isLoading={isLoading}
                county={county}
                states={states}
                onSelect={updateCounty}
              />
            </FieldBlock>
          </Grid>
          {currentScan.flags.map(({ id }) => (
            <FlagViewer key={`delivery-single-flag-item-${id}`} id={id} />
          ))}
          <CardError
            hide={!hasErrors}
            message={t('An error occurred while trying to edit this form')}
          />
          <TextBlock>
            <Font.Label variant="section">
              {`${t('Scan')} #${currentScan.scan_number} in ${
                currentScan.packet.original_filename
              }`}{' '}
            </Font.Label>
          </TextBlock>
          {currentScan.pledge_card_url ? (
            <GalleryButtonBlock
              currentPacket={currentScan.packet}
              scanNumber={currentScan.scan_number}
            />
          ) : null}
          <PdfViewer
            file={currentScan.file_url}
            removedAt={currentScan.file_locator.metadata.redacted_at}
            showDownload={false}
            showPaginator={false}
          />
        </Sheet>
        <Sheet>
          <Section label={t('Visual Quality Control')}>
            <DetailItem label={t('Notes')}>
              {currentScan.notes || t('No notes')}
            </DetailItem>
          </Section>
        </Sheet>
        <Sheet>
          <CallHistoryList attempts={callHistory} />
        </Sheet>
      </ViewContainer>
    </>
  )
}

export default ScanSingle
