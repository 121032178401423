import pipe from 'lodash/fp/pipe'
import filter from 'lodash/fp/filter'
import orderBy from 'lodash/fp/orderBy'
import map from 'lodash/fp/map'
import moment from 'moment'
import {
  ENGLISH,
  notContactedChoices,
  excludeFromQueue,
} from '../../constants/qcCallScriptsConfig/phoneVerificationQuestions'

export const castToBool = value => {
  if (typeof value !== 'string') return value

  return value.toLowerCase() === 'true'
}

const attemptResultsMap = notContactedChoices[ENGLISH].reduce(
  (acc, choice) => {
    acc[choice.value] = choice.label
    return acc
  },
  { t: 'Verified', f: 'Not verified' }
)

export const getIsScanExcludedByVisualReview = scan =>
  scan.visual_reviews.some(
    review =>
      review.response.implies_not_form ||
      review.response.implies_skips_phone_verification
  )

export const getIsScanExcludedByPreviousNotContactedResponse = scan =>
  scan.phone_verification_responses.some(phoneVerificationResponse =>
    excludeFromQueue.includes(phoneVerificationResponse.response)
  )

export const countScanAttempts = scanResponses =>
  scanResponses.reduce((highestRound, response) => {
    if (!response) return highestRound
    return Math.max(highestRound, response.round_number)
  }, 0)

export const findLastCompletedPacketRound = scans => {
  const attempts = scans.map(scan =>
    countScanAttempts(scan.phone_verification_responses)
  )

  return attempts.length ? Math.min(...attempts) : 0
}

const isContacted = r => r?.['contacted?']

const formatCallData = r => ({
  callTime: r.created_at,
  contacted: isContacted(r),
  result: attemptResultsMap[r.response],
  notes: r.notes || '',
  number: r.call.number,
  ...r,
})

const formatCallAttempts = pipe(
  map(formatCallData),
  orderBy('callTime')('desc')
)

const isUsefulResponse = verifyingId => r =>
  !isContacted(r) || r.phone_verification_question_id === verifyingId

export const getFailedCallsForResponse = (
  responses,
  responseCall,
  scanPhoneCalls
) => {
  const responseCallsOldToNew = responses
    .map(response => response.call)
    .sort(
      (callA, callB) =>
        Date.parse(callA.created_at) - Date.parse(callB.created_at)
    )

  const failedCalls = scanPhoneCalls.filter(scanPhoneCall => {
    const responseCallIndex = responseCallsOldToNew.findIndex(
      rCall => rCall.id === responseCall.id
    )
    if (responseCallIndex === 0) {
      return moment(scanPhoneCall.created_at).isBefore(responseCall.created_at)
    }
    return (
      moment(scanPhoneCall.created_at).isBefore(responseCall.created_at) &&
      moment(scanPhoneCall.created_at).isAfter(
        responseCallsOldToNew[responseCallIndex - 1].created_at
      ) &&
      !responseCallsOldToNew.some(
        responseCall => responseCall.id === scanPhoneCall.id
      )
    )
  })

  return failedCalls.sort(
    (callA, callB) =>
      Date.parse(callB.created_at) - Date.parse(callA.created_at)
  )
}

export const formatAllAttempts = (responses, verifyingId) =>
  pipe(filter(isUsefulResponse(verifyingId)), formatCallAttempts)(responses)

export const calculatePhoneVerifiedScanCount = (scans, verifyingId) =>
  scans.filter(scan =>
    scan.phone_verification_responses.some(
      response =>
        response.phone_verification_question_id === verifyingId &&
        response.response === 't'
    )
  ).length

export const isPhoneVerificationComplete = (
  scans,
  verifiedPercent,
  minPhoneVerificationRounds,
  minPhoneVerificationPercent
) =>
  verifiedPercent >= minPhoneVerificationPercent ||
  findLastCompletedPacketRound(scans) >= minPhoneVerificationRounds

export const isVisualReviewComplete = scans =>
  scans.every(
    scan =>
      scan.visual_reviews &&
      scan.visual_reviews.filter(vr => vr.user !== null).length > 0
  )

const setScanPage = (index, setQueryParams, sortedScanIds) => {
  setQueryParams({ scanId: sortedScanIds[index] })
}

export const changeScanPage = (
  delta,
  {
    currentScanIndex,
    shouldSkip,
    sortedScanIds,
    filteredScanIds,
    setQueryParams,
  }
) => {
  let nextIndex = currentScanIndex + delta

  const setNextSkippedIndex = () => {
    for (let i = nextIndex; i <= sortedScanIds.length + 1; i += delta) {
      if (filteredScanIds.includes(sortedScanIds[i])) {
        nextIndex = i
        break
      }
      if (
        filteredScanIds[0] === sortedScanIds[currentScanIndex] &&
        delta === -1
      ) {
        const lastFilteredScanId = filteredScanIds[filteredScanIds.length - 1]
        i = sortedScanIds.indexOf(lastFilteredScanId) + 1
      }
      if (
        i === sortedScanIds.length &&
        filteredScanIds.length > 1 &&
        delta === +1
      ) {
        i = -1
      }
    }
  }

  if (shouldSkip) setNextSkippedIndex()

  setScanPage(nextIndex, setQueryParams, sortedScanIds)
}

export const getCallNotes = (responses, call) => {
  const callId = call.id
  const callResponses = responses.filter(({ call }) => call.id === callId)
  return callResponses.reduce((note, call) => note || call.notes, '')
}
