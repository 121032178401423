import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  ContentBlock,
  Icon,
  Modal,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { useCurrent } from 'contexts/index'
import { destroyTurfLevel } from 'requests/turfLevels'
import styles from './TurfLevelDeleteModal.module.scss'

const TurfLevelDeleteModal = ({ turfLevel, onClose }) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const canDeleteTurfLevel = doesCurrentUserHavePermission({
    resource: 'turf_level',
    ability: 'remove',
  })

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    onClose && onClose()
    setModalOpen(false)
  }

  const { makeRequest, isLoading, hasErrors } = useRequest(destroyTurfLevel, {
    onSuccess: closeModal,
  })

  return (
    <>
      <Button
        disabled={!!turfLevel.turfs.length || !canDeleteTurfLevel}
        onClick={openModal}
        aria-label={t('Delete ')}
      >
        <Icon.TrashAlt />
      </Button>
      <Modal
        id="turf-level-delete"
        title={t(`Delete turf level`)}
        isOpen={modalOpen}
        showLoading={isLoading}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!hasErrors}
              message={t('There was an error deleting your turf level')}
              hideSupportLink
            />
            <TextBlock className={styles.text}>
              {t('Are you sure you want to delete')}{' '}
              <strong>{turfLevel.name}</strong>
            </TextBlock>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Danger
              onClick={() => {
                makeRequest(turfLevel.id)
              }}
            >
              {t('Delete')}
            </Button.Danger>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default TurfLevelDeleteModal
