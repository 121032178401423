import React from 'react'
import { FontIcon } from 'react-md'
import { Button, ButtonBlock, TextBlock } from 'components'
import { connect } from 'react-redux'
import { getCurrentRegistrationForm } from 'store/qcScans/reducer'
import { useTranslation } from 'react-i18next'
import styles from './RegistrantProfileEditButton.module.scss'

const RegistrantProfileEditButton = ({
  currentRegistrationForm,
  setIsEditModalOpen,
}) => {
  const { t } = useTranslation()
  const isDisabled = !currentRegistrationForm.id
  return (
    <>
      <ButtonBlock>
        <Button
          className={styles.button}
          primary
          flat
          disabled={isDisabled}
          onClick={() => setIsEditModalOpen(true)}
          iconEl={<FontIcon iconClassName="fa fa-pencil" />}
        >
          {t('Update info')}
        </Button>
      </ButtonBlock>
      {isDisabled && (
        <TextBlock>
          <p className={styles['disabled-message']}>
            {t(
              'Registrant information is currently being processed and is unavailable for updates.'
            )}
          </p>
        </TextBlock>
      )}
    </>
  )
}

export default connect(state => ({
  currentRegistrationForm: getCurrentRegistrationForm(state),
}))(RegistrantProfileEditButton)
