import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { CardError } from 'components'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  Section,
  TextField,
  DateField,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { useForm } from 'contexts'
import { useRequest, useReactRouter } from 'hooks'
import { postCampaign, putCampaign } from 'requests/campaigns'
import { formatErrorMessage } from 'utils/formatting'

const CampaignForm = ({ campaign }) => {
  const isEditForm = !!campaign
  const formAction = isEditForm
    ? campaign => putCampaign(campaign.id, campaign)
    : postCampaign
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const { formData, setField, setFormData } = useForm()
  const { makeRequest, isLoading, hasErrors, errors } = useRequest(formAction, {
    onSuccess: () => {
      history.push('/admin/campaigns')
    },
  })

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    campaign && setFormData(campaign)
  }, [])

  const isValidEndDate = formData.start_date < formData.end_date

  const isFormValid = () =>
    !isEmpty(formData.name) &&
    typeof formData.start_date !== 'undefined' &&
    typeof formData.end_date !== 'undefined' &&
    isValidEndDate

  const submitForm = e => {
    e.preventDefault()
    makeRequest(formData)
  }

  return (
    <>
      <ProgressBar show={isLoading} />
      <CardError hide={!hasErrors} message={errorMsg} />
      <form onSubmit={submitForm}>
        <Section>
          <FieldBlock>
            <TextField
              id="name"
              label={t('Campaign name')}
              value={formData.name}
              onChange={val => setField(val, 'name')}
              required
            />
          </FieldBlock>
          <FieldBlock>
            <DateField
              label={t('Start date')}
              value={formData.start_date}
              onChange={val => setField(val, 'start_date')}
              required
            />
          </FieldBlock>
          <FieldBlock>
            <DateField
              label={t('End date')}
              value={formData.end_date}
              onChange={val => setField(val, 'end_date')}
              required
              error={formData.end_date && !isValidEndDate}
              errorMessage={t('End date must be after start date')}
            />
          </FieldBlock>
        </Section>
        <Section>
          <ButtonBlock justify="left">
            <Button.Accent type="submit" disabled={!isFormValid() || isLoading}>
              {t(isEditForm ? 'Update Campaign' : 'Create Campaign')}
            </Button.Accent>
          </ButtonBlock>
        </Section>
      </form>
    </>
  )
}

export default CampaignForm
