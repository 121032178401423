import { fetchJSON, stringifyParams } from 'utils/req'
import {
  RECEIVE_PACKET,
  RECEIVE_SCAN,
  SET_CURRENT_PACKET_SCANS,
  RECEIVE_PHONE_VER_QUESTIONS,
  SET_SCRIPT_LANGUAGE,
  CLEAR_CURRENT,
} from 'store/qcScans/actionTypes'
import {
  getCurrentPacket,
  getCurrentRegistrationForm,
  getCurrentScan,
  getPhoneVerificationQuestions,
  getCurrentPacketTurfId,
} from 'store/qcScans/reducer'
import {
  READY_FOR_PHONE_VERIFICATION,
  IN_PHONE_VERIFICATION,
  COMPLETED,
} from 'constants/qualityControl'
import { advanceShiftStatus } from 'requests/qualityControl'
import packetFields from 'store/utils/packetFields'
import { putScan } from 'requests/shifts'
import { NOT_CONTACTED_RESPONSES } from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import { redoVisualQc } from './requests'

export const scanParams = {
  fields: [
    'id',
    'county',
    'created_at',
    'scan_number',
    'file_url',
    'file_locator',
    'notes',
    { shift: ['shift_start'] },
    {
      unresolved_flag_triggers: ['needs_reupload', 'stops_qc'],
    },
    {
      turf: [
        'id',
        'name',
        'min_phone_verification_rounds',
        'min_phone_verification_percent',
        'voter_registration_config',
      ],
    },
    {
      form: [
        'first_name',
        'last_name',
        'id',
        'county',
        'phone_number',
        'voting_street_address_one',
        'voting_street_address_two',
        'voting_state',
        'voting_zipcode',
        'voting_city',
        'date_of_birth',
        'metadata',
        'pledge_card_metadata',
        'pledge_card_url',
      ],
    },
    {
      visual_reviews: [
        'id',
        'created_at',
        {
          response: [
            'id',
            'response',
            'description',
            'implies_not_form',
            'implies_skips_phone_verification',
          ],
        },
        {
          user: ['id', 'name'],
        },
      ],
    },
    {
      phone_verification_responses: [
        'id',
        'response',
        'notes',
        'round_number',
        'phone_verification_question_id',
        'created_at',
        { caller: ['id', 'name'] },
        { call: ['id', 'created_at', 'number'] },
      ],
    },
    {
      phone_verification_calls: ['id', 'created_at', 'number'],
    },
  ],
}

export const setScriptLanguage = lang => ({
  type: SET_SCRIPT_LANGUAGE,
  payload: lang,
})

const receiveScan = payload => ({
  type: RECEIVE_SCAN,
  payload,
})

export const clearCurrent = () => ({
  type: CLEAR_CURRENT,
})

const advanceCurrentPacket = newStatus => async (dispatch, getState) => {
  const state = getState()
  const currentPacket = getCurrentPacket(state)

  const { shift_id } = currentPacket
  await advanceShiftStatus(shift_id, newStatus, null)
  const endpoint = `/api/v1/packets/${currentPacket.id}`

  const { voter_registration_scan_batch: updatedCurrentPacket } =
    await fetchJSON(
      `${endpoint}?${stringifyParams({ fields: packetFields })}`,
      'GET',
      null,
      { useJwt: true }
    )

  await dispatch({
    type: RECEIVE_PACKET,
    packet: updatedCurrentPacket,
  })
}

export const updateRegistrantInformation =
  registrantInformation => async (dispatch, getState) => {
    const state = getState()
    const scan = getCurrentScan(state)
    const { id: formId } = getCurrentRegistrationForm(state)

    const endpoint = `/api/v1/forms/${formId}`

    const { registration_form } = await fetchJSON(
      `${endpoint}?${stringifyParams({
        fields: [
          'first_name',
          'last_name',
          'id',
          'county',
          'phone_number',
          'voting_street_address_one',
          'voting_street_address_two',
          'voting_state',
          'voting_zipcode',
          'voting_city',
          'date_of_birth',
          'metadata',
        ],
      })}`,
      'PUT',
      { form: registrantInformation },
      {
        useJwt: true,
      }
    )

    dispatch(receiveScan({ ...scan, form: registration_form }))
  }

export const advanceCurrentPacketToReadyForPhoneVerification = () =>
  advanceCurrentPacket(READY_FOR_PHONE_VERIFICATION)

export const advanceCurrentPacketToPhoneVerification = () =>
  advanceCurrentPacket(IN_PHONE_VERIFICATION)

export const advanceCurrentPacketToCompleted = () =>
  advanceCurrentPacket(COMPLETED)

export const setCurrentPacketScans = scans => ({
  type: SET_CURRENT_PACKET_SCANS,
  payload: scans,
})

export const fetchScan = scanId => async (dispatch, getState) => {
  const baseUrl = `/api/v1/scans/${scanId}?${stringifyParams(scanParams)}`
  const { voter_registration_scan: fetchedScan } = await fetchJSON(
    baseUrl,
    'GET',
    null,
    { useJwt: true }
  )

  fetchedScan.visual_reviews = fetchedScan.visual_reviews.map(vrr => ({
    ...vrr,
    user: vrr.user
      ? { ...vrr.user, name: vrr.user.name, full_name: vrr.user.name }
      : vrr.user,
  }))

  fetchedScan.phone_verification_responses =
    fetchedScan.phone_verification_responses.map(({ response, ...rest }) => ({
      ...rest,
      response,
      'contacted?': !NOT_CONTACTED_RESPONSES[response],
    }))

  const currentPacket = getCurrentPacket(getState())
  const scans = currentPacket.scans.map(scan =>
    scan.id === fetchedScan.id ? fetchedScan : scan
  )

  dispatch(setCurrentPacketScans(scans))
  dispatch(receiveScan(fetchedScan))

  return fetchedScan
}

export const updateScan = (scan, params) => async (dispatch, getState) => {
  const state = getState()
  const currentPacket = getCurrentPacket(state)

  const { voter_registration_scan: updatedScan } = await putScan(
    scan.id,
    scan,
    params
  )

  dispatch({
    type: RECEIVE_SCAN,
    payload: updatedScan,
  })

  const endpoint = `/api/v1/packets/${currentPacket.id}`

  const { voter_registration_scan_batch: updatedCurrentPacket } =
    await fetchJSON(
      `${endpoint}?${stringifyParams({ fields: packetFields })}`,
      'GET',
      null,
      { useJwt: true }
    )

  dispatch({
    type: RECEIVE_PACKET,
    packet: updatedCurrentPacket,
  })

  return updatedScan
}

export const approveScan =
  ({ visualReviewApprovedOption }) =>
  async (dispatch, getState) => {
    const state = getState()
    const currentScan = getCurrentScan(state)

    const updatedScan = {
      id: currentScan.id,
      visual_reviews: [
        {
          visual_review_response_id: visualReviewApprovedOption.id,
        },
      ],
    }
    await dispatch(updateScan(updatedScan, scanParams))
  }

export const disapproveScan =
  (scanId, notesValue, issueReasons) => async dispatch => {
    const disapprovedScan = {
      id: scanId,
      notes: notesValue,
      visual_reviews: issueReasons.map(issue => ({
        visual_review_response_id: issue,
      })),
    }

    const updatedScan = await dispatch(updateScan(disapprovedScan, scanParams))

    return updatedScan
  }

export const redoScanResponses = currentScan => async (dispatch, getState) => {
  const state = getState()
  const currentPacket = getCurrentPacket(state)

  await redoVisualQc(currentScan)

  const updatedScan = {
    ...currentScan,
    notes: '',
    visual_reviews: [],
  }

  dispatch({
    type: RECEIVE_SCAN,
    payload: updatedScan,
  })

  dispatch({
    type: RECEIVE_PACKET,
    packet: {
      ...currentPacket,
      scans: currentPacket.scans.map(s =>
        s.id === updatedScan.id ? updatedScan : s
      ),
    },
  })

  return updatedScan
}

export const fetchPhoneVerificationQuestions =
  () => async (dispatch, getState) => {
    const state = getState()
    const currentPacketTurfId = getCurrentPacketTurfId(state)
    const questions = { ...getPhoneVerificationQuestions(state) }

    const json = await fetchJSON(
      `/api/v1/turfs/${currentPacketTurfId}?${stringifyParams({
        fields: [{ phone_verification_questions: ['id', 'question'] }],
      })}`,
      'GET',
      null,
      { useJwt: true }
    )

    json.turf.phone_verification_questions.forEach(q => {
      questions[q.question].id = q.id
    })

    dispatch({ type: RECEIVE_PHONE_VER_QUESTIONS, payload: questions })

    return json
  }

export const fetchPacket = packetId => dispatch =>
  new Promise((resolve, reject) => {
    fetchJSON(
      `/api/v1/packets/${packetId}?${stringifyParams({
        fields: packetFields,
      })}`,
      'GET',
      null,
      { useJwt: true }
    )
      .then(({ voter_registration_scan_batch: packet }) => {
        dispatch({ type: RECEIVE_PACKET, packet })
        resolve(packet)
      })
      .catch(error => {
        reject(error)
      })
  })
