import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { CardError } from 'components'
import {
  TextField,
  Button,
  SelectField,
  FieldBlock,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { useCurrent, useForm } from 'contexts'
import { DEPRECATED_useRequest, useTimeout } from 'hooks'
import { languageOptions } from 'constants/users'
import { isValidEmail } from 'utils/inputValidations'
import { TimeZoneSelectField } from 'components/TimeZoneSelectField/TimeZoneSelectField'
import { buildForm } from './utils'

const UserAccountForm = () => {
  const { t } = useTranslation()

  const { currentUser: user, updateCurrentUser } = useCurrent()

  const [isComplete, setComplete] = useState(false)
  const { startTimeout, clearTimeout } = useTimeout(() => {
    setComplete(false)
  }, 5000)
  const setTempComplete = () => {
    setComplete(true)
    startTimeout()
  }

  const { formData, setField, setFormData } = useForm()
  const { makeRequest, isLoading, errorMsg } = DEPRECATED_useRequest(
    updateCurrentUser,
    setTempComplete
  )

  const setUserData = () => {
    setFormData(buildForm(user))
  }

  useEffect(() => {
    setUserData()
  }, [])

  const isFormValid = () =>
    !isEmpty(formData.first_name) &&
    !isEmpty(formData.last_name) &&
    isValidEmail(formData.email) &&
    !isEmpty(formData.locale) &&
    !isEmpty(formData.time_zone)

  const submitForm = e => {
    e.preventDefault()
    clearTimeout()
    setComplete(false)
    makeRequest(formData)
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      <form onSubmit={submitForm}>
        <FieldBlock>
          <TextField
            id="first_name"
            label={t('First name')}
            value={formData.first_name}
            onChange={val => setField(val, 'first_name')}
            required
          />
          <TextField
            id="last_name"
            label={t('Last name')}
            value={formData.last_name}
            onChange={val => setField(val, 'last_name')}
            required
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="email"
            label={t('Email')}
            type="email"
            value={formData.email}
            onChange={val => setField(val, 'email')}
            error={!!formData.email && !isValidEmail(formData.email)}
            errorText={t('Must be a valid email')}
            required
          />
          <SelectField
            id="locale"
            label={t('Default language')}
            options={languageOptions}
            value={formData.locale}
            onSelect={val => {
              setField(val, 'locale')
            }}
            required
          />
        </FieldBlock>
        <FieldBlock>
          <TimeZoneSelectField
            value={formData.time_zone}
            onSelect={val => {
              setField(val, 'time_zone')
            }}
            required
          />
        </FieldBlock>
        <ButtonBlock>
          <Button.Accent type="submit" disabled={!isFormValid() || isLoading}>
            {isLoading && t('Saving')}
            {!isLoading && isComplete && t('Saved')}
            {!isLoading && !isComplete && t('Save')}
          </Button.Accent>
          <Button.Secondary
            type="button"
            disabled={isLoading}
            onClick={setUserData}
          >
            {t('Cancel')}
          </Button.Secondary>
        </ButtonBlock>
      </form>
    </>
  )
}

export default UserAccountForm
