import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError, HeaderBlock } from 'components'
import {
  Sheet,
  Button,
  Icon,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { fetchTeam } from 'requests/teams'
import { defaultTo } from 'lodash'
import TeamForm from '../TeamForm/TeamForm'

const TeamEdit = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()

  const {
    makeRequest: teamRequest,
    isLoading,
    hasErrors,
    isRequestComplete,
    response,
  } = useRequest(fetchTeam)

  const currentTeam = defaultTo(response?.['people/team'], {})

  useEffect(() => {
    teamRequest(match.params.id, {
      fields: [
        'id',
        'name',
        'slug',
        'type',
        'active',
        { organizer: ['id', 'full_name', 'turf_id'] },
        { leader: ['id', 'name', { residential_address: ['city', 'state'] }] },
        { campaigns: ['id', 'name'] },
        { organizations: ['id', 'name'] },
      ],
      associations: ['organizer', 'campaigns', 'organizations'],
    })
  }, [match.params.id])

  const teamLoaded =
    isRequestComplete &&
    !hasErrors &&
    (currentTeam.id === +match.params.id ||
      currentTeam.slug === match.params.id)

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <Sheet>
          <HeaderBlock title={t('Edit Team')}>
            <ButtonBlock justify="right">
              <Link to="/organize/teams">
                <Button.Secondary>
                  <Icon.Times />
                </Button.Secondary>
              </Link>
            </ButtonBlock>
          </HeaderBlock>
          <CardError
            hide={!hasErrors}
            message={t("We're unable to retrieve this team")}
          />
          {teamLoaded && <TeamForm team={currentTeam} />}
        </Sheet>
      </ViewContainer>
    </View>
  )
}

export default TeamEdit
