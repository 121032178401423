import { Section, DetailItem, FormattedData } from 'components'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentPacket } from 'store/qcScans/reducer'

const PacketDetails = ({ currentPacket }) => {
  const { t } = useTranslation()
  const { shift, turf, location } = currentPacket

  return (
    <Section secondary>
      <DetailItem label={t('Canvasser')}>
        <p>{`${shift.canvasser.first_name} ${shift.canvasser.last_name}`}</p>
      </DetailItem>
      <DetailItem label={t('Shift Date')}>
        <FormattedData type="date" value={shift.shift_start} />
      </DetailItem>
      <DetailItem label={t('Shift Location')}>
        <p>{location.name}</p>
      </DetailItem>
      <DetailItem label={t('Turf')} hide={!turf}>
        <p>{turf.name}</p>
      </DetailItem>
      <DetailItem label={t('Shift notes')} hide={!shift.notes?.length}>
        <p>{shift.notes}</p>
      </DetailItem>
    </Section>
  )
}

export default connect(state => ({
  currentPacket: getCurrentPacket(state),
}))(PacketDetails)
