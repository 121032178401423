import { useTranslation } from 'react-i18next'
import { useReactRouter, useRequest } from 'hooks'
import { Grid, Section, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Font,
} from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import { formatLocationName } from 'utils/formatting'
import { putMeeting } from 'requests/events'

const MeetingDetails = ({ currentMeeting, setCurrentMeeting }) => {
  const { t } = useTranslation()
  const { match } = useReactRouter()

  const { makeRequest, isLoading, hasErrors } = useRequest(putMeeting, {
    onSuccess: ({ meeting }) => {
      setCurrentMeeting(meeting)
    },
  })

  const cancelEvent = () => {
    makeRequest(currentMeeting.id, {
      cancelled: true,
    })
  }

  return (
    <>
      <Grid>
        <Section
          label={t('Date')}
          tight
          empty={!currentMeeting.start_time}
          emptyMessage={t('Not set')}
        >
          <TextBlock>
            <Font.Copy>
              {moment(currentMeeting.start_time).format(dateFormat)}
            </Font.Copy>
            <Font.Copy>
              {moment(currentMeeting.start_time).format('h:mm A')}
              {' - '}
              {moment(currentMeeting.end_time).format('h:mm A')}
            </Font.Copy>
          </TextBlock>
        </Section>
        {currentMeeting.type && (
          <Section label={t('Type')}>
            <TextBlock>
              <Font.Copy>{currentMeeting.type}</Font.Copy>
            </TextBlock>
          </Section>
        )}
        <Section
          label={t('Venue')}
          empty={!currentMeeting.location}
          emptyMessage={t('No venue')}
        >
          {currentMeeting.location && (
            <TextBlock>
              <Font.Copy>
                {formatLocationName(currentMeeting.location)}
                <br />
                {currentMeeting.location.street_address}
                <br />
                {currentMeeting.location.city}, {currentMeeting.location.state}{' '}
                <br />
                {currentMeeting.location.zipcode}
              </Font.Copy>
            </TextBlock>
          )}
        </Section>
        <Section
          label={t('Campaign')}
          empty={!currentMeeting.campaign}
          emptyMessage={t('No campaign')}
        >
          {currentMeeting.campaign && (
            <TextBlock>
              <Font.Copy>{currentMeeting.campaign.name}</Font.Copy>
            </TextBlock>
          )}
        </Section>
      </Grid>
      <Section label={t('Notes')} tight>
        {currentMeeting.notes ? (
          <TextBlock>
            <Font.Copy variant="reading-block">
              {currentMeeting.notes}
            </Font.Copy>
          </TextBlock>
        ) : (
          <ButtonBlock>
            <Link to={`${match.url}/edit`}>
              <Button primary>Add Notes</Button>
            </Link>
          </ButtonBlock>
        )}
      </Section>
      <Section>
        <TextBlock>
          {currentMeeting.cancelled ? (
            <Font.Copy>
              <p className="uppercase text--warn">Event cancelled</p>
            </Font.Copy>
          ) : (
            <Button.Danger onClick={cancelEvent} disabled={isLoading}>
              {t('Cancel meeting')}
            </Button.Danger>
          )}
        </TextBlock>
        <CardError hide={!hasErrors} />
      </Section>
    </>
  )
}

export default MeetingDetails
