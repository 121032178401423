import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { CardError } from 'components'
import { TextBlock, Section, Tab } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks'
import { generateCheckboxGroupProvider } from 'providers/CheckboxGroupProvider'
import {
  getActiveTab,
  getFlagDataToReview,
  getUnreadStatusForFlagGroups,
} from 'store/qcFlags/reducer'
import {
  setActiveTab,
  resetFlagsRequest,
  checkUnreadStatusForFlagGroups,
} from 'store/qcFlags/actions'
import { formatErrorMessage } from 'utils/formatting'
import { useCurrent } from 'contexts/index'
import { PendingTableProgramReviewList } from '../TableProgramReviewList'
import TableProgramBulkReview from '../TableProgramBulkReview'
import TableProgramFilters from '../TableProgramFilters'
import GroupQcActionList from '../GroupQcActionList'
import ResolvedList from '../ResolvedList'

const AwaitingTableReviewBlankSlate = () => {
  const { t } = useTranslation()
  return (
    <Section secondary collapsed>
      <TextBlock>{t('There are no flags awaiting table review')}</TextBlock>
    </Section>
  )
}

const AwaitingResolutionBlankSlate = () => {
  const { t } = useTranslation()
  return (
    <Section secondary collapsed>
      <TextBlock>{t('There are no flags awaiting resolution')}</TextBlock>
    </Section>
  )
}

const [TableReviewCheckboxGroupContext, TableReviewCheckboxGroupProvider] =
  generateCheckboxGroupProvider(getFlagDataToReview)

const [
  AwaitingResolutionCheckboxGroupContext,
  AwaitingResolutionReviewCheckboxGroupProvider,
] = generateCheckboxGroupProvider(getFlagDataToReview)

const TABLE_REVIEW = '0'
const AWAITING_GROUP_QC_ACTION = '1'
const AWAITING_RESOLUTION = '2'
const RESOLVED = '3'

const TableManagerTabs = ({
  setActiveTab,
  activeTab,
  resetFlagsRequest,
  checkUnreadStatusForFlagGroups,
  unreadStatusForFlagGroups,
}) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(activeTab || TABLE_REVIEW)
  const {
    currentUser: { email },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const { makeRequest, hasErrors, errors } = useRequest(
    checkUnreadStatusForFlagGroups
  )

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    makeRequest(email, currentTurfPerformsExternalQC)
  }, [])

  return (
    <>
      <CardError hide={!hasErrors} message={errorMsg} />
      <Tab.Container
        selectedTabId={tab}
        onChange={({ tabId }) => {
          resetFlagsRequest()
          setActiveTab(tabId)
          setTab(tabId)
          makeRequest()
        }}
      >
        <Tab
          id={TABLE_REVIEW}
          alert={unreadStatusForFlagGroups.tableReview}
          label={t('Table Review')}
        >
          {tab === TABLE_REVIEW && (
            <TableReviewCheckboxGroupProvider>
              <TableProgramFilters />
              <TableProgramBulkReview
                checkboxContext={TableReviewCheckboxGroupContext}
              />
              <PendingTableProgramReviewList
                checkboxContext={TableReviewCheckboxGroupContext}
                typeOverride="start"
                BlankSlate={AwaitingTableReviewBlankSlate}
              />
            </TableReviewCheckboxGroupProvider>
          )}
        </Tab>
        <Tab
          label={t('Awaiting Group QC Action')}
          id={AWAITING_GROUP_QC_ACTION}
          alert={unreadStatusForFlagGroups.awaitingGroupQc}
        >
          {tab === AWAITING_GROUP_QC_ACTION && (
            <>
              <TableProgramFilters />
              <GroupQcActionList />
            </>
          )}
        </Tab>
        <Tab
          label={t('Awaiting Resolution')}
          id={AWAITING_RESOLUTION}
          alert={unreadStatusForFlagGroups.awaitingResolution}
        >
          {tab === AWAITING_RESOLUTION && (
            <AwaitingResolutionReviewCheckboxGroupProvider>
              <TableProgramFilters />
              <TableProgramBulkReview
                checkboxContext={AwaitingResolutionCheckboxGroupContext}
                typeOverride="complete"
              />
              <PendingTableProgramReviewList
                checkboxContext={AwaitingResolutionCheckboxGroupContext}
                typeOverride="complete"
                BlankSlate={AwaitingResolutionBlankSlate}
              />
            </AwaitingResolutionReviewCheckboxGroupProvider>
          )}
        </Tab>
        <Tab label={t('Resolved')} id={RESOLVED}>
          {tab === RESOLVED && (
            <>
              <TableProgramFilters />
              <ResolvedList />
            </>
          )}
        </Tab>
      </Tab.Container>
    </>
  )
}

export default connect(
  state => ({
    unreadStatusForFlagGroups: getUnreadStatusForFlagGroups(state),
    activeTab: getActiveTab(state),
  }),
  {
    resetFlagsRequest,
    setActiveTab,
    checkUnreadStatusForFlagGroups,
  }
)(TableManagerTabs)
