import { FontIcon } from 'react-md'
import { useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from '@politechdev/blocks-design-system'
import { CardError, Uploader, Button, ButtonBlock } from 'components'
import { useTranslation } from 'react-i18next'
import { useReactRouter, DEPRECATED_useRequest } from 'hooks'
import { fetchPacket } from 'store/qcScans/actions'
import {
  getCurrentFlagPacket,
  getCurrentFlagTrigger,
} from 'store/qcFlags/reducer'
import { reviewFlag } from 'store/qcFlags/requests'
import { reuploadPacket } from 'requests/shifts'
import { usePacketAssignment } from '../packetAssignment/PacketAssignmentContext'

const FlagSingleReuploadButton = ({
  currentPacket,
  currentFlagTrigger,

  fetchPacket,
}) => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const [didSucceed, setDidSucceed] = useState(false)
  const [nextRoute, setNextRoute] = useState('')
  const { history, match } = useReactRouter()
  const { unassignPacket } = usePacketAssignment()
  const { makeRequest, errorMsg } = DEPRECATED_useRequest(
    async fileData => {
      await reuploadPacket(currentPacket.shift_id, fileData)
      await unassignPacket(currentPacket)
      await fetchPacket(currentPacket.id)
      await reviewFlag(match.params.flagId)
      setNextRoute(`/quality_control/flags/${match.params.type}`)
    },
    () => {
      setDidSucceed(true)
    }
  )

  const canReupload = (type, trigger) =>
    type !== 'start' && type !== 'complete' && trigger && trigger.needs_reupload

  return (
    <>
      {canReupload(match.params.type, currentFlagTrigger) && (
        <ButtonBlock>
          <Button
            raised
            accent
            onClick={() => {
              setDidSucceed(false)
              setOpen(true)
            }}
          >
            {t('Reupload')}
          </Button>
        </ButtonBlock>
      )}
      {isOpen && (
        <Modal
          id="reupload-packet-modal"
          onHide={() => {
            if (didSucceed) {
              history.push(nextRoute)
            }
            setOpen(false)
          }}
          title={t('Reupload Packet')}
          isOpen={isOpen}
        >
          <Modal.Body>
            <CardError hide={!errorMsg} message={errorMsg} />
            {didSucceed ? (
              <div>
                <div className="space">
                  {t('The packet has been successfully reuploaded')}
                </div>
                <div className="space">
                  <FontIcon>check</FontIcon>
                </div>
              </div>
            ) : (
              <Uploader
                label={t('Scan')}
                onUpload={makeRequest}
                autoUpload
                error={errorMsg}
                fileTypes={['.pdf']}
                endpoint="/system/files/upload?metadata_generator=voter_registration&url=true"
                stringifyData={false}
                fileValidator={file => {
                  // eslint-disable-next-line no-useless-escape
                  const invalidChars = file.name.match(/[^\w\.]/)

                  if (invalidChars) {
                    return `Filename contains invalid characters: ${invalidChars.join(
                      ', '
                    )}`
                  }
                }}
              />
            )}
          </Modal.Body>
          <Modal.Actions>
            <ButtonBlock justify="right">
              <Button
                raised
                primary
                onClick={() => {
                  if (didSucceed) {
                    history.push(nextRoute)
                  }
                  setOpen(false)
                }}
              >
                {t('Done')}
              </Button>
            </ButtonBlock>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default connect(
  state => ({
    currentPacket: getCurrentFlagPacket(state),
    currentFlagTrigger: getCurrentFlagTrigger(state),
  }),
  { fetchPacket }
)(FlagSingleReuploadButton)
