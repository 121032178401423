import { useEffect, useRef } from 'react'
import debounce from 'awesome-debounce-promise'

export {
  useReactRouter,
  useRoutePathParams,
  useRouteQueryParams,
} from './router'

export { default as useLocalForm } from './useLocalForm'
export { default as useTimer } from './useTimer'
export { default as useTwilioCall } from './useTwilioCall'
export { default as useTwilioDevice } from './useTwilioDevice'
export { default as useMounted } from './useMounted'
export { default as useInterval } from './useInterval'
export { default as useTimeout } from './useTimeout'
export { default as useBackgroundRequest } from './useBackgroundRequest'
export { default as useControlled } from './useControlled'
export { default as useDocumentEvent } from './useDocumentEvent'
export { default as useStoredState } from './useStoredState'
export { default as useEvent } from './useEvent'
export { default as useOnScreen } from './useOnScreen'
export { useRequest, DEPRECATED_useRequest } from './useRequest'
export { useReduxAction } from './useReduxAction'

export default function useConstant(fn) {
  const ref = useRef()

  if (!ref.current) {
    ref.current = { value: fn() }
  }

  return ref.current.value
}

export function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export function useDebounce(fn, ms, args) {
  return useConstant(() => debounce(fn.bind(null, args), ms))
}
