import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useRoutePathParams, useRouteQueryParams } from 'hooks'
import { fetchPacket, fetchScan } from 'store/qcScans/actions'
import { getCurrentPacket, getCurrentScan } from 'store/qcScans/reducer'

const PacketAndScanFetcher = ({
  currentPacket,
  currentScan,
  fetchPacket,
  fetchScan,
  children,
}) => {
  const [{ packetId }] = useRoutePathParams()
  const [{ scanId }] = useRouteQueryParams()

  useEffect(() => {
    const maybeFetchPacket = () => {
      if (!packetId) return

      if (currentPacket.id !== +packetId) {
        fetchPacket(packetId)
      }
    }

    maybeFetchPacket()
  }, [packetId, currentPacket.id])

  useEffect(() => {
    const maybeFetchScan = () => {
      if (!currentPacket.id || !scanId) return

      if (currentScan.id !== +scanId) {
        return fetchScan(scanId)
      }
    }

    maybeFetchScan()
  }, [currentPacket.id, scanId, currentScan.id])

  return children
}

const mapStateToProps = state => ({
  currentPacket: getCurrentPacket(state),
  currentScan: getCurrentScan(state),
})

export default connect(mapStateToProps, { fetchPacket, fetchScan })(
  PacketAndScanFetcher
)
