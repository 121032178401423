export const buildRequest = (formData, isEditForm) => ({
  ...formData,
  turf_level_id: formData.turf_level?.id,
  visual_review_responses: isEditForm
    ? undefined
    : formData.visual_review_responses.map(({ id }) => id),
  phone_verification_questions: isEditForm
    ? undefined
    : formData.phone_verification_questions.map(({ id }) => id),
})

export const getDescendantCount = (parentTurf, turfs) => {
  if (!parentTurf) return 0
  const childTurfs = turfs.filter(
    turf => turf.lft > parentTurf.lft && turf.rgt < parentTurf.rgt
  )
  return childTurfs.length
}
