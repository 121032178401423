import { CardError, LoadBar } from 'components'
import { Button } from 'react-md'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import {
  advanceFlagsStatuses,
  checkUnreadStatusForFlagGroups,
  requestPendingFlags,
} from 'store/qcFlags/actions'
import { connect } from 'react-redux'
import { DEPRECATED_useRequest, useReactRouter } from 'hooks'
import { useCurrent } from 'contexts/index'
import { getBulkReviewButtonText } from './utils'

const TableProgramBulkReview = ({
  checkboxContext,
  advanceFlagsStatuses,
  requestPendingFlags,
  typeOverride,
  checkUnreadStatusForFlagGroups,
}) => {
  const { t } = useTranslation()
  const { match } = useReactRouter()

  const { getCheckedCheckboxValues, checkAll, areAllChecked, uncheckAll } =
    useContext(checkboxContext)

  const {
    currentUser: { email, id: userId },
    currentTurfPerformsExternalQC,
  } = useCurrent()

  const {
    makeRequest: unreadRequest,
    isLoading: unreadLoading,
    errorMsg: unreadError,
  } = DEPRECATED_useRequest(checkUnreadStatusForFlagGroups)

  const {
    makeRequest: advanceRequest,
    isLoading: advanceLoading,
    errorMsg: advanceError,
  } = DEPRECATED_useRequest(advanceFlagsStatuses, async () => {
    uncheckAll()
    await requestPendingFlags(
      typeOverride !== undefined ? typeOverride : match.params.type,
      currentTurfPerformsExternalQC,
      userId
    )
    unreadRequest(email, currentTurfPerformsExternalQC)
  })

  if (getCheckedCheckboxValues().length === 0) {
    return null
  }

  const errorMsg = advanceError || unreadError
  const isLoading = advanceLoading || unreadLoading

  return (
    <div>
      <CardError hide={!errorMsg} message={errorMsg} />
      <LoadBar show={isLoading} />
      <div className="button__group button__block">
        <Button
          primary
          raised
          onClick={areAllChecked() ? uncheckAll : checkAll}
        >
          {areAllChecked() ? t('Clear all') : t('Select all')}
        </Button>
        <Button
          secondary
          raised
          onClick={() => {
            advanceRequest(
              getCheckedCheckboxValues(),
              typeOverride !== undefined ? typeOverride : match.params.type
            )
          }}
        >
          {getBulkReviewButtonText(
            typeOverride !== undefined ? typeOverride : match.params.type
          )}
        </Button>
      </div>
    </div>
  )
}

export default connect(undefined, {
  advanceFlagsStatuses,
  requestPendingFlags,
  checkUnreadStatusForFlagGroups,
})(TableProgramBulkReview)
