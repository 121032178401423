import produce from 'immer'
import { createSelector } from 'reselect'
import { uniqBy } from 'lodash/array'
import { getSortedScanIds } from 'store/qcScans/reducer'
import {
  SET_ACTIVE_TAB,
  RECEIVE_FLAGS_TO_REVIEW,
  SET_TO_REVIEW_PAGE,
  RECEIVE_SELECTED_FLAG,
  RECEIVE_FLAG_TRIGGERS,
  SET_SORT_BY,
  SET_START_DATE_FILTER,
  SET_END_DATE_FILTER,
  SET_CANVASSER_FILTER,
  SET_CANVASSER_FLAG_FILTER,
  SET_FLAG_NAME_FILTER,
  SET_FLAG_ID_FILTER,
  SET_TURF_FILTER,
  SET_TECHNICAL_FLAG_FILTER,
  CLEAR_ALL_FILTERS,
  RECEIVE_FLAGS_AWAITING_GROUP_QC_ACTION,
  SET_AWAITING_GROUP_QC_ACTION_LIST_PAGE,
  SET_UNREAD_STATUS_FOR_FLAG_GROUPS,
  SET_NEW_COMMENTS_FILTER,
  RECEIVE_RESOLVED_FLAGS,
  SET_RESOLVED_FLAGS_LIST_PAGE,
  RECEIVE_FLAGS_ASSOCIATED_WITH_CURRENT_FLAG,
  RECEIVE_CURRENT_FLAG_DUPLICATES,
} from './actionTypes'

const defaultState = {
  activeTab: 0,
  currentFlag: {
    action_plan: '',
    triggered_by_scans: [],
  },
  currentFlagDuplicates: [],
  flagsAssociatedWithCurrentFlag: [],
  flagTriggers: [],
  flagDataToReview: {
    'quality_control/flags': [],
    meta: {
      total_count: 0,
      per: 10,
    },
  },
  flagDataAwaitingGroupQcAction: {
    'quality_control/flags': [],
    meta: {
      total_count: 0,
      per: 10,
    },
  },
  resolvedFlags: {
    'quality_control/flags': [],
    meta: {
      total_count: 0,
      per: 10,
    },
  },
  toReviewListPage: 1,
  awaitingGroupQcActionListPage: 1,
  resolvedFlagListPage: 1,
  filters: {
    flagName: '',
    id: '',
    startDate: undefined,
    endDate: undefined,
    turf: '',
    canvasser: '',
    canvasserFlag: true,
    technicalFlag: true,
    sortBy: '',
    newComments: false,
  },
  unreadStatusForFlagGroups: {
    tableReview: false,
    awaitingGroupQc: false,
    awaitingResolution: false,
  },
}

export const qcFlags = (state = defaultState, action) =>
  produce(state, draft => {
    const { type, payload } = action
    switch (type) {
      case SET_ACTIVE_TAB: {
        draft.activeTab = payload
        return draft
      }
      case RECEIVE_SELECTED_FLAG: {
        draft.currentFlag = payload
        return draft
      }
      case RECEIVE_FLAG_TRIGGERS: {
        draft.flagTriggers = payload
        return draft
      }
      case RECEIVE_FLAGS_TO_REVIEW: {
        draft.flagDataToReview = payload
        return draft
      }
      case RECEIVE_FLAGS_AWAITING_GROUP_QC_ACTION: {
        draft.flagDataAwaitingGroupQcAction = payload
        return draft
      }
      case SET_AWAITING_GROUP_QC_ACTION_LIST_PAGE: {
        draft.awaitingGroupQcActionListPage = payload
        return draft
      }
      case RECEIVE_RESOLVED_FLAGS: {
        draft.resolvedFlags = payload
        return draft
      }
      case SET_RESOLVED_FLAGS_LIST_PAGE: {
        draft.resolvedFlagListPage = payload
        return draft
      }
      case SET_TO_REVIEW_PAGE: {
        draft.toReviewListPage = payload
        return draft
      }
      case SET_SORT_BY: {
        draft.filters.sortBy = payload
        return draft
      }
      case SET_FLAG_NAME_FILTER: {
        draft.filters.flagName = payload
        return draft
      }
      case SET_FLAG_ID_FILTER: {
        draft.filters.id = payload
        return draft
      }
      case SET_TURF_FILTER: {
        draft.filters.turf = payload
        return draft
      }
      case SET_CANVASSER_FILTER: {
        draft.filters.canvasser = payload
        return draft
      }
      case SET_START_DATE_FILTER: {
        draft.filters.startDate = payload
        return draft
      }
      case SET_END_DATE_FILTER: {
        draft.filters.endDate = payload
        return draft
      }
      case SET_CANVASSER_FLAG_FILTER: {
        draft.filters.canvasserFlag = payload
        return draft
      }
      case SET_TECHNICAL_FLAG_FILTER: {
        draft.filters.technicalFlag = payload
        return draft
      }
      case SET_NEW_COMMENTS_FILTER: {
        draft.filters.newComments = payload
        return draft
      }
      case CLEAR_ALL_FILTERS: {
        draft.filters = defaultState.filters
        return draft
      }
      case SET_UNREAD_STATUS_FOR_FLAG_GROUPS: {
        draft.unreadStatusForFlagGroups = payload
        return draft
      }
      case RECEIVE_FLAGS_ASSOCIATED_WITH_CURRENT_FLAG: {
        draft.flagsAssociatedWithCurrentFlag = payload
        return draft
      }
      case RECEIVE_CURRENT_FLAG_DUPLICATES: {
        draft.currentFlagDuplicates = payload
        return draft
      }
      default: {
        return state
      }
    }
  })

const getQcFlags = state => state.qcFlags

export const getCurrentFlag = createSelector(
  getQcFlags,
  qcFlags => qcFlags.currentFlag
)

export const getCurrentFlagTrigger = createSelector(
  getCurrentFlag,
  currentFlag => currentFlag.trigger
)

export const doesCurrentFlagNeedReupload = createSelector(
  getCurrentFlagTrigger,
  currentFlagTrigger => currentFlagTrigger?.needs_reupload
)

export const getCurrentFlagPacket = createSelector(
  getCurrentFlag,
  currentFlag => currentFlag.packet
)

export const getCurrentFlagPacketName = createSelector(
  getCurrentFlagPacket,
  packet => packet?.scan_name
)

export const isCurrentFlagComplete = createSelector(
  getCurrentFlag,
  currentFlag => currentFlag.status === 'completed'
)

export const isCurrentFlagTechnical = createSelector(
  getCurrentFlagTrigger,
  trigger => !trigger?.implies_canvasser_issue
)

export const getCurrentFlagName = createSelector(
  getQcFlags,
  qcFlags => qcFlags.currentFlag?.trigger?.name
)

export const getCurrentFlagPackets = createSelector(getCurrentFlag, flag =>
  uniqBy(
    flag.triggered_by_scans
      .map(scan => scan.packet)
      .concat(flag.packet ? [flag.packet] : []),
    'id'
  )
)

export const getCurrentFlagPacketIds = createSelector(
  getCurrentFlagPackets,
  packets => packets.map(packet => packet.id)
)

export const getCurrentFlagScans = createSelector(getCurrentFlag, flag =>
  flag.triggered_by_scans.sort((a, b) => a.id - b.id)
)

export const getFirstFlagPacketId = createSelector(
  getCurrentFlagPacketIds,
  packetIds => packetIds[0] || ''
)

export const getCurrentFlagActionPlan = createSelector(
  getCurrentFlag,
  currentFlag => currentFlag.action_plan
)

export const getCurrentFlagComments = createSelector(
  getCurrentFlag,
  currentFlag => {
    const { comments } = currentFlag
    return comments !== undefined ? comments : []
  }
)

export const getCurrentFlagCanvasser = createSelector(
  getCurrentFlag,
  currentFlag => currentFlag.canvasser
)

export const getCurrentFlagTurf = createSelector(
  getCurrentFlag,
  currentFlag => currentFlag.turf
)

export const getFlagDataToReview = createSelector(
  getQcFlags,
  qcFlags => qcFlags.flagDataToReview['quality_control/flags']
)

export const getToReviewMetaData = createSelector(
  getQcFlags,
  qcFlags => qcFlags.flagDataToReview.meta
)

export const getTotalToReviewFlagCount = createSelector(
  getToReviewMetaData,
  meta => meta.total_count
)

export const getToReviewListPage = createSelector(
  getQcFlags,
  qcFlags => qcFlags.toReviewListPage
)

export const getTotalToReviewListPages = createSelector(
  [getToReviewMetaData],
  flagMetaData => Math.ceil(flagMetaData.total_count / flagMetaData.per)
)

export const getFlagDataAwaitingGroupQcAction = createSelector(
  getQcFlags,
  qcFlags => qcFlags.flagDataAwaitingGroupQcAction['quality_control/flags']
)

export const getAwaitingGroupQcActionMetaData = createSelector(
  getQcFlags,
  qcFlags => qcFlags.flagDataAwaitingGroupQcAction.meta
)

export const getTotalAwaitingGroupQcActionListPages = createSelector(
  [getAwaitingGroupQcActionMetaData],
  flagMetaData => Math.ceil(flagMetaData.total_count / flagMetaData.per)
)

export const getAwaitingGroupQcActionListPage = createSelector(
  getQcFlags,
  qcFlags => qcFlags.awaitingGroupQcActionListPage
)

export const getResolvedFlagData = createSelector(
  getQcFlags,
  qcFlags => qcFlags.resolvedFlags['quality_control/flags']
)

export const getResolvedFlagMetaData = createSelector(
  getQcFlags,
  qcFlags => qcFlags.resolvedFlags.meta
)

export const getTotalResolvedFlagListPages = createSelector(
  [getResolvedFlagMetaData],
  flagMetaData => Math.ceil(flagMetaData.total_count / flagMetaData.per)
)

export const getResolvedFlagListPage = createSelector(
  getQcFlags,
  qcFlags => qcFlags.resolvedFlagListPage
)

export const getSortBy = createSelector(
  getQcFlags,
  qcFlags => qcFlags.filters.sortBy
)

export const getStartDateFilter = createSelector(
  getQcFlags,
  qcFlags => qcFlags.filters.startDate
)

export const getEndDateFilter = createSelector(
  getQcFlags,
  qcFlags => qcFlags.filters.endDate
)

export const getActiveTab = createSelector(
  getQcFlags,
  qcFlags => qcFlags.activeTab
)

export const getFlagNameFilter = createSelector(
  getQcFlags,
  qcFlags => qcFlags.filters.flagName
)

export const getFlagIdFilter = createSelector(
  getQcFlags,
  qcFlags => qcFlags.filters.id
)

export const getTurfFilter = createSelector(
  getQcFlags,
  qcFlags => qcFlags.filters.turf
)

export const getCanvasserFilter = createSelector(
  getQcFlags,
  qcFlags => qcFlags.filters.canvasser
)

export const getTechnicalFlagFilter = createSelector(
  getQcFlags,
  qcFlags => qcFlags.filters.technicalFlag
)

export const getCanvasserFlagFilter = createSelector(
  getQcFlags,
  qcFlags => qcFlags.filters.canvasserFlag
)

export const getNewCommentsFilter = createSelector(
  getQcFlags,
  qcFlags => qcFlags.filters.newComments
)

export const getFilters = createSelector(
  [
    getSortBy,
    getFlagNameFilter,
    getFlagIdFilter,
    getTurfFilter,
    getStartDateFilter,
    getEndDateFilter,
    getCanvasserFilter,
    getCanvasserFlagFilter,
    getTechnicalFlagFilter,
    getNewCommentsFilter,
  ],
  (
    sortBy,
    flagName,
    id,
    turf,
    startDate,
    endDate,
    canvasser,
    canvasserFlag,
    technicalFlag,
    newComments
  ) => ({
    canvasser,
    canvasserFlag,
    endDate,
    id,
    turf,
    flagName,
    startDate,
    technicalFlag,
    newComments,
    sortBy,
  })
)

export const getFlagTriggers = createSelector(
  getQcFlags,
  qcFlags => qcFlags.flagTriggers
)

export const getFlagTriggersAsOptions = createSelector(
  getFlagTriggers,
  flagTriggers =>
    flagTriggers
      .reduce((triggers, trigger) => {
        const existing = triggers.find(t => t.name === trigger.name)

        if (!existing) {
          return [...triggers, trigger]
        }

        return triggers.map(t => {
          if (t.name !== trigger.name) return t

          return {
            ...t,
            count: t.count + trigger.count,
          }
        })
      }, [])
      .sort((a, b) => b.count - a.count)
      .map(trigger => ({
        key: `${trigger.name} (${trigger.count})`,
        value: trigger.name,
        disabled: trigger.count <= 0,
      }))
)

export const createRequestFilters = createSelector(
  getFilters,
  ({
    canvasser,
    canvasserFlag,
    endDate,
    id,
    turf,
    flagName,
    startDate,
    technicalFlag,
    newComments,
  }) =>
    userId => {
      const filters = []
      if (!technicalFlag) {
        filters.push({
          column: 'trigger_implies_canvasser_issue',
          operator: 'is',
          param: true,
        })
      }

      if (!canvasserFlag) {
        filters.push({
          column: 'trigger_implies_canvasser_issue',
          operator: 'is',
          param: false,
        })
      }

      if (turf && turf !== 'all') {
        filters.push({
          column: 'in_turf_tree',
          param: turf,
        })
      }

      if (startDate) {
        filters.push({
          column: 'created_at',
          operator: 'after',
          param: startDate,
        })
      }

      if (endDate) {
        filters.push({
          column: 'created_at',
          operator: 'before',
          param: endDate,
        })
      }

      if (canvasser) {
        filters.push({
          column: 'triggered_by_canvasser_id',
          operator: 'is',
          param: canvasser,
        })
      }

      if (flagName && flagName !== 'all') {
        filters.push({
          column: 'trigger_name',
          operator: 'is',
          param: flagName,
        })
      }

      if (id && id !== '') {
        filters.push({
          column: 'id',
          operator: 'containing',
          param: id,
        })
      }

      if (newComments && userId) {
        filters.push({
          column: 'with_unread_comments',
          param: userId,
        })
      }

      return filters
    }
)

export const getUnreadStatusForFlagGroups = createSelector(
  getQcFlags,
  qcFlags => qcFlags.unreadStatusForFlagGroups
)

export const getCurrentFlagDuplicates = createSelector(
  getQcFlags,
  qcFlags => qcFlags.currentFlagDuplicates
)

export const doesCurrentFlagHaveDuplicates = createSelector(
  getCurrentFlagDuplicates,
  duplicates => !!(duplicates || []).length
)

export const getFlagsAssociatedWithCurrentFlag = createSelector(
  getQcFlags,
  qcFlags => qcFlags.flagsAssociatedWithCurrentFlag
)

export const getTechnicalFlagsAssociatedWithCurrentFlag = createSelector(
  [getFlagsAssociatedWithCurrentFlag, getCurrentFlag],
  (flagsAssociatedWithCurrentFlag, currentFlag) =>
    flagsAssociatedWithCurrentFlag.filter(
      flag =>
        flag.id !== currentFlag.id && !flag.trigger.implies_canvasser_issue
    )
)

export const getCurrentFlagTriggeredScanIds = createSelector(
  getCurrentFlag,
  getSortedScanIds,
  (currentFlag, scanIds) =>
    currentFlag.triggered_by_scans
      .filter(scan => scanIds.includes(scan.id))
      .sort((a, b) => a.scan_number - b.scan_number)
      .map(scan => scan.id)
)
