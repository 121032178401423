import { createRequestFilters, getSortBy } from 'store/qcFlags/reducer'
import { fetchJSON, stringifyParams } from 'utils/req'
import { defaultFlagParams } from './actions'

export const fetchFlags = params =>
  fetchJSON(
    `/api/v1/quality_control/flags?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const fetchFlag = (id, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${id}?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const updateFlag = (id, data) =>
  fetchJSON(`/api/v1/quality_control/flags/${id}`, 'PUT', data, {
    useJwt: true,
  })

export const startFlag = id =>
  fetchJSON(`/api/v1/quality_control/flags/${id}/start`, 'PUT', null, {
    useJwt: true,
  })

export const reviewFlag = id =>
  fetchJSON(`/api/v1/quality_control/flags/${id}/review`, 'PUT', null, {
    useJwt: true,
  })

export const completeFlag = id =>
  fetchJSON(`/api/v1/quality_control/flags/${id}/complete`, 'PUT', null, {
    useJwt: true,
  })

export const reopenFlag = id =>
  fetchJSON(`/api/v1/quality_control/flags/${id}/reopen`, 'PUT', null, {
    useJwt: true,
  })

export const createFlagComment = (flagId, commentBody, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${flagId}/comments?${stringifyParams(
      params
    )}`,
    'POST',
    { comment: { body: commentBody } },
    {
      useJwt: true,
    }
  )

export const fetchFlagComments = (flagId, params = {}) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${flagId}/comments/?${stringifyParams(
      params
    )}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const updateFlagComment = (
  flagId,
  commentId,
  commentBody,
  params = {}
) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${flagId}/comments/${commentId}?${stringifyParams(
      params
    )}`,
    'PUT',
    { comment: { body: commentBody } },
    {
      useJwt: true,
    }
  )

export const deleteFlagComment = (flagId, commentId) =>
  fetchJSON(
    `/api/v1/quality_control/flags/${flagId}/comments/${commentId}`,
    'DELETE',
    null,
    {
      useJwt: true,
    }
  )

export const requestFlagsWithBaseFilters = (
  state,
  page,
  additionalFilterRules,
  filterRulesToDisable = [],
  userId
) => {
  const filterRules = createRequestFilters(state)(userId)
  const sortBy = getSortBy(state)
  const sort_attr = sortBy && sortBy !== 'none' ? sortBy : undefined
  const sort_dir = sortBy === 'id' ? 'asc' : 'desc'
  const requestFilterRules = [...filterRules, ...additionalFilterRules].filter(
    requestFilterRule =>
      requestFilterRule &&
      !filterRulesToDisable.find(
        filterRuleToDisable =>
          filterRuleToDisable.column === requestFilterRule.column
      )
  )
  return fetchFlags({
    ...defaultFlagParams,
    current_page: page,
    filters: {
      rules: requestFilterRules,
    },
    sort_attr,
    sort_dir,
  })
}

export const requestFlagsWithUnreadCommentsByStatus = (
  status,
  per = 10,
  page = 1,
  filters
) => {
  const filter_rules = [
    {
      column: 'status',
      operator: 'is',
      param: status,
    },
    ...filters,
  ]
  status === 'ready' &&
    filter_rules.push({
      column: 'trigger_needs_reupload',
      operator: 'is',
      param: true,
    })
  return fetchFlags({
    ...defaultFlagParams,
    filters: { rules: filter_rules },
    per,
    current_page: page,
  })
}

export const deleteFlag = id => {
  const path = `/api/v1/quality_control/flags/${id}`
  return fetchJSON(path, 'DELETE', null, {
    useJwt: true,
  })
}

export const fetchFlagTriggers = queryParams => {
  const url = queryParams
    ? `/api/v1/quality_control/flag_triggers?${stringifyParams(queryParams)}`
    : `/api/v1/quality_control/flag_triggers`
  return fetchJSON(url, 'GET', null, {
    useJwt: true,
  })
}
