import React from 'react'
import { Reject, CheckCircle } from 'components/uiKit/icons'
import classNames from 'classnames/bind'
import { useRouteQueryParams } from 'hooks'
import styles from './PageIndicator.module.scss'

const cx = classNames.bind(styles)

const statusIconMap = {
  approved: <CheckCircle className={styles['icon--approved']} />,
  issue: <Reject className={styles['icon--rejected']} />,
  unreviewed: null,
}

const PageIndicator = ({ isActive, status, showStatusIcons, scan }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setQueryParams] = useRouteQueryParams()

  return (
    <div
      className={styles.container}
      onClick={() => setQueryParams({ scanId: scan.id })}
    >
      <div
        className={cx('bubble', {
          'bubble--active': isActive,
        })}
      >
        <div>{scan.scan_number}</div>
      </div>
      {!!showStatusIcons && statusIconMap[status]}
    </div>
  )
}

export default PageIndicator
