import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  ViewContainer,
  HeaderBlock,
  CardError,
  Calendar,
  TurfSelectField,
} from 'components'
import { SelectField, FieldBlock } from '@politechdev/blocks-design-system'
import { DEPRECATED_useRequest } from 'hooks'
import { fetchShifts } from 'requests/shifts'
import { useCurrent, useTurfs } from 'contexts/index'
import ShiftCalendarItem from '../ShiftCalendarItem/ShiftCalendarItem'
import ShiftCalendarLegend from '../ShiftCalendarLegend/ShiftCalendarLegend'
import { buildParams, buildShifts } from './utils'
import ShiftCalendarWeekDownload from '../ShiftCalendarWeekDownload/ShiftCalendarWeekDownload'

const ShiftCalendar = () => {
  const { t } = useTranslation()
  const {
    currentUser: {
      turf: { id: topLevelTurfId },
    },
    doesCurrentUserHavePermission,
  } = useCurrent()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  const canViewShifts = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'view',
  })

  if (!canViewShifts) {
    return (
      <CardError
        hide={canViewShifts}
        message={t('You do not have permission to view this page')}
        hideSupportLink
      />
    )
  }

  const [turfId, setTurfId] = useState(topLevelTurfId)
  const [shiftType, setShiftType] = useState('all')

  useEffect(() => {
    refreshCurrentTurfs({
      associations: [''],
      fields: ['id', 'name', 'parent_id', 'archived'],
      indexed: true,
    })
  }, [])

  const [dateRange, setDateRange] = useState(null)
  const { makeRequest, isLoading, errorMsg, response } =
    DEPRECATED_useRequest(fetchShifts)

  useEffect(() => {
    dateRange &&
      makeRequest(
        buildParams({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          turfId,
          shiftType,
        })
      )
  }, [dateRange, turfId, shiftType])

  const shifts = (response?.shifts || []).map(buildShifts)

  return (
    <View>
      <HeaderBlock title={t('Shift Calendar')} />
      <ViewContainer>
        <Calendar
          items={shifts}
          isLoading={isLoading || turfRefreshIsLoading}
          errorMsg={errorMsg}
          onRangeChange={setDateRange}
          renderItem={ShiftCalendarItem}
          renderFilters={() => (
            <FieldBlock>
              <TurfSelectField label={t('Turf')} onSelect={setTurfId} />
              <SelectField
                id="shift-type"
                label={t('Shift type')}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'petitions',
                    label: 'Petitions',
                  },
                  {
                    value: 'voter_registration',
                    label: 'Voter Registration',
                  },
                ]}
                value={shiftType}
                onSelect={setShiftType}
              />
            </FieldBlock>
          )}
          dateKey="shift_start"
          WeekDownload={ShiftCalendarWeekDownload}
        />
        <ShiftCalendarLegend />
      </ViewContainer>
    </View>
  )
}

export default ShiftCalendar
